import React, { useState } from 'react';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../Style/StyleSettings';
import { useNotifications } from '../../../Notifications/NotificationContext';
import NoData from '../../../components/NoData';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../../components/datagridComp/SideBar';
import { handleSearch } from '../../../utils/searchUtils';
import { getListContracts } from '../repository/SettingsRepo';
import { getColumnsContracts } from '../Utils/ContractColumns';



const ContractsTab: React.FC = () => {

    const { addNotification } = useNotifications();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<any[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);

    const columns = getColumnsContracts();

    async function getListInServer() {
        try {
            const result = await getListContracts();
            setRows(result);

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((item) => item.id));
        setSelectedRows(allIds);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }



    React.useEffect(() => {
        getListInServer();
    }, []);

    return (
        <>
            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                    <BoxIcon icon={faAdd} className="fa-icon" /> Criar Contrato
                </BtnFaIcon >

            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
            </ContainerInfo>
            {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}
        </>
    )

}
export default ContractsTab