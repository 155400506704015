


import React, { useState, useEffect, useRef } from 'react';
import JoditEditor from 'jodit-react';
import styled from 'styled-components';
import { iTemplateForm, iTemplateScreen } from '../repository/TemplateRepo';
import { v4 } from 'uuid';
import { add } from 'date-fns';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { copyOptions, saveFormatOptions, WHATSAPP } from '../Utils/OptionsTemplates';

interface TemplateInlineProps {
    initialData?: iTemplateScreen;
    onUpdate?: (item: iTemplateScreen) => void;
    onCancel?: () => void;
}

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ControlGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const TextInput = styled.input`
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const TemplateInline: React.FC<TemplateInlineProps> = ({ initialData, onUpdate, onCancel }) => {
    const { addNotification } = useNotifications();
    const editor = useRef(null);
    const [message, setMessage] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [saveFormat, setSaveFormat] = useState<string>(WHATSAPP);

    const config: any = {
        readonly: false,
        toolbarSticky: false,
        minHeight: 400,
        language: 'pt_br',
        enter: 'br',
        buttons: [
            'bold', 'italic', 'underline', 'copy', 'paste', 'cut',
            'link', 'image', 'ul', 'ol', 'table', 'left', 'center', 'right', 'undo', 'redo'
        ],
        toolbarAdaptive: false,
    };



    // Função para remover tags HTML, caso seja necessário salvar como texto simples
    /*  const stripHtml = (html: string) => {
          const doc = new DOMParser().parseFromString(html, 'text/html');
          return doc.body.textContent || '';
      };*/

    const handleSave = async () => {
        if (!message) {
            addNotification('error', 'O conteúdo da mensagem não pode estar vazio', 'error');
            return;
        }
        if (!description) {
            addNotification('error', 'A descrição do template não pode estar vazia', 'error');
            return;
        }
        const data: iTemplateScreen = {
            idEmpresa: initialData?.idEmpresa || 0,
            unique_id: initialData?.unique_id || v4(),
            template_type: saveFormat,
            template_html: message,
            template_description: description,
            id: 0,
            created_at: new Date(),
        };
        onUpdate && onUpdate(data);

    };

    useEffect(() => {
        if (initialData) {
            console.log(initialData)
            setMessage(initialData.template_html)
            setDescription(initialData.template_description)
            setSaveFormat(initialData.template_type || WHATSAPP);
        } else {
            setMessage('');
            setDescription('');
            setSaveFormat(WHATSAPP);
        }
    }, [initialData]);

    const handleCancel = () => {
        if (onCancel) onCancel();
        else {
            setMessage('');
            setDescription('');
            setSaveFormat(WHATSAPP);
        }
    };

    // Função para copiar o placeholder específico para a área de transferência
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
    };

    return (
        <EditorContainer>
            <ControlGroup>
                {/* Botões de Salvar e Cancelar */}
                <Button onClick={handleSave}>Salvar</Button>
                <Button onClick={handleCancel}>Cancelar</Button>

                {/* Campo de descrição */}
                <TextInput
                    type="text"
                    placeholder="Descrição do template"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </ControlGroup>
            {/* Controles na parte superior */}
            <ControlGroup>


                <Select onChange={(e) => copyToClipboard(e.target.value)} defaultValue="">
                    {copyOptions.map((option) => (
                        <option key={option.value} value={option.value} disabled={option.disabled}>
                            {option.label}
                        </option>
                    ))}
                </Select>

                <Select value={saveFormat} onChange={(e) => setSaveFormat(e.target.value)}>
                    {saveFormatOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            </ControlGroup>

            {/* Editor de mensagem */}
            <JoditEditor
                ref={editor}
                value={message}
                config={config}
                onBlur={newContent => setMessage(newContent)}
                onChange={() => { }}
            />
        </EditorContainer>
    );
};

export default TemplateInline;
