import React from 'react';
import { Stack, Card, IconButton, Box } from '@mui/material';
import { Save, Cancel } from '@mui/icons-material';
import { ctrlBarUtilsButtons } from '../interfaces/interfaces'
import './CompStyles.css'

interface BarUtilsProps {
    onSaveClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onCancelClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    CtrlButtons?: ctrlBarUtilsButtons
}



const BarUtils: React.FC<BarUtilsProps> = (props) => {

    return (
        <>
            <Card>
                <div className='boxCard'>
                    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">

                        {props.CtrlButtons?.SaveShow ? <IconButton aria-label="save" disabled={props.CtrlButtons?.SaveDisable} color="primary" onClick={props.onSaveClick}>
                            <Save />
                        </IconButton> : <></>}
                        {props.CtrlButtons?.CancelShow ? <IconButton aria-label="cancel" disabled={props.CtrlButtons?.CancelDisable} color="error" onClick={props.onCancelClick}>
                            <Cancel />
                        </IconButton> : <></>}
                    </Stack>
                </div>
            </Card>
        </>
    )

}
export default BarUtils