import React from 'react';
import TabButton from '../TabButton';
import { MapConfig } from '../../utilsMap/mapContext';



const ToolBarButtonsTabHistory: React.FC = () => {
    const { tabHistoryActive, countAlerts, countEvents, countPositions, updateConfig } = MapConfig();

    const setTabActive = (tab: string) => {
        updateConfig({ tabHistoryActive: tab });
    };

    return (
        <>
            {/*<TabButton
                title="ALERTAS"
                isActive={tabHistoryActive === "TAB_HISTORY_ALERTS"}
                onClick={() => setTabActive("TAB_HISTORY_ALERTS")}
                badgeContent={countAlerts}
            />*/}
            <TabButton
                title="PARADAS"
                isActive={tabHistoryActive === "TAB_HISTORY_EVENTS"}
                onClick={() => setTabActive("TAB_HISTORY_EVENTS")}
                badgeContent={countEvents}
            />
            <TabButton
                title="POSIÇÕES"
                isActive={tabHistoryActive === "TAB_HISTORY_TRAJETOS"}
                onClick={() => setTabActive("TAB_HISTORY_TRAJETOS")}
                badgeContent={countPositions}
            />
        </>
    )

}
export default ToolBarButtonsTabHistory