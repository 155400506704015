import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AvatarProps {
    icon: IconProp;
}

const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #bbbbbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarCuston: React.FC<AvatarProps> = ({ icon }) => {
    return (
        <StyledAvatar>
            <FontAwesomeIcon icon={icon} />
        </StyledAvatar>
    );
};

export default AvatarCuston;
