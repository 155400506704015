import { httpAxiosV4 } from "../../../gateways/Axios";



// Função para fazer o upsert de um template
export async function upsertTemplate(templateData: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>("templates", "POST", templateData);
    return data;
}

// Função para soft delete de um template
export async function softDeleteTemplate(uniqueId: string): Promise<any> {
    const { data } = await httpAxiosV4<any, any>("templates/soft-delete", "POST", { uniqueId });
    return data;
}

// Função para hard delete de um template
export async function hardDeleteTemplate(uniqueId: string): Promise<any> {
    const { data } = await httpAxiosV4<any, any>("templates/hard-delete", "POST", { uniqueId });
    return data;
}

// Função para buscar templates por idEmpresa, com filtro opcional de templateType
export async function getTemplatesByEmpresa(templateType?: string): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(`templates${templateType ? `?templateType=${templateType}` : ''}`, "GET");
    return data;
}


export interface iTemplateForm {
    idEmpresa: number;                // INT NOT NULL in SQL -> number
    uniqueId: string;                 // UUID UNIQUE in SQL -> string
    templateType: string;             // VARCHAR(50) NOT NULL in SQL -> string
    templateHtml: string;             // TEXT NOT NULL in SQL -> string
    templateDescription: string;     // TEXT in SQL -> string | null | undefined
}


export interface iTemplateScreen {
    id: number;                       // SERIAL PRIMARY KEY in SQL -> number
    idEmpresa: number;                // INT NOT NULL in SQL -> number
    unique_id: string;                 // UUID UNIQUE in SQL -> string
    template_type: string;             // VARCHAR(50) NOT NULL in SQL -> string
    template_html: string;             // TEXT NOT NULL in SQL -> string
    template_description: string;     // TEXT in SQL -> string | null | undefined
    created_at: Date;                  // TIMESTAMP DEFAULT NOW() in SQL -> Date
    updated_at?: Date | null;          // TIMESTAMP DEFAULT NULL in SQL -> Date | null | undefined

}


