import React, { createContext, useContext, useRef, useEffect, useState, ReactNode } from 'react';
import { loadMapApi } from '../utils/GoogleMapsApi';
import LoadingGoogleMaps from './LoadingGoogleMaps';

// Crie um contexto para o Google Maps
const GoogleMapContext = createContext<google.maps.Map | null>(null);

// Definir o tipo das props para o provider
interface GoogleMapProviderProps {
    children: ReactNode;  // Define que `children` é do tipo `ReactNode`
}

// Hook para acessar o contexto
export const useGoogleMap = () => {
    return useContext(GoogleMapContext);
};

// Provedor do contexto
export const GoogleMapProvider: React.FC<GoogleMapProviderProps> = ({ children }) => {
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const mapRef = useRef<google.maps.Map | null>(null);

    useEffect(() => {
        const loadMap = async () => {
            try {
                const googleMaps = await loadMapApi();
                if (!mapRef.current) {
                    const mapDiv = document.createElement('div');
                    mapDiv.style.height = '100%';  // Definindo a altura
                    mapDiv.style.width = '100%';   // Definindo a largura

                    const map = new googleMaps.Map(mapDiv, {
                        center: { lat: -15.7801, lng: -47.9292 },
                        zoom: 4,
                        mapTypeControl: true,
                        streetViewControl: false,
                        rotateControl: true,
                        scaleControl: true,
                        fullscreenControl: true,
                        panControl: true,
                        zoomControl: true,
                        mapId: 'ec009120bd8c9ea',
                        mapTypeControlOptions: {
                            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: google.maps.ControlPosition.BOTTOM_LEFT,
                        },
                        fullscreenControlOptions: {
                            position: google.maps.ControlPosition.TOP_RIGHT,
                        },
                        gestureHandling: 'greedy',// 'cooperative',//controla o zoom do mapa sem o CTRl
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                    });
                    mapRef.current = map;
                    setIsMapLoaded(true);
                }
            } catch (error) {
                console.error('Error loading Google Maps:', error);
            }
        };

        loadMap();
    }, []);

    return (
        <GoogleMapContext.Provider value={mapRef.current}>
            {isMapLoaded ? children : <LoadingGoogleMaps />}
        </GoogleMapContext.Provider>
    );
};
