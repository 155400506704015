import React, { useEffect, useState } from 'react';
import DeliveryProgressChart from '../Components/DeliveryProgressChart';
import { styled } from 'styled-components';
import DeliveryProgressChartGeral from '../Components/DeliveryProgressChartGeral';
import { DeliveryData, getRankingApi } from '../repository/DeliveryRepo';
import ListTaskRouterComp from '../Components/ListTaskRouterComp';
import { SearchAndSelectContainer } from '../../ComandsCreator/StyleCommands';
import SearchField from '../../../components/datagridComp/SearchField';
import { Select } from '@mui/material';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import SelectFormInput from '../../../components/datagridComp/SelectFormInput';
import { set } from 'date-fns';

const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
`;
const TableContainer = styled.div`
    display:block;
`;

let intervalMap: any

const optionsActions = [
    { value: 'ALL', label: 'Todas as Rotas' },
    { value: 'ACCEPTED', label: 'Rotas Aceitas' },
    { value: 'REFUSED', label: 'Rotas Recusadas' },
    { value: 'FINISHED', label: 'Rotas Finalizadas' },
    { value: 'WAIT', label: 'Rotas Em Espera' },
];

let filterSelected = 'ALL'

const RankingDeliveryView: React.FC = () => {


    const [selectedDelivery, setSelectedDelivery] = useState<DeliveryData | null>(null);
    const [ranking, setRanking] = useState<any[]>([]);
    const [selectFilterValue, setSelectFilterValue] = React.useState<string>(filterSelected);


    const handleSelect = (item: DeliveryData) => {
        setSelectedDelivery(item);
    };
    // Calcular o total geral
    const totalTarefas = ranking.reduce((acc, data) => acc + data.num_opened + data.num_canceled + data.num_finished, 0);
    const totalFinalizadas = ranking.reduce((acc, data) => acc + data.num_finished, 0);
    const totalCanceladas = ranking.reduce((acc, data) => acc + data.num_canceled, 0);


    const getRanking = async () => {
        const result = await getRankingApi(filterSelected);
        setRanking(result);
    }

    React.useEffect(() => {

        getRanking()
        intervalMap = setInterval(() => {
            getRanking()
        }, 5000)


        //componentUnAmount()
        return () => {
            console.log("Destruct RankingView")
            clearInterval(intervalMap)
        }
    }, [])

    const handleSelectAction = (action: string) => {
        console.log("Ação selecionada:", action);
        setSelectFilterValue(action)
        filterSelected = action
    };


    return (
        <div>
            {!selectedDelivery && (<>
                <DeliveryProgressChartGeral
                    totalTarefas={totalTarefas}
                    totalFinalizadas={totalFinalizadas}
                    totalCanceladas={totalCanceladas}
                />
                <SearchAndSelectContainer>
                    <SelectFormInput
                        options={optionsActions}
                        onSelect={handleSelectAction}
                        title='Selecione um Filtro'
                        selected={selectFilterValue} />
                </SearchAndSelectContainer>
            </>)}
            {selectedDelivery && (

                <button
                    className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors"
                    onClick={() => {
                        setSelectedDelivery(null)
                    }}>
                    Voltar
                </button>
            )}
            <CardsContainer>
                {selectedDelivery && (
                    <DeliveryProgressChart
                        key={selectedDelivery.id}
                        deliveryData={selectedDelivery}
                        onClick={() => { }}
                        selected={true}
                    />
                )}
                {!selectedDelivery && ranking.map((deliveryData) => (
                    <DeliveryProgressChart
                        key={deliveryData.id}
                        deliveryData={deliveryData}
                        onClick={() => handleSelect(deliveryData)}
                        selected={selectedDelivery === deliveryData.id}
                    />
                ))}
            </CardsContainer>
            {selectedDelivery && (
                <TableContainer>
                    <ListTaskRouterComp id={selectedDelivery.id} />
                </TableContainer>)}


        </div>
    );

}
export default RankingDeliveryView