import React from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons';

interface ButtonReportCardProps {
  title: string;
  isNew?: boolean;
  Icon: IconType; // Tipagem para ícones do FontAwesome ou de outras bibliotecas de ícones
  selected: boolean; // Indica se o card está selecionado
  onClick: () => void; // Função chamada ao clicar no card
}

const ButtonReportCard: React.FC<ButtonReportCardProps> = ({ title, isNew, Icon, selected, onClick }) => {
  return (
    <CardContainer onClick={onClick} selected={selected}>
      {isNew && <NewBadge> Novo </NewBadge>}
      <IconContainer>
        <Icon size={30} />
      </IconContainer>
      <Title>{title}</Title>
    </CardContainer>
  );
};

export default ButtonReportCard;

// Styled Components

const CardContainer = styled.button<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: #fff;
  border: ${(props) => (props.selected ? '2px solid #00c853' : '1px solid #e5e5e5')};
  border-radius: 8px;
  position: relative;
  padding: 16px;
  box-shadow: ${(props) => (props.selected ? '0 4px 8px rgba(0, 200, 83, 0.3)' : 'none')};
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease, border 0.3s ease;
  outline: none;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  &:focus {
    box-shadow: 0 0 0 4px rgba(0, 128, 255, 0.3);
  }
`;

const NewBadge = styled.span`
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: #00c853;
    color: #fff;
    font-size: 11px;
    padding: 1px 6px;
    border-radius: 9px;
    font-weight: bold;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  color: #7e7e7e;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 0;
`;
