import React from 'react';
import styled from 'styled-components';

// Interface das propriedades do tanque
export interface TankProps {
  level: number;        // Nível de preenchimento do tanque (em litros)
  capacity: number;     // Capacidade máxima do tanque (em litros)
  minStock: number;     // Estoque mínimo (em litros)
  color: string;        // Cor do líquido
  width: number;        // Largura do tanque
  height: number;       // Altura do tanque
  label: string;        // Descrição do tanque
}

// Componente FuelTankComp
const FuelTankComp: React.FC<TankProps> = ({ level, capacity, minStock, color, width, height, label }) => {
  // Cálculo da porcentagem interna com base nos litros fornecidos
  const levelPercentage = (level / capacity) * 100;
  const liquidHeight = (level / capacity) * height;  // Altura proporcional do líquido
  const minStockHeight = (minStock / capacity) * height; // Altura da linha do estoque mínimo

  // Se o nível estiver abaixo do estoque mínimo, a cor do líquido será vermelha
  const liquidColor = level < minStock ? 'red' : color;

  return (
    <TankWrapper width={width} height={height}>
      {/* Capacidade máxima dentro do tanque */}
      <MaxCapacityText>{capacity}L</MaxCapacityText>

      <CylinderBody>
        <LiquidFill color={liquidColor} height={liquidHeight} />
        {/* Linha indicando o estoque mínimo */}
        <MinStockLine height={minStockHeight}>
          Estoque Mínimo: {minStock}L
        </MinStockLine>
      </CylinderBody>

      {/* Texto mostrando o nível atual em litros e porcentagem */}
      <LevelText>
        {Math.round(level)}L ({Math.round(levelPercentage)}%)
      </LevelText>
      <TankLabel>{label}</TankLabel> {/* Descrição do tanque */}
    </TankWrapper>
  );
};

// Estilização do tanque
const TankWrapper = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height + 70}px; /* Incluindo a altura do texto de capacidade máxima e descrição */
`;

// Corpo do tanque
const CylinderBody = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #ccc;
  overflow: hidden;
`;

// Preenchimento do líquido dentro do tanque
const LiquidFill = styled.div<{ color: string; height: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  background: ${(props) => props.color};
  transition: height 0.5s ease-in-out; /* Animação suave do nível */
`;

// Texto que mostra o nível do combustível (litros e porcentagem)
const LevelText = styled.div`
  position: relative;
    bottom: 150px;
    left: 50px;
  transform: translate(-50%, -50%);
  color: black; /* Texto central em preto */
  font-size: 20px;
  font-weight: bold;
  z-index: 1;
`;

// Linha que marca o estoque mínimo
const MinStockLine = styled.div<{ height: number }>`
  position: absolute;
  bottom: ${(props) => props.height}px;
  width: 100%;
  height: 2px;
  background-color: black; /* Linha preta */
  text-align: center;
  color: black;  /* Texto de estoque mínimo em preto */
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
`;

// Texto que exibe a capacidade máxima dentro do tanque
const MaxCapacityText = styled.div`
  position: relative;
  top: 25px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: black;  /* Garantindo que o texto fique preto e visível */
  z-index: 3;
  font-weight: bold;
`;

// Texto que exibe a descrição do tanque (na parte inferior)
const TankLabel = styled.div`
  margin-top: -25px;
  font-size: 16px;
  color: #333;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* ou uma largura específica se desejar limitar */
`;

export default FuelTankComp;
