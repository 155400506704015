import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { styled } from 'styled-components';
import { TrajectData } from '../../repository/MapsRepo';

interface SpeedTrajectsChartProps {
    data: TrajectData[];
}

// Card container styling
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  width: 315px;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SpeedTrajectsChart: React.FC<SpeedTrajectsChartProps> = ({ data }) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        let root = am5.Root.new(chartRef.current!);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true
            })
        );

        // Add a title
        chart.children.unshift(
            am5.Label.new(root, {
                text: "Gráfico de Velocidade",
                fontSize: 12,
                fontWeight: "400",
                x: am5.p50,
                centerX: am5.p50,
                y: 0,
                paddingTop: 20
            })
        );

        // Create X-Axis (Day of the month)
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: "dthr_equip",
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
                visible: false
            })
        );

        xAxis.data.setAll(data);

        // Create Y-Axis (Values)
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                numberFormat: "#a"
            })
        );
        // Create series for kmgps_dia (Bar chart)
        let kmSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "speed",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "speed",
                categoryXField: "dthr_equip",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY} km/h"
                })
            })
        );

        kmSeries.columns.template.setAll({
            width: am5.percent(50),
            tooltipText: "{categoryX}: {valueY} km"
        });

        kmSeries.data.setAll(data);


        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {
            //behavior: "zoomX"
        }));

        // Clean up chart on component unmount
        return () => {
            root.dispose();
        };
    }, [data]);

    return (
        <CardContainer>
            <div id="chartdiv" ref={chartRef} style={{ width: "100%", height: "150px" }}></div>
        </CardContainer>
    );
};

export default SpeedTrajectsChart;
