import { createSlice } from '@reduxjs/toolkit'
import { iReduxMap } from '../interfaces/interfaces'
import { ActionMaps } from '../utils/values'
import { RootState } from './store'




export const slice = createSlice({
    name: 'mapOptions',
    initialState: {} as iReduxMap,
    reducers: {
        changeMap(state, { payload }) {
            console.log(payload)
            return { ...state, ...payload }

        },
        resetMap(state, { payload }) {
            payload = { resetMap: true, ender: "", mapEvtClick: false, action: ActionMaps.CLEAR_MAP, marker: null, markers: [], response: null }
            return { ...state, ...payload }

        }
    }
})


export const { changeMap, resetMap } = slice.actions

export const selectMap = (state: RootState) => state

export default slice.reducer