import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import IconCancel from '../../../assets/icons/VectorCancel.png'
import IconDelivery from '../../../assets/icons/VectorDelivered.png'
import IconSpeed from '../../../assets/icons/VectorSpeed.png'
import IconChecked from '../../../assets/icons/Vectorchecked.png'
import { iPanelDeliveryInfo } from '../deliveryInterfaces';

//ASSETS

const Row = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    `

const Column = styled.div`
width: 100%;
    display: block;`

const Divider = styled.div`
    width: 100%;
    border: 0.5px solid #cfcfcf7d;
    `

const ImgAvatar = styled.img.attrs(props => ({
    src: `${props.src}`
}))
    `
width: 60px;
height: 60px;
border-radius: 50%;
border: 1px solid red;
display: flex;`


const ImgIcon = styled.img.attrs(props => ({
    src: `${props.src}`
}))
    `
padding-right: 5px;    
width: 22px;`



const FontBold16px = styled.div`
    font-size:16px;
    font-weight: 500;
    `
const Font12px = styled.div`
    font-size:12px;
    `
const ValueIcon = styled.div`
    font-size:14px;
    padding-right: 5px;
    `


const ContainerCard = styled.div`
      display: flex;
    flex-direction: column;
    padding: 8px;
    background: #ffffff;
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 15%);
    width: 330px;
`

const Button = styled.button`
    background: black;
    color: white;
    font-size: 1em;
    margin: 0 auto;
    padding: 0.45em 2em;
    border: 0;
    border-radius: 3px;
    display: block;
    margin-top: 25px;
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: var(--btn-hover-color) !important;
    }
`
/*
const initialDadosEquip: iPanelDeliveryInfo = {
    DriverName: 'RAFAEL SANTOS',
    DriverAvatar: 'pic-3.8f888b2b87406627cc6b.jpg',
    VehiclePlaca: 'KPD-9538',
    VehicleModel: 'HR',
    VehicleMarca: 'HYNDAY',
    idGroupDelivery: 106,
    numOpened: 5,
    numCanceled: 12,
    numFinished: 18,
    TimerEquip: 'ATUALIZADA A 10 MIN',
    TimerIgnEquip: 'PARADO A 50 MIN',
    IgnEquipStatusColor: '#CC00CC',
    TxEquipStatusColor: '#82e70f',
    SpeedEquipStatusColor: '#f8b706',
    SpeedEquip: 85
}*/

function GetUrlAvatar(filename: string) {
    //return `http://smart.maxtracer.com.br/img/drivers/${filename}`
    return process.env.REACT_APP_DRIVER_AVATAR_PATH + `/${filename}`
}



interface PropsComp {
    dataCard: iPanelDeliveryInfo
    onClickCard: (item: iPanelDeliveryInfo) => void
}


const CardDeliveryPanel: React.FC<PropsComp> = (props) => {

    return (
        <>
            <ContainerCard onClick={() => props.onClickCard(props.dataCard)}>
                <Row>
                    <ImgAvatar src={GetUrlAvatar(props.dataCard.DriverAvatar)}></ImgAvatar>
                    <Column>
                        <FontBold16px>{props.dataCard.VehiclePlaca}</FontBold16px>
                        <Font12px>{props.dataCard.VehicleMarca}-{props.dataCard.VehicleModel}</Font12px>

                        <Divider></Divider>
                        <Row>
                            <FontBold16px>{props.dataCard.DriverName}</FontBold16px>
                            <div>Nº {props.dataCard.idGroupDelivery}</div>
                        </Row>
                    </Column>
                </Row>
                <Divider></Divider>
                <Row>
                    <ImgIcon src={IconDelivery}></ImgIcon>
                    <ValueIcon>{props.dataCard.numOpened}</ValueIcon>
                    <ImgIcon src={IconCancel}></ImgIcon>
                    <ValueIcon>{props.dataCard.numCanceled}</ValueIcon>
                    <ImgIcon src={IconChecked}></ImgIcon>
                    <ValueIcon>{props.dataCard.numFinished}</ValueIcon>

                </Row>
                <Divider></Divider>
                {/*} <Row>
                    <Font12px>{props.dataCard.dtEquip}</Font12px>
                    <Font12px>{props.dataCard.dtIgn}</Font12px>
                    <ImgIcon src={IconSpeed}></ImgIcon>
                    <ValueIcon>{props.dataCard.SpeedVehicle}</ValueIcon>
    </Row>*/}
            </ContainerCard>
        </>
    )

}
export default CardDeliveryPanel 