import styled from 'styled-components';

export const ContainerBlock = styled('div')({
    display: 'block'
});

export const ContainerFlexInline = styled('div')({
    display: 'inline-flex'
});

export const ContainerTable = styled.div`
padding: 6px;
    height: calc(100vh - 210px);
    overflow: auto;
`