import { Column, SelectColumn } from 'react-data-grid';
import { iMsgLogs } from '../repository/MessagesRepo';
import { formatDateToScreenString } from '../../../utils/Converter';


export const getColumnsMessages = (
    editItem: (row: iMsgLogs) => void
): Column<iMsgLogs>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        // Coluna para o status do log
        {
            key: 'status_log',
            name: 'Status',
            width: 150
        },
        // Coluna para o código (ID) do cliente
        {
            key: 'idcliente',
            name: 'Cód Cliente',
            width: 100
        },
        {
            key: 'cliente_nome',
            name: 'Cliente',
            width: 150
        },
        // Coluna para o código (ID) do registro
        {
            key: 'idrecord',
            name: 'Cód Registro',
            width: 90
        },
        // Coluna para a solicitação
        {
            key: 'request',
            name: 'Solicitação',
            width: 180
        },
        // Coluna para o contato
        {
            key: 'contact',
            name: 'Contato',
            width: 150
        },
        // Coluna para a data de criação, com formatação personalizada
        {
            key: 'created_at',
            name: 'Data de Criação',
            width: 150,
            renderCell(props: any) {
                return formatDateToScreenString(props.row.created_at);
            },
        },

        // Coluna para a data de atualização, com formatação personalizada
        {
            key: 'updated_at',
            name: 'Data de Atualização',
            width: 150,
            renderCell(props: any) {
                return formatDateToScreenString(props.row.created_at);
            },
        },
        // Coluna para o tipo de envio
        {
            key: 'type_send',
            name: 'Tipo de Envio',
            width: 120
        },
        // Coluna para a descrição
        {
            key: 'descr',
            name: 'Descrição',
            width: 200
        },

        // Coluna para informações adicionais
        {
            key: 'info',
            name: 'Informações',
            width: 200
        },
        // Coluna para a mensagem (conteúdo extenso)
        {
            key: 'msg',
            name: 'Mensagem',
            width: 300
        },
        // Coluna para o identificador único
        {
            key: 'unique_id',
            name: 'ID Único',
            width: 200
        },

    ];
};
