import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { loadMapApi } from '../../../utils/GoogleMapsApi';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { GeocoderService } from '../../../GoogleMaps/GeocoderService';
import { DeliveryConfig, iMarkerPoint } from '../utilsDelivery/DeliveryProvider';
import { add } from 'date-fns';
import { iMarker } from '../../../interfaces/interfaces';

const ContainerMaps = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #f5f5f5;
  padding: 5px;
  height: 93vh;
`;

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;



const MapRouter: React.FC = () => {
    const { RouterInMap } = DeliveryConfig();
    const map = useGoogleMap();
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const markersRef = useRef<google.maps.Marker[]>([]);
    const polyLineRef = useRef<google.maps.Polyline>();

    useEffect(() => {
        if (map && mapContainerRef.current) {
            map.setOptions({
                center: { lat: -15.7801, lng: -47.9292 }, // Ajuste conforme necessário
                zoom: 4,
            });
            mapContainerRef.current.appendChild(map.getDiv());
            google.maps.event.trigger(map, 'resize');
        }
    }, [map]);

    const clearAllItensMap = () => {
        if (markersRef.current) {
            markersRef.current.forEach(marker => {
                marker.setMap(null)
            });
        }
        if (polyLineRef.current) {
            polyLineRef.current.setMap(null)
        }
        markersRef.current = []
        polyLineRef.current = undefined
    }


    useEffect(() => {
        if (RouterInMap) {
            clearAllItensMap()
            addListMarker(RouterInMap.points)
            addPolyline(RouterInMap.polyline)
        }
    }, [RouterInMap]);

    useEffect(() => {
        console.log('MapRouter Open')

        return () => {
            console.log('MapRouter Close')
            clearAllItensMap()
        }
    }, []);


    const addListMarker = (mkrs: iMarker[]): void => {
        if (mkrs.length > 0) {
            let bounds = new google.maps.LatLngBounds();

            mkrs.forEach(mkr => {
                const position = new google.maps.LatLng(mkr.position);
                const icon = {
                    url: mkr.icon ?? '', // url
                    scaledSize: new google.maps.Size(40, 40), // scaled size
                    origin: new google.maps.Point(0, 0), // origin
                    anchor: new google.maps.Point(20, 20) // anchor
                };
                let new_marker = new google.maps.Marker({
                    position,
                    map: map,
                    label: {

                        text: mkr.label ?? '',
                        className: 'marker-label',

                    },
                    draggable: mkr.isDragable
                });
                if (mkr.icon) {
                    new_marker.setIcon(icon)
                }
                bounds.extend(position);
                markersRef.current.push(new_marker)
            });
            setTimeout(() => {
                map?.fitBounds(bounds);
            }, 1000);

        }
    }

    const addPolyline = (polyline: string | undefined): void => {
        if (polyline && polyline.length > 0) {
            let LatLngArray: google.maps.LatLng[] = google.maps.geometry.encoding.decodePath(polyline)
            polyLineRef.current = new google.maps.Polyline({
                path: LatLngArray,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: map
            });
        }
    }

    return (
        <ContainerMaps>
            <MapContainer ref={mapContainerRef} />
        </ContainerMaps>
    );

}
export default MapRouter