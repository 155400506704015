import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaSpinner, FaExchangeAlt } from 'react-icons/fa';
import { iDetailsVehicleData } from '../repository/MapsRepo';
import { useNotifications } from '../../../Notifications/NotificationContext';
import ClientIcon from './ClientIcon';
import ModalListClients from './modals/ModalListClients';
import ConfirmationDialog from '../../../components/datagridComp/ConfirmationDialog';
import ModalComp from '../../../components/datagridComp/ModalComp';
import { httpAxiosV4 } from '../../../gateways/Axios';
import LoadingIndicator from '../../../components/datagridComp/LoadingComp';

// Interfaces
interface InfoClientMapsProps {
  item: iDetailsVehicleData;
  onSync?: () => void;
}

// Styled Components
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  width: 315px;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.h3`
  font-size: 12px;
  color: #666;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BoxHeader = styled.div`
  margin-left: 8px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #c5c5c5;
  margin: 1px 0;
`;

const BoxActions = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 6px;
    `;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 0 10px;
  color: #555;
  font-weight: 600;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  gap: 4px; /* Espaço entre o ícone e o texto */

  &:hover {
    color: #000;
  }

  svg {
    font-size: 16px; /* Ajusta o tamanho do ícone */
  }
`;

const InfoClientMaps: React.FC<InfoClientMapsProps> = ({ item, onSync }) => {

  const [isOpenListModal, setIsOpenListModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado de loading durante o upload
  const { addNotification } = useNotifications();
  const [selectedClient, setSelectedClient] = useState<any>(null);


  const handleSelectItem = async (item: any) => {
    setSelectedClient(item)
  };

  // Função para buscar os dados da API
  async function sendActionAlterVehicle(payload: any): Promise<any> {
    try {
      setIsLoading(true);
      const { data } = await httpAxiosV4<any, any>(`vehicles/alter-client`, "PUT", payload);
      return data;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }

  }

  const handleConfirmed = async () => {
    try {
      setIsOpenConfirmModal(false)
      let payload = {
        idclienteOld: item.idcliente,
        idcliente: selectedClient.id,
        idveiculo: item.idveiculo
      }
      await sendActionAlterVehicle(payload)
      addNotification(
        'Alteração realizada com sucesso',
        `Veículo placa [${item.veiculo_placa}] movido para o cliente ${selectedClient.name}`,
        'success'
      );
      onSync?.();
    } catch (error) {
      addNotification(
        'Erro ao realizar a alteração',
        `Erro ao mover o veículo placa [${item.veiculo_placa}] para o cliente ${selectedClient.name}`,
        'error'
      );
    }
  };



  useEffect(() => {
    if (selectedClient) {
      setIsOpenConfirmModal(true);
    }
  }, [selectedClient]);

  const handleAlterClick = () => {
    setIsOpenListModal(true);
    setSelectedClient(null);
  };

  return (
    <>
      <CardContainer>
        <Subtitle>Cliente ( Cód.: {item.idcliente} )</Subtitle>
        <Header>
          <ClientIcon />
          <BoxHeader>
            <Title>{item.client_name}</Title>
            {item.client_address && <Subtitle>{item.client_address}</Subtitle>}
            <Subtitle>Tel.:{item.client_phone}</Subtitle>
          </BoxHeader>
        </Header>
        <Divider />
        <BoxActions>
          <ActionButton onClick={handleAlterClick} disabled={isLoading}>
            <FaExchangeAlt /> Alterar
          </ActionButton>
        </BoxActions>
      </CardContainer>
      {isOpenListModal && <ModalListClients
        onClose={() => { setIsOpenListModal(false) }}
        onSelectAlert={handleSelectItem} />}
      {isOpenConfirmModal && <ModalComp title="Confirmar Alteração" subtitle="" onClose={() => setIsOpenConfirmModal(false)}>
        <ConfirmationDialog
          type={'QUESTION'}
          onCancel={() => setIsOpenConfirmModal(false)}
          onConfirm={handleConfirmed}
          message={`Confirme a migração do veículo placa [${item.veiculo_placa}], movendo do cliente ${item.client_name}, para o cliente ${selectedClient?.name}`} />
      </ModalComp>}
      {isLoading && <ModalComp title="Aguarde..." subtitle="" onClose={() => { }}>
        <LoadingIndicator message='Salvando alterações...' />
      </ModalComp>}
    </>
  );
};

export default InfoClientMaps;
