import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { TAB_HISTORY_EVENTS } from './ConstMaps';
import { VehicleData } from '../../StreamCam/StreamInterfaces';

type ConfigMap = {
    showPhotoDriverInMap: boolean,
    tabActive: string
    tabHistoryActive: string
    modeActive: string
    actionPressButton: string | null
    searchVehicles: string
    countAlerts: number
    countEvents: number
    countPositions: number
    filterApplyInMap: boolean
    idVehicleSel: number
    vehicleSelected: VehicleData | null
    updateConfig: (newConfig: Partial<ConfigMap>) => void;
};

const defaultConfig: ConfigMap = {
    showPhotoDriverInMap: true,
    tabActive: 'MAPA',
    modeActive: 'ALL_VEHICLES',
    tabHistoryActive: TAB_HISTORY_EVENTS,
    actionPressButton: null,
    searchVehicles: '',
    updateConfig: () => { },
    countAlerts: 0,
    countEvents: 0,
    countPositions: 0,
    filterApplyInMap: true,
    idVehicleSel: 0,
    vehicleSelected: null
};

const MapContext = createContext<ConfigMap>(defaultConfig);

export const MapConfig = () => {
    return useContext(MapContext);
};

type MapProviderProps = {
    children: ReactNode;
};

export const MapProvider = ({ children }: MapProviderProps) => {
    const [config, setConfig] = useState(defaultConfig);

    const updateConfig = (newConfig: Partial<ConfigMap>) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...newConfig,
        }));
    };

    return (
        <MapContext.Provider value={{ ...config, updateConfig }}>
            {children}
        </MapContext.Provider>
    );
};
