
import moment from "moment";

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency', //currency,percent,decimal
    currency: 'BRL'
});

export function FormatterCurrency(value) {
    return currencyFormatter.format(value)
}

const currencyDecimal = new Intl.NumberFormat('pt-BR', {
    style: 'decimal', //currency,percent,decimal
});


export function FormatterDecimal(value) {
    let result = currencyDecimal.format(value)
    result = result.replace(/,/g, "");
    return result
}

export function FormatterDecimal2(value) {
    return currencyDecimal.format(value)
}

export function DateTimeFormatter(value) {
    return moment(value).format('DD-MM-YYYY HH:mm:ss');
}

export function DateTimeFormatterBd(value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
}

export function DateAndTimeFormatterBd(date, time) {
    return moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss');
}

export function TimeFormatter(value) {
    return moment(value).format('HH:mm:ss');
}


export function DateFormatter(value) {
    return moment(value).format('DD-MM-YYYY');
}


export function DateFormatterBd(value) {
    return moment(value).format('YYYY-MM-DD');
}

export function adicionarEscape(string) {
    let novaString = '';
    for (let i = 0; i < string.length; i++) {
        if (string[i] === '\\') {
            novaString += '\\\\';
        } else {
            novaString += string[i];
        }
    }
    return novaString;
}
export function removerEscape(string) {
    let novaString = '';
    for (let i = 0; i < string.length; i++) {
        if (string[i] === '\\' && string[i + 1] === '\\') {
            novaString += '\\';
            i++;
        } else {
            novaString += string[i];
        }
    }
    return novaString;
}



export function formatArea(area) {
    if (area > 1) {
        return `${area} km²`;
    } else {
        // Retorna em m² com zero casas decimais
        return `${Math.round(area)} m²`;
    }

}