import React from 'react';
import { styled } from 'styled-components';
import { useNotifications } from '../../../Notifications/NotificationContext';

import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { getSyncVersion } from '../../../utils/Converter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

import { Form } from '@unform/web';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as yup from 'yup';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import UploadImageForm, { ImageType } from '../../ExpensesPage/components/UploadImageForm';
import { saveExpense, uploadImageExpense } from '../../ExpensesPage/repository/ExpensesRepo';
import { iDriversStorage, iFuelTypeStorage, iVehicleStorage } from '../../../Config/StorageRepository';
import SelectVehiclesSmall from '../components/SelectVehiclesSmall';
import SelectDriverSmall from '../components/SelectDriverSmall';
import SelectSuppliersSmall, { iSupplier } from '../components/SelectSuppliersOrTanksSmall';
import SelectFuelTypeSmall from '../components/SelectFuelTypeSmall';
import { StorageConfig } from '../../../Config/StorageContext';
import { iFuelInvoicesScreen } from '../models/FuelListModels';
import SelectSuppliersOrTanksSmall from '../components/SelectSuppliersOrTanksSmall';

interface CompProps {
    uuid: string
    initialData?: iFuelInvoicesScreen
    closeForm: () => void
    updateList: () => void
}

const Container = styled.div`
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 750px;  
    gap: 5px;`;

const ContainerForm = styled.div`
    display: flex;
    margin: 20px auto;
    justify-content: center;
    flex-direction: column;
    max-width: 750px;
    min-width: 350px;  
    gap: 5px;`;

const ContainerInline = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 10px;`;

const ContainerBoxTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;`;

const ContainerInlinePicker = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;
    margin-bottom: 10px;
    `;

const ContainerSelect = styled.div`
    width: 100%;
    display: flex;
    `;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;


enum FuelField {
    Amount = 'amount',
    PriceUnit = 'priceUnit',
    TotalUnit = 'totalUnit'
}
interface IOption {
    value: string;
    label: string;
}

const optionsTypeVehicle: IOption[] = [
    { value: '1', label: 'Linha Leve' },
    { value: '2', label: 'Linha Pesada' },
    { value: '3', label: 'Máquinas' }

];

const optionsMetricsType: IOption[] = [
    { value: '1', label: 'Km' },
    { value: '2', label: 'Horas' },
];



const FormFuelView: React.FC<CompProps> = ({ initialData, closeForm, uuid, updateList }) => {
    const { fuelTypes, syncFuel, vehicles, syncVehicles, drivers, syncDrivers, suppliers, syncSuppliers, tanks, syncTanks } = StorageConfig();
    const formRef = React.useRef<FormHandles>(null)
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const [images, setImages] = React.useState<ImageType[]>([]);
    const [errors, setErrorsForms] = React.useState<FormErrors>({});
    const [dtEvt, setDtEvt] = React.useState<Dayjs | null>(dayjs(initialData?.evt_at));
    const [hrEvt, setHrEvt] = React.useState<Dayjs | null>(dayjs(initialData?.evt_at));
    const [metricsType, setMetricsType] = React.useState<number>(1);
    const [usedKmHour, setUsedKmHour] = React.useState<string>();
    const [avgFuel, setAvgFuel] = React.useState<string>();
    const [costKm, setCostKm] = React.useState<string>();



    const [driver, setDriver] = React.useState<iDriversStorage>();
    const [vehicle, setVehicle] = React.useState<iVehicleStorage>();
    const [fuel, setFuel] = React.useState<iFuelTypeStorage>();
    const [supplier, setSupplier] = React.useState<iSupplier>();

    const categSel = 1;
    const convertExpenseToDouble = (value: string): number => {
        const parsed = Number.parseFloat(value);
        return isNaN(parsed) ? 0 : parsed;
    };

    const extractFileName = (img: ImageType): string | null => {
        console.log('extractFileName', img)
        return img.fileLocal ? img.fileLocal.name : img.fileRemote ?? null;
    }

    const calculateFuel = (changedField: FuelField): void => {

        let amountValue = convertExpenseToDouble(formRef.current?.getFieldValue('amount'));
        let priceUnitValue = convertExpenseToDouble(formRef.current?.getFieldValue('price_unity'));
        let totalUnitValue = convertExpenseToDouble(formRef.current?.getFieldValue('qty'));

        switch (changedField) {
            case FuelField.Amount:
                if (priceUnitValue > 0) {
                    totalUnitValue = amountValue / priceUnitValue;
                    formRef.current?.setFieldValue('qty', totalUnitValue.toFixed(2))
                }
                break;
            case FuelField.PriceUnit:
                if (amountValue > 0) {
                    totalUnitValue = amountValue / priceUnitValue;
                    formRef.current?.setFieldValue('qty', totalUnitValue.toFixed(2))
                }
                break;
            case FuelField.TotalUnit:
                if (priceUnitValue > 0) {
                    amountValue = totalUnitValue * priceUnitValue;
                    formRef.current?.setFieldValue('amount', amountValue.toFixed(2))
                }
                break;
            default:
                console.log('calculateFuel', 'default')
                break;
        }
        calculateKm();
    };

    const checkIsKm = (): boolean => {
        return formRef.current?.getFieldValue('id_metrics_type') === '1';
    }

    const calculatCostKm = (usedKmHours: number): number => {
        let totalPayValue = convertExpenseToDouble(formRef.current?.getFieldValue('amount'));
        let cost = checkIsKm() ? totalPayValue > 0 ? totalPayValue / usedKmHours : 0 :
            totalPayValue > 0 ? totalPayValue / usedKmHours : 0;
        return cost;
    }

    const calculateAvgFuel = (usedKmHours: number): number => {
        let totalUnitValue = convertExpenseToDouble(formRef.current?.getFieldValue('qty'));
        let avg = checkIsKm() ? totalUnitValue > 0 ? usedKmHours / totalUnitValue : 0 :
            totalUnitValue > 0 ? totalUnitValue / usedKmHours : 0;
        return avg;
    }

    const calculateKm = (): void => {
        let startValue = convertExpenseToDouble(formRef.current?.getFieldValue('km_start')); // Hora inicial
        let endValue = convertExpenseToDouble(formRef.current?.getFieldValue('km_end')); // Hora final

        if (startValue > 0 && endValue > 0) {
            let usedKmHours = endValue - startValue; // Total de quilômetros/horas percorridos
            if (usedKmHours < 0) {
                setErrorsForms({
                    ...errors, kmAtual: checkIsKm() ?
                        'Km final deve ser maior que Km inicial' :
                        'Hora final deve ser maior que Hora inicial'
                });
            } else {
                let avg = calculateAvgFuel(usedKmHours)
                let cost = calculatCostKm(usedKmHours)
                setUsedKmHour(`${usedKmHours.toFixed(2)} ${checkIsKm() ? 'Km' : 'Horas'}`);
                setAvgFuel(`${avg.toFixed(2)} ${checkIsKm() ? 'Km/L' : 'L/Hora'}`);
                setCostKm(`${cost.toFixed(2)} ${checkIsKm() ? 'R$/Km' : 'R$/Hora'}`);
                setErrorsForms({ ...errors, kmAtual: undefined });
            }
        }

    };



    const handleSelectVehicle = (vehicle: iVehicleStorage | undefined): void => {
        setVehicle(vehicle)
        if (categSel === 1 && vehicle) {
            formRef.current?.setFieldValue('km_start', vehicle.km_last_abast)
            formRef.current?.setFieldValue('km_end', vehicle.odometer_vehicle)
            calculateKm();
        };
    }

    const submitForm: SubmitHandler<iFuelInvoiceForm> = async data => {
        data.id_categ = 1
        try {
            formRef.current?.setErrors({})
            let itemShape: any = {
                descr: yup.string().required("Esse campo é Obrigatório"),
                amount: yup.number().required("Esse campo é Obrigatório"),
            };
            if (Number(data.id_categ) === 1) {
                itemShape = {
                    ...itemShape,
                    price_unity: yup.number().required("Esse campo é Obrigatório"),
                    qty: yup.number().required("Esse campo é Obrigatório"),
                    km_start: yup.number().required("Esse campo é Obrigatório"),
                    km_end: yup.number().required("Esse campo é Obrigatório"),
                }
            }
            const schema = yup.object().shape(itemShape);
            await schema.validate(data, {
                abortEarly: false,
            });

            let newErrors: FormErrors = {};
            //if (images.length === 0) newErrors.images = "Adicione pelo menos uma imagem."

            let km_start = data.km_start ? data.km_start : 0
            let km_end = data.km_end ? data.km_end : 0
            let kmRunValue = 0
            let avgFuelValue = 0
            let costKm = 0
            if (km_start > 0 && km_end > 0) {
                kmRunValue = km_end - km_start;
                avgFuelValue = calculateAvgFuel(kmRunValue);
                costKm = calculatCostKm(kmRunValue);
            }

            if (!vehicle) {
                newErrors.vehicle = "Selecione um veículo"
            }
            if (data.id_categ === 1 && !fuel) {
                newErrors.fuel = "Selecione um tipo de combustível"
            }

            if (Object.keys(newErrors).length > 0) {
                setErrorsForms(newErrors)
                return;
            }

            let fuel_cod = fuel ? fuel.fuel_cod : 0;
            let fuel_descr = fuel ? fuel.descr : null;

            let internal_tank_id = supplier && supplier.type === 'tanks' ? supplier.identifier : null;
            let fornecedor_uuid = supplier && supplier.type === 'suppliers' ? supplier.identifier : null;

            let dtEvt = getDateEvt();
            let expense: iFuelInvoiceForm = {
                unique_id: initialData?.unique_id ? initialData.unique_id : uuidv4(),
                id_client: user.idcliente,
                id_empresa: user.idempresa,
                id_motorista: driver ? driver.id : 0,
                id_viagem: 0,
                id_veiculo: vehicle!.id,
                fornecedor_uuid: fornecedor_uuid?.toString() ?? null,
                internal_tank_id: Number(internal_tank_id ?? 0),
                id_categ: data.id_categ,
                descr: data.descr,
                amount: data.amount,
                expense_type: data.id_categ === 7 ? 'C' : 'D',
                status: data.status ? data.status : 'PENDENTE',
                lat: 0,
                lng: 0,
                photo1: images[0] ? extractFileName(images[0]) : null,
                photo2: images[1] ? extractFileName(images[1]) : null,
                photo3: images[2] ? extractFileName(images[2]) : null,
                km_start: km_start,
                km_end: km_end,
                km_run: kmRunValue,
                avg_fuel: avgFuelValue,
                cost_km: costKm,
                qty: data.qty ? data.qty : 0,
                price_unity: data.price_unity ? data.price_unity : 0,
                obs: null,
                fuel_cod,
                fuel_descr,
                tipo_payment_id: null,
                evt_at: dtEvt,
                sync_version: getSyncVersion(),
                id_metrics_type: data.id_metrics_type,
                id_vehicle_type: data.id_vehicle_type
            }
            console.log(expense)
            SaveExpenseFunc(expense)
        } catch (err) {
            console.log(err)
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }

    const uploadImages = async () => {
        try {
            for (let x = 0; x < images.length; x++) {
                if (images[x].fileLocal !== undefined) {
                    let file = images[x].fileLocal as File
                    let resp = await uploadImageExpense(file, user)
                    console.log('uploadImageExpense', resp)
                }
            }
            addNotification('', 'Imagens Salvas com sucesso', 'success');
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }

    const SaveExpenseFunc = async (expense: iFuelInvoiceForm) => {
        try {
            const result = await saveExpense(expense);
            addNotification('', 'Despesa salva com sucesso', 'success');
            formRef.current?.reset()
            if (images.length > 0) {
                await uploadImages()
            }
            updateList()
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }


    }



    const recoverInitialData = async () => {
        if (initialData) {
            let vehiclesLocal = vehicles.length === 0 ? await syncVehicles() : vehicles
            let driversLocal = drivers.length === 0 ? await syncDrivers() : drivers
            let fuelTypesLocal = fuelTypes.length === 0 ? await syncFuel() : fuelTypes
            let supplierLocal = suppliers.length === 0 ? await syncSuppliers() : suppliers
            let tanksLocal = tanks.length === 0 ? await syncTanks() : tanks

            let vehicle = vehiclesLocal.find(vehicle => vehicle.id === initialData.idvehicle)
            let driver = driversLocal.find(driver => driver.id === initialData.iddriver)
            let fuel = fuelTypesLocal.find(fuel => fuel.id === initialData.fuel_cod)
            if (initialData.internal_tank_id) {
                let supplier = tanksLocal.find(supplier => supplier.id === initialData.internal_tank_id)

                let supplierValue: iSupplier = {
                    identifier: supplier.id,
                    type: 'tanks',
                    descr: supplier.identifier ?? '',
                    current_volume: supplier.current_volume,
                    color_fuel: supplier.color_fuel
                }
                setSupplier(supplierValue)
            }
            if (initialData.fornecedor_uuid) {
                let supplier = supplierLocal.find(supplier => supplier.unique_id === initialData.fornecedor_uuid)
                let supplierValue: iSupplier = {
                    identifier: supplier.unique_id,
                    type: 'suppliers',
                    descr: supplier.name ?? '',
                    current_volume: 0,
                    color_fuel: ''
                }
                setSupplier(supplierValue)
            }
            setVehicle(vehicle)
            setDriver(driver)
            setFuel(fuel)
            calculateKm();
        }
    }


    React.useEffect(() => {
        if (initialData) {
            recoverInitialData()
        } else {
            setDriver(undefined)
            setVehicle(undefined)
            setFuel(undefined)
            setSupplier(undefined)
        }
        formRef.current?.setErrors({})
    }, [uuid])



    const getDateEvt = () => {
        if (!dtEvt || !hrEvt) return '';
        const hours = hrEvt.hour();
        const minutes = hrEvt.minute();
        const seconds = hrEvt.second();

        // Atualiza dtEvt com as horas, minutos e segundos extraídos
        const combinedDateTime = dtEvt.hour(hours).minute(minutes).second(seconds);

        // Retorna a data formatada como string em formato ISO local
        return combinedDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
    }

    const setImagesItens = () => {
        let images: ImageType[] = []
        if (initialData) {
            if (initialData.photo1) {
                images.push({ fileRemote: initialData.photo1 })
            }
            if (initialData.photo2) {
                images.push({ fileRemote: initialData.photo2 })
            }
            if (initialData.photo3) {
                images.push({ fileRemote: initialData.photo3 })
            }
        }
        return images
    }

    const handleSelectTypeMetrics = (type: number) => {
        calculateKm()
        setMetricsType(type)
    }


    return (
        <Form placeholder={""} ref={formRef} onSubmit={submitForm} noValidate={true} initialData={initialData}>

            <Container>
                <ContainerBoxTop>
                    <UploadImageForm onImagesChange={setImages} imageUrls={
                        setImagesItens()
                    } />
                    <ContainerInlinePicker>
                        <DatePicker
                            value={dtEvt}
                            label="Data da Despesa"
                            onChange={(newValue) => setDtEvt(newValue)}
                            format='DD/MM/YYYY'
                        />
                        <TimeField
                            value={hrEvt}
                            label="Hora da Despesa"
                            onChange={(newValue) => setHrEvt(newValue)}
                            format="HH:mm:ss"
                        />
                    </ContainerInlinePicker>
                </ContainerBoxTop>
                {errors.images && (
                    <ErrorMessage>
                        <FontAwesomeIcon icon={faExclamationCircle} /> {errors.images}
                    </ErrorMessage>)}
                <ContainerInline>
                    <ContainerForm>

                        <SelectVehiclesSmall
                            vehicle={vehicle}
                            updateItem={handleSelectVehicle}
                        />
                        {errors.vehicle && (
                            <ErrorMessage>
                                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.vehicle}
                            </ErrorMessage>)}
                        <SelectDriverSmall
                            driver={driver}
                            updateItem={setDriver}
                        />

                        {categSel === 1 && <SelectFuelTypeSmall
                            fuel={fuel}
                            updateItem={setFuel}
                        />}
                        {errors.fuel && (
                            <ErrorMessage>
                                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.fuel}
                            </ErrorMessage>)}


                        <SelectSuppliersOrTanksSmall
                            supplier={supplier}
                            updateItem={setSupplier}
                        />

                    </ContainerForm>
                    <ContainerForm>
                        <InputFieldForm
                            label="Descrição"
                            placeholder="Descrição"
                            name={'descr'}
                            type='text'
                        />
                        <InputFieldForm
                            label="Valor pago"
                            placeholder="Valor Pago"
                            name={'amount'}
                            type='number'
                            onChange={(e) => {
                                calculateFuel(FuelField.Amount);
                            }}
                        />
                        {categSel === 1 && <>
                            <ContainerInline>
                                <div>
                                    <InputFieldForm
                                        label="Preço / Litro"
                                        placeholder="Preço / Litro"
                                        name='price_unity'
                                        type='number'
                                        onChange={(e) => {
                                            calculateFuel(FuelField.PriceUnit);
                                        }}
                                    />
                                </div>
                                <div>
                                    <InputFieldForm
                                        label="Litros"
                                        placeholder="Litros"
                                        name={'qty'}
                                        type='number'
                                        onChange={(e) => {
                                            calculateFuel(FuelField.TotalUnit);
                                        }}
                                    />
                                </div>
                            </ContainerInline>
                            <ContainerInline>
                                <ContainerSelect>
                                    <SelectFieldForm name={'id_vehicle_type'} options={optionsTypeVehicle} label={'Tipo De Veículo'} />

                                </ContainerSelect>
                                <ContainerSelect>
                                    <SelectFieldForm
                                        name={'id_metrics_type'}
                                        options={optionsMetricsType}
                                        label={'Tipo De Cálculo'}
                                        onChange={(e) => {
                                            handleSelectTypeMetrics(Number(e.target.value) ?? 0)
                                        }} />
                                </ContainerSelect>
                            </ContainerInline>
                            <ContainerInline>
                                <div>
                                    <InputFieldForm
                                        label={metricsType === 1 ? "Km Inicial" : "Hora Inicial"}
                                        placeholder="km último abastecimento"
                                        name={'km_start'}
                                        type='number'
                                        onKeyUp={(e) => {
                                            calculateKm();
                                        }}
                                    />
                                </div>
                                <div>
                                    <InputFieldForm
                                        label={metricsType === 1 ? "Km Final" : "Hora Final"}
                                        placeholder="Km Atual"
                                        name={'km_end'}
                                        type='number'
                                        onKeyUp={(e) => {
                                            calculateKm();
                                        }}
                                    />
                                </div>
                            </ContainerInline>
                            <ContainerForm>
                                {avgFuel && <p>Consumo Médio: {avgFuel}</p>}
                                {usedKmHour && <p>{checkIsKm() ? 'Km Rodados: ' : 'Horas Ligadas: '} {usedKmHour}</p>}
                                {costKm && <p>{checkIsKm() ? 'Custo por Km: ' : 'Custo por Hora: '} {costKm}</p>}
                            </ContainerForm>
                        </>



                        }



                    </ContainerForm>
                </ContainerInline>

                <div className="flex justify-around w-full max-w-md">
                    <button
                        className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors"
                        onClick={() => {
                            closeForm()
                            formRef.current?.reset()
                        }}>
                        Cancelar
                    </button>
                    <button
                        className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors"
                        type="submit">
                        Confirmar
                    </button>
                </div>
            </Container>
        </Form>
    )

}
export default FormFuelView


export interface FormErrors {
    categorie?: string;
    vehicle?: string;
    driver?: string;
    fuel?: string;
    descr?: string;
    amount?: string;
    priceUnit?: string;
    qty?: string;
    kmAnt?: string;
    kmAtual?: string;
    images?: string;
}


export type iFuelInvoiceForm = {
    unique_id: string
    id_client: number;
    id_empresa: number;
    id_motorista: number;
    id_viagem: number;
    id_veiculo: number;
    fornecedor_uuid: string | null;
    internal_tank_id: number | null;
    id_categ: number;
    descr: string;
    amount: number;
    expense_type: string;
    status: string;
    lat: number | null;
    lng: number | null;
    photo1: string | null;
    photo2: string | null;
    photo3: string | null;
    km_start: number | null;
    km_end: number | null;
    cost_km: number | null;
    km_run: number | null;
    avg_fuel: number | null;
    qty: number | null;
    price_unity: number | null;
    obs: string | null;
    fuel_cod: number | null;
    fuel_descr: string | null;
    tipo_payment_id: number | null;
    evt_at: string;
    created_at?: string;
    updated_at?: string;
    sync_version: number | null;
    id_metrics_type: number;
    id_vehicle_type: number;
};

