import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { isValidDate } from '../../../utils/Converter';
import { ReportConfig } from '../utilsMap/ReportContext';



interface IOption {
    value: string;
    label: string;
}

export interface dtFilter {
    dtStart?: string;
    dtEnd?: string;
    lastreg: number;
}


const optionsStatus: IOption[] = [
    { value: '0', label: 'Hoje' },
    { value: '1', label: 'Ontem' },
    { value: '2', label: 'Últimos 2 Dias' }, // Corrigido de '1' para '2'
    { value: '5', label: 'Últimos 5 Dias' }, // Corrigido de '4' para '5'
    { value: '7', label: 'Últimos 7 Dias' }, // Mantido '7'
    { value: 'M0', label: 'Mês Atual' },
    { value: 'M1', label: 'Mês Anterior' },
    { value: 'intervalo', label: 'Intervalo Personalizado' },
];

const Container = styled.div`
  margin-top: 10px;
`;
const ContainerInline = styled.div`
display: flex;
justify-content: center;
    align-items: start;
    gap: 10px;
`;

const ContainerInlinePicker = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;
    margin-bottom: 10px;
    max-width: 650px;
    min-width: 500px;
    `;



const DateFilterReport: React.FC = () => {
    const { dateStartFilter, dateEndFilter, filterSelect, updateConfig } = ReportConfig();
    const [error, setError] = useState('');
    const [areDatesValid, setAreDatesValid] = useState(false);


    const handleDateChange = (value: any) => {
        if (value === 'intervalo') {
            updateConfig({ filterSelect: value });
            return;
        }

        let start = new Date();
        let end = new Date();

        switch (value) {
            case 'M0': // Mês Atual
                start.setDate(1);
                start.setMonth(start.getMonth());
                end.setDate(0);
                end.setMonth(end.getMonth() + 1);
                break;
            case 'M1': // Mês Anterior
                start.setDate(1);
                start.setMonth(start.getMonth() - 1);
                end.setDate(0);
                end.setMonth(end.getMonth());
                break;
            case 'A0': // Ano Atual
                start.setDate(1);
                start.setMonth(0); // Janeiro
                end.setDate(31);
                end.setMonth(11); // Dezembro
                break;
            case 'A1': // Ano Anterior
                start.setDate(1);
                start.setMonth(0); // Janeiro do ano anterior
                start.setFullYear(start.getFullYear() - 1);
                end.setDate(31);
                end.setMonth(11); // Dezembro do ano anterior
                end.setFullYear(end.getFullYear() - 1);
                break;
            default:
                // Para '0' (Hoje), '1' (Ontem), '2' (Últimos 2 Dias), '5' (Últimos 5 Dias), '7' (Últimos 7 Dias)
                start.setDate(start.getDate() - Number(value));
                break;
        }

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        updateConfig({ filterSelect: value, dateStartFilter: dayjs(start), dateEndFilter: dayjs(end) });
    };


    const handleStartDateChange = (value: Dayjs | null) => {
        updateConfig({ dateStartFilter: value ? value : dayjs(new Date()), filterSelect: 'intervalo' });
    }

    const handleEndDateChange = (value: Dayjs | null) => {
        updateConfig({ dateEndFilter: value ? value : dayjs(new Date()), filterSelect: 'intervalo' });
    }

    useEffect(() => {
        const areDatesValid = isValidDate(dateStartFilter) && isValidDate(dateEndFilter);
        setAreDatesValid(areDatesValid);
    }, [dateStartFilter, dateEndFilter]);

    return (
        <Container>
            <ContainerInline >
                <FormControl sx={{ m: 0, width: 180 }}>
                    <InputLabel id="demo-multiple-chip-label">Selecione o período</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        value={filterSelect}
                        onChange={(e) => handleDateChange(e.target.value)}
                        label="Selecione o período"
                    >
                        {optionsStatus.map((item) => (
                            <MenuItem
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {areDatesValid && (
                    <ContainerInlinePicker>
                        <DatePicker
                            value={dateStartFilter}
                            label="Data Inicial"
                            onChange={(newValue) => handleStartDateChange(newValue)}
                            format='DD/MM/YYYY'
                        />
                        <DatePicker
                            value={dateEndFilter}
                            label="Data Final"
                            onChange={(newValue) => handleEndDateChange(newValue)}
                            format='DD/MM/YYYY'
                        />
                        <TimePicker
                            value={dateStartFilter}
                            views={['hours', 'minutes', 'seconds']}
                            label="Hora Inicial"
                            ampm={false}
                            onChange={(newValue) => handleStartDateChange(newValue)}
                        />
                        <TimePicker
                            value={dateEndFilter}
                            views={['hours', 'minutes', 'seconds']}
                            label="Hora Inicial"
                            ampm={false}
                            onChange={(newValue) => handleEndDateChange(newValue)}
                        />

                    </ContainerInlinePicker>
                )}
            </ContainerInline>
        </Container>
    );
};

export default DateFilterReport;
