import React, { useEffect } from 'react';
import { Column, SelectColumn } from 'react-data-grid';
import { styled } from 'styled-components';
import DataGridComp from '../components/datagridComp/DataGridComp';
import { httpAxiosV4 } from '../gateways/Axios';
import SearchField from '../components/datagridComp/SearchField';
import ModalComp from '../components/datagridComp/ModalComp';
import { handleSearch } from '../utils/searchUtils';
import { StorageConfig } from '../Config/StorageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { AuthConfig } from '../Config/AuthContext';
import { iTank } from '../pages/FuelPage/repository/TanksRepo';
import { iSupplier } from '../pages/FuelPage/components/SelectSuppliersOrTanksSmall';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Box } from '@mui/material';


const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '10px',
    height: '450px',
    overflow: 'auto'
});

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
    padding: 5px;
`;


interface CompProps {
    isOpen: boolean;
    isMultiSelect?: boolean;
    onRequestClose: () => void;
    onSelectAlert: (row: iSupplier) => void;
}

const ModalListSuppliersOrTanks: React.FC<CompProps> = ({ onRequestClose, onSelectAlert, isOpen, isMultiSelect }) => {
    const { suppliers, syncSuppliers, tanks, syncTanks } = StorageConfig();
    const { user } = AuthConfig();
    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());
    const [typeSuppliers, setTypeSuppliers] = React.useState<string>('tanks');


    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const columnsSuppliers: Column<any>[] = [
        ...(isMultiSelect ? [{
            ...SelectColumn,
            width: 150,
        },] : []),
        { key: 'id', name: 'Cód', width: 80 },
        { key: 'name', name: 'Descr', width: 250 },
        { key: 'cnpj_cpf', name: 'CNPJ/CPF', width: 120 },
        { key: 'whatsapp', name: 'Whatsapp', width: 120 },
        { key: 'contato_principal', name: 'Contato', width: 120 },
    ]

    const columnsTanks: Column<iTank>[] = [
        ...(isMultiSelect ? [{
            ...SelectColumn,
            width: 150,
        },] : []),
        { key: 'id', name: 'Cód', width: 80 },
        { key: 'identifier', name: 'Descr', width: 250 },
        { key: 'current_volume', name: 'Litros', width: 90 },
        { key: 'max_capacity', name: 'Capacidade', width: 150 },
    ]

    const filteredRows = React.useMemo(() => {
        if (value === '2') {
            return handleSearch(tanks, findInputTerms);
        } else {
            return handleSearch(suppliers, findInputTerms);
        }
    }, [findInputTerms, suppliers, tanks, value]);

    useEffect(() => {
        if (isOpen) {
            if (value === '2') {
                if (tanks.length === 0) {
                    syncTanks();
                }
            } else {
                if (suppliers.length === 0) {
                    syncSuppliers();
                }
            }
        }
    }, [isOpen]);

    // Função que lida com a seleção de uma linha
    const handleSingleSelect = (row: any) => {
        let resp: iSupplier = {
            identifier: value === '2' ? row.id : row.unique_id,
            type: value === '2' ? 'tanks' : 'suppliers',
            descr: value === '2' ? row.identifier : row.name,
            current_volume: row.current_volume,
            color_fuel: row.color
        }
        onSelectAlert(resp);
        onRequestClose();
    };

    return (
        <ModalComp title={value === '2' ? "Lista de Tanques" : "Lista de Fornecedores"} subtitle="" onClose={onRequestClose} isShowCloseButton={true}>
            <ModalContent>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                            <Tab label="Fornecedor" value="1" />
                            <Tab label="Bombas Internas" value="2" />
                        </TabList>
                    </Box>
                    <div>
                        <TabPanel value="1">
                            <>
                                <SearchAndSelectContainer>
                                    <SearchField onSearch={setFindInputTerms} />
                                    <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={value === '2' ? syncTanks : syncSuppliers} >
                                        <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                                    </BtnFaIcon >
                                    {isMultiSelect && <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={syncSuppliers} >
                                        <BoxIcon icon={faClipboardCheck} className="fa-icon" /> Aplicar
                                    </BtnFaIcon >}
                                </SearchAndSelectContainer>
                                <ContainerInfo>
                                    <InfoBox>Exibindo {filteredRows.length} de {value === '2' ? tanks.length : suppliers.length}</InfoBox>
                                    {isMultiSelect && <InfoBox>Selecionados {selectedRows.size} de {value === '2' ? tanks.length : suppliers.length}</InfoBox>}
                                </ContainerInfo>
                                <ContainerTable>
                                    <DataGridComp
                                        rows={filteredRows}
                                        columns={columnsSuppliers}
                                        selectedRows={selectedRows}
                                        onRowClick={isMultiSelect ? () => { } : handleSingleSelect}
                                        onRowSelect={setSelectedRows}
                                    />
                                </ContainerTable> </>
                        </TabPanel>
                        <TabPanel value="2"> <>
                            <SearchAndSelectContainer>
                                <SearchField onSearch={setFindInputTerms} />
                                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={value === '2' ? syncTanks : syncSuppliers} >
                                    <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                                </BtnFaIcon >
                                {isMultiSelect && <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={syncSuppliers} >
                                    <BoxIcon icon={faClipboardCheck} className="fa-icon" /> Aplicar
                                </BtnFaIcon >}
                            </SearchAndSelectContainer>
                            <ContainerInfo>
                                <InfoBox>Exibindo {filteredRows.length} de {suppliers.length}</InfoBox>
                                {isMultiSelect && <InfoBox>Selecionados {selectedRows.size} de {value === '2' ? tanks.length : suppliers.length}</InfoBox>}
                            </ContainerInfo>
                            <ContainerTable>
                                <DataGridComp
                                    rows={filteredRows}
                                    columns={columnsTanks}
                                    selectedRows={selectedRows}
                                    onRowClick={isMultiSelect ? () => { } : handleSingleSelect}
                                    onRowSelect={setSelectedRows}
                                />
                            </ContainerTable> </></TabPanel>
                    </div>
                </TabContext>
            </ModalContent>
        </ModalComp>
    );
};

export default React.memo(ModalListSuppliersOrTanks);


const ModalContent = styled.div`
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  min-width: 800px;
`;


const BtnFaIcon = styled.span<BtnFaIconProps>`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px; 
  color: ${({ iconColor }) => iconColor || 'green'};

  .fa-icon { // Assume que você adicionará esta classe ao seu ícone FontAwesome
    color: ${({ iconColor }) => iconColor || 'inherit'}; // Usa a cor passada, ou herda se não for especificada
  }
`;

const BoxIcon = styled(FontAwesomeIcon) <{ iconColor?: string }>`
  color: ${({ iconColor }) => iconColor || 'green'};
  font-size: 16px;
`;

type BtnFaIconProps = {
    iconColor?: string; // Permite que a cor seja opcional
};


const ContainerInfo = styled.div`
padding: 6px;
display: flex;
    justify-content: left;
`

const InfoBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;
