import React, { useEffect, useState } from 'react';
import InfoClientMaps from '../components/InfoClientMaps';
import { MapConfig } from '../utilsMap/mapContext';
import { getDetailsVehicle, iDetailsVehicleData } from '../repository/MapsRepo';
import InfoDeviceMaps from '../components/InfoDeviceMaps';
import styled from 'styled-components';
import InfoVehicleMaps from '../components/InfoVehicleMaps';
import InfoDriverMaps from '../components/InfoDriverMaps';

const Container = styled.div`
  display: flex;
  justify-content: start; /* Espaço entre as colunas */
  gap: 10px; /* Espaço entre as colunas */
  margin-top: 10px;
  padding: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espaço entre os itens dentro da coluna */
`;

const SettingsVehicelTab: React.FC = () => {
    const { idVehicleSel, updateConfig } = MapConfig();
    const [data, setData] = useState<iDetailsVehicleData>();


    async function getDados() {
        if (idVehicleSel === 0) return
        const result = await getDetailsVehicle(idVehicleSel);
        setData(result)
    }

    useEffect(() => {
        getDados()
    }, []);

    return (
        <>{data &&
            <Container>
                {/* Primeira coluna com InfoClientMaps e InfoDeviceMaps empilhados */}
                <Column>
                    <InfoClientMaps item={data} onSync={getDados} />
                    <InfoDeviceMaps item={data} onSync={getDados} />
                    {/*<InfoDriverMaps item={data} />*/}
                </Column>

                <Column>
                    <InfoVehicleMaps item={data} />
                </Column>
            </Container>
        }</>
    )

}
export default SettingsVehicelTab


