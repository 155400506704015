import { Column, SelectColumn } from "react-data-grid";
import { iTemplateScreen } from "../repository/TemplateRepo";
import { BtnCheckBox } from "../style/StyleTemplate";

export const getTemplateColumns = (
    viewTemplate: (row: iTemplateScreen) => void,
    editTemplate: (row: iTemplateScreen) => void
): Column<iTemplateScreen>[] => {
    return [
        // Coluna para seleção de linhas
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 150,
            renderCell(props: any) {
                return (
                    <>
                        <BtnCheckBox onClick={() => viewTemplate(props.row)}>📂 Abrir </BtnCheckBox>
                        <BtnCheckBox onClick={() => editTemplate(props.row)}>✏️ Editar </BtnCheckBox>
                    </>
                )
            }
        },

        // Coluna de ID
        {
            key: 'id',
            name: 'Cód',
            width: 65,
        },
        // Coluna para o campo uniqueId
        {
            key: 'template_description',
            name: 'Descrição',
            width: 250,
        },

        // Coluna para o campo templateType
        {
            key: 'template_type',
            name: 'Tipo de Template',
            width: 150,
        },

        // Coluna para o campo templateHtml
        {
            key: 'template_html',
            name: 'Conteúdo HTML',
            width: 200,
        },

        // Coluna para o campo createdAt
        {
            key: 'created_at',
            name: 'Data de Criação',
            width: 150,
        },

        // Coluna para o campo updatedAt
        {
            key: 'updated_at',
            name: 'Última Atualização',
            width: 150,
        },

    ];
};
