
import React, { useEffect } from 'react';
import { BoxIcon, BtnFaIcon, ContainerTable, SearchAndSelectContainer } from '../Styles/StyleStreamCam';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { Column } from 'react-data-grid';
import { downLoadFile, getFilesInServer, getVehiclesByStream, IFilesVideo, iListHistoryDashcam } from '../repository/StreamRepo';
import { useSelector } from 'react-redux';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { selectUser } from '../../../redux/userSlice';
import { faDownload, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { formatDateToScreenString } from '../../../utils/Converter';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';

interface Option {
    value: string;
    label: string;
}

const DashCamGallery: React.FC = () => {
    const formRef = React.useRef<FormHandles>(null)
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);

    const [vehicles, setVehicles] = React.useState<Option[]>([]);
    const [vehicle, setVehicle] = React.useState<string>();


    //datagrid
    const [rows, setRows] = React.useState<IFilesVideo[]>([]);

    async function getVehicles() {
        const payload = {
            idcliente: user.idcliente,
            idempresa: user.idempresa,
            nivel: user.nivel
        }
        const result = await getVehiclesByStream(payload);
        let list: Option[] = []
        for (let i = 0; i < result.length; i++) {
            list.push({ value: result[i].imei, label: `${result[i].apelido}` })
        }
        setVehicles(list)
        setVehicle(list[0].value)
    }

    async function getFiles() {
        if (vehicle === undefined) {
            addNotification('', 'Selecione um veículo', 'error');
            return
        }
        const result = await getFilesInServer(vehicle);
        setRows(result.filesDetails)
    }


    useEffect(() => {
        getVehicles()
    }, [])

    useEffect(() => {
        getFiles()
    }, [vehicle])



    async function downloadFile(row: IFilesVideo) {
        console.log(row)
        if (vehicle === undefined) {
            addNotification('', 'Selecione um veículo', 'error');
            return
        }
        let url = downLoadFile(vehicle, row.nome);
        addNotification(
            'Download Vídeo',
            'Download do vídeo em andamento',
            'success'
        )
        window.open(url, '_blank')
    }

    const columns: Column<IFilesVideo>[] = [

        {
            key: 'download',
            name: 'Download',
            width: 130,
            renderCell(props: any) {
                return (
                    <BtnFaIcon title='Download Vídeo' onClick={() => downloadFile(props.row)} >
                        <BoxIcon icon={faDownload} iconColor='green' /> Download Vídeo
                    </BtnFaIcon >
                )
            }
        },
        {
            key: 'nome',
            name: 'Nome',
            width: 250
        },
        {
            key: 'dataCriacao',
            name: 'Data Criação',
            width: 250,
            renderCell(props: any) {
                return (
                    <div>{formatDateToScreenString(props.row.dataCriacao)}</div>
                )
            }
        },
        {
            key: 'tamanho',
            name: 'Tamanho',
            width: 100
        },


    ]

    return (
        <>
            <SearchAndSelectContainer>
                <Form placeholder={""} ref={formRef} onSubmit={() => { }} noValidate={true}>

                    <SelectFieldForm
                        name={'imei'}
                        options={vehicles}
                        label={'Selecione um Veículo'}
                        onChange={(e) => {
                            setVehicle(e.target.value)
                        }}
                    />
                </Form>
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getFiles} >
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                </BtnFaIcon >

            </SearchAndSelectContainer>
            <ContainerTable>
                <DataGridComp
                    rows={rows}
                    columns={columns}
                    selectedRows={new Set()}
                /> </ContainerTable>
        </>
    )

}
export default DashCamGallery