
import React, { useEffect } from 'react';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerBarTop, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, PriorityItem, SearchAndSelectContainer } from '../style/StyleChecklists';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBell, faCar, faFlag, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Column, SelectColumn } from 'react-data-grid';
import { getListTemplatesServer, iCheckListTemplate } from '../Repository/CheckListRepo';


const ChecklistTemplatesTab: React.FC = () => {

    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<iCheckListTemplate[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<iCheckListTemplate[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<iCheckListTemplate>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));


    const columns: Column<iCheckListTemplate>[] = [
        {
            ...SelectColumn,
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 140,
            renderCell(props: any) {
                return (
                    <>
                        <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox></>
                )
            }
        },

    ]
    const handleSearch = () => {
        let searchText = findInputTerms
        const filtered = rows.filter((vehicle: any) => {
            const normalizedSearchText = searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();

            // Verifica se algum valor do veículo corresponde ao texto de busca
            const matchesSearchText = Object.values(vehicle).some((value) => {
                const normalizedValue = String(value)
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            });
            return matchesSearchText;

        })


        setFilteredRows(filtered);
    };

    useEffect(() => {
        handleSearch();
    }, [findInputTerms]);


    async function getDados() {
        try {
            const result = await getListTemplatesServer();
            console.log(result)
            setRows(result)
            setFilteredRows(result);
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }

    React.useEffect(() => {
        getDados()
    }, [])

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((alert) => alert.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const editItem = (item: iCheckListTemplate) => {

    }

    const handleClickRow = (row: iCheckListTemplate) => {
        // console.log(row)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    return (
        <>
            <ContainerTable>
                <>
                    <SearchAndSelectContainer>
                        <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />

                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getDados} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        <BtnFaIcon title='Cadastrar nova regra' onClick={() => { }} >
                            <BoxIcon icon={faAdd} iconColor='green' /> Criar Alerta
                        </BtnFaIcon >


                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        visibleColumns={visibleColumns}
                        onRowClick={handleClickRow}
                        onRowSelect={handleRowSelect}
                    />

                </>
            </ContainerTable>
        </>
    )

}
export default ChecklistTemplatesTab