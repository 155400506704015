import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import credReducer from './credSlice'
import appBarReducer from './appBarSlice'
import mapReducer from './mapSlice'
import alertServiceSlice from './alertServiceSlice'
export const store = configureStore({
    reducer: {
        user: userReducer,
        cred: credReducer,
        appbar: appBarReducer,
        mapOptions: mapReducer,
        alertService: alertServiceSlice
    }
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch