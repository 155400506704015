import { Column, SelectColumn } from 'react-data-grid';
import { iMsgPrepare } from '../View/MessagesCreate';
export const getColumnsMsgPrepared = (): Column<iMsgPrepare>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar',
        },
        // Coluna para o ID do cliente
        {
            key: 'idcliente',
            name: 'ID Cliente',
            width: 100,
            resizable: true,
        },
        // Coluna para o contato
        {
            key: 'contact',
            name: 'Contato',
            width: 200,
            resizable: true,
        },
        // Coluna para o tipo de envio
        {
            key: 'type_send',
            name: 'Tipo de Envio',
            width: 150,
            resizable: true,
        },
        // Coluna para a mensagem
        {
            key: 'msg',
            name: 'Mensagem',
            width: 300,
            resizable: true,
        },
        // Coluna para o Unique ID
        {
            key: 'unique_id',
            name: 'Unique ID',
            width: 250,
            resizable: true,
        },
    ];
};