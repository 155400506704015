import React from 'react';

interface SmallButtonProps {
    onClick: () => void; // Ação de clique passada pelo componente pai
    label: string; // Rótulo do botão
}

const SmallButton: React.FC<SmallButtonProps> = ({ onClick, label }) => {
    return (
        <button
            onClick={onClick}
            className="text-white bg-orange-500 hover:bg-orange-700 transition-colors duration-150 ease-in-out font-bold py-1 px-2 rounded text-xs uppercase"
        >
            {label}
        </button>
    );
};

export default SmallButton;
