import { httpAxiosV4 } from "../../../gateways/Axios";
import { dtFilter } from "../../ExpensesPage/components/DateFilterDashboard";
import { formateFuelInvoices, iFuelInvoicesScreen } from "../models/FuelListModels";


export async function getFuelDashboad(payload: any): Promise<IDashboardFuel[]> {
    const { data } = await httpAxiosV4<reqDashboardFuel, IDashboardFuel[]>(//request, response
        `fuel/dashboard`,
        "POST",
        payload
    );
    return data;
}

export async function getFuelInvoices(payload: any): Promise<iFuelInvoicesScreen[]> {
    const { data } = await httpAxiosV4<reqDashboardFuel, any>(//request, response
        `fuel/invoices`,
        "POST",
        payload
    );
    return formateFuelInvoices(data);
}



export interface iFuelInvoices {
    id: number;
    unique_id: string;
    placa: string;
    descr: string;
    icon_svg: string;
    id_client: number;
    id_empresa: number;
    id_motorista: number;
    id_viagem: number;
    id_veiculo: number;
    fornecedor_uuid: string | null;
    supplier_name: string | null;
    id_categ: number;
    categories: string
    amount: number;
    expense_type: string;
    status: 'Pendente' | 'Aprovado' | 'Cancelado' | 'Em Análise' | 'Rejeitado' | 'Pago';
    lat: number;
    lng: number;
    photo1: string;
    photo2: string;
    photo3: string;
    km_start: number;
    km_end: number;
    km_vehicle: number;
    km_run: number;
    avg_fuel: number;
    cost_km: number;
    qty: number | null;
    price_unity: number | null;

    obs: string | null;
    fuel_cod: number;
    fuel_descr: string | null;
    tipo_payment_id: number | null;
    sync_version: string;
    evt_at: string;
    create_at: string;
    update_at: string;
    delete_at: string | null;
    name: string | null;
    driveravatar: string;
    drivername: string;
    metrics: string;
    id_metrics_type?: number;
    internal_tank_id: number;
};

export interface reqDashboardFuel {
    idvehicle?: number
    iddriver?: number
    ExpenseId?: number
    cercaId?: number
    typeEvt?: string
    status?: string
    dtFilter?: dtFilter
}

export type IDashboardFuel = {
    id_veiculo: number;            // ID do veículo
    placa: string;                 // Placa do veículo
    total_km_rodados: number;      // Total de quilômetros rodados
    media_combustivel: number;     // Média de combustível
    total_litros: number;          // Total de litros abastecidos
    total_gastos: number;          // Total de gastos em abastecimento
    custo_por_km: number;          // Custo por quilômetro rodado
    media_km_por_litro: number;    // Média de quilômetros por litro (eficiência)
    total_abastecimentos: number;  // Total de abastecimentos
};

export type iCategoryFuelResume = {
    category: string;
    total_amount: string;
}

