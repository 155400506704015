
import React, { } from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import ModalListVehicles from '../../Modals/ModalListVehicles';
import { iVehicleStorage } from '../../Config/StorageRepository';



const Box = styled('div')({
    marginLeft: '5px',
    margin: '10px'
});


const Container = styled('div')({
    display: 'flex',
    padding: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    //border: '1px solid #dbdbdb',
    borderRadius: '5px',
    maxWidth: '350px',
    minHeight: '100px',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)'
});

const ContainerInfo = styled('div')({
    display: 'block',
    padding: 5,
    minWidth: '270px'

});

const ContainerInline = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'

});

const TextApelido = styled('div')({
    fontSize: '10px',
    textTransform: 'uppercase'
});
const Title = styled('div')({
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px'
});
const Subtitle = styled('div')({
    fontSize: '10px',
    fontWeight: '600',
    textTransform: 'uppercase',
});


const OnClickPointer = styled('div')({
    cursor: 'pointer'
});

/*
const AvatarStyle = styled.img.attrs({
    src: `${spinImg}`
})`
    margin: 0 auto;
    height: 28vh;
    margin-bottom: 15px;
    margin-top: 15px;
    display: block;
`*/

const Button = styled.button`
    background: black;
    color: white;
    font-size: 1em;
    margin: 0 auto;
    padding: 0.45em 2em;
    border: 0;
    border-radius: 3px;
    display: block;
    margin-top: 25px;
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: var(--btn-hover-color) !important;
    }
`

interface iPropsvehicle {
    vehicle?: iVehicleStorage
    setVehicle: (vehicle: iVehicleStorage | undefined) => void
}

const CardvehicleViewWidget: React.FC<iPropsvehicle> = ({ vehicle, setVehicle }) => {
    const [isOpenListVehicles, setIsOpenListVehicles] = React.useState(false);

    const handleSelectVehicle = (row: any) => {
        setVehicle(row);
        setIsOpenListVehicles(false);
    }
    return (
        <>
            <Box>
                <TextApelido>Veículo</TextApelido>
                {vehicle?.id ?
                    <><Container onClick={() => { setIsOpenListVehicles(true) }}>
                        <Avatar src="/broken-image.jpg" />
                        <ContainerInfo>
                            <TextApelido>{vehicle?.manufacturer} - {vehicle?.model}</TextApelido>
                            <Title>{vehicle?.placa}</Title>
                            <ContainerInline>
                                <Subtitle>{vehicle?.odometer_vehicle ?? 0} KM</Subtitle>
                                <Subtitle>Cód: {vehicle?.id}</Subtitle>
                            </ContainerInline>
                            {vehicle?.idclient && <TextApelido>{vehicle?.client_name} ({vehicle?.idclient})</TextApelido>}
                        </ContainerInfo>
                    </Container>
                        <ContainerInfo>
                            <Subtitle><OnClickPointer onClick={() => setVehicle(undefined)}>❌ Remover Seleção</OnClickPointer></Subtitle>
                        </ContainerInfo>
                    </>
                    :
                    <Container onClick={() => { setIsOpenListVehicles(true) }}>
                        <Avatar src="/broken-image.jpg" />
                        <ContainerInfo>
                            <Subtitle>Selecione um veículo</Subtitle>
                        </ContainerInfo>
                    </Container>
                }
            </Box>
            {isOpenListVehicles && <ModalListVehicles
                onRequestClose={() => { setIsOpenListVehicles(false) }}
                isOpen={isOpenListVehicles}
                isMultiSelect={false}
                onSelectAlert={handleSelectVehicle} />}
        </>
    )

}
export default CardvehicleViewWidget