import { httpAxiosV4 } from "../../../gateways/Axios";


const version = 'v1'

export async function getListTemplatesServer(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `${version}/checklist/templates`,
        "GET"
    );
    return data;
}


export interface iCheckListTemplate {
    id: number;
    name: string;
    description: string;
    status: string;
    created_at: string;
    updated_at: string;
} 