
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import ChipProvidersSelect, { IChipProvider } from './ChipProvidersSelect';
import ModalComp from '../../../components/datagridComp/ModalComp';



const Box = styled('div')({
    marginLeft: '5px',
    margin: '10px'
});


const Container = styled('div')({
    display: 'flex',
    padding: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    //border: '1px solid #dbdbdb',
    borderRadius: '5px',
    maxWidth: '350px',
    minHeight: '100px',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)'
});

const ContainerInfo = styled('div')({
    display: 'block',
    padding: 5,
    minWidth: '270px'

});

const ContainerInline = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'

});

const TextApelido = styled('div')({
    fontSize: '10px',
    textTransform: 'uppercase'
});
const Title = styled('div')({
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px'
});
const Subtitle = styled('div')({
    fontSize: '10px',
    fontWeight: '600',
    textTransform: 'uppercase',
});


const OnClickPointer = styled('div')({
    cursor: 'pointer'
});

/*
const AvatarStyle = styled.img.attrs({
    src: `${spinImg}`
})`
    margin: 0 auto;
    height: 28vh;
    margin-bottom: 15px;
    margin-top: 15px;
    display: block;
`*/

const Button = styled.button`
    background: black;
    color: white;
    font-size: 1em;
    margin: 0 auto;
    padding: 0.45em 2em;
    border: 0;
    border-radius: 3px;
    display: block;
    margin-top: 25px;
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: var(--btn-hover-color) !important;
    }
`

interface iPropsVehicle {
    select?: (item: IChipProvider | undefined) => void
}

const ProviderCardSelect: React.FC<iPropsVehicle> = (props) => {

    const [chip, setChip] = React.useState<IChipProvider>()
    const [IsOpen, setOpen] = React.useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClearSelect = () => {
        setChip(undefined)
        props.select?.(undefined)
    };

    const handleSelect = (item: IChipProvider) => {
        handleClose()
        setChip(item)
        if (props.select) { props.select(item) }
    };



    return (
        <>
            {IsOpen && (
                <ModalComp title="" subtitle="" onClose={handleClose}>
                    <ChipProvidersSelect onRowSelect={handleSelect} />
                </ModalComp>
            )}
            <Box>
                <TextApelido>Fornecedor</TextApelido>
                {chip?.id ?
                    <><Container onClick={() => handleClickOpen()}>
                        <ContainerInfo>
                            <Title>{chip?.fornecedor}</Title>
                            <Subtitle>{chip?.Operadora}</Subtitle>
                            <Subtitle>{chip?.apn}</Subtitle>
                            <Subtitle>Usúario: {chip?.apn_user} / senha: {chip?.apn_pass}</Subtitle>
                        </ContainerInfo>
                    </Container>
                        <ContainerInfo>
                            <Subtitle><OnClickPointer onClick={handleClearSelect}>❌ Remover Seleção</OnClickPointer></Subtitle>
                        </ContainerInfo>
                    </>
                    :
                    <Container onClick={() => handleClickOpen()}>
                        <Avatar src="/broken-image.jpg" />
                        <ContainerInfo>
                            <Subtitle>Selecione um fornecedor</Subtitle>
                        </ContainerInfo>
                    </Container>
                }
            </Box>
        </>
    )

}
export default ProviderCardSelect