import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUpload, FaEdit, FaExclamationTriangle, FaCheck, FaTimes, FaSpinner } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid'; // Importa a função uuid v4
import { updatePhotoVehicle } from '../repository/MapsRepo';
import { useNotifications } from '../../../Notifications/NotificationContext';

// Interfaces
interface VehiclePhotoCompProps {
  filename: string | null;
  idclient: number;
  idempresa: number;
  idvehicle: number;  // Troca de idrecord para idvehicle
}

// Styled Components
const CardContainer = styled.div<{ isEditing: boolean; small: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  width: 315px;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: ${({ isEditing, small }) => (isEditing ? '230px' : small ? '55px' : '180px')};  // Altura aumentada para modo de edição
  transition: height 0.3s ease;
  position: relative;
`;

const Container = styled.div<{ isEditing: boolean; small: boolean }>`
  display: flex;
  flex-direction: column;
  width: 302px;
  font-family: Arial, sans-serif;
  height: ${({ isEditing, small }) => (isEditing ? '230px' : small ? '55px' : '180px')};  // Altura aumentada para modo de edição
  transition: height 0.3s ease;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ImageWrapper = styled.div<{ small: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f0f0f0;
  position: relative;
`;

const VehicleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const BoxNoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 15px;
`;
const MsgNoImage = styled.div`
  font-size: 12px;
  color: #666;
  text-align: center;
`;
const PlaceholderIcon = styled.div<{ small: boolean }>`
  cursor: pointer;
  font-size: ${({ small }) => (small ? '24px' : '48px')};
  color: #aaa;
`;

const BrokenImageIcon = styled.div<{ small: boolean }>`
  font-size: ${({ small }) => (small ? '24px' : '48px')};
  color: #d9534f;
`;

const EditButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
`;

const Divider = styled.div`
  border-bottom: 1px solid #c5c5c5;
  margin: 1px 0;
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ActionButton = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;
  color: #555;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    color: #000;
  }
`;

const UploadButton = styled.label`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;
  color: #555;

  &:hover {
    color: #000;
  }
`;

const VehiclePhotoComp: React.FC<VehiclePhotoCompProps> = ({ filename, idclient, idempresa, idvehicle }) => {
  const [imageError, setImageError] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(filename ? `${process.env.REACT_APP_FILES_SERVER}/vehicle-photo/${filename}` : null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Armazena o arquivo selecionado
  const [isEditing, setIsEditing] = useState(false); // Modo de edição
  const [isLoading, setIsLoading] = useState(false); // Estado de loading durante o upload

  const { addNotification } = useNotifications();

  const handleImageError = () => {
    setImageError(true);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));  // Exibe o preview imediatamente
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      addNotification('Erro', 'Nenhum arquivo selecionado', 'error');
      return;
    }

    setIsLoading(true); // Ativa o loading

    const formData = new FormData();
    const newFileName = `${uuidv4()}${selectedFile.name.substring(selectedFile.name.lastIndexOf('.'))}`; // Gera um nome único com UUID e extensão

    const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type }); // Renomeia o arquivo com o UUID

    formData.append('picture', renamedFile); // Envia o arquivo renomeado no campo 'picture'
    formData.append('idclient', idclient.toString());
    formData.append('idempresa', idempresa.toString());
    formData.append('idvehicle', idvehicle.toString()); // Agora envia o idvehicle
    if (filename) {
      formData.append('oldAvatar', filename); // Envia o nome do arquivo antigo
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_FILES_SERVER}/upload-vehicle-photo`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        const newFilename = data.filename;
        setImageUrl(`${process.env.REACT_APP_FILES_SERVER}/vehicle-photo/${newFilename}`);
        setPreviewUrl(null);

        // Atualiza a tabela com o nome da nova imagem
        await updatePhotoVehicle({ filename: newFilename, idvehicle });

        setIsEditing(false); // Sai do modo de edição após salvar
        addNotification('Sucesso', 'Imagem enviada com sucesso', 'success');
      } else {
        console.error('Erro ao enviar a imagem');
      }
    } catch (error) {
      console.error('Erro na requisição de upload', error);
    } finally {
      setIsLoading(false); // Desativa o loading
    }
  };

  const handleCancel = () => {
    setPreviewUrl(null); // Remove o preview
    setSelectedFile(null); // Remove o arquivo selecionado
    setIsEditing(false);  // Sai do modo de edição
  };

  const small = !imageUrl || imageError; // Se não houver imagem ou houver erro, o card será menor

  return (
    <Container isEditing={isEditing} small={small && !previewUrl}>
      <ImageWrapper small={small && !previewUrl}>
        {previewUrl ? (
          <VehicleImage
            src={previewUrl}
            alt="Preview"
          />
        ) : imageUrl && !imageError ? (
          <VehicleImage
            src={imageUrl}
            alt="Vehicle"
            onError={handleImageError}
          />
        ) : imageError ? (
          <BrokenImageIcon small={small}>
            <FaExclamationTriangle />
          </BrokenImageIcon>
        ) : (
          <BoxNoImage >
            <PlaceholderIcon small={small} onClick={() => setIsEditing(true)}>
              <FaUpload />
            </PlaceholderIcon>
            <MsgNoImage>Carregar Imagem do veículo</MsgNoImage>
          </BoxNoImage>
        )}

        {/* O botão de editar desaparece quando estamos no modo de edição */}
        {!isEditing && imageUrl && (
          <EditButton onClick={() => setIsEditing(true)}>
            <FaEdit />
          </EditButton>
        )}
      </ImageWrapper>

      {/* Exibe os botões de "Upload", "Salvar" e "Cancelar" no modo de edição */}
      {isEditing && (<>
        <ButtonContainer>
          <UploadButton htmlFor="file-upload">
            <FaUpload />
            <input
              type="file"
              id="file-upload"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </UploadButton>
          <ActionButton onClick={handleUpload} disabled={isLoading}>
            {isLoading ? <FaSpinner className="fa-spin" /> : <FaCheck />}
          </ActionButton>
          <ActionButton onClick={handleCancel} disabled={isLoading}>
            <FaTimes />
          </ActionButton>

        </ButtonContainer>
        <Divider /></>
      )}
    </Container>
  );
};

export default VehiclePhotoComp;
