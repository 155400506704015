
import { Add } from '@mui/icons-material';

interface ButtonRefreshProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ButtonAdd: React.FC<ButtonRefreshProps> = ({ onClick }) => {
  return (
    <button
      className="p-1 rounded-full bg-orange-500 hover:bg-orange-600 text-white focus:outline-none focus:ring-2 focus:ring-orange-300"
      aria-label="Atualizar"
      onClick={onClick}
    >
      <Add className="h-5 w-5" />
      Adicionar
    </button>
  );
}

export default ButtonAdd;
