import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaEdit, FaTrash, FaMapMarkerAlt } from 'react-icons/fa';
import { StorageConfig } from '../../../Config/StorageContext';
import { MapConfig } from '../utilsMap/mapContext';
import SearchField from '../../../components/datagridComp/SearchField';
import { IFence } from '../../../Config/StorageRepository';
import { handleSearch } from '../../../utils/searchUtils';
import Tooltip from '../../../components/datagridComp/Tooltip';
import VirtualFenceForm from './VirtualFenceForm';
import ConfirmationDialog from '../../../components/datagridComp/ConfirmationDialog';
import ModalComp from '../../../components/datagridComp/ModalComp';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { deleteCerca } from '../repository/CercasRepo';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { pl } from 'date-fns/locale';
import { isValidHexColor } from '../../../utils/Converter';
import { formatArea } from '../../../utils/format';

const CREATE_FLAGS = 'CREATE_FLAGS';
let placeSelected: IFence | undefined;

let markerPosition: google.maps.Marker | null = null;
let polygon: google.maps.Polygon | null = null;
let circle: google.maps.Circle | null = null;

const FenceComp: React.FC = () => {
    const map = useGoogleMap();
    const { fences, syncFences } = StorageConfig();
    const { modeActive, actionPressButton } = MapConfig();
    const { addNotification } = useNotifications();
    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [showFenceForm, setShowFenceForm] = useState<boolean>(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);


    const clearMap = () => {
        if (polygon) {
            polygon.setMap(null);
            polygon = null;
        }
        if (circle) {
            circle.setMap(null);
            circle = null;
        }
        if (markerPosition) {
            markerPosition.setMap(null);
            markerPosition = null;
        }
    }

    useEffect(() => {
        if (modeActive === CREATE_FLAGS && fences.length === 0) {
            syncFences();
        }

        return () => {
            console.log("List Fence Desativado");
            clearMap();
        };
    }, [modeActive]);


    const handleIconClick = (action: string) => {
        switch (action) {
            case 'create-fence':
                clearMap();
                placeSelected = undefined;
                setShowFenceForm(true);
                break;
            case 'closeform-fence':
                setShowFenceForm(false);
                break;
            case 'save-fence':
                setShowFenceForm(false);
                syncFences();
                break;
            case 'update-fences':
                syncFences();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (actionPressButton)
            handleIconClick(actionPressButton)
    }, [actionPressButton]);

    const filteredRows = React.useMemo(() => {
        return handleSearch(fences, findInputTerms);
    }, [findInputTerms, fences]);

    const handleConfirmed = async () => {
        try {
            clearMap();
            setIsOpenConfirmModal(false)
            await deleteCerca(placeSelected?.id);
            addNotification(
                'Cerca Apagada com sucesso',
                `Cerca ${placeSelected?.descr} apagada com sucesso`,
                'success'
            );
            syncFences();
        } catch (error) {
            addNotification(
                'Erro ao realizar a ação',
                `Erro ao apagar a cerca ${placeSelected?.descr}`,
                'error'
            );
        }
    };

    // Funções de controle de ações
    const handleEdit = (place: IFence) => {
        clearMap();
        placeSelected = place;
        setShowFenceForm(true);
    };

    const handleDelete = (place: IFence) => {
        placeSelected = place;
        setIsOpenConfirmModal(true);
    };

    const handleMapView = (place: IFence) => {
        const position = new google.maps.LatLng(place.lat, place.lng); // Cria um objeto de posição
        clearMap();
        drawerMarker(position); // Desenha o marker no mapa    
        if (place.typecerca === 'raio') drawCircle(position, place);
        if (place.typecerca === 'polygon') drawPolygon(place);
    };


    const drawerMarker = (position: google.maps.LatLng) => {
        if (markerPosition) {
            markerPosition.setMap(null); // Remove o marker anterior do mapa
        }
        map?.panTo(position); // Centraliza o mapa na posição obtida
        if (map?.getZoom()! < 15) map?.setZoom(15); // Ajusta o zoom para 15, se necessário

        markerPosition = new google.maps.Marker({
            position: position,
            map
        });
    };


    const drawCircle = (position: google.maps.LatLng, place: IFence) => {
        if (circle) {
            circle.setMap(null); // Remove o círculo anterior
        }
        let color = isValidHexColor(place.color) ? place.color : '#FF0000';
        circle = new google.maps.Circle({
            center: position,
            radius: place.raio,
            map,
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.25,
            editable: false,
        });
    };

    // Função para desenhar o Polígono no mapa
    const drawPolygon = (place: IFence) => {
        if (polygon) {
            polygon.setMap(null); // Remove o polígono anterior
        }
        let color = isValidHexColor(place.color) ? place.color : '#FF0000';
        polygon = new google.maps.Polygon({
            paths: google.maps.geometry.encoding.decodePath(place.encod),
            map,
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.25
        });
    };

    return (
        <>
            {showFenceForm && <VirtualFenceForm initialData={placeSelected} />}
            {!showFenceForm && (
                <Container>
                    <Header>
                        <SummaryFences>
                            Exibindo {filteredRows.length} de {fences.length} Cercas
                        </SummaryFences>
                        <SearchField onSearch={setFindInputTerms} />
                    </Header>

                    <FenceList>
                        {filteredRows.map((place: IFence) => (
                            <FenceCard key={place.id} color={place.color}>
                                <Description>{place.descr}</Description>
                                <Address>{place.address}</Address>
                                <ActionContainer>
                                    <InfoContainer>
                                        <Tooltip text="Tipo de cerca">
                                            {place.typecerca === 'raio' ? (
                                                <CircleIcon color={place.color} />
                                            ) : (
                                                <PolygonIcon color={place.color} />
                                            )}
                                        </Tooltip>
                                        <Tooltip text={place.typecerca === 'raio' ? 'Raio' : 'Área'}>
                                            {place.typecerca === 'raio' && <span>{place.raio} m</span>}
                                            {(place.typecerca === 'polygon' && place.area > 0) && <span>{formatArea(place.area)}</span>}
                                        </Tooltip>
                                        <div>Cód: {place.id}</div>
                                    </InfoContainer>
                                    <IconContainer>
                                        <Tooltip text="Editar">
                                            <IconButton onClick={() => handleEdit(place)}>
                                                <FaEdit />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip text="Excluir">
                                            <IconButton onClick={() => handleDelete(place)}>
                                                <FaTrash />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip text="Localizar">
                                            <IconButton onClick={() => handleMapView(place)}>
                                                <FaMapMarkerAlt />
                                            </IconButton>
                                        </Tooltip>
                                    </IconContainer>
                                </ActionContainer>
                            </FenceCard>
                        ))}
                    </FenceList>
                </Container>
            )}
            {isOpenConfirmModal && <ModalComp title="Confirme a ação" subtitle="" onClose={() => setIsOpenConfirmModal(false)}>
                <ConfirmationDialog
                    type={'QUESTION'}
                    onCancel={() => setIsOpenConfirmModal(false)}
                    onConfirm={handleConfirmed}
                    message={`Deseja apagar a cerca ${placeSelected?.descr}, Cód: ${placeSelected?.id}  ?`} />
            </ModalComp>}
        </>
    );
};



// Estilização com styled-components

const Container = styled.div`
  padding: 5px;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-bottom: 8px;
`;

const SummaryFences = styled.div`
font-size: 12px;
color: #666;
padding-left: 5px;
display: flex;
align-items: start;
width: 100%;
`;

const FenceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 75vh;
  overflow-y: auto;
  max-width: 320px;
`;

const FenceCard = styled.div<{ color: string }>`
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
    flex-direction: column;
    gap: 2px;
    
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

const Address = styled.span`
  font-size: 14px;
  color: #7f7f7f;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 8px;
  color: #7f7f7f;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-top: 2px;
`;
const IconContainer = styled.div`
  display: flex;
  gap: 12px;
  color: #7f7f7f;
  justify-content: end;
  margin-right: 5px;
`;
const InfoContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #7f7f7f;
  align-items: center;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
`;

const Footer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CircleIcon = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const PolygonIcon = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  background-color: ${({ color }) => color};
  border-radius: 3px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
`;

export default FenceComp;
