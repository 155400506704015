import React, { useEffect } from 'react';
import { Column, SelectColumn } from 'react-data-grid';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from 'styled-components';
import DataGridComp from '../components/datagridComp/DataGridComp';
import { httpAxiosV4 } from '../gateways/Axios';
import SearchField from '../components/datagridComp/SearchField';
import ModalComp from '../components/datagridComp/ModalComp';
import { handleSearch } from '../utils/searchUtils';
import { StorageConfig } from '../Config/StorageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { AuthConfig } from '../Config/AuthContext';


const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '10px',
    height: '450px',
    overflow: 'auto'
});

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
    padding: 5px;
`;


interface CompProps {
    isOpen: boolean;
    isMultiSelect?: boolean;
    onRequestClose: () => void;
    onSelectAlert: (row: any) => void;
}

const ModalListVehicles: React.FC<CompProps> = ({ onRequestClose, onSelectAlert, isOpen, isMultiSelect }) => {
    const { vehicles, syncVehicles } = StorageConfig();
    const { user } = AuthConfig();
    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());


    const getDisplayName = (placa: string, apelido: string): string => {
        return placa === apelido ? placa : `${placa} (${apelido})`;
    };


    const columns: Column<any>[] = [
        ...(isMultiSelect ? [{
            ...SelectColumn,
            width: 150,
        },] : []),
        { key: 'id', name: 'Cód', width: 80 },
        ...(user?.nivel === 2 ? [{ key: 'client_name', name: 'Cliente', width: 200 }] : []),
        {
            key: 'apelido', name: 'Descr', width: 250,
            renderCell(props: any) {
                return (<>{getDisplayName(props.row.placa, props.row.apelido)}</>)
            }
        },
        {
            key: '', name: 'Fabricante / Modelo', width: 250,
            renderCell(props: any) {
                return (<>{props.row.manufacturer} / {props.row.model}</>)
            }
        },
        { key: 'odometer_vehicle', name: 'Odômetro', width: 150 }
    ];

    const filteredRows = React.useMemo(() => {
        return handleSearch(vehicles, findInputTerms);
    }, [findInputTerms, vehicles]);

    useEffect(() => {
        console.log('ModalListVehicles isOpen', isOpen, vehicles);
        if (isOpen && vehicles.length === 0) {
            syncVehicles()
        }
    }, [isOpen]);

    // Função que lida com a seleção de uma linha
    const handleSingleSelect = (row: any) => {
        onSelectAlert(row);
        onRequestClose();
    };

    return (
        <ModalComp title="Lista de Veículos" subtitle="" onClose={onRequestClose} isShowCloseButton={true}>
            <ModalContent>
                <SearchAndSelectContainer>
                    <SearchField onSearch={setFindInputTerms} />
                    <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={syncVehicles} >
                        <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                    </BtnFaIcon >
                    {isMultiSelect && <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={syncVehicles} >
                        <BoxIcon icon={faClipboardCheck} className="fa-icon" /> Aplicar
                    </BtnFaIcon >}
                </SearchAndSelectContainer>
                <ContainerInfo>
                    <InfoBox>Exibindo {filteredRows.length} de {vehicles.length}</InfoBox>
                    {isMultiSelect && <InfoBox>Selecionados {selectedRows.size} de {vehicles.length}</InfoBox>}
                </ContainerInfo>
                <ContainerTable>
                    <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        onRowClick={isMultiSelect ? () => { } : handleSingleSelect}
                        onRowSelect={setSelectedRows}
                    />
                </ContainerTable>
            </ModalContent>
        </ModalComp>
    );
};

export default React.memo(ModalListVehicles);


const ModalContent = styled.div`
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  min-width: 800px;
`;


const BtnFaIcon = styled.span<BtnFaIconProps>`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px; 
  color: ${({ iconColor }) => iconColor || 'green'};

  .fa-icon { // Assume que você adicionará esta classe ao seu ícone FontAwesome
    color: ${({ iconColor }) => iconColor || 'inherit'}; // Usa a cor passada, ou herda se não for especificada
  }
`;

const BoxIcon = styled(FontAwesomeIcon) <{ iconColor?: string }>`
  color: ${({ iconColor }) => iconColor || 'green'};
  font-size: 16px;
`;

type BtnFaIconProps = {
    iconColor?: string; // Permite que a cor seja opcional
};


const ContainerInfo = styled.div`
padding: 6px;
display: flex;
    justify-content: left;
`

const InfoBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;
