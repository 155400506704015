import React, { useState } from 'react';
import { Column } from 'react-data-grid';
import { styled } from 'styled-components';

interface ColumnSelectProps<T> {
    columns: Column<T>[];
    onColumnVisibilityChange: (visibleColumnKeys: string[]) => void;
}

const BtnCheckBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

const ColumnSelect = <T,>({ columns, onColumnVisibilityChange }: ColumnSelectProps<T>) => {
    const [isVisible, setIsVisible] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(columns.map(column => column.key as string));

    const handleCheckboxChange = (key: string, isChecked: boolean) => {
        setSelectedColumns(prev => isChecked ? [...prev, key] : prev.filter(columnKey => columnKey !== key));
    };

    const toggleDropdown = () => setIsVisible(!isVisible);

    const selectAll = () => {
        setSelectedColumns(columns.map(column => column.key as string));
    };

    const deselectAll = () => {
        setSelectedColumns([]);
    };

    // Notificar o componente pai quando as colunas visíveis mudarem
    React.useEffect(() => {
        onColumnVisibilityChange(selectedColumns);
    }, [selectedColumns, onColumnVisibilityChange]);

    return (
        <div className="relative">
            <button onClick={toggleDropdown} className="border border-gray-300 px-4 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500">
                {isVisible ? '❌ Fechar Menu' : 'Menu Colunas'}
            </button>
            {isVisible && (
                <div className="absolute z-10 border border-gray-300 bg-white p-4 rounded-md">
                    <div className="flex justify-center flex-row mb-2 w-52 z-2 border border-gray-300 bg-white p-2 rounded-md">

                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                    </div>
                    {columns.map((column) => (
                        <div key={column.key}>
                            <label className="flex mb-1">
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    checked={selectedColumns.includes(column.key as string)}
                                    onChange={(e) => handleCheckboxChange(column.key as string, e.target.checked)}
                                />
                                {column.name}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ColumnSelect;
