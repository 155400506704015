import React from 'react';
import styled from 'styled-components';
import { useConfig } from '../../../Config/configContext';
import { MapConfig } from '../utilsMap/mapContext';

interface VehicleIconMapProps {
  driverAvatar?: string;
  vehicleImage?: string;
  vehicleIcon: string;
  color?: string;
}

const Container = styled.div`
  position: relative;
  width: 46px;
  height: 46px;
`;

const BackgroundCircle = styled.div<{ color: string }>`
  width: 46px;
  height: 46px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Image = styled.img`
      z-index: 0;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
`;

const VehicleIconMap: React.FC<VehicleIconMapProps> = ({ driverAvatar, vehicleIcon, vehicleImage, color = 'gray' }) => {
  const { showPhotoDriverInMap } = MapConfig();
  const isShowDriverAvatar = showPhotoDriverInMap && driverAvatar;
  const driverAvatarPath = driverAvatar ? `${process.env.REACT_APP_FILES_SERVER}/avatar-driver/${driverAvatar}` : null;
  const vehicleImagePath = !isShowDriverAvatar && vehicleImage ? `${process.env.REACT_APP_FILES_SERVER}/vehicle-photo/${vehicleImage}` : null;
  const iconPath = `assets/icons_maps/${vehicleIcon}.png`;

  return (
    <Container>
      <BackgroundCircle color={color} />
      <Image src={vehicleImagePath || driverAvatarPath || iconPath} />
    </Container>
  );
};

export default VehicleIconMap;
