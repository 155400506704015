const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_KEYMAPS}&libraries=geometry,places,marker&v=3.58`;

export const loadMapApi = (): Promise<typeof google.maps> => {
    return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(`script[src^="https://maps.googleapis.com/maps/api/js"]`);
        if (existingScript) {
            console.log("Google Maps script already exists.");
            if (existingScript.getAttribute('data-loaded') === 'true') {
                console.log("Google Maps script is already loaded.");
                resolve(window.google.maps);
            } else {
                existingScript.addEventListener('load', () => {
                    console.log("Google Maps script loaded.");
                    resolve(window.google.maps);
                });
                existingScript.addEventListener('error', (error) => {
                    console.error('Failed to load existing Google Maps API:', error);
                    reject(new Error('Failed to load existing Google Maps API'));
                });
            }
            return;
        }

        console.log("Creating Google Maps script.");
        const googleMapScript = document.createElement('script');
        googleMapScript.src = mapsURL;
        googleMapScript.async = true;
        googleMapScript.defer = true;
        googleMapScript.setAttribute('data-loaded', 'true');

        googleMapScript.onload = () => {
            console.log("Google Maps script loaded.");
            resolve(window.google.maps);
        };

        googleMapScript.onerror = (error) => {
            console.error('Failed to load new Google Maps API:', error);
            reject(new Error('Failed to load new Google Maps API'));
        };

        document.body.appendChild(googleMapScript);
    });
};
