import React from 'react';
import UploadImageForm, { ImageType } from '../components/UploadImageForm';
import { styled } from 'styled-components';
import { getListDriversV4 } from '../../Drivers/Gateways/DriversGateway';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { ExpenseItemScreen, getCategories, getFuel, getVehiclesToExpense, iFuelTypeExpense, iVehicleExpense, saveExpense, uploadImageExpense } from '../repository/ExpensesRepo';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { getSyncVersion } from '../../../utils/Converter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

import { Form } from '@unform/web';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as yup from 'yup';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface CompProps {
    initialData?: ExpenseItemScreen
    closeForm: () => void
}

const Container = styled.div`
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 750px;  
    gap: 5px;`;

const ContainerForm = styled.div`
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    max-width: 750px;
    min-width: 350px;  
    gap: 5px;`;

const ContainerInline = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 10px;`;

const ContainerBoxTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;`;

const ContainerInlinePicker = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;
    margin-bottom: 10px;
    `;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

interface Option {
    value: string;
    label: string;
}

enum FuelField {
    Amount = 'amount',
    PriceUnit = 'priceUnit',
    TotalUnit = 'totalUnit'
}

const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 750px;
    min-width: 600px;
    margin: 0 auto;
    border: 1px solid #ccc;
`;

let listVehicles: iVehicleExpense[] = []
let listFuel: iFuelTypeExpense[] = []


const FormExpensesCopy: React.FC<CompProps> = ({ initialData, closeForm }) => {
    const formRef = React.useRef<FormHandles>(null)
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const [categSel, setSelectCateg] = React.useState<number>(0);
    const [images, setImages] = React.useState<ImageType[]>([]);
    const [drivers, setDrivers] = React.useState<Option[]>([]);
    const [vehicles, setVehicles] = React.useState<Option[]>([]);
    const [categories, setCategories] = React.useState<Option[]>([]);
    const [fuel, setFuel] = React.useState<Option[]>([]);
    const [errors, setErrorsForms] = React.useState<FormErrors>({});
    const [dtEvt, setDtEvt] = React.useState<Dayjs | null>(dayjs(initialData?.evt_at));
    const [hrEvt, setHrEvt] = React.useState<Dayjs | null>(dayjs(initialData?.evt_at));


    const convertExpenseToDouble = (value: string): number => {
        const parsed = Number.parseFloat(value);
        return isNaN(parsed) ? 0 : parsed;
    };

    const extractFileName = (img: ImageType): string | null => {
        console.log('extractFileName', img)
        return img.fileLocal ? img.fileLocal.name : img.fileRemote ?? null;
    }

    const calculateFuel = (changedField: FuelField): void => {

        let amountValue = convertExpenseToDouble(formRef.current?.getFieldValue('amount'));
        let priceUnitValue = convertExpenseToDouble(formRef.current?.getFieldValue('price_unity'));
        let totalUnitValue = convertExpenseToDouble(formRef.current?.getFieldValue('qty'));

        switch (changedField) {
            case FuelField.Amount:
                if (priceUnitValue > 0) {
                    totalUnitValue = amountValue / priceUnitValue;
                    formRef.current?.setFieldValue('qty', totalUnitValue.toFixed(2))
                }
                break;
            case FuelField.PriceUnit:
                if (amountValue > 0) {
                    totalUnitValue = amountValue / priceUnitValue;
                    formRef.current?.setFieldValue('qty', totalUnitValue.toFixed(2))
                }
                break;
            case FuelField.TotalUnit:
                if (priceUnitValue > 0) {
                    amountValue = totalUnitValue * priceUnitValue;
                    formRef.current?.setFieldValue('amount', amountValue.toFixed(2))
                }
                break;
            default:
                console.log('calculateFuel', 'default')
                break;
        }
    };

    const calculateKm = (): void => {
        let kmAntValue = convertExpenseToDouble(formRef.current?.getFieldValue('km_start'));
        let kmAtualValue = convertExpenseToDouble(formRef.current?.getFieldValue('km_end'));
        if (kmAntValue > 0 && kmAtualValue > 0) {
            let kmRunValue = kmAtualValue - kmAntValue;
            if (kmRunValue < 0) {
                setErrorsForms({ ...errors, kmAtual: 'Km Atual deve ser maior que Km Anterior' })
            } else {
                setErrorsForms({ ...errors, kmAtual: undefined })
            }
        }
    }

    async function getListCategories() {
        try {
            const result = await getCategories();
            let list: Option[] = []
            for (let i = 0; i < result.length; i++) {
                list.push({ value: result[i].id, label: result[i].descr })
            }
            setCategories(list)

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }
    async function getListDrivers() {
        try {
            const result = await getListDriversV4();
            let list: Option[] = []
            for (let i = 0; i < result.length; i++) {
                list.push({ value: result[i].id, label: result[i].DriverName })
            }
            setDrivers(list)
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }
    async function getListVehicles() {
        try {
            const result = await getVehiclesToExpense();
            let list: Option[] = []
            for (let i = 0; i < result.length; i++) {
                list.push({ value: result[i].idvehicle, label: `${result[i].placa} / ${result[i].model}` })
            }
            listVehicles = result
            setVehicles(list)
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }
    async function getListFuel() {
        try {
            const result = await getFuel();
            let list: Option[] = []
            for (let i = 0; i < result.length; i++) {
                list.push({ value: result[i].id, label: `${result[i].descr}` })
            }
            listFuel = result
            setFuel(list)
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }

    function selectVehicleById(id: number): iVehicleExpense | undefined {
        return listVehicles.find(vehicle => vehicle.idvehicle === id);
    }


    const handleSelectCategory = (option: number) => {
        setSelectCateg(option)
        if (option === 1) {
            setItensVehicle(formRef.current?.getFieldValue('id_veiculo') ?? '0')
        }
    }

    const setItensVehicle = (id: string) => {
        let vehicle = selectVehicleById(id ? parseInt(id) : 0)
        if (vehicle) {
            let fuel = listFuel.find(f => f.id === vehicle?.fuel_primary)
            formRef.current?.setFieldValue('km_start', vehicle.km_last_abast)
            formRef.current?.setFieldValue('km_end', vehicle.odometer_vehicle)
            if (fuel !== undefined) {
                formRef.current?.setFieldValue('fuel_cod', fuel.fuel_cod)
            }
        }
    }

    const handleSelectVehicle = () => {
        if (categSel === 1) {
            setItensVehicle(formRef.current?.getFieldValue('id_veiculo') ?? '0')
        };
    }

    const submitForm: SubmitHandler<iExpense> = async data => {

        try {
            formRef.current?.setErrors({})
            let itemShape: any = {
                descr: yup.string().required("Esse campo é Obrigatório"),
                amount: yup.number().required("Esse campo é Obrigatório"),
                id_veiculo: yup.number().required("Esse campo é Obrigatório"),
                id_motorista: yup.number().required("Esse campo é Obrigatório"),
                id_categ: yup.number().required("Esse campo é Obrigatório"),
            };
            if (Number(data.id_categ) === 1) {
                itemShape = {
                    ...itemShape,
                    price_unity: yup.number().required("Esse campo é Obrigatório"),
                    qty: yup.number().required("Esse campo é Obrigatório"),
                    km_start: yup.number().required("Esse campo é Obrigatório"),
                    km_end: yup.number().required("Esse campo é Obrigatório"),
                    fuel_cod: yup.string().required("Esse campo é Obrigatório"),
                }
            }
            const schema = yup.object().shape(itemShape);
            await schema.validate(data, {
                abortEarly: false,
            });

            let newErrors: FormErrors = {};
            if (images.length === 0) newErrors.images = "Adicione pelo menos uma imagem."

            let km_start = data.km_start ? data.km_start : 0
            let km_end = data.km_end ? data.km_end : 0
            let kmRunValue = 0
            let avgFuelValue = 0
            let costKm = 0
            if (km_start > 0 && km_end > 0) {
                kmRunValue = km_end - km_start;
                avgFuelValue = data.qty ? kmRunValue / data.qty : 0;
                costKm = data.amount ? data.amount / kmRunValue : 0;
            }

            let dtEvt = getDateEvt();
            let expense: iExpense = {
                unique_id: initialData?.unique_id ? initialData.unique_id : uuidv4(),
                id_client: user.idcliente,
                id_empresa: user.idempresa,
                id_motorista: data.id_motorista,
                id_viagem: 0,
                id_veiculo: data.id_veiculo,
                fornecedor_uuid: null,
                id_categ: data.id_categ,
                descr: data.descr,
                amount: data.amount,
                expense_type: data.id_categ === 7 ? 'C' : 'D',
                status: data.status ? data.status : 'PENDENTE',
                lat: 0,
                lng: 0,
                photo1: images[0] ? extractFileName(images[0]) : null,
                photo2: images[1] ? extractFileName(images[1]) : null,
                photo3: images[2] ? extractFileName(images[2]) : null,
                km_start: km_start,
                km_end: km_end,
                km_run: kmRunValue,
                avg_fuel: avgFuelValue,
                cost_km: costKm,
                qty: data.qty ? data.qty : 0,
                price_unity: data.price_unity ? data.price_unity : 0,
                obs: null,
                fuel_cod: data.fuel_cod ? data.fuel_cod : null,
                fuel_descr: data.fuel_descr ? data.fuel_descr : null,
                tipo_payment_id: null,
                evt_at: dtEvt,
                sync_version: getSyncVersion()
            }
            // console.log(expense)
            SaveExpenseFunc(expense)
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }

    const uploadImages = async () => {
        try {
            for (let x = 0; x < images.length; x++) {
                if (images[x].fileLocal !== undefined) {
                    let file = images[x].fileLocal as File
                    let resp = await uploadImageExpense(file, user)
                    console.log('uploadImageExpense', resp)
                }
            }
            addNotification('', 'Imagens Salvas com sucesso', 'success');
            closeForm()
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            closeForm()
        }
    }

    const SaveExpenseFunc = async (expense: iExpense) => {
        try {
            const result = await saveExpense(expense);
            addNotification('', 'Despesa salva com sucesso', 'success');
            formRef.current?.reset()
            uploadImages()
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }


    }



    React.useEffect(() => {
        getListDrivers()
        getListVehicles()
        getListCategories()
        getListFuel()
    }, [])

    /*React.useEffect(() => {
        console.log('initialData', initialData)
    }, [initialData])*/

    React.useEffect(() => {
        let cod = initialData?.id_categ ? initialData?.id_categ.toString() : '0'
        formRef.current?.setFieldValue('id_categ', cod)
        setSelectCateg(initialData?.id_categ ?? 0)
    }, [categories])

    React.useEffect(() => {
        let cod = initialData?.id_veiculo ? initialData?.id_veiculo.toString() : '0'
        formRef.current?.setFieldValue('id_veiculo', cod)
    }, [vehicles])

    React.useEffect(() => {
        let cod = initialData?.id_motorista ? initialData?.id_motorista.toString() : '0'
        formRef.current?.setFieldValue('id_motorista', cod)
    }, [drivers])

    React.useEffect(() => {
        let cod = initialData?.fuel_cod ? initialData?.fuel_cod.toString() : '0'
        formRef.current?.setFieldValue('fuel_cod', cod)
    }, [fuel])

    const getDateEvt = () => {
        if (!dtEvt || !hrEvt) return '';
        const hours = hrEvt.hour();
        const minutes = hrEvt.minute();
        const seconds = hrEvt.second();

        // Atualiza dtEvt com as horas, minutos e segundos extraídos
        const combinedDateTime = dtEvt.hour(hours).minute(minutes).second(seconds);

        // Retorna a data formatada como string em formato ISO local
        return combinedDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
    }

    const setImagesItens = () => {
        let images: ImageType[] = []
        if (initialData) {
            if (initialData.photo1) {
                images.push({ fileRemote: initialData.photo1 })
            }
            if (initialData.photo2) {
                images.push({ fileRemote: initialData.photo2 })
            }
            if (initialData.photo3) {
                images.push({ fileRemote: initialData.photo3 })
            }
        }
        return images
    }


    return (
        <Form placeholder={""} ref={formRef} onSubmit={submitForm} noValidate={true} initialData={initialData}>

            <Container>
                <ContainerBoxTop>
                    <UploadImageForm onImagesChange={setImages} imageUrls={
                        setImagesItens()
                    } />
                    <ContainerInlinePicker>
                        <DatePicker
                            value={dtEvt}
                            label="Data da Despesa"
                            onChange={(newValue) => setDtEvt(newValue)}
                            format='DD/MM/YYYY'
                        />
                        <TimeField
                            value={hrEvt}
                            label="Hora da Despesa"
                            onChange={(newValue) => setHrEvt(newValue)}
                            format="HH:mm:ss"
                        />
                    </ContainerInlinePicker>
                </ContainerBoxTop>
                {errors.images && (
                    <ErrorMessage>
                        <FontAwesomeIcon icon={faExclamationCircle} /> {errors.images}
                    </ErrorMessage>)}
                <ContainerInline>
                    <ContainerForm>
                        <SelectFieldForm
                            options={categories}
                            label={'Selecione a Categoria'}
                            name={'id_categ'}
                            onChange={(e) => {
                                handleSelectCategory(Number(e.target.value) ?? 0)
                            }}
                        />
                        <SelectFieldForm
                            name={'id_veiculo'}
                            options={vehicles}
                            label={'Selecione um Veículo'}
                            onChange={(e) => {
                                handleSelectVehicle()
                            }}
                        />
                        {categSel === 1 && <SelectFieldForm name={'fuel_cod'} options={fuel} label={'Selecione um combustível'} />}
                        <SelectFieldForm name={'id_motorista'} options={drivers} label={'Selecione um Motorista'} />

                    </ContainerForm>
                    <ContainerForm>
                        <InputFieldForm
                            label="Descrição"
                            placeholder="Descrição"
                            name={'descr'}
                            type='text'
                        />
                        <InputFieldForm
                            label="Valor pago"
                            placeholder="Valor Pago"
                            name={'amount'}
                            type='number'
                            onChange={(e) => {
                                calculateFuel(FuelField.Amount);
                            }}
                        />
                        {categSel === 1 && <>
                            <ContainerInline>
                                <div>
                                    <InputFieldForm
                                        label="Preço / Litro"
                                        placeholder="Preço / Litro"
                                        name='price_unity'
                                        type='number'
                                        onChange={(e) => {
                                            calculateFuel(FuelField.PriceUnit);
                                        }}
                                    />
                                </div>
                                <div>
                                    <InputFieldForm
                                        label="Litros"
                                        placeholder="Litros"
                                        name={'qty'}
                                        type='number'
                                        onChange={(e) => {
                                            calculateFuel(FuelField.TotalUnit);
                                        }}
                                    />
                                </div>
                            </ContainerInline>
                            <ContainerInline>
                                <div>
                                    <InputFieldForm
                                        label="Km Anterior"
                                        placeholder="km último abastecimento"
                                        name={'km_start'}
                                        type='number'
                                        onChange={(e) => {
                                            calculateKm();
                                        }}
                                    />
                                </div>
                                <div>
                                    <InputFieldForm
                                        label="Km Atual"
                                        placeholder="Km Atual"
                                        name={'km_end'}
                                        type='number'
                                        onChange={(e) => {
                                            calculateKm();
                                        }}
                                    />
                                </div>
                            </ContainerInline></>}


                    </ContainerForm>
                </ContainerInline>

                <div className="flex justify-around w-full max-w-md">
                    <button
                        className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors"
                        onClick={() => {
                            closeForm()
                            formRef.current?.reset()
                        }}>
                        Cancelar
                    </button>
                    <button
                        className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors"
                        type="submit">
                        Confirmar
                    </button>
                </div>
            </Container>
        </Form>
    )

}
export default FormExpensesCopy


export interface FormErrors {
    categorie?: string;
    vehicle?: string;
    driver?: string;
    fuel?: string;
    descr?: string;
    amount?: string;
    priceUnit?: string;
    qty?: string;
    kmAnt?: string;
    kmAtual?: string;
    images?: string;
}


export type iExpense = {
    unique_id: string
    id_client: number;
    id_empresa: number;
    id_motorista: number;
    id_viagem: number;
    id_veiculo: number;
    fornecedor_uuid: string | null;
    id_categ: number;
    descr: string;
    amount: number;
    expense_type: string;
    status: string;
    lat: number | null;
    lng: number | null;
    photo1: string | null;
    photo2: string | null;
    photo3: string | null;
    km_start: number | null;
    km_end: number | null;
    cost_km: number | null;
    km_run: number | null;
    avg_fuel: number | null;
    qty: number | null;
    price_unity: number | null;
    obs: string | null;
    fuel_cod: string | null;
    fuel_descr: string | null;
    tipo_payment_id: number | null;
    evt_at: string;
    created_at?: string;
    updated_at?: string;
    sync_version: number | null;
};

