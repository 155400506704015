


export const formatHtmlToWhatsapp = (htmlContent: string): string => {
    return htmlContent
        .replace(/<br\s*\/?>/g, '\n') // Substitui <br> ou <br/> por \n
        .replace(/<\/?b>/g, '*') // Substitui <b> e </b> por * para negrito
        .replace(/<\/?strong>/g, '*') // Substitui <strong> e </strong> por * para negrito
        .replace(/<\/?[^>]+(>|$)/g, '') // Remove qualquer outra tag HTML
        .replace(/&nbsp;/g, ' ') // Substitui &nbsp; por espaço
        .replace(/&amp;/g, '&') // Substitui &amp; por &
        .replace(/&lt;/g, '<') // Substitui &lt; por <
        .replace(/&gt;/g, '>'); // Substitui &gt; por >
};