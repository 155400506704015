import React from 'react';
import styled from 'styled-components';

interface ModalProps {
  title: string;
  subtitle: string;
  onClose: () => void;
  isShowCloseButton?: boolean;
  children: React.ReactNode;


}

const ModalComp: React.FC<ModalProps> = ({ title, subtitle, onClose, children, isShowCloseButton }) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          {isShowCloseButton && <CloseButton onClick={onClose}>&times;</CloseButton>}
        </ModalHeader>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <ModalBody>
          {children}
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalComp;

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
`;

const ModalContent = styled.div`
  background: white;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  min-width: 350px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  font-size: 22px;
  font-weight: bold;
  margin: 5px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
  font-weight: 600;
  margin-right: 10px;

  &:hover {
    color: #ff0000;
  }
`;

const Subtitle = styled.p`
  margin-top: 1rem;
  font-size: 1rem;
  color: #555;
`;

const ModalBody = styled.div`
  margin-top: 5px;
`;

