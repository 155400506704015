
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
//import CategsDialog from '../CategsDialog';
import { iCateg } from '../../interfaces/interfaces';
import httpCommon from '../../services/http-common';
import CategModal from '../Modal/CategModal';


interface iPropsCateg {
    idCateg?: number
    selectCateg?: (item: iCateg) => void
}

const ImgBox = styled.img`
    margin: 0 auto;
    height: 46px;
`

const Box = styled('div')({
    marginLeft: '5px',
    margin: '10px'
});

const Container = styled('div')({
    display: 'flex',
    padding: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '5px',
    maxWidth: '250px',
    minHeight: '70px',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)'

});

const ContainerInfo = styled('div')({
    display: 'block',
    padding: 5,
    minWidth: '170px'

});

const ContainerBlock = styled('div')({
    display: 'block',
    justifyContent: 'space-between'
});

const TextApelido = styled('div')({
    fontSize: '10px',
    textTransform: 'uppercase'
});
const Title = styled('div')({
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px'
});
const Subtitle = styled('div')({
    fontSize: '10px',
    fontWeight: '600',
    textTransform: 'uppercase'
});

const CardCategWidget: React.FC<iPropsCateg> = (props) => {

    const [Categ, SetCateg] = React.useState<iCateg>()
    const [IsOpen, setOpen] = React.useState<boolean>(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (item: iCateg) => {
        handleClose()
        SetCateg(item)
        if (props.selectCateg) { props.selectCateg(item) }
    };

    const handleGetInfoCateg = async () => {
        const result = await httpCommon.get("getCategInfo", { params: { idCateg: props.idCateg } })
        let resp = result.data;
        handleSelect(resp)
    }


    React.useEffect(() => {
        if (props.idCateg) {
            handleGetInfoCateg()
        }
    }, [props.idCateg])

    return (
        <>
            {<CategModal onClose={handleClose} open={IsOpen} selectCateg={handleSelect} />}
            <Box>
                <TextApelido>Categoria</TextApelido>
                {Categ?.id ? <Container onClick={() => handleClickOpen()}>
                    <ImgBox src={require(`../../assets/icons/${Categ.icon}`)} />
                    <ContainerInfo>
                        <Title>{Categ.descr}</Title>
                    </ContainerInfo>
                </Container>
                    : <>
                        <Container onClick={() => handleClickOpen()}>
                            <Avatar src="/broken-image.jpg" />
                            <ContainerInfo>
                                <Subtitle>Selecione uma categoria</Subtitle>
                            </ContainerInfo>
                        </Container>
                    </>}
            </Box>

        </>
    )

}
export default CardCategWidget