import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Interfaces
export interface iQRCode {
  status: 'QRCODE' | 'CLOSED' | 'INITIALIZING' | 'CONNECTED';
  qrcode?: string;
  urlcode?: string;
  version?: string;
  session?: string;
}

export interface QRCodeProps {
  params: iQRCode;
  logoutSession?: () => void;
}

// Estilização
const Card = styled.div`
 width: 400px;
 margin: 50px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: white;
  border-radius: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const QRCodeImage = styled.img`
  margin: 10px;
  max-width: 200px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  border: 2px solid;
  background: transparent;
`;

const LoadingText = styled.p`
  color: #666;
`;

const StatusMessage = styled.p`
  color: #007BFF;
`;

const ConnectedIcon = styled.span`
  color: green;
  font-size: 24px;
  margin-bottom: 10px;
`;

// Componente
const QRCodeComponent: React.FC<QRCodeProps> = ({ params, logoutSession }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (params.status === 'INITIALIZING') {
      setSeconds(0);
      interval = setInterval(() => {
        setSeconds(prev => prev + 1);
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [params.status]);

  switch (params.status) {
    case 'QRCODE':
      return (
        <Card>
          <Container>
            <QRCodeImage src={params.qrcode} alt="QR Code" />
            <StatusMessage>O QRCode deve ser lido em até 40 segundos.</StatusMessage>
          </Container>
        </Card>
      );

    case 'CLOSED':
    case 'INITIALIZING':
      return (
        <Card>
          <Container>
            <LoadingText>Inicializando, pode demorar alguns segundos... {seconds}</LoadingText>
          </Container>
        </Card>
      );

    case 'CONNECTED':
      return (
        <Card>
          <Container>
            <ConnectedIcon>✔ Conectado</ConnectedIcon>
          </Container>
        </Card>
      );

    default:
      return <Card><Container>Status desconhecido</Container></Card>;
  }
};

export default QRCodeComponent;
