
import React, { useState } from 'react';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../style/StyleTemplate';

import { useNotifications } from '../../../Notifications/NotificationContext';
import NoData from '../../../components/NoData';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../../components/datagridComp/SideBar';
import { handleSearch } from '../../../utils/searchUtils';
import { getTemplatesByEmpresa, iTemplateScreen } from '../repository/TemplateRepo';
import { getTemplateColumns } from '../Utils/TemplateColumns';
import TemplateEditor from './TemplateEditor';


const EDIT_FORM = 'EDIT_FORM';
const NEW_FORM = 'NEW_FORM';



const TemplatePage: React.FC = () => {

    const { addNotification } = useNotifications();
    const [itemEdit, setItemEdit] = React.useState<iTemplateScreen | undefined>(undefined);


    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();
    const handleCloseSideBar = () => setSideContent(undefined);

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<iTemplateScreen[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);


    async function getListInServer() {
        try {
            const result = await getTemplatesByEmpresa();
            setRows(result);

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }


    // Função para editar o item, controlada pelo componente pai
    const handleEditItem = (row: iTemplateScreen) => {
        setItemEdit(row);
    };
    // Função para editar o item, controlada pelo componente pai
    const viewTemplate = (row: iTemplateScreen) => {
        setItemEdit(row);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    React.useEffect(() => {
        if (itemEdit) {
            setSideContent(EDIT_FORM);
        }
    }, [itemEdit]);

    const CancelFormFuel = () => {
        setItemEdit(undefined);
        setSideContent(undefined);
    }


    // Obtenha as colunas passando as funções de controle
    const columns = getTemplateColumns(handleEditItem, viewTemplate);

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((alert) => alert.id));
        setSelectedRows(allIds);
    };

    const handleNewInvoice = () => {
        setItemEdit(undefined);
        setSideContent(NEW_FORM);
    };

    const handleUpdateList = () => {
        getListInServer();
        CancelFormFuel();
    };


    React.useEffect(() => {
        getListInServer();
    }, []);



    return (
        <>

            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                </BtnFaIcon >
                <BtnFaIcon title='Cadastrar Novo Fornecedor' onClick={handleNewInvoice} >
                    <BoxIcon icon={faAdd} iconColor='green' /> Novo Template
                </BtnFaIcon >

            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
            </ContainerInfo>
            {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}


            <Sidebar width='800px' isOpen={sideContent === EDIT_FORM} onClose={CancelFormFuel} title={'Editar Template'} >
                {itemEdit &&
                    <TemplateEditor
                        onUpdate={handleUpdateList}
                        initialData={itemEdit}
                        onCancel={CancelFormFuel} />}
            </Sidebar>

            <Sidebar width='800px' isOpen={sideContent === NEW_FORM} onClose={CancelFormFuel} title={'Cadastro de Template'}>
                <TemplateEditor onUpdate={handleUpdateList} onCancel={CancelFormFuel} initialData={itemEdit} />
            </Sidebar>
        </>
    )
}
export default TemplatePage