import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar/Avatar';
import Stack from '@mui/material/Stack';
import { iDestinos, iPlaces } from '../interfaces/interfaces';
import PlacesDialog from './PlacesDialog';
import { Flag } from '@mui/icons-material';
import DialogListDest from './DialogListDest';

interface DialogProps {
    handleSelPoint: (item: iDestinos) => void,
    title: string
    PlaceSel?: iDestinos
}

const PlacesComp: React.FC<DialogProps> = (props) => {

    const [itemSelect, setSelected] = React.useState<iDestinos>()
    const [IsOpen, setOpen] = React.useState<boolean>(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (params: iPlaces) => {
        /*setSelected(params)
        props.handleSelPoint(params)
        handleClose()*/
    }

    const handleSelectDestinos = (params: iDestinos) => {
        setSelected(params)
        props.handleSelPoint(params)
        handleClose()
    }

    React.useEffect(() => {
        console.log("useEffect Places Comp")
        if (props.PlaceSel) setSelected(props.PlaceSel)
    }, [props.PlaceSel])

    const card = (
        <div className='cardBox'>
            <div className='titleCard'>{props.title}</div>
            <div className='cardContent' onClick={handleClickOpen}>
                <Stack direction="row" spacing={1}>
                    <Avatar sx={{ width: 32, height: 32 }} ><Flag /></Avatar>
                    <div className='infoCards'>
                        Selecione aqui
                    </div>
                </Stack>
            </div>
        </div>
    );

    const cardItem = (
        <div className='cardBox'>
            <div className='titleCard'>{props.title}</div>
            <div className='cardContent' onClick={handleClickOpen}>
                <Stack direction="row" spacing={1}>
                    <Avatar sx={{ width: 32, height: 32 }} ><Flag /></Avatar>
                    <div className='infoCards'>
                        {itemSelect?.descr}
                    </div>
                </Stack>
            </div>
        </div>
    );

    return <>
        {/*<PlacesDialog onClose={handleClose} open={IsOpen} onMultiSelect={false} handleSelect={handleSelect} />*/}
        <DialogListDest onClose={handleClose} open={IsOpen} onSelect={handleSelectDestinos} onMultiSelect={false} />
        <Box sx={{ minWidth: 200, maxWidth: 230 }}>
            {itemSelect ? <Card variant="outlined">{cardItem}</Card> : <Card variant="outlined">{card}</Card>}
        </Box>
    </>
}

export default React.memo(PlacesComp)