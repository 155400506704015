import { createSlice } from '@reduxjs/toolkit'
import { iCred } from '../interfaces/interfaces'
import { RootState } from './store'




export const slice = createSlice({
    name: 'cred',
    initialState: {} as iCred,
    reducers: {
        changeCred(state, { payload }) {
            return { ...state, ...payload }

        },
        logout(state) {
            return { ...state, ...{} }
        }
    }
})

export const { changeCred, logout } = slice.actions

export const selectCred = (state: RootState) => state

export default slice.reducer