import React from 'react';
import styled from 'styled-components';

// Definição dos estilos do container dos cards
const CardsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 20px;
  gap: 10px;
`;

// Estilo de cada card individual
const Card = styled.div`
  min-width: 200px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
`;

// Estilo para o título da categoria com destaque
const CategoryTitle = styled.h4`
  font-weight: bold;
  color: #d35400; // Cor laranja escura para destaque
`;

// Estilo para destacar o valor monetário
const Amount = styled.p`
  font-size: 1.2em;
  font-weight: bold;
  color: #27ae60; // Cor verde para valores monetários
  /*background-color: #ecf0f1; */// Cor de fundo suave para destacar o valor
  padding: 5px;
  border-radius: 4px;
`;

// Estilo para o ícone SVG
const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;
  color: #d35400;
`;

interface Category {
  category: string;
  category_icon: string;
  total_amount: number;
}

interface Props {
  categories: Category[];
}

const CategoryCardList: React.FC<Props> = ({ categories }) => {

  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  return (
    <CardsContainer>
      {categories.map((category, index) => (
        <Card key={index}>
          <Icon src={`/assets/icons_category/${category.category_icon}`} alt={category.category} />
          <div>
            <CategoryTitle>{category.category}</CategoryTitle>
            <Amount>{currencyFormatter.format(category.total_amount)}</Amount>
          </div>
        </Card>
      ))}
    </CardsContainer>
  );
};

export default CategoryCardList;
