import React from 'react';
import styled from 'styled-components';
import { FaVideoSlash } from 'react-icons/fa';

const PlaceholderContainer = styled.div<{ width: string | number; height: string | number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: #616161;
  color: #fff;
  text-align: center;
  padding: 20px;
`;

const Icon = styled(FaVideoSlash)`
  font-size: 4rem;
  margin-bottom: 20px;
`;

const Message = styled.p`
  font-size: 1.2rem;
  margin: 0;
`;

interface VideoPlaceholderProps {
  width: string | number;
  height: string | number;
  message?: string;
}

const VideoPlaceholder: React.FC<VideoPlaceholderProps> = ({ width, height, message }) => {
  return (
    <PlaceholderContainer width={width} height={height}>
      <Icon />
      <Message>{message || 'Transmissão indisponível!'}</Message>
    </PlaceholderContainer>
  );
};

export default VideoPlaceholder;
