import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Stack, IconButton, Paper, InputBase } from '@mui/material';
import { Settings, AddCircle, Delete, Refresh, Save, Cancel, LocalShipping, Search, LocationOn, ChatOutlined, Filter, FilterAlt, Chat } from '@mui/icons-material';
import { ctrlButtons } from '../interfaces/interfaces'
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'


interface BarProps {
    onSettingsClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onAddClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onRefresClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onDeleteClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onSaveClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onCancelClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onRouterClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onMapClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onChatClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onFilterClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onSearchValue?: (val: string) => void
    CtrlButtons?: ctrlButtons
}

const BarTable: React.FC<BarProps> = (props) => {

    const [search, setSearch] = React.useState<string>()
    const handleSearchClick = () => {
        props.onSearchValue && props.onSearchValue(search || '')
    }

    return (
        <>
            <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                {props.CtrlButtons?.addShow ? <IconButton aria-label="add" disabled={props.CtrlButtons?.addDisable} color="primary" onClick={props.onAddClick}>
                    <AddCircle />
                </IconButton> : <></>}
                {props.CtrlButtons?.RefreshShow ? <IconButton aria-label="refresh" disabled={props.CtrlButtons?.RefreshDisable} color="primary" onClick={props.onRefresClick}>
                    <Refresh />
                </IconButton> : <></>}
                {props.CtrlButtons?.DeleteShow ? <IconButton aria-label="delete" disabled={props.CtrlButtons?.DeleteDisable} color="primary" onClick={props.onDeleteClick}>
                    <Delete />
                </IconButton> : <></>}
                {props.CtrlButtons?.SaveShow ? <IconButton aria-label="save" disabled={props.CtrlButtons?.SaveDisable} color="primary" onClick={props.onSaveClick}>
                    <Save />
                </IconButton> : <></>}
                {props.CtrlButtons?.CancelShow ? <IconButton aria-label="cancel" disabled={props.CtrlButtons?.CancelDisable} color="primary" onClick={props.onCancelClick}>
                    <Cancel />
                </IconButton> : <></>}
                {props.CtrlButtons?.RouterShow ? <IconButton aria-label="cancel" disabled={props.CtrlButtons?.RouterDisable} color="primary" onClick={props.onRouterClick}>
                    <LocalShipping />
                </IconButton> : <></>}
                {props.CtrlButtons?.OpenMap ? <IconButton aria-label="cancel" disabled={props.CtrlButtons?.OpenMapDisable} color="primary" onClick={props.onMapClick}>
                    <LocationOn />
                </IconButton> : <></>}
                {props.CtrlButtons?.ChatShow ? <IconButton aria-label="cancel" disabled={props.CtrlButtons?.ChatDisable} color="primary" onClick={props.onChatClick}>
                    <Chat />
                </IconButton> : <></>}
                {props.CtrlButtons?.FilterShow ? <IconButton aria-label="cancel" disabled={props.CtrlButtons?.FilterDisable} color="primary" onClick={props.onFilterClick}>
                    <FilterAlt />
                </IconButton> : <></>}
                {props.CtrlButtons?.SettingsShow ? <IconButton aria-label="cancel" disabled={props.CtrlButtons?.SettingsDisable} color="primary" onClick={props.onSettingsClick}>
                    <Settings />
                </IconButton> : <></>}
                {props.CtrlButtons?.SearchBarShow ?
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', width: 300 }}
                    >

                        <InputBase
                            placeholder="Pesquisar..."
                            inputProps={{ 'aria-label': 'search google maps' }}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyPress={event => { if (event.key === 'Enter') { handleSearchClick() } }}
                        />
                    </Paper> : <></>}
                {props.CtrlButtons?.SearchBarShow ? <IconButton aria-label="cancel" disabled={props.CtrlButtons?.RouterDisable} color="primary" onClick={handleSearchClick}>
                    <Search />
                </IconButton> : <></>}

            </Stack>
        </>
    )

}
export default BarTable