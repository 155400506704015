import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faClock, faTruck } from '@fortawesome/free-solid-svg-icons';

interface DeliveryProgressChartGeralProps {
    totalTarefas: number;
    totalFinalizadas: number;
    totalCanceladas: number;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px;
`;

const CardsContainer = styled.div`
    display: flex;
    gap: 8px;
    /*background-color: #e0e0e0; /* fundo aplicado */
    padding: 5px;
    border-radius: 10px;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
    width: 175px;
    height: 75px;
    text-align: center;
    padding: 10px;
`;

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
`;

const CardContent = styled.div`
    font-size: 14px;
    color: #666;
`;

const ProgressBarContainer = styled.div`
    width: calc(170px * 3 + 40px); /* Largura proporcional aos 3 cards + gaps */
    background-color: #e0e0e0;
    border-radius: 5px;
`;

const ProgressBar = styled.div<{ progress: number }>`
    width: ${({ progress }) => progress}%;
    height: 10px;
    background-color: #4caf50;
    border-radius: 5px;
    transition: width 0.5s ease-in-out;
`;

const DeliveryProgressChartGeral: React.FC<DeliveryProgressChartGeralProps> = ({ totalTarefas, totalFinalizadas, totalCanceladas }) => {
    const totalCompleted = totalFinalizadas + totalCanceladas;
    const progress = (totalCompleted / totalTarefas) * 100;
    const totalEmAndamento = totalTarefas - totalCompleted;

    return (
        <Container>
            <CardsContainer>
                {/* Card Finalizadas */}
                <Card>
                    <CardHeader>
                        <FontAwesomeIcon icon={faCheckCircle} color="#4caf50" size="1x" />
                        <span>{totalFinalizadas}</span>
                    </CardHeader>
                    <CardContent>Finalizadas</CardContent>
                </Card>

                {/* Card Canceladas */}
                <Card>
                    <CardHeader>
                        <FontAwesomeIcon icon={faTimesCircle} color="#f44336" size="1x" />
                        <span>{totalCanceladas}</span>
                    </CardHeader>
                    <CardContent>Canceladas</CardContent>
                </Card>

                {/* Card Em Andamento */}
                <Card>
                    <CardHeader>
                        <FontAwesomeIcon icon={faTruck} color="#fa7d08" size="1x" />
                        <span>{totalEmAndamento}</span>
                    </CardHeader>
                    <CardContent>Em Andamento</CardContent>
                </Card>
            </CardsContainer>
            {/* Barra de Progresso */}
            <ProgressBarContainer>
                <ProgressBar progress={progress} />
            </ProgressBarContainer>

        </Container>
    );
};

export default DeliveryProgressChartGeral;
