import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar/Avatar';
import Stack from '@mui/material/Stack';
import DriversDialog from './DriversDialog';
import { iDrivers } from '../interfaces/interfaces';

import './CompStyles.css'

interface DialogProps {
    handleSelDriver: (item: iDrivers) => void,
    DriverSel?: iDrivers
}

const DriversComp: React.FC<DialogProps> = (props) => {

    const [itemSelect, setSelected] = React.useState<iDrivers>()
    const [IsOpen, setOpen] = React.useState<boolean>(false);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (item: iDrivers) => {
        handleClose()
        setSelected(item)
        props.handleSelDriver(item)
    };

    React.useEffect(() => {
        console.log("useEffect Driver Comp")
        if (props.DriverSel) setSelected(props.DriverSel)
    }, [props.DriverSel])

    const card = (
        <div className='cardBox'>
            <div className='titleCard'>Colaborador</div>
            <div className='cardContent' onClick={handleClickOpen}>
                <Stack direction="row" spacing={1}>
                    <Avatar
                        sx={{ width: 32, height: 32 }}
                    />
                    <div className='infoCards'>
                        Selecione aqui
                    </div>
                </Stack>
            </div>
        </div>
    );

    const cardItem = (
        <div className='cardBox'>
            <div className='titleCard'>Colaborador</div>
            <div className='cardContent' onClick={handleClickOpen}>
                <Stack direction="row" spacing={1}>
                    <Avatar
                        sx={{ width: 32, height: 32 }}
                    />
                    <div className='infoCards'>
                        {itemSelect?.DriverName}
                    </div>
                </Stack>
            </div>
        </div>
    );

    return <>
        <DriversDialog onClose={handleClose} open={IsOpen} onMultiSelect={false} handleSelect={handleSelect} />
        <Box sx={{ minWidth: 200, maxWidth: 230 }}>
            {itemSelect ? <Card variant="outlined">{cardItem}</Card> : <Card variant="outlined">{card}</Card>}
        </Box>
    </>
}

export default React.memo(DriversComp)