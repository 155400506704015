import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, Avatar, ListItem, ListItemAvatar, ListItemText, Box, TextField, Stack } from '@mui/material';
import * as React from 'react';
import { pink } from '@mui/material/colors';
import { iDialogConfirme } from '../interfaces/interfaces';

import { DeleteForever, Cancel } from '@mui/icons-material';


interface DialogProps {
    open: boolean,
    onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    onConfirme?: () => void,
    content?: iDialogConfirme
}



const DialogConfirme: React.FC<DialogProps> = (props) => {


    return <>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '50%', minHeight: 300, maxHeight: 500, maxWidth: 500 } }}
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>{props.content?.title}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} marginBottom={5} justifyContent="center" alignItems="center"><DeleteForever sx={{ fontSize: 60, color: pink[500] }} /></Stack>

                <Stack spacing={2} justifyContent="center" alignItems="center">{props.content?.msg}</Stack>


            </DialogContent>
            <DialogActions>
                <Button variant="contained" color='error' autoFocus onClick={props.onClose}>
                    Cancelar
                </Button>
                <Button variant="outlined" onClick={props.onConfirme}>Confirmar</Button>
            </DialogActions>
        </Dialog></>

}

export default React.memo(DialogConfirme)