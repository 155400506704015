

import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { IconButton, Collapse, AlertTitle, SvgIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { iMsgAlert } from '../interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { CloseAlert, selectAlert } from '../redux/alertServiceSlice';





const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let timerAlert: any;

const AlertComp: React.FC = (props) => {
  const dispatch = useDispatch();
  const { alertService } = useSelector(selectAlert);

  const handleClose = () => {
    dispatch(CloseAlert({ open: false, icon: '', msg: '', sound: null, title: '' }))
  }

  const tocarAlertSound = (filepath: string | undefined) => {
    if (!filepath) {
      console.log("file undefined", filepath)
      return
    }
    let fileAudio = require(`../../public/assets/sounds/${filepath}`)
    fetch(fileAudio)
      .then(response => {
        if (response.status === 200) {
          //let audio = new Audio(require(`../../public/assets/sounds/${filepath}`));
          fileAudio.play()
        }
      })
      .catch(error => {
        console.error('Erro ao verificar o arquivo:', error);
      });
  }

  React.useEffect(() => {
    console.log("AlertComp", alertService)
    if (alertService.open) {
      if (alertService.sound) {
        try {
          tocarAlertSound(alertService.sound)
          //let audio = new Audio(require(`../../public/assets/sounds/${alertService.sound}`));
          //audio.play()
        } catch (error) {
          console.log(error)
        }
      }
      if (timerAlert) { clearTimeout(timerAlert) }
      timerAlert = setTimeout(() => {
        handleClose()
      }, 5000);
    }
  }, [alertService])


  /*const MenuIcon = (props: any) => (
     <ReactSVG src="https://sistema.maxtracer.com.br/assets/user_phone.svg" onError={(error) => {
       console.error(error)
     }} />
    //<svg xmlns="https://sistema.maxtracer.com.br/assets/user_phone.svg"></svg>

    //<img className='svgAlertClass' src="https://sistema.maxtracer.com.br/assets/user_phone.svg" alt='icon' />

  )*/

  return (
    <div className='alert-style'>
      <Collapse in={alertService.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={alertService.type} sx={{ width: '100%' }}>

          <AlertTitle>{alertService.title}</AlertTitle>
          {alertService.msg}
        </Alert>
      </Collapse>
    </div>
  )

}
export default AlertComp