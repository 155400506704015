import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LabelItem = styled.div`
    display: block;
    font-size: medium;
    color: #7e7e7e;
    width: 180px;
`
export const LabelTitle = styled.div`
    display: block;
    font-size: medium;
    color: #7e7e7e;
    width: 150px;
`
export const InfoItem = styled.div`
    display: flex;
    padding-top: 8px;
`
export const ValueItem = styled.div`
    display: block;
    margin-left: 10px;
    color: black;
`

export const ValueAlert = styled.div`
   display: block;
    margin-left: 10px;
    color: white;
    background: red;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
`
export const ValueItem2 = styled.div`
    display: block;
    margin-left: 10px;
    color: black;
    font-weight: 500;
    font-size: 20px;
`
export const Container = styled('div')({
    display: 'block',
    padding: 8
});

export const ContainerFlexInline = styled('div')({
    display: 'flex',
    justifyContent: 'initial',
    alignItems: 'center'
});
export const ContainerFlexCenter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px'
});

export const ContainerCenter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});


export const ContainerCard = styled.div`
    display: flex;
    align-items: center;
    padding: 18px;
    background: #ffffff;
    margin: 7px;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 15%);
`
export const ContainerBar = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    background: #ffffff;
`
export const ContainerButton = styled.button`
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 5px;

`



export const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
`;

export const ContainerBarTop = styled.div`
padding: 6px;
display: flex;
    justify-content: space-between;
`

export const ContainerSearch = styled.div`
padding: 6px;
display: flex;
flex-direction: column;
    justify-content: space-between;
    > input {
        margin: 0;
        padding: 8px;
        border: 1px solid #7F7F7F;
        border-radius: 7px;
        width: 250px;
  }
`
export const ContainerInfo = styled.div`
padding: 6px;
display: flex;
    justify-content: left;
`

export const ContainerSpan = styled.div`
padding: 6px;
`

export const ContainerTable = styled.div`
padding: 6px;
    height: calc(100vh - 109px);
    overflow: auto;
    
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 2px;
  padding: 4px;
  //height: calc(100vh - 220px);
  overflow: auto;
`;

export const BtnCheckBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

export const InfoBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;


// export const BtnFaIcon = styled.span`
//   margin-right: 8px;
//   font-size: 12px;
//   font-weight: 700;
//   cursor: pointer;
//   display: inline-flex;
//   align-items: center;
//   gap: 4px; // Adiciona espaço entre o ícone e o texto
// `;

type BtnFaIconProps = {
    iconColor?: string; // Permite que a cor seja opcional
};

export const BtnFaIcon = styled.span<BtnFaIconProps>`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px; 
  color: ${({ iconColor }) => iconColor || 'green'};

  .fa-icon { // Assume que você adicionará esta classe ao seu ícone FontAwesome
    color: ${({ iconColor }) => iconColor || 'inherit'}; // Usa a cor passada, ou herda se não for especificada
  }
`;

export const BoxIcon = styled(FontAwesomeIcon) <{ iconColor?: string }>`
  color: ${({ iconColor }) => iconColor || 'green'};
  font-size: 16px;
`;

export const PriorityItem = styled.span<{ level: number }>`
  /*background-color: ${({ level }) => (level === 1 ? 'lightgreen' : level === 2 ? 'yellow' : 'red')};*/
  /*color: ${({ level }) => (level === 3 ? 'white' : 'black')};*/
  color: black;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

export const PointsItem = styled.span<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inline' : 'none')};
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;
