import React from 'react';
import { Column } from 'react-data-grid';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { styled } from 'styled-components';
import { getListAlertsSetup } from '../repository/AlertsRepo';

export interface AlertRow {
    id: number;
    title: string;
    descr: string;
    icon: string;
    type_alert: string;
}


const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '10px',
    height: '450px',
    overflow: 'auto'
});


interface CompProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSelectAlert: (row: AlertRow) => void;
}

const ModalListAlertType: React.FC<CompProps> = ({ isOpen, onRequestClose, onSelectAlert }) => {
    const [rows, setRows] = React.useState<AlertRow[]>([]);
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());

    const columns: Column<AlertRow>[] = [
        { key: 'id', name: 'ID' },
        { key: 'title', name: 'Título' },
        { key: 'descr', name: 'Descrição' }
    ];

    async function getDados() {
        try {
            const result = await getListAlertsSetup();
            setRows(result)
        } catch (e) {
            let error = e as Error
            console.log('[ModalListAlertType]', error)
        }
    }



    React.useEffect(() => {
        getDados()
    }, [])


    const handleRowClick = (row: AlertRow) => {
        onSelectAlert(row);
        onRequestClose();
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 800, maxHeight: 600 } }}
            maxWidth="md"
            open={isOpen}
            onClose={onRequestClose}
        >
            <DialogTitle>Tipos de Alertas</DialogTitle>
            <DialogContent>
                <ContainerTable>
                    <DataGridComp
                        rows={rows}
                        columns={columns}
                        selectedRows={selectedRows}
                        onRowClick={handleRowClick}
                    />
                </ContainerTable>
            </DialogContent>
        </Dialog>
    );
};
export default ModalListAlertType



