import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

interface controlLoad {
    open: boolean
    onClose: () => void
}

const LoadingBackdrop: React.FC<controlLoad> = (props) => {

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )

}
export default LoadingBackdrop