import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTimes,
    faLock,
    faCog,
    faRoute,
    faStreetView,
    faMapMarkedAlt,
    faShareSquare,
    faSatelliteDish,
    faSyncAlt,
    faBroom
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../components/datagridComp/Tooltip';

library.add(faTimes, faLock, faCog, faRoute, faStreetView, faMapMarkedAlt, faShareSquare, faSatelliteDish, faSyncAlt, faBroom);

const IconButtonContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 10px; /* Espaçamento entre os botões */
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }

  .fa-icon {
    font-size: 24px;
    color: #474747;
  }
`;

type IconButtonsProps = {
    mode: string;
    onIconClick: (action: string) => void;
};

const IconButtonsMonitor: React.FC<IconButtonsProps> = ({ mode, onIconClick }) => {
    return (
        <IconButtonContainer>
            <IconButton onClick={() => onIconClick(mode === 'HISTORY_VEHICLE' ? 'monitoring' : 'close')} data-tip="Fechar">
                <FontAwesomeIcon icon="times" className="fa-icon" />
            </IconButton>
            <IconButton onClick={() => onIconClick('settings')} data-tip="Configurações">
                <FontAwesomeIcon icon="cog" className="fa-icon" />
            </IconButton>

            {mode === 'ONE_VEHICLE' && (
                <>
                    <IconButton onClick={() => onIconClick('list')} data-tip="Listar">
                        <FontAwesomeIcon icon="route" className="fa-icon" />
                    </IconButton>
                    <Tooltip text="Sinal">
                        <IconButton onClick={() => onIconClick('streetView')} data-tip="Street View">
                            <FontAwesomeIcon icon="street-view" className="fa-icon" />
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => onIconClick('map')} data-tip="Google Maps">
                        <FontAwesomeIcon icon="map-marked-alt" className="fa-icon" />
                    </IconButton>
                    {/*<IconButton onClick={() => onIconClick('share')} data-tip="Compartilhar">
                        <FontAwesomeIcon icon="share-square" className="fa-icon" />
                    </IconButton>*/}
                    <IconButton onClick={() => onIconClick('lock')} data-tip="Bloquear">
                        <FontAwesomeIcon icon="lock" className="fa-icon" />
                    </IconButton>
                </>
            )}
            {mode === 'HISTORY_VEHICLE' && (
                <>
                    <IconButton onClick={() => onIconClick('clear')} data-tip="Limpar">
                        <FontAwesomeIcon icon="broom" className="fa-icon" />
                    </IconButton>
                    <IconButton onClick={() => onIconClick('update')} data-tip="Atualizar Registros">
                        <FontAwesomeIcon icon="sync-alt" className="fa-icon" />
                    </IconButton>
                </>
            )}
        </IconButtonContainer>
    );
};

export default IconButtonsMonitor;
