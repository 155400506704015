import React from 'react';
import SearchField from '../../../components/datagridComp/SearchField';
import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import DataGrid, { CellClickArgs, Column, SortColumn } from 'react-data-grid';
import { getComparator, renderCheckbox, renderSortStatus, rowKeyGetter } from '../../../utils/datagridTools';
import { httpAxiosV4 } from '../../../gateways/Axios';
import "../../../styles/datagrid/datagrid.css"

export type IChipProvider = {
    id: number;
    id_provider: number;
    apn: string;
    apn_user: string;
    apn_pass: string;
    Operadora: string;
    fornecedor: string;
};

const columns: Column<IChipProvider>[] = [
    {
        key: 'id',
        name: 'ID',
        width: 80
    },
    {
        key: 'fornecedor',
        name: 'Fornecedor',
        width: 150
    },
    {
        key: 'apn',
        name: 'APN',
        width: 230
    },
    {
        key: 'apn_user',
        name: 'Usuário APN',
        width: 120
    },
    {
        key: 'apn_pass',
        name: 'Senha APN',
        width: 120
    },
    {
        key: 'Operadora',
        name: 'Operadora',
        width: 120
    },

];

interface ChipProvidersSelectProps {
    onRowSelect?: (row: IChipProvider) => void;
}

const ChipProvidersSelect: React.FC<ChipProvidersSelectProps> = ({ onRowSelect }) => {

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);
    const [rows, setRows] = React.useState<IChipProvider[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<IChipProvider[]>(rows);



    async function getData() {
        try {
            const { data } = await httpAxiosV4<unknown, IChipProvider[]>(
                `chip-providers`,
                "GET"
            );
            setRows(data)
            setFilteredRows(data)

        } catch (e) {
        }
    }

    React.useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);


    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const sortedRows = React.useMemo((): readonly IChipProvider[] => {
        return [...filteredRows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator<IChipProvider>(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [filteredRows, sortColumns]);

    const handleCellClick = React.useCallback((args: CellClickArgs<IChipProvider, unknown>) => {
        const row = args.row;
        onRowSelect?.(row);
    }, [onRowSelect]);

    return (
        <div className="p-6 h-[calc(100vh-210px)] overflow-auto">

            <div>
                <div className="flex items-center gap-4">
                    <SearchField onSearch={handleSearch} />
                    <ButtonRefresh onClick={() => getData()} />
                </div>
            </div>
            <div className="flex flex-col box-border p-8">
                <DataGrid
                    columns={columns}
                    rows={sortedRows}
                    rowKeyGetter={rowKeyGetter}
                    onRowsChange={setRows}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    selectedRows={selectedRows}
                    renderers={{ renderSortStatus }}
                    onCellClick={handleCellClick}
                    defaultColumnOptions={{
                        resizable: true,
                        sortable: true
                    }}
                    className='DatagridClass'
                />
            </div>
        </div>
    );
};

export default ChipProvidersSelect;
