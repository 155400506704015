
// EventCard.tsx
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { iReduxMap, iTasks } from '../../../interfaces/interfaces';
import { ActionMaps } from '../../../utils/values';
import { changeMap } from '../../../redux/mapSlice';
import { useDispatch } from 'react-redux';
import StyledChipDelivery from './StyledChipDelivery';
import ImageGalleryTasks from './ImageGalleryTasks';

type CompProps = {
  item: iTasks;
  onSelect: (id: number) => void;
  editItem: (item: any) => void;
  isSelected: boolean;
};

const Card = styled.div<{ isSelected: boolean }>`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
  width: calc(100% - 16px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: ${({ isSelected }) => (isSelected ? '#e7f3ff' : 'white')};
`;

const TitleCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 2px;
`;

const Data = styled.span`
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  margin-right: 8px;
`;

const Points = styled.span<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inline' : 'none')};
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

const Priority = styled.span<{ level: number }>`
  /*background-color: ${({ level }) => (level === 1 ? 'lightgreen' : level === 2 ? 'yellow' : 'red')};*/
  /*color: ${({ level }) => (level === 3 ? 'white' : 'black')};*/
  color: black;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;
const Detail = styled.div`
  margin-bottom: 4px;
`;

const DetailWithIcons = styled.div`
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 8px; // Espaço entre ícones e texto
`;

// Placeholder para os ícones. Substitua estas linhas pelos componentes de ícone reais.
const VehicleIcon = styled.span`
cursor: pointer;
  &:before {
    content: "🚗"; // Placeholder para ícone de veículo
  }
`;

const IconsInfo = styled.span`
  cursor: pointer;
  display: inline-block; // Para permitir a aplicação do background
  width: 24px; // Largura do ícone
  height: 24px; // Altura do ícone
`;


const DriverIcon = styled.span`
cursor: pointer;
  &:before {
    content: "👤"; // Placeholder para ícone de motorista
  }
`;

const Separator = styled.div`
  height: 1px;
  background-color: #e0e0e0; // Escolha uma cor que combine com o design do seu card
  margin: 4px 0; // Ajuste as margens conforme necessário para o layout
`;

const InfoButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const MapIcon = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  &:before {
    content: '📌'; // Placeholder para ícone de mapa
  }
`;


const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
`;

const TextOverflow = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const TaskCard: React.FC<CompProps> = ({ item, onSelect, isSelected, editItem }) => {

  const dispatch = useDispatch()

  function removeHTMLTags(str: string): string {
    return str.replace(/<[^>]*>?/gm, '');
  }



  const viewInMap = () => {

    let mapConfig: iReduxMap = {
      mapEvtClick: false,
      resetMap: false,
      action: ActionMaps.SHOW_MARKER,
      ender: '',
      marker: {
        position: { lat: Number(item.lat), lng: Number(item.lng) },
        isDragable: false
      }
    }
    dispatch(changeMap(mapConfig))
  }

  useEffect(() => {
    console.log('TaskCard', item)
  }, [item])

  return (
    <Card isSelected={isSelected}>
      <TitleCheckboxContainer>
        <Title>{item.company}</Title>
      </TitleCheckboxContainer>
      <Separator />
      <InfoButtonContainer>
        <div>

        </div>
        <StyledChipDelivery status={item.status} />
      </InfoButtonContainer>
      <DetailContainer>
        <span>Cód: {item.idtask}</span>
      </DetailContainer>
      <DetailContainer>
        <span>{item.ender}</span>
      </DetailContainer>
      <Separator />
      <ImageGalleryTasks filenames={[
        item.photo1,
        item.photo2,
        item.photo3

      ]} />
    </Card>
  );
};

export default TaskCard;
