

import { httpAxiosV4 } from "../../../gateways/Axios";




export async function getListContracts(): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `contracts`,
        "GET"
    );
    return response;
}

