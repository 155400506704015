import React from 'react';
import { getListInvoices, itemCobrancas } from '../Gateways/FinanceGateway';

import 'react-data-grid/lib/styles.css';
import DataGrid, { SortColumn, SortDirection } from 'react-data-grid';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import '../../../styles/datagrid/datagrid.css'
import '../../../styles/datagrid/pagination.css'
import { getDateView } from '../../../utils/Converter';
import { DateFormatterBd, FormatterCurrency, FormatterDecimal } from '../../../utils/format';
import { selectUser } from '../../../redux/userSlice';
import { useSelector } from 'react-redux';
import { ContainerCard, ContainerDate, ContainerPicker } from '../../../components/Modal/StyleCompGlobal';


import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface CompProps {
    item: string
}


interface CompProps {
    item: string
}

type DataRow = {
    title: string;
    year: string;
};

const StatusPay = styled.div<{ value: number, num_days: number }>`
    color: ${p => p.value === 0 ? p.num_days <= 0 ? '#0b0fee' : '#ff0000cc' : '#08b611'};
    font-weight: 500;
    display: flex;
    align-items: center ;
    justify-content:center;
`


const pageSize = 50;

const ContainerDataGrid = styled.div`

`

const CobrancasPage: React.FC = () => {


    const [rows, setRows] = React.useState<itemCobrancas[]>([]);
    const { user } = useSelector(selectUser);
    const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [currentPage, setCurrentPage] = React.useState(0);
    const [filteredItens, setFilteredItens] = React.useState<itemCobrancas[]>([]);
    const [startDate, setStartDate] = React.useState(new Date());
    const [isOpenDataPicker, setOpenDataPicker] = React.useState(false);
    const [DateSelected, setDateSelected] = React.useState<string>();


    async function getDados() {
        const result = await getListInvoices({
            dtIni: '2023-03-01 00:00:00',
            dtFim: '2023-06-30 23:59:59',
            idempresa: user.idempresa
        });
        setRows(result)
        setFilteredItens(result)
    }

    React.useEffect(() => {
        getDados()
    }, [])

    const getStatus = (item: itemCobrancas) => {
        if (item.pago) return `PAGO ${item.pay_type ?? ''}`
        let resp = item.num_days === 0 ? 'VENCE HOJE' : item.num_days > 0 ? `ATRASADO À ${item.num_days} D` : `VENCE EM ${item.num_days * -1} D`
        return resp
    }


    /*const sortedRows = React.useMemo((): readonly itemCobrancas[] => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
          for (const sort of sortColumns) {
            const comparator = getComparator(sort.columnKey);
            const compResult = comparator(a, b);
            if (compResult !== 0) {
              return sort.direction === 'ASC' ? compResult : -compResult;
            }
          }
          return 0;
        });
      }, [rows, sortColumns]);*/


    const columns: any = [
        {
            key: 'id',
            name: "Código",
            maxWidth: 60
        },
        {
            key: 'pago',
            name: "Status",
            resizable: true,
            minWidth: 100,
            renderCell(props: any) {
                const info = props.row.pago ? 'PAGO' : 'ABERTO' + props.row.num_days;
                return (
                    <>
                        <StatusPay value={props.row.pago} num_days={props.row.num_days}>{getStatus(props.row)}</StatusPay>
                    </>
                )
            }
        },
        {
            key: 'cliente_nome',
            name: "Cliente",
            resizable: true,
            width: 250,
        },
        {
            key: 'descr',
            name: "Descrição",
            resizable: true,
            width: 200,
        },
        {
            key: 'amount',
            name: "Valor",
            renderCell(props: any) {
                return FormatterCurrency(props.row.amount);
            }


        },
        {
            key: 'dt_venc',
            name: "Vencimento",
            renderCell(props: any) {
                return getDateView(props.row.dt_venc);
            }
        },
        {
            key: 'pix_txid',
            name: "Chave",
            resizable: true,
            width: 200,
        },
        {
            key: 'boletoid',
            name: "Cód Boleto"
        },
    ];

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;

        const filteredPeople = rows.filter((person) =>
            Object.values(person).some((value) =>
                String(value).toLowerCase().includes(searchText.toLowerCase())
            )
        );
        setFilteredItens(filteredPeople);
        handlePageChange({ selected: 0 });
    };


    const handleChange = (selectedRows: itemCobrancas) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        console.log('Selected Rows: ', selectedRows);
    };

    const handleSort = (columnKey: string, sortDirection: SortDirection) => {
        // Ordenação
    };

    const pageCount = Math.ceil(filteredItens.length / pageSize); // Número total de páginas

    const handlePageChange = (selectedPage: { selected: number }) => {
        setCurrentPage(selectedPage.selected);
    };

    const offset = currentPage * pageSize; // Índice de início dos itens exibidos na página atual
    const paginatedItens = filteredItens.slice(offset, offset + pageSize); // Itens exibidos na página atual


    const handleChangeDataPicker = (newValue: any) => {
        setOpenDataPicker(false)
        setStartDate(newValue)
        setDateSelected(DateFormatterBd(newValue))
    };


    const formData = (
        <React.Fragment>
            <ContainerDate>
                <label >Data</label>
                <DatePicker
                    selected={startDate}
                    onChange={handleChangeDataPicker}
                    popperPlacement="top-end"
                    locale="ptBr"
                    dateFormat="dd-MM-yyyy"
                    className='InputDate'

                />
            </ContainerDate>
        </React.Fragment>
    );

    return (
        <>
            <ReactPaginate
                previousLabel={'<<'}
                nextLabel={'>>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={currentPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'react-paginate'}
                activeClassName={'paginate-active'}
            />
            <ContainerDataGrid>
                {/*<ContainerCard>
                    <ContainerPicker>
                        {formData}
                    </ContainerPicker>
    </ContainerCard>*/}
                <input type="text" onChange={handleSearch} placeholder="Search" /> <span>Encontrados {filteredItens.length} de {rows.length}</span>
                <DataGrid
                    columns={columns}
                    rows={paginatedItens}
                    defaultColumnOptions={{
                        sortable: true,
                        resizable: true
                    }}
                    className='DatagridClass'
                />
            </ContainerDataGrid>

        </>
    )

}
export default CobrancasPage

