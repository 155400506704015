import { Column, SelectColumn } from "react-data-grid";
import { BtnCheckBox } from "../../Messages/Style/StyleMessages";
import { iSupplier } from "../repository/SupplierRepo";

export const getColumnsSuppliers = (
    editItem: (row: iSupplier) => void
): Column<iSupplier>[] => {
    return [
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        {
            key: 'id',
            name: 'Cód',
            width: 65
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 90,
            renderCell(props: any) {
                return (
                    <>
                        <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox>
                    </>
                );
            }
        },
        {
            key: 'name',
            name: 'Nome',
            width: 180
        },
        {
            key: 'tipo_servico_produto',
            name: 'Serviço/Produto',
            width: 150
        },
        {
            key: 'cnpj_cpf',
            name: 'CNPJ/CPF',
            width: 130
        },
        {
            key: 'endereco_completo',
            name: 'Endereço Completo',
            width: 200
        },
        {
            key: 'contato_principal',
            name: 'Contato Principal',
            width: 150
        },
        {
            key: 'telefone',
            name: 'Telefone',
            width: 120
        },
        {
            key: 'email',
            name: 'E-mail',
            width: 180
        }

    ];
};
