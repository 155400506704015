import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { GeocoderService } from '../../../GoogleMaps/GeocoderService';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { FaSearch, FaTimes, FaTrash, FaCheck, FaEdit, FaDrawPolygon, FaCircle, FaSave, FaBackspace } from 'react-icons/fa';
import { MapConfig } from '../utilsMap/mapContext';
import { CREATE_FLAGS } from '../utilsMap/ConstMaps';
import ModalComp from '../../../components/datagridComp/ModalComp';
import ColorPickerModal from '../../FuelPage/components/ColorPickerModal';
import { iCercas, saveCerca } from '../repository/CercasRepo';
import SaveProgress from './SaveProgress';
import { IFence } from '../../../Config/StorageRepository';
import { isValidHexColor } from '../../../utils/Converter';
import { useNotifications } from '../../../Notifications/NotificationContext';

let clickListener: google.maps.MapsEventListener | undefined;
let markerPosition: google.maps.Marker | null = null;
let polygon: google.maps.Polygon | null = null;
let circle: google.maps.Circle | null = null;
let circleRadiusExt = 80;

interface FenceFormProps {
    initialData?: IFence;
}

const VirtualFenceForm: React.FC<FenceFormProps> = ({ initialData }) => {
    const map = useGoogleMap();
    const { addNotification } = useNotifications();
    const { modeActive, updateConfig } = MapConfig();
    const [findAddress, setFindAddress] = useState('');
    const [address, setAddress] = useState('');
    const [isDrawing, setIsDrawing] = useState(false);
    const [path, setPath] = useState<google.maps.LatLng[]>([]);
    const [isPolygonSaved, setIsPolygonSaved] = useState(false);
    const [isCircleMode, setIsCircleMode] = useState(true); // Inicia com o modo de círculo
    const [circleRadius, setCircleRadius] = useState(circleRadiusExt); // Raio padrão do círculo
    const [isColorModalOpen, setColorModalOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#FF0000');
    const [nameFlag, setNameFlag] = useState('');
    const [enableSave, setEnableSave] = useState(false);

    // Função para buscar endereço e atualizar marker
    const handleSearch = async () => {
        if (!findAddress) return;
        const resp = await GeocoderService(findAddress);
        if (resp) {
            setAddress(resp.address);
            updateMarkerPosition(resp.position);
        }
    };

    useEffect(() => {
        if (initialData) {
            console.log('Dados iniciais recebidos:', initialData);
            const { lat, lng, raio, encod, typecerca, icon, descr, color } = initialData;
            const position = new google.maps.LatLng(lat, lng);
            const colorSelected = isValidHexColor(color) ? color : '#FF0000';
            updateMarkerPosition(position);
            setNameFlag(descr);
            setSelectedColor(colorSelected);
            if (typecerca === 'raio') {
                setIsCircleMode(true);
                setCircleRadius(raio);
                drawCircle(position);
            } else {
                setIsCircleMode(false);
                setIsPolygonSaved(true);
                setPath((prevPath) => {
                    const newPath = google.maps.geometry.encoding.decodePath(encod);
                    drawPolygonInNoEdit(newPath);
                    return newPath;
                });
            }
        }
    }, [initialData]);

    // Atualiza a posição do marker no mapa e ajusta o zoom se necessário
    const updateMarkerPosition = async (position: google.maps.LatLng) => {
        if (markerPosition) {
            markerPosition.setMap(null); // Remove o marker anterior do mapa
        }
        map?.panTo(position); // Centraliza o mapa na posição obtida
        if (map?.getZoom()! < 15) map?.setZoom(15); // Ajusta o zoom para 15, se necessário

        markerPosition = new google.maps.Marker({
            position: position,
            map,
            draggable: true,
        });
        markerPosition.addListener('dragend', () => {
            if (markerPosition?.getPosition()) {
                updateMarkerPosition(markerPosition.getPosition()!);
            }
        });

        const resp = await GeocoderService(position);
        if (resp) {
            setAddress(resp.address);
        }
    };


    useEffect(() => {
        if (markerPosition) {
            if (isCircleMode && !isPolygonSaved) {
                drawCircle(markerPosition.getPosition()!);
            } else if (circle) {
                circle.setMap(null); // Remove o círculo se estiver no modo de área ou uma área foi desenhada
            }
        }
    }, [markerPosition]);

    // Função para desenhar o círculo com o raio atual
    const drawCircle = (position: google.maps.LatLng) => {
        if (circle) {
            circle.setMap(null); // Remove o círculo anterior
        }
        circle = new google.maps.Circle({
            center: position,
            radius: circleRadius,
            map,
            strokeColor: selectedColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: selectedColor,
            fillOpacity: 0.25,
            editable: true,
        });

        // Listener para capturar a mudança de raio
        google.maps.event.addListener(circle, 'radius_changed', () => {
            const newRadius = circle?.getRadius();
            // circleRadiusExt = newRadius!;
            setCircleRadius(newRadius!);
        });
    };

    // Alterna para o modo de círculo e desenha o círculo inicial
    const handleCircleMode = () => {
        setIsCircleMode(true);
        setIsDrawing(false); // Desativa o modo de desenho de área
        setIsPolygonSaved(false); // Limpa qualquer polígono salvo

        activeClickMapToMarker()

        if (markerPosition) {
            drawCircle(markerPosition.getPosition()!); // Desenha o círculo ao redor do marker
        }
    };



    // Alterna para o modo de desenho de polígono (área)
    const handleDrawMode = () => {
        setIsDrawing(true);
        setIsCircleMode(false); // Desativa o modo de círculo
        setPath([]); // Reinicia o path para começar um novo desenho
        setIsPolygonSaved(false); // Redefine a flag de salvamento

        if (circle) {
            circle.setMap(null); // Remove o círculo ao entrar no modo de área
        }

        if (clickListener) {
            google.maps.event.removeListener(clickListener);
        }

        // Adiciona o listener para desenhar polígono ao clicar no mapa
        clickListener = map?.addListener('click', (event: google.maps.MapMouseEvent) => {
            const clickPosition = event.latLng;
            if (clickPosition) {
                setPath((prevPath) => {
                    const newPath = [...prevPath, clickPosition];
                    drawPolygon(newPath);
                    return newPath;
                });
            }
        });
    };

    // Função para desenhar o Polígono no mapa
    const drawPolygon = (path: google.maps.LatLng[]) => {
        if (polygon) {
            polygon.setMap(null); // Remove o polígono anterior
        }
        polygon = new google.maps.Polygon({
            paths: path,
            map,
            strokeColor: selectedColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: selectedColor,
            fillOpacity: 0.25,
            draggable: true,
            editable: true,
            clickable: false,
        });
    };
    const drawPolygonInNoEdit = (path: google.maps.LatLng[]) => {
        if (polygon) {
            polygon.setMap(null); // Remove o polígono anterior
        }
        polygon = new google.maps.Polygon({
            paths: path,
            map,
            strokeColor: selectedColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: selectedColor,
            fillOpacity: 0.25,
            clickable: false,
        });
    };

    const activeClickMapToMarker = () => {
        // Remove o listener de desenho e restaura o clique padrão para atualizar o marker
        if (clickListener) {
            google.maps.event.removeListener(clickListener);
        }
        clickListener = map?.addListener('click', async (event: google.maps.MapMouseEvent) => {
            const clickPosition = event.latLng;
            if (clickPosition) {
                updateMarkerPosition(clickPosition);
            }
        });
    }

    // Função para salvar o polígono, desativando a edição
    const handleSavePolygon = () => {
        if (polygon && path.length >= 3) { // Verifica se há pelo menos 3 pontos
            polygon.setEditable(false);
            polygon.setDraggable(false);
            setIsPolygonSaved(true);
            setIsDrawing(false);
            activeClickMapToMarker()
        }
    };

    // Função para limpar o desenho atual, mantendo o modo de desenho ativo
    const handleClearDrawing = () => {
        if (polygon) {
            polygon.setMap(null);
            polygon = null;
        }
        setPath([]);
    };

    // Função para cancelar o desenho e redefinir para o modo padrão de posicionamento do marker
    const handleCancel = () => {
        handleClearDrawing();
        handleCircleMode();
    };

    // Função para editar o polígono existente
    const handleEditPolygon = () => {
        if (polygon) {
            polygon.setEditable(true);
            polygon.setDraggable(true);
            setIsDrawing(true);
            setIsPolygonSaved(false);

            // Redefine o listener do clique para o modo de desenho
            if (clickListener) {
                google.maps.event.removeListener(clickListener);
            }
            clickListener = map?.addListener('click', (event: google.maps.MapMouseEvent) => {
                const clickPosition = event.latLng;
                if (clickPosition) {
                    setPath((prevPath) => {
                        const newPath = [...prevPath, clickPosition];
                        drawPolygon(newPath);
                        return newPath;
                    });
                }
            });
        }
    };

    // Função para apagar o polígono existente e exibir novamente as opções de desenho
    const handleDeletePolygon = () => {
        if (polygon) {
            polygon.setMap(null);
            polygon = null;
            setIsPolygonSaved(false);
            setPath([]);
        }
        handleCircleMode();

    };

    // Configura o listener padrão de clique ao iniciar
    useEffect(() => {
        if (modeActive === CREATE_FLAGS) {
            console.log('Modo de cerca virtual ativado');

            activeClickMapToMarker()
        }

        // Limpeza ao sair do modo
        return () => {
            console.log("Modo de cerca virtual Desativado");
            if (clickListener) {
                google.maps.event.removeListener(clickListener);
                clickListener = undefined;
            }
            if (polygon) {
                polygon.setMap(null);
                polygon = null;
            }
            if (circle) {
                circle.setMap(null);
                circle = null;
            }
            if (markerPosition) {
                markerPosition.setMap(null);
                markerPosition = null;
            }
        };
    }, [modeActive]);

    const handleSetRadius = (radius: number) => {
        circleRadiusExt = radius;
        setCircleRadius(radius);
    };

    const handleOpenColorModal = () => setColorModalOpen(true);
    const handleCloseColorModal = () => setColorModalOpen(false);
    const handleColorSelect = (color: string) => {
        setSelectedColor(color);
        handleCloseColorModal();
    };


    useEffect(() => {
        if (circle) {
            circle.setOptions({
                strokeColor: selectedColor,
                fillColor: selectedColor,
            });
        }
        if (polygon) {
            polygon.setOptions({
                strokeColor: selectedColor,
                fillColor: selectedColor,
            });
        }
    }, [selectedColor]);

    const handleCancelForm = () => {
        updateConfig({ actionPressButton: 'closeform-fence' });
    }

    const handleSaveCerca = async () => {

        let encod = polygon ? google.maps.geometry.encoding.encodePath(polygon.getPath()) : '';
        const area = polygon ? google.maps.geometry.spherical.computeArea(polygon.getPath()) : 0;
        let radius = circle ? circle.getRadius() : 0;
        const cerca: iCercas = {
            lat: markerPosition?.getPosition()?.lat() || 0,
            lng: markerPosition?.getPosition()?.lng() || 0,
            ender: address,
            raio: radius,
            area: Math.round((area / 1000000) * 100),
            encod: encod,
            type: isCircleMode ? 'raio' : 'polygon',
            tipomonitor: isCircleMode ? 'raio' : 'polygon',
            icone: '',
            nome: nameFlag,
            color: selectedColor,
        }
        initialData && (cerca.id = initialData.id);

        // Salvar no banco de dados
        try {
            const result = await saveCerca(cerca);
            console.log(result);
            addNotification('', 'Cerca salva com sucesso', 'success');
            updateConfig({ actionPressButton: 'save-fence' });
        } catch (error: any) {
            console.error("Erro ao salvar cerca:", error);
            addNotification('', 'Erro ao salvar cerca', error.message);

        }
    }

    return (
        <Container>
            <Title>Cadastro de Cercas</Title>

            {/* {!markerPosition && <InfoButton>Primeiro click no mapa ou pesquise pelo endereço</InfoButton>}
            {markerPosition && <InfoButton>Selecione o tipo de desenho, faça os ajustes e clique em salvar</InfoButton>} */}
            <SearchContainer>
                <SearchFieldV2 onSearch={setFindAddress} searchTerm={findAddress} />
                <SearchButton onClick={handleSearch}>
                    <FaSearch />
                </SearchButton>
            </SearchContainer>
            <AddressDisplay>{address}</AddressDisplay>

            {(!isPolygonSaved && !isDrawing && markerPosition) && (
                <ButtonColumn>
                    <InfoButton>Escolha o desenho</InfoButton>
                    <ButtonContainer>
                        <StyledButton selected={isCircleMode} onClick={handleCircleMode}>
                            <FaCircle /> Círculo
                        </StyledButton>
                        <StyledButton selected={isDrawing} onClick={handleDrawMode}>
                            <FaDrawPolygon /> Área
                        </StyledButton>
                    </ButtonContainer>
                </ButtonColumn>
            )}

            {isDrawing && (
                <ButtonColumn>
                    <InfoButton>Comece a desenhar no mapa</InfoButton>
                    <DrawingControls>
                        <StyledIconButton
                            onClick={handleSavePolygon}
                            title="Salvar"
                            disabled={path.length < 3} // Ativa o botão somente com 3+ pontos
                        >
                            <FaCheck />
                        </StyledIconButton>
                        <StyledIconButton onClick={handleClearDrawing} title="Limpar">
                            <FaTrash />
                        </StyledIconButton>
                        <StyledIconButton onClick={handleCancel} title="Cancelar">
                            <FaTimes />
                        </StyledIconButton>
                    </DrawingControls>
                </ButtonColumn>
            )}
            {isPolygonSaved && (
                <ButtonContainer>
                    <>
                        <Indicator>Área Desenhada</Indicator>
                        <StyledIconButton onClick={handleEditPolygon} title="Editar">
                            <FaEdit />
                        </StyledIconButton>
                        <StyledIconButton onClick={handleDeletePolygon} title="Apagar">
                            <FaTrash />
                        </StyledIconButton>
                    </>
                </ButtonContainer>
            )}

            <ColorPickerContainer onClick={handleOpenColorModal}>
                <ColorLabel>Cor de fundo:</ColorLabel>
                <ColorDisplay color={selectedColor} />
            </ColorPickerContainer>

            {isColorModalOpen && (
                <ModalComp title="Cor da Cerca" subtitle="" onClose={() => { }}>
                    <ColorPickerModal
                        initialColor={selectedColor}
                        onColorSelect={handleColorSelect}
                        onClose={handleCloseColorModal}
                    />
                </ModalComp>
            )}
            <SearchContainer>
                <InputStyle
                    placeholder={"Nome da Cerca"}
                    value={nameFlag}
                    onChange={(e) => setNameFlag(e.target.value)}
                    className="border border-gray-300 px-4 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
            </SearchContainer>
            <SaveProgress
                nameFlag={nameFlag}
                markerPosition={markerPosition ? true : false}
                isCircleMode={isCircleMode}
                isPolygonSaved={isPolygonSaved}
                onEnableSave={setEnableSave}
            />
            <ButtonColumn>
                <ButtonContainer>
                    <StyledButtonCmds color='red' onClick={handleCancelForm} enable={true}>
                        <FaBackspace /> Cancelar
                    </StyledButtonCmds>
                    <StyledButtonCmds color='green' onClick={handleSaveCerca} enable={enableSave}>
                        <FaSave /> Salvar
                    </StyledButtonCmds>
                </ButtonContainer>
            </ButtonColumn>

        </Container>
    );
};

export default VirtualFenceForm;

const SizedBoxHeight = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const InputStyle = styled.input`
     width: 100%;
    padding: 8px 40px 8px 12px;  /* Espaço extra para o botão de apagar */
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    outline: none;

    &:focus {
        border-color: #ff7a00;
        box-shadow: 0 0 0 3px rgba(255, 122, 0, 0.3);
    }
`;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
    margin-right: 5px;
`;

const SearchButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  font-size: 20px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`;

const AddressDisplay = styled.p`
  font-size: 14px;
  color: #333;
  margin: 5px 0;
`;

const Title = styled.div`
  font-size: 14px;
  color: #2e2e2e;
  padding: 5px;
  text-align: left;
`;
const InfoButton = styled.div`
  font-size: 14px;
  color: #7f7f7f;
  padding: 5px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonColumn = styled.div`
  display: flex;
    flex-direction: column;
  gap: 5px;
`;

const DrawingControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

const RadiusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const RadiusInput = styled.input`
  width: 60px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const RadiusButton = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
`;

const Indicator = styled.div`
  font-size: 14px;
  color: #00c853;
  display: flex;
  align-items: center;
  padding: 5px;
`;

const StyledButton = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.selected ? '#00c853' : '#e0e0e0')};
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  transition: border-color 0.3s ease, color 0.3s ease;
  outline: none;

  &:hover {
    border-color: #00c853;
  }

  svg {
    margin-right: 8px;
  }
`;

const StyledButtonCmds = styled.button<{ color?: string; enable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: ${(props) => (props.enable ? 'pointer' : 'not-allowed')};
  border: 2px solid ${(props) => (props.enable ? props.color || '#333' : '#ccc')};
  background-color: ${(props) => (props.enable ? '#fff' : '#f0f0f0')};
  color: ${(props) => (props.enable ? props.color || '#333' : '#999')};
  border-radius: 8px;
  transition: border-color 0.3s ease, color 0.3s ease;
  outline: none;
  pointer-events: ${(props) => (props.enable ? 'auto' : 'none')};

  &:hover {
    border-color: ${(props) => (props.enable ? '#00c853' : '#ccc')};
  }

  svg {
    margin-right: 8px;
  }
`;


const StyledIconButton = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 10px;
  ${(props) => props.disabled && 'cursor: not-allowed; color: #bbb;'}

  &:hover {
    color: #00c853;
  }
`;


const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
`;

const ColorLabel = styled.label`
  margin-right: 10px;
`;

const ColorDisplay = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.color};
  border: 1px solid #000;
  border-radius: 4px;
  margin-left: 8px;
`;