import React, { useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import PhotoIcon from '@material-ui/icons/Photo';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

const Placeholder = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  cursor: not-allowed;
`;

type ImageGalleryTasksProps = {
    filenames: string[];
};

const ImageGalleryTasks: React.FC<ImageGalleryTasksProps> = ({ filenames }) => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [brokenImages, setBrokenImages] = useState<Record<string, boolean>>({});

    const handleOpen = (filename: string) => {
        setSelectedImage(`${process.env.REACT_APP_FILES_SERVER}/img-delivery/${filename}`);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleImageError = (filename: string) => {
        setBrokenImages(prev => ({ ...prev, [filename]: true }));
    };

    return (
        <>
            <ImageContainer>
                {filenames.map((filename, index) =>
                    filename ? (
                        brokenImages[filename] ? (
                            <Placeholder key={`${filename}-${index}`}>
                                <BrokenImageIcon />
                            </Placeholder>
                        ) : (
                            <Thumbnail
                                key={`${filename}-${index}`}
                                src={`${process.env.REACT_APP_FILES_SERVER}/img-delivery/${filename}`}
                                alt="Expense"
                                onClick={() => handleOpen(filename)}
                                onError={() => handleImageError(filename)}
                            />
                        )
                    ) : (
                        <Placeholder key={`${filename}-${index}`}>
                            <PhotoIcon color="disabled" />
                        </Placeholder>
                    )
                )}
            </ImageContainer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Image Preview
                    <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <img src={selectedImage} alt="Expanded" style={{ width: '100%' }} onError={() => handleImageError(selectedImage)} />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<GetAppIcon />}
                        component="a"
                        href={selectedImage}
                        download
                        target="_blank"  // Aqui adicionamos target="_blank" para abrir em uma nova janela
                        style={{ marginTop: 10 }}
                    >
                        Download Image
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ImageGalleryTasks;
