import { httpAxiosV4 } from "../../../gateways/Axios";


export async function saveCerca(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `fence/save`,
        "POST",
        payload
    );
    return data;
}

export async function deleteCerca(id: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `fence?id=${id}`,
        "DELETE"
    );
    return data;
}


export interface iCercas {
    lat: number;
    lng: number;
    ender: string;
    raio: number;
    area: number;
    encod: string;
    type: string;
    tipomonitor: string;
    icone: string;
    nome: string;
    color: string;
    id?: number;
}
