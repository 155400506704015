import { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type ConfigDrawer = {
    isShowTitle: boolean
    isOpenMenu: boolean
    orintation: string
    sizeIcon: string
    colorIconActive: string
    colorIconInactive: string
    updateConfig: (newConfig: Partial<ConfigDrawer>) => void;
};

const defaultConfig: ConfigDrawer = {
    isShowTitle: true,
    isOpenMenu: false,
    orintation: 'right',
    sizeIcon: '24px',
    colorIconActive: '#404040',
    colorIconInactive: '#7e7e7e',
    updateConfig: () => { },
};

const DrawerContext = createContext<ConfigDrawer>(defaultConfig);

export const DrawerConfig = () => {
    return useContext(DrawerContext);
};

type DrawerProviderProps = {
    children: ReactNode;
};

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
    const [config, setConfig] = useState(defaultConfig);

    useEffect(() => {
        // Carregar configurações do localStorage ao montar
        const loadConfig = () => {
            const localConfig = localStorage.getItem('drawerConfig');
            if (localConfig) {
                setConfig(JSON.parse(localConfig));
            } else {
                // Salvar configurações padrão no localStorage se não houver registro
                localStorage.setItem('drawerConfig', JSON.stringify(defaultConfig));
            }
        };

        loadConfig();
    }, []);

    /*useEffect(() => {
        // Salvar configurações no localStorage sempre que mudarem
        localStorage.setItem('mapConfig', JSON.stringify(config));
    }, [config]);*/

    const updateConfig = (newConfig: Partial<ConfigDrawer>) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...newConfig,
        }));
    };

    return (
        <DrawerContext.Provider value={{ ...config, updateConfig }}>
            {children}
        </DrawerContext.Provider>
    );
};
