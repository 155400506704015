import styled from "styled-components";



export const ContainerDataGrid = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    block-size: 65vh;
    padding: 8px;
    contain: inline-size;

`

export const ContainerBarTop = styled.div`
padding: 6px;
display: flex;
    justify-content: space-between;
`

export const ContainerTable = styled.div`
padding: 6px;
    height: calc(100vh - 210px);
    overflow: auto;
`

export const sortPriorityClassname = styled.div`
  color: grey;
  margin-left: 2px;
`;


export const selectCellClassname = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > input {
    margin: 0;
  }
`;