import { FormatterCurrency, FormatterDecimal, FormatterDecimal2 } from "../../../utils/format";
import { iFuelInvoices } from "../repository/FuelRepo";







export function formateFuelInvoices(params: any[]): iFuelInvoicesScreen[] {
    const itens: iFuelInvoicesScreen[] = params.map((item: iFuelInvoices) => {


        const metricsType = item.id_metrics_type === 1 ? 'Km' : 'Hr';
        const avgType = item.id_metrics_type === 1 ? 'Km/h' : 'Lts/Hr';
        const metrics = `${FormatterDecimal2(item.km_run)}${metricsType} (${FormatterDecimal2(item.km_start)}${metricsType} - ${FormatterDecimal2(item.km_end)}${metricsType})`;
        const cost_km = FormatterCurrency(item.cost_km);
        const avg_fuel = `${FormatterDecimal2(item.avg_fuel)} ${avgType}`;
        const iconMetrics = item.id_metrics_type === 1 ? 'speed' : 'local_gas_station';
        const newItem: iFuelInvoicesScreen = {
            id: item.id,
            idvehicle: item.id_veiculo,
            iddriver: item.id_motorista,
            unique_id: item.unique_id,
            placa: item.placa,
            descr: item.descr,
            supplier_name: item.supplier_name || null,
            id_categ: item.id_categ,
            categories: item.categories,
            amount: item.amount,
            expense_type: item.expense_type,
            status: item.status,
            lat: item.lat,
            lng: item.lng,
            photo1: item.photo1,
            photo2: item.photo2,
            photo3: item.photo3,
            km_start: item.km_start,
            km_end: item.km_end,
            km_vehicle: item.km_vehicle,
            km_run: item.km_run,
            avg_fuel,
            qty: item.qty || null,
            price_unity: item.price_unity || null,
            obs: item.obs || null,
            fuel_cod: item.fuel_cod,
            fuel_descr: item.fuel_descr || null,
            tipo_payment_id: item.tipo_payment_id || null,
            evt_at: item.evt_at,
            create_at: item.create_at,
            name: item.name || null,
            driveravatar: item.driveravatar,
            drivername: item.drivername,
            metrics,
            cost_km,
            iconMetrics,
            fornecedor_uuid: item.fornecedor_uuid,
            internal_tank_id: item.internal_tank_id ?? 0
        }
        return newItem;
    });


    return itens;
}


export interface iFuelInvoicesScreen {
    id: number;
    idvehicle: number;
    iddriver: number;
    unique_id: string;
    placa: string;
    descr: string;
    supplier_name: string | null;
    id_categ: number;
    categories: string
    amount: number;
    expense_type: string;
    status: 'Pendente' | 'Aprovado' | 'Cancelado' | 'Em Análise' | 'Rejeitado' | 'Pago';
    lat: number;
    lng: number;
    photo1: string;
    photo2: string;
    photo3: string;
    km_start: number;
    km_end: number;
    km_vehicle: number;
    km_run: number;
    avg_fuel: string;
    cost_km: string;
    qty: number | null;
    price_unity: number | null;
    obs: string | null;
    fuel_cod: number;
    fuel_descr: string | null;
    tipo_payment_id: number | null;
    evt_at: string;
    create_at: string;
    name: string | null;
    driveravatar: string;
    drivername: string;
    metrics: string;
    iconMetrics: string;
    fornecedor_uuid: string | null;
    internal_tank_id: number;
};