import React from 'react';

interface SearchFieldProps {
    onSearch: (searchTerm: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = React.useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        onSearch(event.target.value);
    };

    return (
        <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchChange}
            className="border border-gray-300 px-4 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
        />
    );
};

export default SearchField 