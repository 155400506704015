import { IconButton } from '@mui/material';
import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { ctrlButtons, iItemTravel, iTasks } from '../interfaces/interfaces';

import http from '../services/http-common'
import { selectUser } from '../redux/userSlice';
import { useSelector } from 'react-redux';
import WidgetDataGridComp from '../widgets/WidgetDataGrid';
import { FileOpen, Edit } from '@mui/icons-material';
import TravelForm from './forms/TravelForms';
import TravelDetailsComp from './TravelDetailsComp';



const initialBtns: ctrlButtons = {
    RefreshShow: true,
    addShow: true
}

const TravelListComp: React.FC = (props) => {


    const { user } = useSelector(selectUser);
    const [listDatagrid, setListDatagrid] = React.useState<iTasks[]>([]);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);
    const [carFilter, setCarFilter] = React.useState<number[]>([]);
    const [driverFilter, setDriverFilter] = React.useState<number[]>([]);
    const [statusFilter, setStatusFilter] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isShowDetails, setShowDetails] = React.useState<boolean>(false);
    const [isShowCad, setShowCad] = React.useState<boolean>(false);
    const [formCadData, setformCadData] = React.useState<iItemTravel | undefined>();


    async function getDados() {
        try {
            const result = await http.post("/ListTripsByClient", {
                IdClient: user.idcliente,

            });
            let resp = result.data;
            console.log(resp)
            if (resp) {
                setListDatagrid(resp)
            }

        } catch (error) {
            console.log('[ListTasksDelivery]', error)
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'actions', headerName: 'Eventos', width: 80,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => handleOpenTravel(params.row)}>
                            <FileOpen />
                        </IconButton>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => EditItemClick(params.row)}>
                            <Edit />
                        </IconButton>
                    </>
                );
            }


        },
        {
            field: 'id',
            headerName: 'Cód',
            width: 90,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
        },
        {
            field: 'DriverName',
            headerName: 'Colaborador',
            width: 180,
        },
        {
            field: 'veiculo_placa',
            headerName: 'Veículo',
            width: 150
        },
        {
            field: 'manifest',
            headerName: 'Manifesto',
            width: 220
        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width: 350,
        }

    ];

    React.useEffect(() => {
        (async () => {
            console.log("useEffect DialogListDest")
            if (user.idcliente) { getDados() } else { console.log("Cliente Invalid DialogListDest") }
        })();
    }, [user]);

    const EditItemClick = (item: iItemTravel) => {
        setformCadData(item)
        setShowCad(true)
    }

    const setSel = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
        CtrlBtns.SaveDisable = i.length ? false : true
        setCtrlBtns(CtrlBtns)
    }

    const handleRefresh = () => {
        if (user.idcliente) { getDados() }
    }


    const handleOpenTravel = (item: any) => {
        setformCadData(item)
        setShowDetails(true)
    }

    const handleCloseDetails = () => {
        setShowDetails(false)
    }


    const OpenCadNew = () => {
        setformCadData(undefined)
        setShowCad(true)
    }
    const handleSaveTravel = () => {
        getDados()
        setShowCad(false)
    }
    const handleCancelForm = () => {
        setShowCad(false)
    }

    return <>

        {isShowDetails && <TravelDetailsComp travel={formCadData} onCancelClick={handleCloseDetails} />}
        {isShowCad && <TravelForm onSaveClick={handleSaveTravel} onCancelClick={handleCancelForm} initialData={formCadData} />}
        {isShowCad || isShowDetails ? <></> : <WidgetDataGridComp
            isLoading={isLoading}
            rows={listDatagrid}
            columns={columns}
            CheckBoxSelect={false}
            handleSelRow={setSel}
            initialBtns={CtrlBtns}
            onRefresClick={handleRefresh}
            onAddClick={OpenCadNew}
        //onDeleteClick={handleDelete}
        />
        }
    </>

}

export default TravelListComp