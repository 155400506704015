import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #fff3e0; // Um tom suave de laranja.
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 600px; // Define a largura para 600px.
  max-width: 100%; // Garante que não ultrapasse a largura da tela.
  margin: 0 auto; // Centraliza horizontalmente.
`;


const Title = styled.h2`
  color: #ef6c00; // Um tom mais escuro de laranja.
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ef6c00;
  &:focus {
    outline: none;
    border-color: #f57c00;
  }
`;

const Button = styled.button`
  background-color: #ef6c00;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f57c00;
  }
`;

const InfoText = styled.p`
  color: #666;
`;

const LinkToLaw = styled.a`
  color: #ef6c00;
  &:hover {
    text-decoration: underline;
  }
`;

function DeleteDataRequestLGPD() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log(formData);
        alert('Sua solicitação foi enviada. Entraremos em contato em breve.');
    };

    return (
        <Container>
            <Title>Solicitação de Exclusão de Dados - LGPD</Title>
            <InfoText>
                De acordo com a Lei Geral de Proteção de Dados (LGPD), você tem o direito de solicitar a exclusão dos seus dados pessoais de nossa base. Preencha o formulário abaixo para prosseguir com sua solicitação.
            </InfoText>
            <LinkToLaw href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm" target="_blank">
                Leia mais sobre a LGPD
            </LinkToLaw>
            <Form onSubmit={handleSubmit}>
                <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Seu nome"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Seu e-mail"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <Button type="submit">Enviar Solicitação</Button>
            </Form>
        </Container>
    );
}

export default DeleteDataRequestLGPD;
