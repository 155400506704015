import { httpAxiosV4 } from "../../../gateways/Axios";




export async function upsertSupplier(data: iSupplier): Promise<any> {
    const { data: response } = await httpAxiosV4<iSupplier, any>(
        `supplier`,
        "POST",
        data
    );
    return response;
}



export async function getListSuppliers(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(//request, response
        `supplier`,
        "GET"
    );
    return data;
}




export type iSupplier = {
    id?: number;
    unique_id: string;
    id_client: number;
    id_empresa: number;
    id_categ: number;
    name: string;
    tipo_servico_produto: string;
    cnpj_cpf: string;
    endereco_completo: string;
    contato_principal?: string;
    telefone?: string;
    whatsapp?: string;
    email?: string;
    website?: string;
    condicoes_pagamento?: string;
    dados_bancarios?: string;
    historico_compras?: string;
    avaliacao_desempenho?: string;
    status?: string;
    observacoes_gerais?: string;
    latitude?: number;
    longitude?: number;
    create_at?: Date;
    update_at?: Date;
    delete_at?: Date | null;
    sync_version?: number;
    active?: boolean;
};