import { RenderCheckboxProps, RenderSortStatusProps } from "react-data-grid";
import { sortPriorityClassname } from "../styles/datagrid/datagridStyle";

export function rowKeyGetter(row: any) {
    return row.id;
}

export function renderCheckbox({ onChange, ...props }: RenderCheckboxProps) {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        onChange(e.target.checked, (e.nativeEvent as MouseEvent).shiftKey);
    }

    return <input type="checkbox" {...props} onChange={handleChange} />;
}

export function renderSortStatus({ sortDirection, priority }: RenderSortStatusProps) {
    return (
        <>
            {sortDirection !== undefined ? (sortDirection === 'ASC' ? '\u2B9D' : '\u2B9F') : null}
            <span className={"sortPriorityClassname"}>{priority}</span>
        </>
    );
}




type Comparator<T> = (a: T, b: T) => number;
export function getComparator<T>(sortColumn: string): Comparator<T> {
    return (a, b) => {
        const aValue = a[sortColumn as keyof T];
        const bValue = b[sortColumn as keyof T];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            if (aValue == null && bValue == null) return 0;
            if (aValue == null) return -1;
            if (bValue == null) return 1;
            return aValue.localeCompare(bValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            return aValue - bValue;
        }

        return 0
        //throw new Error(`Unsupported type for sortColumn: "${String(sortColumn)}" , ${aValue}-${bValue}`);
    };
}