import React, { useEffect, useState } from 'react';
import TravelListComp from '../components/TravelList';

const TravelPage: React.FC = () => {

    return (
        <>
            <TravelListComp />
        </>
    )

}
export default TravelPage