
import React from 'react';
import { VideoData } from '../HelpPage';

type VideoCardProps = {
    video: VideoData;
    onClick: () => void;
};


const VideoCard: React.FC<VideoCardProps> = ({ video, onClick }) => {
    return (
        <div onClick={onClick} className="cursor-pointer flex rounded-lg overflow-hidden shadow-lg m-4 bg-white hover:bg-orange-100 max-w-md">
            <img src={`${process.env.REACT_APP_HELPDESK}/thumbnail/${video.thumbnail_url}`} alt={video.title} className="w-24 h-auto object-cover" />
            <div className="p-4 flex flex-col justify-center flex-1">
                <h3 className="font-bold text-lg text-orange-800">{video.title}</h3>
                <h4 className="text-md text-orange-600">{video.subtitle}</h4>
            </div>
        </div>
    );
};

export default VideoCard;
