
import { Column, SelectColumn } from "react-data-grid";
import { BtnCheckBox } from "../../Messages/Style/StyleMessages";
import { iFuelInvoice } from "../repository/TanksRepo";


export const getColumnsFuelEntries = (
    editItem: (row: iFuelInvoice) => void
): Column<iFuelInvoice>[] => {
    return [
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        {
            key: 'unique_id',
            name: 'ID Único',
            width: 200
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 90,
            renderCell: (props: any) => (
                <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox>
            )
        },
        {
            key: 'tank_id',
            name: 'ID do Tanque',
            width: 100
        },
        {
            key: 'fuel_id',
            name: 'ID do Combustível',
            width: 130
        },
        {
            key: 'quantity',
            name: 'Quantidade (L)',
            width: 120
        },
        {
            key: 'price_per_liter',
            name: 'Preço por Litro',
            width: 120
        },
        {
            key: 'total_cost',
            name: 'Custo Total',
            width: 120,
        },
        {
            key: 'supplier_uuid',
            name: 'Fornecedor',
            width: 180,
        },
        {
            key: 'entry_date',
            name: 'Data de Entrada',
            width: 160,
        },
        {
            key: 'payment_type',
            name: 'Tipo de Pagamento',
            width: 150,
        },
        {
            key: 'created_by',
            name: 'Criado por',
            width: 100
        },
        {
            key: 'observations',
            name: 'Observações',
            width: 200,
        }
    ];
};