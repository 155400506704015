// Drawer.jsx
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppBar } from '../redux/appBarSlice';
import { logout, selectUser } from '../redux/userSlice';
import { selectCred } from '../redux/credSlice';
import { IMenu, getItensMenu } from './menu';
import { styled } from 'styled-components';
import { DrawerConfig, DrawerProvider } from './drawerContext';
import { boolean } from 'yup';
import { AuthConfig } from '../Config/AuthContext';


const DrawerContainer = styled.div<{ isOpen: boolean }>`
  width:  ${(props) => (props.isOpen ? '190px' : '60px')};
  height: 100vh;
  background-color: #f1f5f9;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isOpen ? 'start' : 'center')};
`;

const MenuItem = styled.div<{ isSelected: boolean, isOpen: boolean }>`
  padding: 0.5rem;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? "#e2e8f0" : "transparent")};
  
  &:hover {
      background-color: #e2e8f0;
    }


  ${(props) =>
    !props.isOpen &&
    `
    /* Tooltip */
    &:hover::after {
      content: attr(data-tooltip); /* O conteúdo do tooltip */
      position: absolute;
      top: 50%;
      left: 120%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      padding: 0.5rem;
      border-radius: 0.5rem;
      white-space: nowrap;
      z-index: 1;
      font-size: 0.75rem;
      pointer-events: none;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
      z-index: 1;
    }
  `}

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  ${(props) => (props.isOpen ? 'width: 100%' : '')};
`;

const ItemIcon = styled.div<{ isSelected: boolean, activeColor: string, inactiveColor: string, iconSize: string }>`
  height: ${(props) => props.iconSize}; /* Tamanho dinâmico do ícone */
  width: ${(props) => props.iconSize};  /* Tamanho dinâmico do ícone */
  flex: none;

  svg {
    height: 100%;
    width: 100%;
    fill: ${(props) => (props.isSelected ? props.activeColor : props.inactiveColor)}; /* Cor dinâmica do ícone */
    stroke: ${(props) => (props.isSelected ? "#ececec" : "#ececec")};
  }
`;

interface drawerProps {
  isOpen: boolean
}

const Drawer: React.FC<drawerProps> = ({ isOpen }) => {
  const { logout } = AuthConfig();
  const { isShowTitle, colorIconActive, colorIconInactive, sizeIcon } = DrawerConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<string>("");
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [menuItems, setMenuItems] = React.useState<IMenu[]>([]);
  const { user } = useSelector(selectUser);
  const { cred } = useSelector(selectCred);

  const handleItemClick = (path: string) => {
    if (path === '/logout') {
      logout();
      return;
    }
    navigate(path);
    setSelectedItem(path);
    window.localStorage.setItem("pathSelected", path);
  };

  const processItensMenu = () => {
    const i = getItensMenu(user.nivel, cred);
    setMenuItems(i);
  };

  React.useEffect(() => {
    setSelectedItem(location.pathname);
    processItensMenu();
  }, [location.pathname]);


  return (
    <DrawerContainer isOpen={isOpen}>
      {menuItems?.map((item, index) => (
        <MenuItem
          key={index}
          isSelected={selectedItem === item.path}
          isOpen={isOpen}
          onClick={() => handleItemClick(item.path)}
          data-tooltip={item.title} /* Tooltip personalizado */
        >
          <ItemIcon
            isSelected={selectedItem === item.path}
            activeColor={colorIconActive} /* Cor ativa do ícone */
            inactiveColor={colorIconInactive} /* Cor inativa do ícone */
            iconSize={sizeIcon} /* Tamanho dinâmico do ícone */
          >
            <item.Icon />
          </ItemIcon>
          {/* O título é exibido quando isShowTitle for verdadeiro */}
          {isOpen && <span>{item.title}</span>}
        </MenuItem>
      ))}
    </DrawerContainer>
  );
};




export default Drawer;
