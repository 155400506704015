import { createSlice } from '@reduxjs/toolkit'
import { iMsgAlert } from '../interfaces/interfaces'
import { RootState } from './store'


const init: iMsgAlert = {
    open: false,
    type: 'success'
}

export const slice = createSlice({
    name: 'alertService',
    initialState: init,
    reducers: {
        changeAlert(state, { payload }) {
            return { ...state, ...payload }
        },
        CloseAlert(state, { payload }) {
            return { ...state, ...payload }
        }
    }
})

export const { changeAlert, CloseAlert } = slice.actions

export const selectAlert = (state: RootState) => state

export default slice.reducer