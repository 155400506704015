import React, { useState } from 'react';
import SelectFormInput from '../../../components/datagridComp/SelectFormInput';
import { styled } from 'styled-components';
import { MdSave } from 'react-icons/md';
import { start } from 'repl';



interface IOption {
    value: string;
    label: string;
}

export interface dtFilter {
    dtStart?: string;
    dtEnd?: string;
    lastreg: number;
}

// Tipos para props e para o objeto de datas
type DateFilterProps = {
    onDateChange: (dates: dtFilter) => void;
};

const optionsStatus: IOption[] = [
    { value: '-1', label: 'Últimos 1000 registros' },
    { value: '0', label: 'Hoje' },
    { value: '1', label: 'Ontem' },
    { value: '6', label: 'Últimos 7 Dias' },
    { value: '29', label: 'Últimos 30 Dias' },
    { value: '89', label: 'Últimos 90 Dias' },
    { value: 'intervalo', label: 'Intervalo Personalizado' },

];

const Container = styled.div`
  border: 1px solid #ccc;  // Adicionando borda cinza claro
  border-radius: 8px;
  margin-top: 10px;
`;


const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
`;

const CustomInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const IconButton = styled.button`
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-top: 10px;

  &:hover {
    background: #f0f0f0;  // Efeito de hover para melhor feedback visual
  }

  svg {
    margin-left: 5px;  // Espaço entre o texto e o ícone
  }
`;

const ErrorMessage = styled.div`
  color: red;
    font-size: 16px;
    margin: 5px;
    font-weight: 700;
    display: flex;
    justify-content: center;
`;

const InfoMessage = styled.div`
  color: gray;
    font-size: 16px;
    margin: 5px;
    font-weight: 600;
    display: flex;
    justify-content: left;
`;



const DateFilter: React.FC<DateFilterProps> = ({ onDateChange }) => {
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [selectValue, setSelectValue] = React.useState<string>('-1');
    const [error, setError] = useState<string>('');
    const [info, setInfo] = useState<string>('');

    const handleDateChange = (value: any) => {
        setSelectValue(value);
        console.log(value);

        if (value === '-1') {
            onDateChange({ lastreg: 1000 });
            setInfo('');
            return
        }

        if (value === 'intervalo') {
            setInfo('');
            return;
        }

        let start = new Date();
        let end = new Date();

        start.setDate(start.getDate() - Number(value));
        if (value === '1') end.setDate(end.getDate() - 1);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        console.log(start, end);

        const formattedStart = formatDate(start);
        const formattedEnd = formatDate(end);
        onDateChange({ dtStart: formattedStart, dtEnd: formattedEnd, lastreg: 0 });
        if (value === '0' || value === '1') {
            setInfo(`Período: ${formatDateScreen(start)}`);
            return
        }
        setInfo(`Período: ${formatDateScreen(start)} até ${formatDateScreen(end)}`);


    };

    const formatDateScreen = (date: Date): string => {
        return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    }

    const formatDate = (date: Date): string => {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
    };

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value);
    }
    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(e.target.value);
    }

    React.useEffect(() => {
        handleCustomDateSet();
    }, [startDate, endDate]);

    const handleCustomDateSet = () => {
        console.log(startDate, endDate);
        if (!startDate || !endDate) {
            setError('Data inválida');
            return;
        }
        let start = new Date(`${startDate}T00:00:00`);
        let end = new Date(`${endDate}T23:59:59`);
        if (start > end) {
            setError('Data inicial maior que a final');
            return;
        }
        setError('');
        const formattedStart = formatDate(start);
        const formattedEnd = formatDate(end);
        onDateChange({ dtStart: formattedStart, dtEnd: formattedEnd, lastreg: 0 });
    };

    return (
        <div className="flex flex-col items-left justify-left max-w-96">
            <SelectFormInput
                options={optionsStatus}
                onSelect={(value) => handleDateChange(value)}
                selected={selectValue}
                title='Selecione o período'
            />
            {info && <InfoMessage>{info}</InfoMessage>}

            {selectValue === 'intervalo' && (
                <Container>
                    <InputRow>
                        <InputContainer>
                            <Label htmlFor="start-date">Data Inicial</Label>
                            <CustomInput
                                type="date"
                                id="start-date"
                                value={startDate}
                                onChange={handleStartDateChange}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="end-date">Data Final</Label>
                            <CustomInput
                                type="date"
                                id="end-date"
                                value={endDate}
                                onChange={handleEndDateChange}
                            />
                        </InputContainer>
                    </InputRow>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                </Container>
            )}
        </div>
    );
};

export default DateFilter;
