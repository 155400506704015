

import React, { useEffect, useState } from 'react';
import { reqChip, resResumeChips, itemChips } from '../../../interfaces/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { SetEquipInChip, alterApn, alterContract, deleteChips, getListChips, getResumeChips, removerEquip } from '../Gateways/chipsGateway';
import CardTotal from '../../../components/CardTotal';
import styled from 'styled-components';
import { ctrlButtons, iMsgAlert } from '../../../interfaces/interfaces';
import { changeAlert } from '../../../redux/alertServiceSlice';
import ChipsCadForm from './ChipsCadForm';
import { Column, SelectColumn } from 'react-data-grid';
import SearchField from '../../../components/datagridComp/SearchField';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import ColumnSelect from '../../../components/datagridComp/ColumnSelect';
import ModalComp from '../../../components/datagridComp/ModalComp';
import ChipProvidersSelect, { IChipProvider } from '../Components/ChipProvidersSelect';
import LoadingIndicator from '../../../components/datagridComp/LoadingComp';
import ConfirmationDialog, { typeMethods } from '../../../components/datagridComp/ConfirmationDialog';
import ChipEquipSelect, { IEquipList } from '../Components/ChipEquipSelect';
import SmallButton from '../../../components/datagridComp/SmallButton';
import "../../../styles/datagrid/datagrid.css"
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import ButtonAdd from '../../../components/datagridComp/ButtonAdd';
import ButtonEdit from '../../../components/datagridComp/ButtonEdit';
import { BtnCheckBox, ContainerInfo, InfoBox } from '../Styles/StyleChips';
import ChipCardList, { iChipsResume } from '../Components/ChipCardList';
import StatusChip from '../Components/ChipStatus';
import ChipStatusContract from '../Components/ChipStatusContract';
import ChipStatusEquip from '../Components/ChipStatusEquip';
import Sidebar from '../../../components/datagridComp/SideBar';
import SidebarActionChip, { typeMethodsSide } from '../Components/SidebarActionChip';
import { useNotifications } from '../../../Notifications/NotificationContext';


const ContainerViewTable = styled.div`
    display: grid;
  grid-template-rows: 85px 1fr; /* Define as alturas das linhas */
  grid-gap: 5px; /* Define o espaçamento entre as linhas */
  height: 85vh; /* Define a altura do contêiner */
`
const ContainerFlexInline = styled.div`
    display: flex;
    justify-content: center; 
    overflow: auto;   
`
const ContainerTable = styled.div`
padding: 6px;
    height: calc(66vh);
    overflow: auto;
`
const ContainerBarTop = styled.div`
padding: 6px;
display: flex;
    justify-content: space-between;
`
const ContainerSearch = styled.div`
padding: 6px;
display: flex;
flex-direction: column;
    justify-content: space-between;
    > input {
        margin: 0;
        padding: 8px;
        border: 1px solid #7F7F7F;
        border-radius: 7px;
        width: 250px;
  }
`
const ContainerDataGrid = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    block-size: 65vh;
    padding: 8px;
    contain: inline-size;

`

const selectCellClassname = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > input {
    margin: 0;
  }
`;

const sortPriorityClassname = styled.div`
  color: grey;
  margin-left: 2px;
`;

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    addShow: true
}


type filterSelected = {
    allRecords: boolean
    allStock: boolean
    stockChips: boolean
    stockEquipOnline: boolean
    stockChipWithEquip: boolean
    allInstaled: boolean
    instaledOnline: boolean
    instaledOffline: boolean
    instaledPaid: boolean
    instaledNoPaid: boolean
}

let valuesFilter: filterSelected = {
    allRecords: true,
    allStock: false,
    stockChips: false,
    stockEquipOnline: false,
    stockChipWithEquip: false,
    allInstaled: false,
    instaledOnline: false,
    instaledOffline: false,
    instaledPaid: false,
    instaledNoPaid: false
}

const pageSize = 300;

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
`;


const optionsActions = [
    { value: 'CANCEL_CHIP', label: 'Contrato à Cancelar' },
    { value: 'ACTIVE_CHIP', label: 'Ativar Chip' },
    { value: 'DISABLE_CHIP', label: 'Desativar Chip' },
    { value: 'REMOVE_EQUIP', label: 'Remover Equipamento' },
    { value: 'DELETE_CHIPS', label: 'Apagar Chips' },
    { value: 'ALTER_PROVIDER', label: 'Alterar Fornecedor / APN' },
    //{ value: 'ADA', label: 'Alterar data Ativação' },

];

let chipToAction: itemChips;

interface iModelCmd {
    onConfirm: (value?: any) => Promise<void> | void;
    message: string
    title?: string
    requireJustification: boolean
    type: typeMethods

}

const DELETE_CHIPS = 'DELETE_CHIPS'
const REMOVE_EQUIP = 'REMOVE_EQUIP'
const CANCEL_CHIP = 'CANCEL_CHIP'
const ACTIVE_CHIP = 'ACTIVE_CHIP'
const DISABLE_CHIP = 'DISABLE_CHIP'
const ALTER_PROVIDER = 'ALTER_PROVIDER'
const SELECT_DEVICE = 'SELECT_DEVICE'

const ListChipsPage: React.FC = () => {
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();
    const [ResumeChips, setResumeChips] = React.useState<resResumeChips>();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [ShowForm, setShowForm] = React.useState<boolean>(false);

    const [dataCategoryResume, setDataCategoryResume] = React.useState<iChipsResume[]>([]);

    //sidebar
    const handleCloseSideBar = () => setSideContent(undefined);
    const [sideContent, setSideContent] = React.useState<string>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<itemChips[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<itemChips[]>(rows);
    const [columnsFilter, setColumnsFilter] = useState<Column<itemChips>[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[]>(columnsFilter.map(column => column.key.toString()));

    //modal
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const handleCloseModelCmd = () => setModelCmd(undefined);
    const [modalContent, setModalContent] = React.useState<string>('');
    const [modelCmd, setModelCmd] = React.useState<iModelCmd>();

    const columns: Column<itemChips>[] = [
        {
            ...SelectColumn,
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'edit',
            name: 'Editar',
            width: 60,
            renderCell(props: any) {
                return (

                    <ButtonEdit onClick={() => handleEditChip(props.row)} />

                )
            }
        },
        {
            key: 'contract_status',
            name: 'Contrato',
            width: 110,
            renderCell: (props: any) => (
                <ChipStatusContract status={props.row.contract_status} />
            )
        },
        {
            key: 'status_conn',
            name: 'Status',
            width: 100,
            renderCell: (props: any) => (
                <StatusChip status={props.row.status_conn} />
            )
        },
        {
            key: 'equip_status',
            name: 'Equip',
            width: 100,
            renderCell: (props: any) => (
                <ChipStatusEquip status={props.row.equip_status} />
            )
        },
        {
            key: 'fornecedor',
            name: 'Fornecedor',
            width: 150,
        },
        {
            key: 'serial',
            name: 'Serial',
            width: 230,
        },
        {
            key: 'linha',
            name: 'Linha',
            width: 150
        },

        {
            key: 'nDiasConn',
            name: 'Conectado',
            width: 100
        },
        {
            key: 'equip_imei',
            name: 'Imei',
            width: 280,
            renderCell(props: any) {
                return (
                    props.row.equip_imei ? <>
                        <div><i>{props.row.equip_imei}</i> / <strong>{props.row.fab_nome}-{props.row.mod_ref}</strong></div>
                    </> : <SmallButton label='Selecionar Equipamento' onClick={() => {
                        chipToAction = props.row
                        setSideContent(SELECT_DEVICE)
                    }
                    } />
                )
            }
        },
        {
            key: 'finance_status',
            name: 'Financeiro',
            width: 100
        },

        {
            key: 'cliente_name',
            name: 'Cliente',
            width: 240
        },
        {
            key: 'veiculo_placa',
            name: 'Veículo',
            width: 130
        },

        /* {
             key: 'equip_imei',
             name: 'Imei',
             width: 130
         },
         {
             key: 'fab_nome',
             name: 'Fabricante',
             width: 130
         },
         {
             key: 'mod_ref',
             name: 'Modelo',
             width: 100
         },*/


    ];





    const handleEditChip = (item: itemChips) => {
        chipToAction = item
        setShowForm(true)
    }
    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }







    async function recalcCards(data: resResumeChips) {

        const chipsResumeData: iChipsResume[] = [
            { category: "Total de Chips", category_icon: "", total_amount: data?.totalChips ?? 0, isSelected: valuesFilter.allRecords, filterKey: 'allRecords' },
            { category: "Total Estoque", category_icon: "", total_amount: data?.totalEstoque ?? 0, isSelected: valuesFilter.allStock, filterKey: 'allStock' },
            { category: "Chips Novos", category_icon: "", total_amount: data?.estoqueChips ?? 0, isSelected: valuesFilter.stockChips, filterKey: 'stockChips' },
            { category: "Estoque On-Line", category_icon: "", total_amount: data?.estoqueEquipOnline ?? 0, isSelected: valuesFilter.stockEquipOnline, filterKey: 'stockEquipOnline' },
            { category: "Estoque c/ Equipamento", category_icon: "", total_amount: data?.estoqueEquipWithChip ?? 0, isSelected: valuesFilter.stockChipWithEquip, filterKey: 'stockChipWithEquip' },
            { category: "Total Instalados", category_icon: "", total_amount: data?.totalInstalado ?? 0, isSelected: valuesFilter.allInstaled, filterKey: 'allInstaled' },
            { category: "Instalado Online", category_icon: "", total_amount: data?.instaladoOnline ?? 0, isSelected: valuesFilter.instaledOnline, filterKey: 'instaledOnline' },
            { category: "Instalado OffLine", category_icon: "", total_amount: data?.instaladoOffline ?? 0, isSelected: valuesFilter.instaledOffline, filterKey: 'instaledOffline' },
            { category: "Instalado Inadiplentes", category_icon: "", total_amount: data?.instaladoInadiplente ?? 0, isSelected: valuesFilter.instaledNoPaid, filterKey: 'instaledNoPaid' },
            { category: "Instalado Pagos", category_icon: "", total_amount: data?.instaladoPago ?? 0, isSelected: valuesFilter.instaledPaid, filterKey: 'instaledPaid' },
        ];
        setDataCategoryResume(chipsResumeData)

    }
    async function reqResumeChips() {
        try {
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                //idEmpresas: [49, 20250087, 20250098]

            }
            const result = await getResumeChips(payload);
            setResumeChips(result)
            recalcCards(result)
            reqGetListChips()
        } catch (e) {
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[ListTasksDelivery]', error)
        }
    }
    async function reqGetListChips() {
        try {
            setIsLoading(true)
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                //idEmpresas: [49, 20250087, 20250098],
                estoqueChips: valuesFilter.stockChips,
                AllStockChips: valuesFilter.allStock,
                onlineChips: valuesFilter.instaledOnline,
                instaladoInadiplentes: valuesFilter.instaledNoPaid,
                instaladoPagos: valuesFilter.instaledPaid,
                estoqueEquipWithChip: valuesFilter.stockChipWithEquip,
                estoqueEquipOnline: valuesFilter.stockEquipOnline,
                totalInstalado: valuesFilter.allInstaled,
                instaladoOffline: valuesFilter.instaledOffline,
                instaladoOnline: valuesFilter.instaledOnline,
            }
            const result = await getListChips(payload);
            if (result) {
                setRows(result)
                if (ResumeChips) recalcCards(ResumeChips)
                setFilteredRows(result);
            }
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }

    const removeEquip = async (reason?: string) => {
        try {
            setIsLoading(true)
            let payload = {
                ids: Array.from(selectedRows),
                reason: reason ?? 'Não informado'
            }
            await removerEquip(payload);
            deselectAll()
            reqResumeChips();
            addNotification('', 'Equipamentos removidos com sucesso', 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }
    const cancelChip = async (value: any) => {
        try {
            setIsLoading(true)
            let payload = {
                ids: Array.from(selectedRows),
                reason: value.justification ?? 'Não informado'
            }
            await removerEquip(payload);
            deselectAll()
            reqResumeChips();
            addNotification('', 'Chips cancelados com sucesso', 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }



    useEffect(() => {

        (async () => {
            setColumnsFilter(columns)
            reqResumeChips();
        })();
    }, []);


    const handleRefresh = () => {
        reqResumeChips();
    }
    const handleFormChip = () => {
        setShowForm(true)
    }
    const handleCancelFormChip = () => {
        setShowForm(false)
    }
    const handleSavedChip = () => {
        setShowForm(false)
        reqResumeChips();
    }



    const handleFilter = (item: string) => {
        let filter: filterSelected = {
            allRecords: false,
            allStock: false,
            stockChips: false,
            stockEquipOnline: false,
            stockChipWithEquip: false,
            allInstaled: false,
            instaledOnline: false,
            instaledOffline: false,
            instaledPaid: false,
            instaledNoPaid: false
        }
        filter[item as keyof filterSelected] = true
        valuesFilter = filter
        reqGetListChips();
        deselectAll()
    }

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };


    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos um registro', 'warning');
            return;
        }
        switch (action) {
            case 'REMOVE_EQUIP':
                setSideContent(REMOVE_EQUIP)
                break
            case 'DELETE_CHIPS':
                setSideContent(DELETE_CHIPS)
                break
            case 'CANCEL_CHIP':
                setSideContent(CANCEL_CHIP)
                break
            case 'ACTIVE_CHIP':
                setSideContent(ACTIVE_CHIP)
                break
            case 'DISABLE_CHIP':
                setSideContent(DISABLE_CHIP)
                break
            case 'ALTER_PROVIDER':
                setSideContent(ALTER_PROVIDER)
                break
        }
    };

    const handleAPNSelected = async (value: any) => {
        try {
            console.log(value)
            setIsLoading(true)
            let payload = {
                chip_fornecedor: value.provider.id_provider,
                chip_apn: value.provider.id,
                ids: Array.from(selectedRows)
            }
            const result = await alterApn(payload);
            deselectAll()
            reqResumeChips();
            addNotification('', 'Fornecedor / APN alterado com sucesso', 'success');
            handleCloseSidebar()

        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }

    };
    const SendEquipelected = async (payload: any) => {
        if (payload.device === undefined) {
            addNotification('', 'Selecione um equipamento', 'warning');
            return
        }
        if (chipToAction === undefined) {
            addNotification('Falha ao selecionar Chip', 'Repita a operação', 'error');
            return
        }
        const { iddisp, equip_imei } = payload.device
        try {
            let input = {
                iddisp: iddisp,
                idchip: chipToAction.id
            }
            await SetEquipInChip(input);
            reqResumeChips();
            addNotification('Operação realizada com Sucesso!', `Equipamento ${equip_imei} vinculado ao chip cód: ${chipToAction.id}`, 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    };

    const handleConfirmeDelete = async (value: any) => {
        try {
            let payload = {
                ids: Array.from(selectedRows),
                reason: value.justification ?? 'Não informado'
            }
            const result = await deleteChips(payload);
            deselectAll()
            reqResumeChips();
            addNotification('', 'Chips apagadas com sucesso', 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }

    };

    const handleAlterContract = async (value: any) => {
        try {
            let payload = {
                ids: Array.from(selectedRows),
                reason: value.justification,
                status: value.status
            }
            console.log(payload)
            const result = await alterContract(payload);
            deselectAll()
            reqResumeChips();
            addNotification('Alterar contrato', `Contrato alterado: ${value.status}`, 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }

    };


    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((Expense) => Expense.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const handleCloseSidebar = () => {
        setSideContent(undefined);
    };

    return (
        <>
            {!ShowForm && <>
                <ChipCardList categories={dataCategoryResume} onClick={handleFilter} />
                <>
                    {/*<ContainerBarTop>
                            <ContainerSearch>
                                <div className="p-4">
                                    {isModalOpen && (
                                        <ModalComp title="" subtitle="" onClose={handleClose}>
                                            {modalContent === PROVIDERS_CONTENT && <ChipProvidersSelect onRowSelect={handleAPNSelected} />}
                                            {modalContent === EQUIP_CONTENT && <ChipEquipSelect onRowSelect={handleEquipelected} />}
                                            {modalContent === CONFIRM_REMOVE_EQUIP && <ConfirmationDialog type='QUESTION' requireJustification={true} message="Justifique a remoção dos equipamentos dos chips selecionados!" onConfirm={removeEquip} onCancel={() => handleClose()} />}
                                            {modalContent === CONFIRM_ALTER_PROVIDER && <ConfirmationDialog type='QUESTION' requireJustification={true} message="Justifique e confirme a alteração da APN / Fornecedor dos chips selecionados!" onConfirm={() => { }} onCancel={() => handleClose()} />}
                                            {modalContent === LOADING_CONTENT && <LoadingIndicator />}
                                            {modalContent === CONFIRMED_ACTION && <ConfirmationDialog type='CONFIRMED' message="Operação realizada com sucesso!" onConfirm={() => handleClose()} onCancel={() => handleClose()} />}

                                        </ModalComp>
                                    )}
                                </div>
                                <div className="p-4">
                                    {modelCmd && (
                                        <ModalComp title={modelCmd.title ?? ""} subtitle="" onClose={handleCloseModelCmd}>
                                            <ConfirmationDialog
                                                type={modelCmd.type}
                                                requireJustification={modelCmd.requireJustification}
                                                message={modelCmd.message}
                                                onConfirm={modelCmd.onConfirm}
                                                onCancel={() => handleCloseModelCmd()} />
                                        </ModalComp>
                                    )}
                                </div>
                            </ContainerSearch>


                                </ContainerBarTop>*/}
                    <SearchAndSelectContainer>
                        <SearchField onSearch={handleSearch} />
                        <ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
                            title='Selecione a ação'
                        />
                        <ColumnSelect
                            columns={columns}
                            onColumnVisibilityChange={(visibleColumnKeys) => {
                                setVisibleColumns(visibleColumnKeys);
                            }}
                        />
                        <ButtonRefresh onClick={reqResumeChips} />
                        <ButtonAdd onClick={handleFormChip} />
                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            visibleColumns={visibleColumns}
                            onRowSelect={handleRowSelect}
                        /></ContainerTable>

                </>
            </>}
            <Sidebar isOpen={sideContent === DELETE_CHIPS} onClose={handleCloseSidebar} title={'Apagar Chips'}>
                <SidebarActionChip
                    requireJustification={true}
                    isShow={sideContent === DELETE_CHIPS}
                    type={sideContent as typeMethodsSide}
                    message="Apagar os Chips selecionadas!"
                    onConfirm={handleConfirmeDelete}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === REMOVE_EQUIP} onClose={handleCloseSidebar} title={'Remover Equipamentos'}>
                <SidebarActionChip
                    requireJustification={true}
                    isShow={sideContent === REMOVE_EQUIP}
                    type={sideContent as typeMethodsSide}
                    message="Remover equipamentos dos chips selecionadas!"
                    onConfirm={removeEquip}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === CANCEL_CHIP} onClose={handleCloseSidebar} title={'Cancelar Chips'}>
                <SidebarActionChip
                    requireJustification={true}
                    isShow={sideContent === CANCEL_CHIP}
                    type={sideContent as typeMethodsSide}
                    message="Solicitar o Cancelamento dos chips selecionadas!"
                    onConfirm={handleAlterContract}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === ACTIVE_CHIP} onClose={handleCloseSidebar} title={'Ativar Chips'}>
                <SidebarActionChip
                    requireJustification={false}
                    isShow={sideContent === ACTIVE_CHIP}
                    type={sideContent as typeMethodsSide}
                    message="Ativar chips selecionadas!"
                    onConfirm={handleAlterContract}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === DISABLE_CHIP} onClose={handleCloseSidebar} title={'Desativar Chips'}>
                <SidebarActionChip
                    requireJustification={false}
                    isShow={sideContent === DISABLE_CHIP}
                    type={sideContent as typeMethodsSide}
                    message="Desativar chips selecionadas!"
                    onConfirm={handleAlterContract}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === ALTER_PROVIDER} onClose={handleCloseSidebar} title={'Alterar Fornecedor'}>
                <SidebarActionChip
                    requireJustification={false}
                    isShow={sideContent === ALTER_PROVIDER}
                    type={sideContent as typeMethodsSide}
                    message="Alterar fornecedor/APN chips selecionadas!"
                    onConfirm={handleAPNSelected}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === SELECT_DEVICE} onClose={handleCloseSidebar} title={'VINCULAR EQUIPAMENTO'}>
                <SidebarActionChip
                    requireJustification={false}
                    isShow={sideContent === SELECT_DEVICE}
                    type={sideContent as typeMethodsSide}
                    message="Vincular Equipamento ao Chip"
                    onConfirm={SendEquipelected}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>

            {ShowForm && <ChipsCadForm onCancelClick={handleCancelFormChip} onSaveClick={handleSavedChip} initialData={chipToAction} />}
        </>
    )

}

export default ListChipsPage

