import { Dialog, DialogTitle, DialogContent, Stack, DialogActions, Button, List, Avatar, ListItem, ListItemAvatar, ListItemText, Box, TextField } from '@mui/material';
import * as React from 'react';
import { DataGrid, GridColDef, GridToolbarQuickFilter, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridEventListener } from '@mui/x-data-grid';
import BarTable from './BarTable';
import { ctrlButtons, iDestinos } from '../interfaces/interfaces';

import http from '../services/http-common'
import { selectUser } from '../redux/userSlice';
import { useSelector } from 'react-redux';



interface DialogProps {
    open: boolean,
    onMultiSelect: boolean,
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void,
    onSave?: (item: iDestinos[]) => void,
    onSelect?: (item: iDestinos) => void,

}

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    SaveShow: true,
    CancelShow: true,
    SaveDisable: true
}

const DialogListDest: React.FC<DialogProps> = (props) => {


    const { user } = useSelector(selectUser);
    const [listDialog, setListDialog] = React.useState<iDestinos[]>([]);
    const [listDatagrid, setListDatagrid] = React.useState<iDestinos[]>([]);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);

    /*const searchItems = (searchValue: string) => {
        if (searchValue !== '') {
            const filteredData = listDialog.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
            })
            setListDatagrid(filteredData)
        }
        else {
            setListDatagrid(listDialog)
        }
    }*/

    async function getDados() {
        const result = await http.post("/GetListPlaces", {
            idcliente: user.idcliente,
            type: 1
        });
        let resp = result.data.dados;
        setListDialog(resp)
        setListDatagrid(resp)
        console.log(resp)
    }

    const columns: GridColDef[] = [


        {
            field: 'descr',
            headerName: 'Descrição',
            width: 250,
        },
        {
            field: 'contact',
            headerName: 'Contato',
            width: 250
        },
        {
            field: 'document',
            headerName: 'Documento',
            width: 150
        },
        {
            field: 'ender',
            headerName: 'Endereço',
            width: 350,
        },
        {
            field: 'phone',
            headerName: 'Telefone',
            width: 130
        },
        {
            field: 'email',
            headerName: 'E-mail',
            width: 250
        }

    ];

    React.useEffect(() => {
        console.log("useEffect DialogListDest", props.open)
        if (user.idcliente && props.open) {
            CtrlBtns.SaveShow = props.onMultiSelect ? true : false
            setCtrlBtns(CtrlBtns)
            getDados()
        }
    }, [props.open]);

    const setSel = (itens: any) => {
        if (props.onMultiSelect) {
            let i: number[] = []
            for (let x = 0; x < itens.length; x++) {
                i.push(Number(itens[x]));
            }
            setSelectdRows(i)
            CtrlBtns.SaveDisable = i.length ? false : true
            setCtrlBtns(CtrlBtns)
        }
    }

    const SaveSel = () => {
        let itens: iDestinos[] = []
        for (let x = 0; x < selectdRows.length; x++) {
            let i = listDialog.find(element => element.id === selectdRows[x])
            if (i) { itens.push(i) }
        }
        console.log(itens)
        if (props.onSave) props.onSave(itens)
    }


    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        if (!props.onMultiSelect) {
            props.onSelect?.(params.row)
        }
    };

    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    height: 64,
                    padding: 1,
                    backgroundColor: '#ededed',
                }} >
                <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                    <GridToolbarColumnsButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarQuickFilter />
                    <BarTable CtrlButtons={CtrlBtns} onSaveClick={SaveSel} onCancelClick={props.onClose} />
                </Stack>
            </Box>
        );
    }


    return (<>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800 } }}
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Destinos</DialogTitle>
            <DialogContent>
                <Box sx={{ height: '70vh', width: '100%', background: 'white' }}>

                    <DataGrid
                        rows={listDatagrid}
                        columns={columns}
                        density="compact"
                        checkboxSelection={props.onMultiSelect}
                        /*getRowHeight={() => 'auto'}*/
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSel(newSelectionModel)
                        }}
                        onRowClick={handleRowClick}
                        components={{ Toolbar: QuickSearchToolbar }}
                    />
                </Box>

            </DialogContent>
        </Dialog></>)

}

export default DialogListDest