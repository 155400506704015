import React from 'react';
import styled from 'styled-components';

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
`;

const LoadingContent = styled.div`
    text-align: center;

    h2 {
        font-size: 2rem;
        margin-bottom: 20px;
        color: #333;
    }
   
`;

const LoadingGoogleMaps: React.FC = () => {
    return (
        <LoadingContainer>
            <LoadingContent>
                <h2>Carregando Google Maps...</h2>
            </LoadingContent>
        </LoadingContainer>
    );
};

export default LoadingGoogleMaps;
