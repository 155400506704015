import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { selectUser } from './redux/userSlice';
import { useSelector } from 'react-redux';

import AngularPageComp from './pages/AngularPage';
import LoginPage from './pages/LoginPage';
import Delivery from './pages/Delivery/Delivery';
import TravelPage from "./pages/TravelPage";
import ChipsPage from "./pages/Chips/Views/ChipsPage";
import DriversPage from "./pages/Drivers/Views/DriversPage";
import VideoPlayer from "./pages/StreamCam/Views/StreamTab";
import MasterData from "./pages/geral/view/MasterData";
import FinancePage from "./pages/Finance/View/FinancePage";
import HelpPage from "./pages/Help/HelpPage";
import CommandsCreatorPage from "./pages/ComandsCreator/views/CommandsCreatorPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import AlertsPage from "./pages/AlertsPage/AlertsPage";
import ExpensesPage from "./pages/ExpensesPage/ExpensesPage";
import CommandsPage from "./pages/ComandsCreator/CommandsPage";
import StreamPage from "./pages/StreamCam/Views/StreamPage";
import MapPageAdvance from "./pages/MapAdvance/MapPageAdvance";
import FuelMainPage from "./pages/FuelPage/FuelMainPage";

import DeleteDataRequestLGPD from "./pages/LGPD/DeleteDataRequestLGPD";
import PrivacyPolicy from "./pages/LGPD/PrivacyPolicy";
import { AuthConfig } from "./Config/AuthContext";
import ChecklistPage from "./pages/Checklist/ChecklistPage";
import LoadingConfigUsers from "./components/LoadingConfigUsers";
import MessagesPage from "./pages/Messages/View/MessagesPage";
import SettingsPage from "./pages/settings/SettingsPage";
import FormCompany from "./pages/Empresas/FormCompany";

// const PrivateRouteOld = ({ children }) => {
//     const { isLogged } = AuthConfig();
//     console.log('isLogged', isLogged);
//     //const { user } = useSelector(selectUser);
//     return isLogged ? children : <Navigate to="/login" />;
// };

const PrivateRoute = ({ children }) => {
    const { isLogged, loading } = AuthConfig();
    if (loading) {
        // Enquanto o estado de carregamento está ativo, você pode retornar um spinner ou uma tela de carregamento
        return <LoadingConfigUsers />; // Ou um spinner de sua escolha
    }
    if (!isLogged) {
        return <Navigate to="/login" />;
    }

    return children;
};

const PublicRoute = ({ children }) => {
    return children;
};

const ClientRoute = ({ children }) => {
    const { user } = useSelector(selectUser);
    return user && user.isAuthenticated && user.nivel === 3 ? children : <Navigate to="/noauth" />;
};

const AdminRoute = ({ children }) => {
    const { user } = useSelector(selectUser);
    return user && user.isAuthenticated && user.nivel === 2 ? children : <Navigate to="/noauth" />;
};
const MasterRoute = ({ children }) => {
    const { user } = useSelector(selectUser);
    return user && user.isAuthenticated && user.nivel === 1 ? children : <Navigate to="/noauth" />;
};

const RoutesComp = () => (
    <Routes >
        {/*react*/}
        {/*Open Links*/}
        <Route path='/deletelgpd' element={<PublicRoute><DeleteDataRequestLGPD /></PublicRoute>} />
        <Route path='/privacy' element={<PublicRoute><PrivacyPolicy /></PublicRoute>} />
        <Route path='/company/register' element={<PublicRoute><FormCompany /></PublicRoute>} />

        <Route path='/' element={<LoginPage />} />
        <Route path='/noauth' element={<UnauthorizedPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/geral' element={<PrivateRoute><MasterData /></PrivateRoute>} />

        {<Route path='/map' element={<PrivateRoute><MapPageAdvance /></PrivateRoute>} />}

        <Route path='/despesas' element={<ClientRoute><ExpensesPage /></ClientRoute>} />
        <Route path='/delivery' element={<ClientRoute><Delivery /></ClientRoute>} />
        <Route path='/travel' element={<ClientRoute><TravelPage /></ClientRoute>} />
        <Route path='/drivers' element={<ClientRoute><DriversPage /></ClientRoute>} />
        <Route path='/stream' element={<ClientRoute><StreamPage /></ClientRoute>} />
        <Route path='/fuel' element={<ClientRoute><FuelMainPage /></ClientRoute>} />
        <Route path='/checklist' element={<ClientRoute><ChecklistPage /></ClientRoute>} />

        <Route path='/finances_beta' element={<AdminRoute><FinancePage /></AdminRoute>} />
        <Route path='/chips' element={<AdminRoute><ChipsPage /></AdminRoute>} />
        <Route path='/alerts' element={<PrivateRoute><AlertsPage /></PrivateRoute>} />
        <Route path='/messages' element={<AdminRoute><MessagesPage /></AdminRoute>} />
        <Route path='/settings' element={<AdminRoute><SettingsPage /></AdminRoute>} />

        {/*gandalf*/}
        <Route path='/dashboard-max' element={<MasterRoute><MasterData /></MasterRoute>} />
        <Route path='/commands-creator' element={<MasterRoute><CommandsPage /></MasterRoute>} />
        <Route path='/chips-admin' element={<MasterRoute><ChipsPage /></MasterRoute>} />

        {/*angular cliente*/}
        {<Route path='/mapv8' element={<PrivateRoute><AngularPageComp location='mapaV8' /></PrivateRoute>} />}
        <Route path='/report' element={<PrivateRoute><AngularPageComp location='reportv5' /></PrivateRoute>} />
        {/*<Route path='/fuel' element={<PrivateRoute><AngularPageComp location='abastV3' /></PrivateRoute>} />*/}
        <Route path='/manut' element={<PrivateRoute><AngularPageComp location='manut' /></PrivateRoute>} />
        {/*<Route path='/despesas' element={<PrivateRoute><AngularPageComp location='despesas' /></PrivateRoute>} />*/}
        {/*<Route path='/drivers' element={<PrivateRoute><AngularPageComp location='motoristas' /></PrivateRoute>} />*/}
        <Route path='/veiculos_cli' element={<PrivateRoute><AngularPageComp location='veiculos_cli' /></PrivateRoute>} />
        <Route path='/checklist' element={<PrivateRoute><AngularPageComp location='checklist' /></PrivateRoute>} />
        <Route path='/jornada' element={<PrivateRoute><AngularPageComp location='jornadasV2' /></PrivateRoute>} />
        <Route path='/multas' element={<PrivateRoute><AngularPageComp location='multas' /></PrivateRoute>} />
        <Route path='/grupos_user' element={<PrivateRoute><AngularPageComp location='grupos_user' /></PrivateRoute>} />

        {/*angular admin*/}
        <Route path='/dashboard' element={<PrivateRoute><AngularPageComp location='dashboard' /></PrivateRoute>} />
        <Route path='/clients' element={<PrivateRoute><AngularPageComp location='clientesV3' /></PrivateRoute>} />
        <Route path='/finances' element={<PrivateRoute><AngularPageComp location='Finance' /></PrivateRoute>} />
        <Route path='/vehicles' element={<PrivateRoute><AngularPageComp location='veiculos' /></PrivateRoute>} />
        <Route path='/equip' element={<PrivateRoute><AngularPageComp location='equipamentos' /></PrivateRoute>} />
        <Route path='/grupos_user' element={<PrivateRoute><AngularPageComp location='grupos_user' /></PrivateRoute>} />
        <Route path='/empresa' element={<PrivateRoute><AngularPageComp location='empresa' /></PrivateRoute>} />
        {/*<Route path='/chips' element={<PrivateRoute><AngularPageComp location='chips' /></PrivateRoute>} />*/}



        <Route path='/help' element={<PrivateRoute><HelpPage /></PrivateRoute>} />




    </Routes>
);

export default RoutesComp;