import styled, { keyframes } from 'styled-components';
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 20vh; 
  background-color: ${(props) => props.theme.background};
`;

export const Container = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 420px; 
`;

export const Header = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Input = styled.input.attrs({
  className: 'form-control'
})`
    width: 100%;
    height: 40px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 8px;
    color: #808080;
    border: 1px solid #ccc;
  
    &:focus {
      outline: none;
      border: 2px solid #FFA07A;
    }
  `;

export const InputContainer = styled.div`
  position: relative;
  display: inline-block; // ou 'block' dependendo do layout desejado
  width: 100%;
  margin-bottom: 10px; // Ajuste conforme necessário
`;

export const Label = styled.label`
 
  color: #808080; // Cor do texto do label, ajuste conforme necessário
  font-size: 0.875rem; // Tamanho do texto do label, ajuste conforme necessário
`;


export const Button = styled.button`
  width: 100%; 
  padding: 0.75rem 0; 
  background-color: ${(props) => props.theme.primary};
  color: white;
  border: 1px solid ${(props) => props.theme.primary};
  border-radius: 0.25rem;
  font-size: 1rem;
  margin-bottom: 1rem; 

  &:hover {
    background-color: darken(${(props) => props.theme.primary}, 10%);
    border-color: darken(${(props) => props.theme.primary}, 10%);
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.theme.primary}; 
  font-size: 1rem; 
  margin-bottom: 0.5em; 
  font-weight: bold; 
  text-align: center; 
  @media (max-width: 768px) {
    font-size: 0%.9; 
  }
`;

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

export const SpinnerStyled = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #fff;
  animation: ${spin} 1s ease-in-out infinite;
`;


export const BackButton = styled.button`
  color: ${(props) => props.theme.text}; 
  font-weight: 700;
  padding: 0.75rem 0; 
  width: 100%; 
  border: 1px solid ${(props) => props.theme.border}; 
  background-color: ${(props) => props.theme.background}; 
  border-radius: 0.25rem; 
  margin-top: 1rem; 
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.background}; 
    border-color: darken(${(props) => props.theme.border}, 10%); 
  }
`;

export const SaveButton = styled.button<{ saveColor?: string }>`
  color: white; // Cor do texto
  font-weight: 700;
  padding: 0.75rem 0;
  width: 100%;
  border: none; // Removido a borda para usar a cor de fundo como principal
  background-color: ${(props) => props.saveColor || 'green'}; // Cor de fundo verde ou a cor passada por prop
  border-radius: 0.25rem;
  margin-top: 1rem;
  cursor: pointer;

  &:hover {
    background-color: darkgreen; // Cor de fundo mais escura no hover
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between; // Espalha os itens uniformemente, adicionando espaço entre eles
  align-items: flex-start; // Alinha os itens ao início do container flex
  gap: 20px; // Adiciona um espaço entre cada item filho (ajuste conforme necessário)

  @media (max-width: 768px) {
    flex-direction: column; // Empilha os itens verticalmente em telas menores
  }
`;


export const MessageErrorContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #dc3545;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
  font-weight: 700;
  text-align: center;
`;

export const MessageSuccessContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #28a745; 
  border-radius: 4px;
  background-color: #d4edda; 
  color: #155724; 
  font-weight: 700;
  text-align: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #721c24;

  &:hover {
    background-color: #f5c6cb;
  }
`;