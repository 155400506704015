import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import QRCodeComponent, { iQRCode } from '../components/QRCodeComponent';
import { checkConnectionWpp, generateTokenWpp, startSessionWpp, logoutSessionWpp } from '../Gateways/FinanceGateway';

const ContainerDiv = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  width: 100%;  // Largura completa
  height: 80vh;  // Altura fixa, ajuste conforme necessário
  border: 0px; 
`;


// Estilizações com Styled Components
const Card = styled.div`
  width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid black; /* Bordas mais finas */
  background-color: transparent;
  cursor: pointer;
`;

const ConnectedIcon = styled.span`
  color: green;
  font-size: 24px;
  margin-bottom: 20px;
`;

let timerId: NodeJS.Timeout | null = null;
let isStartedSession: boolean = false;

const WhatsAppPage: React.FC = () => {
    const { user } = useSelector(selectUser);
    const [startSession, setStartSession] = useState<iQRCode | null>(null);
    const [connectionStatus, setConnectionStatus] = useState<string | null>(null);


    const checkConnection = async () => {
        if (isStartedSession) {
            startSessionFunc();
            return;
        }
        try {
            const result = await checkConnectionWpp(user.idempresa);
            console.log('Connection check:', result);
            setConnectionStatus(result.message);

        } catch (error) {
            console.error('Connection check failed:', error);
            //setConnectionStatus('Error');
        }
    }

    const startSessionFunc = async () => {
        try {
            isStartedSession = true;
            const result = await startSessionWpp(user.idempresa);
            setStartSession(result);
            if (result.status === 'CONNECTED') {
                isStartedSession = false;
            }
        } catch (error) {
            console.error('Start session failed:', error);
            isStartedSession = false;
            setStartSession(null);
        }
    }

    const handleGenerateToken = async () => {
        try {
            await generateTokenWpp(user.idempresa, user.fantasia);
            checkConnection();
        } catch (error) {
            console.error('Generate token failed:', error);
        }
    }

    const handleLogout = async () => {
        try {
            await logoutSessionWpp(user.idempresa);
            setStartSession(null);
            checkConnection();
        } catch (error) {
            console.error('Logout failed:', error);
            setStartSession(null);
            checkConnection();
        }
    }

    useEffect(() => {
        checkConnection();
        if (timerId) clearInterval(timerId);
        timerId = setInterval(checkConnection, 5000);
        return () => {
            if (timerId) clearInterval(timerId);
        };
    }, []);

    return (
        <ContainerDiv>
            {connectionStatus === 'SESSION_NOTFOUND' && (
                <Card>
                    <Button onClick={handleGenerateToken}>Gerar Token</Button>
                </Card>
            )}
            {connectionStatus === 'Disconnected' && startSession == null && (
                <Card>
                    <Button onClick={startSessionFunc}>Iniciar Sessão</Button>
                </Card>
            )}
            {connectionStatus === 'Connected' && (
                <Card>
                    <ConnectedIcon>✔ Conectado</ConnectedIcon>

                    <Button onClick={handleLogout}>Desconectar Sessão</Button>
                </Card>
            )}
            {startSession && connectionStatus !== 'Connected' && (
                <QRCodeComponent params={startSession} />
            )}
        </ContainerDiv>
    );
}

export default WhatsAppPage;
