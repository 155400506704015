
import React, { useState } from 'react';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../../Messages/Style/StyleMessages';
import { handleSearch } from '../Utils/searchUtils';
import { getFuelInvoices } from '../repository/FuelRepo';
import { useNotifications } from '../../../Notifications/NotificationContext';
import NoData from '../../../components/NoData';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../../components/datagridComp/SideBar';
import FormFuelView from '../FormsFuel/FormFuelView';
import { v4 as uuidv4 } from 'uuid';

import { getColumnsSuppliers } from '../Utils/SuppliersColumns';
import { getListSuppliers, iSupplier } from '../repository/SupplierRepo';
import FormSuppliers from '../FormsFuel/FormSuppliers';


const OPEN_FORM = 'OPEN_FORM';
const EDIT_FORM = 'EDIT_FORM';
const NEW_FORM = 'NEW_FORM';

const SuppliersFuelTab: React.FC = () => {

    const { addNotification } = useNotifications();
    const [InvoiceEdit, setInvoiceEdit] = React.useState<iSupplier>();


    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();
    const handleCloseSideBar = () => setSideContent(undefined);

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<iSupplier[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);



    async function getListInServer() {
        try {
            const result = await getListSuppliers();
            setRows(result);

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }


    // Função para abrir no mapa, controlada pelo componente pai
    const handleViewInMap = (row: iSupplier) => {
        console.log('Abrindo no mapa:', row);
        // Lógica adicional aqui
    };

    // Função para editar o item, controlada pelo componente pai
    const handleEditItem = (row: iSupplier) => {
        setInvoiceEdit(row);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    React.useEffect(() => {
        if (InvoiceEdit) {
            setSideContent(EDIT_FORM);
        }
    }, [InvoiceEdit]);

    const CancelFormFuel = () => {
        setInvoiceEdit(undefined);
        setSideContent(undefined);
    }


    // Obtenha as colunas passando as funções de controle
    const columns = getColumnsSuppliers(handleEditItem);

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((alert) => alert.id));
        setSelectedRows(allIds);
    };

    const handleNewInvoice = () => {
        setInvoiceEdit(undefined);
        setSideContent(NEW_FORM);
    };

    const handleUpdateList = () => {
        getListInServer();
        CancelFormFuel();
    };


    React.useEffect(() => {
        getListInServer();
    }, []);



    return (
        <>

            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                </BtnFaIcon >
                <BtnFaIcon title='Cadastrar Novo Fornecedor' onClick={handleNewInvoice} >
                    <BoxIcon icon={faAdd} iconColor='green' /> Novo Fornecedor
                </BtnFaIcon >

            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
            </ContainerInfo>
            {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}


            <Sidebar width='600px' isOpen={sideContent === EDIT_FORM} onClose={CancelFormFuel} title={'Editar Fornecedor'} >
                {InvoiceEdit &&
                    <FormSuppliers
                        onUpdate={handleUpdateList}
                        initialData={InvoiceEdit}
                        onCancel={CancelFormFuel} />}
            </Sidebar>

            <Sidebar width='600px' isOpen={sideContent === NEW_FORM} onClose={CancelFormFuel} title={'Cadastro de Fornecedor'}>
                <FormSuppliers onUpdate={handleUpdateList} onCancel={CancelFormFuel} />
            </Sidebar>
        </>
    )

}
export default SuppliersFuelTab