

import CircularProgress, { circularProgressClasses, CircularProgressProps, } from '@mui/material/CircularProgress';
import React from 'react';
import { styled } from '@mui/system';

const ProgressCenter = styled('div')({
    display: 'flex',
    padding: 20,
    justifyContent: 'center'
});


const LoadingCircularComp: React.FC = () => {

    return (
        <>
            <ProgressCenter>
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? '#43b309' : '#43b309'),
                        animationDuration: '550ms',
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={40}
                    thickness={4}
                />
            </ProgressCenter>
        </>
    )

}
export default LoadingCircularComp