

import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeLight } from '../styles/themes/MuiTheme';
import { GridEventListener, GridToolbarColumnsButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box, Stack, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import BarTable from '../components/BarTable';
import { ctrlButtons } from '../interfaces/interfaces';


interface DataGridProps {
    isLoading: boolean
    rows: any[]
    columns: GridColDef[]
    handleSelRow?: (itens: any) => void
    initialBtns: ctrlButtons
    CheckBoxSelect?: boolean
    SelectionOnClickRow?: boolean
    pageSize?: number
    onRefresClick?: () => void
    onSaveClick?: () => void
    onActionClick?: () => void
    onMapClick?: () => void
    onChatClick?: () => void
    onFilterClick?: () => void
    onCancelClick?: () => void
    onDeleteClick?: () => void
    onAddClick?: () => void
    onRouterClick?: () => void
    onRowClick?: (item: any) => void
}

const WidgetDataGridComp: React.FC<DataGridProps> = (props) => {

    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(props.initialBtns);


    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    height: 36,
                    padding: 1,
                    backgroundColor: '#ededed',
                }} >
                <Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
                    <GridToolbarColumnsButton />
                    <GridToolbarQuickFilter />
                    <BarTable CtrlButtons={CtrlBtns}
                        onAddClick={props.onAddClick}
                        onRefresClick={props.onRefresClick}
                        onCancelClick={props.onCancelClick}
                        onDeleteClick={props.onDeleteClick}
                        onRouterClick={props.onRouterClick}
                        onMapClick={props.onMapClick}
                        onChatClick={props.onChatClick}
                        onFilterClick={props.onFilterClick}
                        onSaveClick={props.onSaveClick}
                    />
                </Stack>
            </Box>
        );
    }

    const onRowClick: GridEventListener<'rowClick'> = (params) => {
        props.onRowClick?.(params.row)
    };

    return (
        <>
            <ThemeProvider theme={themeLight}>
                <div className='max-container'>
                    {props.isLoading ? <Box sx={{ width: '100%' }}><LinearProgress /></Box> : <></>}
                    <div className='max-box-datagrid22'>
                        <DataGrid
                            autoHeight={true}
                            rows={props.rows}
                            columns={props.columns}


                            density={'compact'}
                            disableRowSelectionOnClick={props.SelectionOnClickRow ? false : true}
                            checkboxSelection={props.CheckBoxSelect ? true : false}

                            className={'tables'}
                            components={{ Toolbar: QuickSearchToolbar }}
                            onRowClick={onRowClick}
                            onRowSelectionModelChange={(newSelectionModel: any) => {
                                props.handleSelRow && props.handleSelRow(newSelectionModel)
                            }}
                        />
                    </div>
                </div>
            </ThemeProvider>
        </>
    )

}
export default WidgetDataGridComp