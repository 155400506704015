import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { styled } from 'styled-components';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import SelectFormInput from '../../../components/datagridComp/SelectFormInput';
import { iDrivers, iVehicle } from '../../../interfaces/interfaces';
import CardVehicleWidget from '../../../components/Widgets/CardVehicleWidget';
import CardDriverWidget from '../../../components/Widgets/CardDriverWidget';
import ProviderCardSelect from './ProviderCardSelect';
import { IChipProvider } from './ChipProvidersSelect';


export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;


interface SidebarEditProps {
    message?: string;
    type: string;
    onCancel?: () => void;
    onConfirm?: (value?: any) => void;
    requireJustification?: boolean;
    isShow: boolean;
}

interface IOption {
    value: string;
    label: string;
}

const optionsStatus: IOption[] = [
    { value: 'PENDENTE', label: 'Pendente' },
    { value: 'APROVADO', label: 'Aprovado' },
    { value: 'REJEITADO', label: 'Rejeitado' },
    { value: 'CANCELADO', label: 'Cancelado' },

];

const SidebarImportChip: React.FC<SidebarEditProps> = ({ type, message, onCancel, onConfirm, requireJustification, isShow }) => {
    const [justification, setJustification] = React.useState('');
    const [errorJustification, setErrorJustification] = React.useState('');
    const [selectProvider, setSelectProvider] = React.useState<IChipProvider>();


    const handleConfirm = () => {
        if (requireJustification) {
            if (justification == null) {
                setErrorJustification("Você precisa informar o motivo!")
                return
            }
            if (justification.trim().length < 10) {
                setErrorJustification("Digite no minímo 10 caracteres")
                return
            }
        }

        switch (type) {

            case "REGISTER_CHIPS":
                onConfirm?.({ provider: selectProvider });
                break;
            case "UPDATE_CHIPS":
                onConfirm?.();
                break;
            case "SETDEL_CHIPS":
                onConfirm?.();
                break;
        }
    };

    const handleCancel = () => {
        ClearFields()
        onCancel?.();
    }

    const ClearFields = () => {
        setErrorJustification("")
        setJustification("")
    }


    React.useEffect(() => {
        ClearFields()
    }, [isShow])

    return (
        <div className="flex flex-col items-center justify-center p-4 space-y-6 max-w-96">
            <p className="text-center text-lg font-semibold mb-6 mx-4">
                {message}
            </p>
            {requireJustification && (
                <>
                    <textarea
                        className="w-full max-w-md p-2 border-2 border-gray-300 rounded-lg"
                        rows={3}
                        placeholder="Justificativa"
                        value={justification}
                        onChange={(e) => setJustification(e.target.value)}
                    ></textarea>
                    {errorJustification && (
                        <ErrorMessage>
                            <FontAwesomeIcon icon={faExclamationCircle} /> {errorJustification}
                        </ErrorMessage>

                    )}
                </>
            )}
            {type === "REGISTER_CHIPS" && (
                <ProviderCardSelect select={setSelectProvider} />
            )}

            {<div className="flex justify-around w-full max-w-md">
                <button
                    className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors"
                    onClick={handleCancel}>
                    Cancelar
                </button>
                <button
                    className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors"
                    onClick={handleConfirm}>
                    Confirmar
                </button>
            </div>}

        </div>
    );
};


export default SidebarImportChip;
