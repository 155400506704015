// VehicleIcon.tsx
import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 48px;
  height: 48px;
`;

const VehicleIcon: React.FC = () => (
    <Svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <circle cx="32" cy="32" r="32" fill="#ddd" />
        <path d="M16 44h32l4-16h-8V20H20v8h-8z" fill="#000" />
    </Svg>
);

export default VehicleIcon;
