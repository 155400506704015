
import React, { useState } from 'react';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../Style/StyleMessages';
import { useNotifications } from '../../../Notifications/NotificationContext';
import NoData from '../../../components/NoData';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faBackspace, faEdit, faMessage, faRefresh, faUpload } from '@fortawesome/free-solid-svg-icons';
import { handleSearch } from '../../../utils/searchUtils';
import { iMsgLogs, sendMessages } from '../repository/MessagesRepo';
import { getListClients } from '../repository/ClientsRepo';
import { getColumnsClientes, iClients } from '../Utils/ClientsColumns';
import Sidebar from '../../../components/datagridComp/SideBar';
import { iTemplateScreen } from '../../Templates/repository/TemplateRepo';
import TemplateEditor from '../../Templates/View/TemplateEditor';
import { v4 } from 'uuid';
import { AuthConfig } from '../../../Config/AuthContext';
import TemplateInline from '../../Templates/View/TemplateInline';
import { getColumnsMsgPrepared } from '../Utils/MsgPreparedColumns';
import { formatHtmlToWhatsapp } from '../../Templates/Utils/TemplatesTools';


const OPEN_FORM = 'OPEN_FORM';
const EDIT_FORM = 'EDIT_FORM';
const NEW_FORM = 'NEW_FORM';

let itemSaved: iTemplateScreen | undefined;

const MessagesCreate: React.FC = () => {

    const { addNotification } = useNotifications();
    const { user } = AuthConfig();
    const [itemEdit, setItemEdit] = React.useState<iTemplateScreen | undefined>(undefined);

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rowsClients, setRows] = React.useState<iClients[]>([]);
    const [rowsMsgPrepared, setMsgPrepared] = React.useState<iMsgPrepare[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rowsMsgPrepared.length > 0 ? rowsMsgPrepared : rowsClients, findInputTerms);
    }, [findInputTerms, rowsClients, rowsMsgPrepared]);



    async function getListInServer() {
        try {
            const result = await getListClients();
            console.log('result:', result);
            setRows(result);

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }



    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }


    const selectAll = () => {
        const allIds = new Set(filteredRows.map((alert) => alert.id));
        setSelectedRows(allIds);
    };


    React.useEffect(() => {
        getListInServer();
    }, []);


    React.useEffect(() => {
        if (itemEdit) {
            setSideContent(EDIT_FORM);
        }
    }, [itemEdit]);

    const CancelForm = () => {
        setSideContent(undefined);
    }

    const handleNew = () => {
        let item: iTemplateScreen;
        if (itemSaved) {
            // Se itemEdit já existir, apenas atualizamos o unique_id
            item = {
                ...itemSaved,
                unique_id: v4(),
            };
        } else {
            // Se itemEdit não existir, criamos um novo item com os valores iniciais
            item = {
                id: 0,
                idEmpresa: user?.idempresa || 0,
                unique_id: v4(),
                template_type: '',
                template_html: '',
                template_description: '',
                created_at: new Date(),
            };
        }

        setItemEdit(item);
    };


    const handleUpdateList = (item: iTemplateScreen) => {
        itemSaved = item;
        CancelForm();
    };


    const prepareMsg = () => {
        if (selectedRows.size === 0) {
            addNotification('Nenhum registro selecionado',
                'Selecione pelo menos um registro para preparar a mensagem.',
                'warning',
            );
            return;
        }
        if (!itemSaved) {
            addNotification('Nenhuma Mensagem criada',
                'Crie uma mensagem antes de preparar a mensagem.',
                'warning',
            );
            return;
        }
        const preparedMessages: iMsgPrepare[] = Array.from(selectedRows).map((id) => {
            const row = rowsClients.find((r) => r.id === id);
            if (row) {
                // Construção do template personalizado substituindo placeholders
                let msgContent = itemSaved?.template_html || '';
                msgContent = msgContent
                    .replace(/\{cliente_nome\}/g, row.name || '')
                    .replace(/\{empresa_nome\}/g, user?.fantasia || '')
                    .replace(/\{empresa_email\}/g, user?.email || '');


                // Verifica se o tipo é WhatsApp e aplica transformações específicas
                if (itemSaved?.template_type === 'WHATSAPP') {
                    msgContent = formatHtmlToWhatsapp(msgContent);
                }



                // Construção do objeto iMsgLogs para cada registro selecionado
                return {// Presumindo que idtemplate vem de itemSaved
                    id: row.id,
                    idcliente: row.id,
                    idempresa: user?.idempresa,
                    request: 'Mensagem Personalizada',
                    contact: row.phone1,
                    type_send: itemSaved?.template_type,
                    descr: itemSaved?.template_description,
                    msg: msgContent,
                    unique_id: v4(),
                };
            }
            return null;
        }).filter((msg): msg is iMsgPrepare => msg !== null);

        setSelectedRows(new Set());
        setMsgPrepared(preparedMessages);
    };


    const handleBackEditor = () => {
        setMsgPrepared([]);
    };

    const handleSendMsg = async () => {
        if (rowsMsgPrepared.length === 0) {
            addNotification('Nenhuma mensagem preparada',
                'Prepare uma mensagem antes de enviar.',
                'warning',
            );
            return;
        }
        let resp = await sendMessages(rowsMsgPrepared);
        addNotification('Mensagens enviadas',
            'Acompanhar na guia Mensagens Enviadas.',
            'success',
        );
        setMsgPrepared([]);
    }


    return (
        <>

            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                {rowsMsgPrepared.length > 0 ? (<>
                    <BtnFaIcon iconColor="darkRed" title='Atualizar registros' onClick={handleBackEditor} >
                        <BoxIcon icon={faBackspace} className="fa-icon" /> Voltar ao Editor
                    </BtnFaIcon >
                    <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={handleSendMsg} >
                        <BoxIcon icon={faUpload} className="fa-icon" /> Enviar Mensagens
                    </BtnFaIcon >
                </>) :
                    <>
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={handleNew} >
                            <BoxIcon icon={faEdit} className="fa-icon" /> Editor de Mensagem
                        </BtnFaIcon >
                        <BtnFaIcon iconColor="darkGreen" title='Atualizar registros' onClick={prepareMsg} >
                            <BoxIcon icon={faMessage} className="fa-icon" /> Preparar Mensagem
                        </BtnFaIcon >
                    </>}

            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rowsMsgPrepared.length > 0 ? rowsMsgPrepared.length : rowsClients.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rowsMsgPrepared.length > 0 ? rowsMsgPrepared.length : rowsClients.length}</InfoBox>
            </ContainerInfo>
            {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={rowsMsgPrepared.length > 0 ? getColumnsMsgPrepared() : getColumnsClientes()}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}
            <Sidebar width='800px' isOpen={sideContent === EDIT_FORM} onClose={CancelForm} title={'Mensagem à enviar'} >
                {itemEdit &&
                    <TemplateInline
                        onUpdate={handleUpdateList}
                        initialData={itemEdit}
                        onCancel={CancelForm} />}
            </Sidebar>
        </>
    )

}
export default MessagesCreate


export type iMsgPrepare = {
    id: number;               // INTEGER PRIMARY KEY AUTOINCREMENT
    idcliente: number;        // INTEGER NOT NULL -> number
    idempresa: number;        // INTEGER NOT NULL -> number
    request: string;          // VARCHAR(100) NOT NULL -> string
    contact: string;          // VARCHAR(100) NOT NULL -> string
    type_send: string;        // VARCHAR(50) NOT NULL -> string
    descr: string;            // VARCHAR(250) NOT NULL -> string
    msg: string;              // TEXT NOT NULL -> string
    unique_id: string;        // UUID UNIQUE NOT NULL -> string
}