import React from 'react';
import { Column } from 'react-data-grid';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from 'styled-components';
import DataGridComp from '../../../../components/datagridComp/DataGridComp';
import { httpAxiosV4 } from '../../../../gateways/Axios';
import SearchField from '../../../../components/datagridComp/SearchField';
import ModalComp from '../../../../components/datagridComp/ModalComp';
import { selectUser } from '../../../../redux/userSlice';
import { useSelector } from 'react-redux';


const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '10px',
    height: '450px',
    overflow: 'auto'
});

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
    padding: 5px;
`;


interface CompProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSelectAlert: (row: any) => void;
}

const ModalListChips: React.FC<CompProps> = ({ isOpen, onRequestClose, onSelectAlert }) => {
    const { user } = useSelector(selectUser);
    const [rows, setRows] = React.useState<any[]>([]);
    const [searchText, setSearchText] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());

    const columns: Column<any>[] = [
        { key: 'id', name: 'Código', width: 80 },
        { key: 'serial', name: 'Serial', width: 220 },
        { key: 'linha', name: 'Linha', width: 150 },
        { key: 'fornecedor', name: 'Fornecedor', width: 150 },
        { key: 'operadora', name: 'Operadora', width: 130 },

    ];

    // Função para buscar os dados da API
    async function getListItens(): Promise<any> {
        try {
            const payload = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                estoqueChips: true
            }
            const { data } = await httpAxiosV4<any, any>(`reqListChips`, "POST", payload);
            return data;
        } catch (error) {
            console.error('Error fetching client list:', error);
            throw error;
        }
    }

    // Função que realiza a busca inicial dos dados
    async function getDados() {
        try {
            const result = await getListItens();
            console.log(result);
            setRows(result);
        } catch (e) {
            console.error('[ModalListChips]', e);
        }
    }

    // Carrega os dados na montagem do componente
    React.useEffect(() => {
        getDados();
    }, []);

    // Filtragem dos dados baseada no texto de busca
    const filteredRows = React.useMemo(() => {
        if (!searchText) return rows;

        return rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
    }, [rows, searchText]);

    // Função para atualizar o texto da busca
    const handleSearch = (searchText: string) => {
        setSearchText(searchText);
    };

    // Função que lida com a seleção de uma linha
    const handleRowClick = (row: any) => {
        onSelectAlert(row);
        onRequestClose();
    };

    return (
        <ModalComp title="Selecionar um Chip do Estoque" subtitle="" onClose={onRequestClose} isShowCloseButton={true}>
            <ModalContent>
                <SearchAndSelectContainer>
                    <SearchField onSearch={handleSearch} />
                </SearchAndSelectContainer>
                <ContainerTable>
                    <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        onRowClick={handleRowClick}
                    />
                </ContainerTable>
            </ModalContent>
        </ModalComp>
    );
};

export default React.memo(ModalListChips);


const ModalContent = styled.div`
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  min-width: 700px;
`;