
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
//import TravelsDialog from '../TravelsDialog';
import { iItemTravel } from '../../interfaces/interfaces';
import httpCommon from '../../services/http-common';


interface iPropsTravel {
    idTravel?: number
    selectTravel?: (item: iItemTravel) => void
}


const Box = styled('div')({
    marginLeft: '5px',
    margin: '10px'
});

const Container = styled('div')({
    display: 'flex',
    padding: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '5px',
    maxWidth: '250px',
    minHeight: '70px',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)'

});

const ContainerInfo = styled('div')({
    display: 'block',
    padding: 5,
    minWidth: '170px'

});

const ContainerBlock = styled('div')({
    display: 'block',
    justifyContent: 'space-between'
});

const TextApelido = styled('div')({
    fontSize: '10px',
    textTransform: 'uppercase'
});
const Title = styled('div')({
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px'
});
const Subtitle = styled('div')({
    fontSize: '10px',
    fontWeight: '600',
    textTransform: 'uppercase'
});

const CardTravelWidget: React.FC<iPropsTravel> = (props) => {

    const [Travel, SetTravel] = React.useState<iItemTravel>()
    const [IsOpen, setOpen] = React.useState<boolean>(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (item: iItemTravel) => {
        handleClose()
        SetTravel(item)
        if (props.selectTravel) { props.selectTravel(item) }
    };

    const handleGetInfoTravel = async () => {
        const result = await httpCommon.get("getTravelInfo", { params: { idTravel: props.idTravel } })
        let resp = result.data;
        handleSelect(resp)
    }


    React.useEffect(() => {
        if (props.idTravel) {
            handleGetInfoTravel()
        }
    }, [props.idTravel])

    return (
        <>
            {/*<TravelsDialog onClose={handleClose} open={IsOpen} onMultiSelect={false} handleSelect={handleSelect} />*/}
            <Box>
                <TextApelido>Viagem</TextApelido>
                {Travel?.id ? <Container onClick={() => handleClickOpen()}>
                    <Avatar src="/broken-image.jpg" />
                    <ContainerInfo>
                        <Title>{Travel.descr}</Title>
                    </ContainerInfo>
                </Container>
                    : <>
                        <Container onClick={() => handleClickOpen()}>
                            <Avatar src="/broken-image.jpg" />
                            <ContainerInfo>
                                <Subtitle>Selecione uma Viagem</Subtitle>
                            </ContainerInfo>
                        </Container>
                    </>}
            </Box>

        </>
    )

}
export default CardTravelWidget