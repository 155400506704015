import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaCamera, FaPlus, FaTrash } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const Container = styled.div`
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  min-width: 380px;
  margin: 10px;
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  margin-top: 4px;
`;

const AddButton = styled.div`
  width: 100px;
  height: 100px;
  background: lightgray;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const Icon = styled.span`
  font-size: 24px;
  display: flex;
  align-items: center;
  color: orangered;
`;
export interface ImageType {
    fileLocal?: File;
    fileRemote?: string;
}

interface CompProps {
    onImagesChange: (images: ImageType[]) => void;
    imageUrls: ImageType[];
}

const ImageUploadForm: React.FC<CompProps> = ({ onImagesChange, imageUrls }) => {
    //const [files, setFiles] = useState<File[]>([]);
    const [images, setImages] = useState<ImageType[]>(imageUrls);



    useEffect(() => {
        console.log(images);
        onImagesChange(images);
    }, [images]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const newFilename = uuidv4() + '.' + file.name.split('.').pop(); // Cria um novo nome com base no UUID e mantém a extensão original

            const newFile = new File([file], newFilename, { type: file.type });

            if (typeof index === 'number') {
                const newImages = [...images];
                newImages[index] = { fileLocal: newFile }; // Update image and status
                setImages(newImages);
                /*setImageStates((prevStates) => {
                  const newState = [...prevStates];
                  newState[index] = 'local';
                  return newState;
                });*/
                // setImages(newImages);
            } else {
                setImages([...images, { fileLocal: newFile }]); // Add new image and status
                //setImageStates((prevStates) => [...prevStates, 'local']);
            }
        }
    };

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        event.preventDefault();
        // Copy the images array
        const imgs = [...images];
        imgs.splice(index, 1); // Remove o item no índice especificado e reorganiza os índices
        console.log(imgs);
        setImages(imgs);
    };

    const shouldRenderAddButton =
        images.length < 3; // Check if there are less than 3 images

    return (
        <Container>
            {images?.length > 0 && images.map((imageUrl, index) => ( // Use provided imageUrls if available
                <ImagePreviewContainer key={index}>
                    {imageUrl.fileLocal ? ( // Check image status
                        <ImagePreview src={URL.createObjectURL(imageUrl.fileLocal)} onClick={() => document.getElementById(`file-input-${index}`)?.click()} />
                    ) : imageUrl.fileRemote ? (
                        <ImagePreview src={`${process.env.REACT_APP_FILES_SERVER}/img-expenses/${imageUrl.fileRemote}`} onClick={() => document.getElementById(`file-input-${index}`)?.click()} />
                    ) : null} {/* Show remote image or nothing if 'deleted' */}
                    <input
                        id={`file-input-${index}`}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => handleImageChange(e, index)}
                    />
                    <IconButton onClick={(e) => handleDelete(e, index)}>
                        <FaTrash />
                    </IconButton>
                </ImagePreviewContainer>
            ))}

            {shouldRenderAddButton && (
                <AddButton onClick={() => document.getElementById('file-input-new')?.click()}>
                    <Icon><FaCamera /></Icon>
                </AddButton>
            )}

            <input
                id="file-input-new"
                type="file"
                style={{ display: 'none' }}
                onChange={handleImageChange}
            />
        </Container>
    );
};


export default ImageUploadForm;
