import React from 'react';
import styled from 'styled-components';

const PrivacyPolicyContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;

    h1 {
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 15px;
    }

    ul {
        margin-left: 20px;
        list-style-type: disc;

        li {
            margin-bottom: 10px;
        }
    }

    a {
        color: #007bff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const PrivacyPolicy: React.FC = () => {
    const domain = window.location.hostname.replace('www.', '');
    const appName = domain.replace('.com.br', '').replace('.com', '').toUpperCase();
    const supportEmail = `support@${domain}`;

    // Calcular a data de vigência para 10 meses atrás
    const today = new Date();
    const tenMonthsAgo = new Date(today.setMonth(today.getMonth() - 10));
    const effectiveDate = tenMonthsAgo.toLocaleDateString('pt-BR');

    return (
        <PrivacyPolicyContainer>
            <h1>Política de Privacidade</h1>

            <p>Data de Vigência: {effectiveDate}</p>

            <h2>1. Introdução</h2>
            <p>
                Bem-vindo ao {appName}. Esta Política de Privacidade explica como coletamos, usamos e compartilhamos suas informações pessoais ao utilizar nosso aplicativo.
            </p>

            <h2>2. Coleta e Uso de Dados</h2>
            <p>
                Coletamos os seguintes tipos de dados para melhorar sua experiência:
            </p>
            <ul>
                <li><strong>Dados Pessoais:</strong> Nome, endereço de e-mail, número de telefone, etc.</li>
                <li><strong>Dados Sensíveis:</strong> Localização, dados biométricos (se aplicável).</li>
                <li><strong>Dados de Uso:</strong> Informações do dispositivo, estatísticas de uso do aplicativo, etc.</li>
            </ul>
            <p>
                Os dados são utilizados para:
            </p>
            <ul>
                <li>Melhorar a funcionalidade e a experiência do aplicativo.</li>
                <li>Fornecer suporte ao cliente.</li>
                <li>Personalizar conteúdo e recursos.</li>
            </ul>

            <h2>3. Compartilhamento de Dados</h2>
            <p>
                Podemos compartilhar seus dados com os seguintes grupos:
            </p>
            <ul>
                <li>Provedores de serviços terceirizados para análises, hospedagem e suporte ao cliente.</li>
                <li>Autoridades regulatórias ou legais, se exigido por lei.</li>
            </ul>

            <h2>4. Segurança de Dados</h2>
            <p>
                Implementamos procedimentos seguros de processamento, incluindo criptografia e controle de acesso, para proteger seus dados pessoais e sensíveis.
            </p>

            <h2>5. Retenção e Exclusão de Dados</h2>
            <p>
                Seus dados são mantidos apenas pelo tempo necessário para cumprir os propósitos descritos nesta Política de Privacidade. Você pode solicitar a exclusão dos dados entrando em contato conosco em <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
            </p>

            <h2>6. Alterações nesta Política de Privacidade</h2>
            <p>
                Podemos atualizar esta política periodicamente. Quaisquer alterações serão publicadas nesta página com a data de vigência atualizada.
            </p>

            <h2>7. Contato</h2>
            <p>
                Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco:
            </p>
            <ul>
                <li>Email: <a href={`mailto:${supportEmail}`}>{supportEmail}</a></li>
            </ul>
        </PrivacyPolicyContainer>
    );
};

export default PrivacyPolicy;
