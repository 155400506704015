import React from 'react';
import { Box, Typography } from '@mui/material'; // Você pode substituir isso pela biblioteca de UI de sua escolha.
import InboxIcon from '@mui/icons-material/Inbox'; // Usando ícone de exemplo da Material-UI.

type NoDataProps = {
    message?: string;  // Mensagem opcional
};

const NoData: React.FC<NoDataProps> = ({ message = 'Nenhum registro encontrado.' }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="200px" // Ajuste conforme necessário
            color="#7f7f7f"
        >
            {/* Ícone de "inbox" para ilustrar ausência de dados */}
            <InboxIcon style={{ fontSize: '48px', color: '#7f7f7f' }} />

            {/* Mensagem que pode ser personalizada */}
            <Typography variant="h6" style={{ color: '#7f7f7f', marginTop: '8px' }}>
                {message}
            </Typography>
        </Box>
    );
};

export default NoData;
