// ProviderCardInfo.tsx
import React from 'react';
import styled from 'styled-components';

// Interface para as props do componente
export interface iProviderData {
  apn: string;
  apn_user: string;
  apn_pass: string;
  Operadora: string;
  fornecedor: string;
  id: number;
  id_provider: number;
}

// Estilização do Card
const Card = styled.div`
  background-color: #ececec;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 20px auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

// Estilização dos detalhes do card
const Detail = styled.div`
  font-size: 14px;
  color: #333;

  & > b {
    font-weight: 500;
  }
`;

// Componente ProviderCardInfo
const ProviderCardInfo: React.FC<{ data: iProviderData }> = ({ data }) => {
  return (
    <Card>
      <Detail><b>APN:</b> {data.apn}</Detail>
      <Detail><b>APN User:</b> {data.apn_user}</Detail>
      <Detail><b>APN Password:</b> {data.apn_pass}</Detail>
      <Detail><b>Operadora:</b> {data.Operadora}</Detail>
      <Detail><b>Fornecedor:</b> {data.fornecedor}</Detail>
    </Card>
  );
};

export default ProviderCardInfo;
