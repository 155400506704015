import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { loadMapApi } from '../../../utils/GoogleMapsApi';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { GeocoderService } from '../../../GoogleMaps/GeocoderService';
import { DeliveryConfig, iMarkerPoint } from '../utilsDelivery/DeliveryProvider';
import { add } from 'date-fns';

const ContainerMaps = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #f5f5f5;
  padding: 5px;
  height: 93vh;
`;

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
`;

const SearchButton = styled.button<{ disabled: boolean }>`
  padding: 10px 20px;
  font-size: 16px;
  background-color: ${({ disabled }) => (disabled ? '#ccc' : '#007bff')};
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#0056b3')};
  }
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffdddd;
  color: #a94442;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  border: 1px solid #ebccd1;
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #a94442;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
`;


const MapDelivery: React.FC = () => {
    const { MarkerPoint, MarkerForm, updateConfig } = DeliveryConfig();
    const map = useGoogleMap();
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const markersRef = useRef<google.maps.Marker>();
    const [address, setAddress] = useState<string>('');
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        console.log('Map IN Delivery Open')
        if (map && mapContainerRef.current) {
            // Associa o mapa ao contêiner específico desta tela
            map.setOptions({
                center: { lat: -15.7801, lng: -47.9292 }, // Ajuste conforme necessário
                zoom: 4,
            });

            // Move o mapa para o contêiner correto
            mapContainerRef.current.appendChild(map.getDiv());
            google.maps.event.trigger(map, 'resize');
            if (MarkerPoint?.editable) {
                google.maps.event.addListener(map, 'click', async (event: any) => {
                    getAddress(event.latLng);
                });
            } else {
                google.maps.event.clearListeners(map, 'click');
            }

        }
    }, [map]);

    useEffect(() => {
        console.log('MapDelivery Open')

        return () => {
            console.log('MapDelivery Close')
            if (markersRef.current) {
                markersRef.current.setMap(null)
            }
        }
    }, []);

    const clearAllItensInmap = () => {
        if (markersRef.current) {
            markersRef.current.setMap(null)
        }
    }
    const resetMap = () => {
        clearAllItensInmap()
        map!.setOptions({
            center: { lat: -15.7801, lng: -47.9292 }, // Ajuste conforme necessário
            zoom: 4,
        });
    }

    const setMarkerInMap = (position: google.maps.LatLng) => {
        clearAllItensInmap()
        const marker = new google.maps.Marker({
            position: position,
            map: map,
            draggable: MarkerPoint?.editable,
        });
        marker.addListener('dragend', async (event: any) => {
            getAddress(event.latLng);
        });
        map?.setZoom(18);
        map?.setCenter(position);
        markersRef.current = marker
        const newMarkerForm = {
            position: {
                lat: position.lat(),
                lng: position.lng()
            },
            address: MarkerForm?.address ?? ''
        }
        updateConfig({ MarkerForm: newMarkerForm });

    }

    useEffect(() => {
        const newMarkerForm = {
            position: MarkerForm?.position ?? { lat: 0, lng: 0 },
            address: address
        }
        updateConfig({ MarkerForm: newMarkerForm });
    }, [address])

    const getAddress = async (param: string | google.maps.LatLng) => {
        setIsLoading(true);
        try {
            const result = await GeocoderService(param);
            if (result) {
                let position = typeof param === 'string' ? result.position : param;
                setMarkerInMap(position);
                setAddress(result.address);
            } else {
                setErrorMessage('Endereço não encontrado.');
            }
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
            setErrorMessage('Erro ao buscar endereço.');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (errorMessage) {
            const timeoutId = setTimeout(() => {
                setErrorMessage('');
            }, 5000); // Oculta a mensagem após 5 segundos

            return () => clearTimeout(timeoutId);
        }
    }, [errorMessage]);

    useEffect(() => {
        console.log("MarkerPoint", MarkerPoint);
        if (!MarkerPoint) return;
        if (MarkerPoint.position.lat !== 0 && MarkerPoint.position.lng !== 0) {
            let position = new google.maps.LatLng(MarkerPoint.position.lat, MarkerPoint.position.lng);
            setMarkerInMap(position);
        } else {
            resetMap();
            if (MarkerPoint.address.length >= 5) getAddress(MarkerPoint.address);
        }
        setAddress(MarkerPoint.address);
    }, [MarkerPoint]);

    const handleSearch = async () => {
        if (!address || address.length < 5) return;
        getAddress(address);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setAddress(value);
        setIsButtonEnabled(value.length >= 5);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && isButtonEnabled) {
            handleSearch();
        }
    };

    return (
        <ContainerMaps>
            <SearchContainer>
                <SearchInput
                    type="text"
                    placeholder="Digite um endereço..."
                    value={address}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    disabled={!MarkerPoint?.editable}
                />
                {MarkerPoint?.editable && <SearchButton onClick={handleSearch} disabled={!isButtonEnabled}>
                    {isLoading ? <Spinner /> : 'Pesquisar'}
                </SearchButton>}
            </SearchContainer>
            {errorMessage && (
                <ErrorMessage>
                    {errorMessage}
                    <CloseButton onClick={() => setErrorMessage('')}>×</CloseButton>
                </ErrorMessage>
            )}
            <MapContainer ref={mapContainerRef} />
        </ContainerMaps>
    );

}
export default MapDelivery