


import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import FuelTankComp, { TankProps } from '../components/FuelTankComp';
import TankForm from '../FormsFuel/TankForm';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { getListTanks, iFuelInvoice, iTank } from '../repository/TanksRepo';
import { BoxIcon, BtnFaIcon, ContainerInfo, InfoBox, SearchAndSelectContainer } from '../../Messages/Style/StyleMessages';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import TankInvoiceForm from '../FormsFuel/TankInvoiceForm';
import Sidebar from '../../../components/datagridComp/SideBar';
import InvoiceStockFuelView from './InvoiceStockFuelView';

const OPEN_FORM = 'OPEN_FORM';
const EDIT_FORM_TANK = 'EDIT_FORM_TANK';
const NEW_FORM_TANK = 'NEW_FORM_TANK';


const FuelStockTab: React.FC = () => {
  const { addNotification } = useNotifications();
  const [isShowFormTanks, setIsShowFormTanks] = useState(false);
  const [isShowFormInvoice, setIsShowFormInvoice] = useState(false);
  const [rows, setRows] = useState<iTank[]>([]);
  const [selectedTank, setSelectedTank] = useState<iTank>();



  //sidebar 
  const [sideContent, setSideContent] = React.useState<string>();

  const handleOnUpdate = () => {
    setSideContent(undefined);
    setSelectedTank(undefined);
    getDados()
  }

  async function getDados() {
    try {
      const result = await getListTanks();
      console.log(result)
      setRows(result);
    } catch (e) {
      let error = e as Error
      addNotification('', error.message, 'error');
    }
  }


  const handleEdit = (tank: iTank) => {
    setSelectedTank(tank)
    setSideContent(EDIT_FORM_TANK);
  }


  useEffect(() => {
    if (selectedTank)
      setIsShowFormTanks(true);
  }, [selectedTank]);

  useEffect(() => {
    getDados()
  }, []);


  const CancelFormTanks = () => {
    setSelectedTank(undefined);
    setSideContent(undefined);
  }

  const handleNewTanks = () => {
    setSelectedTank(undefined);
    setSideContent(NEW_FORM_TANK);
  };


  return (
    <>

      <SearchAndSelectContainer>
        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getDados} >
          <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
        </BtnFaIcon >
        <BtnFaIcon title='Cadastrar Novo Tanque' onClick={handleNewTanks} >
          <BoxIcon icon={faAdd} iconColor='green' /> Novo Tanque
        </BtnFaIcon >
      </SearchAndSelectContainer>
      {/* <ContainerInfo>
          <InfoBox>Exibindo {rows.length} de {rows.length}</InfoBox>
        </ContainerInfo> */}
      <TanksContainer>
        {rows.length > 0 && rows.map((tank, index) => {
          return (
            <div onClick={() => { handleEdit(tank) }}>
              <FuelTankComp
                key={index}
                level={tank.current_volume}
                capacity={tank.max_capacity}
                minStock={tank.stock_min || 100}
                color={tank.color_fuel}
                width={150}
                height={300}
                label={tank.identifier}
              />
            </div>
          );
        })

        }

      </TanksContainer>
      <InvoiceStockFuelView />

      <Sidebar width='400px' isOpen={sideContent === EDIT_FORM_TANK} onClose={CancelFormTanks} title={'Editar Tanque'}>
        {selectedTank && <TankForm onUpdate={handleOnUpdate} initialData={selectedTank} onCancel={CancelFormTanks} />}
      </Sidebar>

      <Sidebar width='400px' isOpen={sideContent === NEW_FORM_TANK} onClose={CancelFormTanks} title={'Cadastrar Novo Tanque'}>
        <TankForm onUpdate={handleOnUpdate} onCancel={CancelFormTanks} />
      </Sidebar>

    </>

  );
}
export default FuelStockTab

// Estilização do contêiner principal para organizar os tanques
const TanksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  width: max-content;
  margin-bottom: 20px;
`;