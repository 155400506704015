import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaLock, FaUnlock } from 'react-icons/fa';
import { VehicleData } from '../../StreamCam/StreamInterfaces';
import { httpAxiosV4 } from '../../../gateways/Axios';
import { iCommandsConfig, iCommandsOptions, iItemCmdConfig, iPrepareCmd } from '../Repositories/CommandsRepo';
import { checkVehiclsHasOnline } from '../../MapAdvance/utilsMap/tools';
import { useNotifications } from '../../../Notifications/NotificationContext';

interface CmdBlockCompProps {
    vehicleData: VehicleData;
    onClose: () => void;
}

const Container = styled.div`
    padding: 16px;
    width: 400px;
    background-color: transparent;
    text-align: center;
`;

const Message = styled.p`
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const IconButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    margin: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: none;
    color: #777;  /* Ícones cinza claro */
    font-size: 16px;
    cursor: pointer;
    gap: 8px;

    &:hover {
        color: #555;
        background-color: #f0f0f0;  /* Fundo mais claro ao passar o mouse */
    }

    &:disabled {
        color: #ccc; 
        cursor: not-allowed;
    }
`;

const ConfirmModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);  /* Fundo escurecido para modal */
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const Loader = styled.div`
    border: 4px solid #f3f3f3;
    border-top: 4px solid #777;  /* Loader mais neutro */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const ConfirmButton = styled.button`
    padding: 8px 16px;
    margin: 8px;
    border: 1px solid #ccc;
    background: none;
    color: #333;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background-color: #f0f0f0;
    }
`;

const CancelButton = styled(ConfirmButton)`
    color: #777;
`;

const CmdBlockComp: React.FC<CmdBlockCompProps> = ({ vehicleData, onClose }) => {
    const [actions, setActions] = useState<iItemCmdConfig[]>([]);
    const [config, setConfig] = useState<iCommandsConfig>();
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCommand, setSelectedCommand] = useState<iItemCmdConfig | null>(null);
    const { txdtEquip, ign, gpsvel, tipo, imei } = vehicleData;
    const currentSpeed = Number(gpsvel);
    const ignitionStatus = ign === 'ON';
    const hasSignal = checkVehiclsHasOnline(txdtEquip);

    const { addNotification } = useNotifications();

    const getCmdByEquip = async (): Promise<any> => {
        const payload = {
            type_cmd: "BLOCK",
            model_equip: tipo,
        }
        const { data } = await httpAxiosV4<any, iCommandsOptions>(
            `CmdByType`,
            "POST",
            payload
        );
        setActions(data.itens);
        setConfig(data.config);
    }

    const SendCommand = async (payload: iPrepareCmd): Promise<any> => {
        setLoading(true);
        const { data } = await httpAxiosV4<any, iCommandsOptions>(
            `sendCommand`,
            "POST",
            payload
        );
        setLoading(false);
        setIsConfirming(false);
        addNotification("Comando enviado com sucesso!", "Acompanhar o status na aba Comandos", "success");
        onClose();
    }

    useEffect(() => {
        if (tipo) {
            getCmdByEquip();
        }
    }, [tipo]);

    const renderMessages = () => {
        if (currentSpeed > 20) {
            return <Message>⚠️ Risco de bloqueio com veículo em movimento!</Message>;
        }
        if (!ignitionStatus) {
            return <Message>⚠️ Ligue a ignição para evitar que o equipamento entre em modo sleep.</Message>;
        }
        if (!hasSignal) {
            return <Message>⚠️ Comando não será enviado ao veículo de forma imediata devido à falta de sinal.</Message>;
        }
        return <Message>✅ Tudo pronto para enviar o comando.</Message>;
    };

    const onCommandExecute = (action: iItemCmdConfig) => {
        if (action.label === 'Bloqueio') {
            setSelectedCommand(action);
            setIsConfirming(true);
        } else {
            executeCommand(action);
        }
    };

    const executeCommand = (action: iItemCmdConfig) => {
        if (!config) return;

        let values = [
            {
                key: action.cmd,
                value: action.value,
            },
        ];
        const payload: iPrepareCmd = {
            imei: imei,
            id_config: config.id,
            id_item: config.id_actions,
            deviceId: '',
            deviceType: 'WEB',
            sintaxe_cmd: config.sitaxe_cmd,
            model_equip: config.model_equip,
            type_cmd: config.type_cmd,
            categ: config.categ,
            values: values
        };
        SendCommand(payload);
    };

    const renderButtons = () => {
        return actions
            .filter(command => command.type_element === 'button' && command.disable === 0)
            .sort((a, b) => a.order_itens - b.order_itens)
            .map(command => (
                <IconButton
                    key={command.id}
                    onClick={() => onCommandExecute(command)}
                    disabled={command.disable === 1}
                >
                    {command.label === 'Bloqueio' ? <FaLock /> : <FaUnlock />}
                    {command.label}
                </IconButton>
            ));
    };

    return (
        <Container>
            {renderMessages()}
            <ButtonContainer>
                {renderButtons()}
            </ButtonContainer>

            {isConfirming && selectedCommand && (
                <ConfirmModal>
                    <ModalContent>
                        <p>Tem certeza que deseja enviar o comando de {selectedCommand.label}?</p>
                        <ConfirmButton onClick={() => executeCommand(selectedCommand)}>
                            Confirmar
                        </ConfirmButton>
                        <CancelButton onClick={() => setIsConfirming(false)}>
                            Cancelar
                        </CancelButton>
                    </ModalContent>
                </ConfirmModal>
            )}

            {loading && <Loader />}
        </Container>
    );
};

export default CmdBlockComp;
