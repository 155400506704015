import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box } from '@mui/material';
import ListExpensesPage from './views/ListExpensesPage';
import DashboardExpensesPage from './views/DashboardExpensesPage';


const ExpensesPage: React.FC = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Dashboard" value="1" />
                        <Tab label="Histórico" value="2" />
                    </TabList>
                </Box>
                <div>
                    <TabPanel value="1"><DashboardExpensesPage /></TabPanel>
                    <TabPanel value="2"><ListExpensesPage /></TabPanel>
                </div>
            </TabContext>
        </>
    );

}
export default ExpensesPage