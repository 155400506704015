import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import styled from 'styled-components';
import { WebSocketContext } from '../../../gateways/WebSocket';
import { changeMenu } from '../../../redux/appBarSlice';
import { iReduxMap } from '../../../interfaces/interfaces';
import { changeMap } from '../../../redux/mapSlice';
import { useNotifications } from '../../../Notifications/NotificationContext';
import axios from 'axios';
import { v4 } from 'uuid';
import StreamH264Player, { actionStream, iStreamH264 } from '../../StreamCam/Components/StreamH264Player';
import { VehicleData } from '../../StreamCam/StreamInterfaces';
import { ctrlStreamCam, iParamsCamIotHub } from '../../StreamCam/repository/StreamRepo';
import CustomButtonPlayStop from '../../StreamCam/widgets/CustomButtonPlayStop';
import { MapConfig } from '../utilsMap/mapContext';

let interval: any
let isInit = true

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column; /* Garante que os elementos fiquem em coluna */
  gap: 5px; /* Mantém o espaçamento de 5px entre os elementos */
  background-color: #f5f5f5; /* Cor de fundo */
  padding: 10px; /* Espaçamento interno */
  max-height: 92vH; 
  max-width: 315px;
  overflow-x: initial;
  align-items: center;
`;

const ContainerBlock = styled.div`
  display: flex;
  flex-direction: row; /* Garante que os elementos fiquem em coluna */
  gap: 15px; /* Mantém o espaçamento de 5px entre os elementos */
  background-color: #f5f5f5; /* Cor de fundo */
  padding: 10px; /* Espaçamento interno */
`;
const ContainerCam = styled.div`
  display: flex;
  flex-direction: row; /* Ou 'column' se quiser em coluna */
  flex-wrap: wrap;    /* Permite a quebra de linha */
  gap: 2px; 
  background-color: #f5f5f5;
  padding: 5px;
`;

const ContainerBtns = styled.div`
   display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  align-items: center; /* Centraliza os botões verticalmente */
  gap: 10px; /* Espaço entre os botões */
`;

const ContainerBoxStream = styled.div`
  display: flex;
  flex-direction: column; /* Ou 'column' se quiser em coluna */
  flex-wrap: wrap;    /* Permite a quebra de linha */
  gap: 2px; 
  background-color: #f5f5f5;
  padding: 5px;
`;


const StreamMapVehicle: React.FC = () => {

    const { addNotification } = useNotifications();
    const [streamCam, setStreamCam] = React.useState<iStreamH264[]>([]);
    const { vehicleSelected, updateConfig } = MapConfig();



    const loadPlayers = () => {
        if (vehicleSelected === undefined || vehicleSelected === null) return
        let stream: iStreamH264[] = []
        if (vehicleSelected.tipo === 'JC450') {
            for (let i = 1; i <= 5; i++) {
                stream.push({
                    channelName: `CH-${i}`,
                    channel: `${i}`,
                    isPlaying: false,
                    streamUrl: `${process.env.REACT_APP_LIVE_HLS}/${i}/${vehicleSelected.imei}/hls.m3u8`,
                    uniqueKey: v4()
                })
            }

        }
        if (vehicleSelected.tipo === 'JC4xx') {
            for (let i = 0; i <= 1; i++) {
                stream.push({
                    channelName: `CH-${i === 1 ? 'INTERNA' : 'EXTERNA'}`,
                    channel: `${i === 1 ? 'IN' : 'OUT'}`,
                    isPlaying: false,
                    streamUrl: `${process.env.REACT_APP_LIVE_HLS}/live/${i}/${vehicleSelected.imei}/hls.m3u8`,
                    uniqueKey: v4()
                })
            }
        }
        if (vehicleSelected.tipo === 'JC400-WABA') {
            for (let i = 0; i <= 1; i++) {
                stream.push({
                    channelName: `CH-${i === 1 ? 'INTERNA' : 'EXTERNA'}`,
                    channel: `${i === 1 ? 'IN' : 'OUT'}`,
                    isPlaying: false,
                    streamUrl: `${process.env.REACT_APP_LIVE_HLS}/live/${vehicleSelected.imei}/hls.m3u8`,
                    uniqueKey: v4()
                })
            }
        }
        console.log(stream)
        setStreamCam(stream)
    }

    const handleActionCam = async (params: actionStream) => {
        try {
            if (vehicleSelected === undefined || vehicleSelected === null) return
            const payload: iParamsCamIotHub = {
                imei: vehicleSelected.imei,
                tipo: vehicleSelected.tipo,
                channel: params.channel,
                action: params.action
            }
            const result = await ctrlStreamCam(payload);
            console.log(result)
            let msg = params.action === 'ON' ? 'Transmissão Solicitada' : 'Transmissão Encerrada'
            if (params.action === 'ON') {
                updateScreen(params.channel)
            } else {
                handleStatusCam(params)
            }
            addNotification('', msg, 'success');
        } catch (err: any) {
            if (axios.isAxiosError(err) && err.response) {
                const { message } = err.response.data;
                addNotification('Falha ao solicitar Stream', message, 'error');
            } else {
                addNotification('', err.message, 'error');
            }

        }
    }
    const updateScreen = async (channel: string) => {
        setStreamCam(prevStreamCam =>
            prevStreamCam.map(cam => {
                if (cam.channel === channel) {
                    return {
                        ...cam,
                        uniqueKey: v4()
                    };
                }
                return cam;
            })
        );
    }

    const handleStatusCam = async (params: actionStream) => {
        setStreamCam(prevStreamCam =>
            prevStreamCam.map(cam => {
                if (cam.channel === params.channel) {
                    return {
                        ...cam,
                        isPlaying: params.action === 'ON'
                    };
                }
                return cam;
            })
        );
    }

    React.useEffect(() => {
        loadPlayers()
    }, [])

    return (
        <ContainerBlock>
            <ContainerBoxStream>
                <ContainerBtns>
                    {streamCam.map((x: iStreamH264) =>
                        <CustomButtonPlayStop
                            key={'ctrl' + x.uniqueKey}
                            isPlay={x.isPlaying}
                            title={x.channelName} onClick={() =>
                                handleActionCam({ channel: x.channel, action: x.isPlaying ? 'OFF' : 'ON' })} />
                    )}
                </ContainerBtns>
                <ContainerCam>
                    {streamCam.map((x: iStreamH264) =>
                        <StreamH264Player
                            key={'screen' + x.uniqueKey}
                            channelName={x.channelName}
                            channel={x.channel}
                            streamUrl={x.streamUrl}
                            onClickAction={handleStatusCam}
                        />
                    )}
                </ContainerCam>
            </ContainerBoxStream>
        </ContainerBlock>
    )

};

export default StreamMapVehicle;
