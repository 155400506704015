import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { styled } from 'styled-components';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import SelectFormInput from '../../../components/datagridComp/SelectFormInput';
import { iDrivers, iVehicle } from '../../../interfaces/interfaces';
import CardVehicleWidget from '../../../components/Widgets/CardVehicleWidget';
import CardDriverWidget from '../../../components/Widgets/CardDriverWidget';
import DateFilter, { dtFilter } from './DateFilter';
import StyledDivider from '../../../components/datagridComp/StyledDivider';


export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;


interface FilterExpensesCardProps {
    onCancel?: () => void;
    onConfirm?: (value?: any) => void;
}

interface IOption {
    value: string;
    label: string;
}



const optionsStatus: IOption[] = [
    { value: 'TODOS', label: 'Todos' },
    { value: 'PENDENTE', label: 'Pendente' },
    { value: 'APROVADO', label: 'Aprovado' },
    { value: 'REJEITADO', label: 'Rejeitado' },
    { value: 'CANCELADO', label: 'Cancelado' },

];

const FilterExpensesCard: React.FC<FilterExpensesCardProps> = ({ onCancel, onConfirm }) => {
    const [selectFilterStatus, setFilterStatus] = React.useState<string>('');
    const [selectVehicle, setSelectVehicle] = React.useState<iVehicle | undefined>();
    const [selectDriver, setSelectDriver] = React.useState<iDrivers | undefined>();
    const [selectDate, setSelectDate] = React.useState<dtFilter>();


    const handleConfirm = () => {
        onConfirm?.({ selectFilterStatus, selectVehicle, selectDriver, selectDate });
    };

    const handleCancel = () => {
        onCancel?.();
    }

    return (
        <div className="flex flex-col items-left justify-left p-4 space-y-6 max-w-96">
            <StyledDivider label="Filtrar por Período" />
            <DateFilter onDateChange={setSelectDate} />
            <StyledDivider label="Filtrar por Status" />
            <SelectFormInput
                options={optionsStatus}
                onSelect={(value) => setFilterStatus(value)}
                selected={selectFilterStatus}
                title='Selecione o status'
            />
            <StyledDivider label="Filtrar por Veículo e Motorista" />
            <CardVehicleWidget idVehicle={0} selectVehicle={setSelectVehicle} />
            <CardDriverWidget idMotorista={0} selectDriver={setSelectDriver} />


            {<div className="flex justify-around w-full max-w-md">
                <button
                    className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors"
                    onClick={handleCancel}>
                    Cancelar
                </button>
                <button
                    className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors"
                    onClick={handleConfirm}>
                    Confirmar
                </button>
            </div>}

        </div>
    );
};


export default FilterExpensesCard;
