import React, { useEffect, useRef, useState } from 'react';
import CardDeliveryPanel from '../Components/CardDeliveryPanel';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { iPanelDeliveryInfo } from '../deliveryInterfaces';
import { changeMenu } from '../../../redux/appBarSlice';
import { iDestinos, iMarker, iReduxMap } from '../../../interfaces/interfaces';
import { changeMap } from '../../../redux/mapSlice';
import { ActionMaps } from '../../../utils/values';
import httpApiV4 from '../../../services/http-xsmart-apiv4';

let interval: any
let isInit = true
const WidthMap = 635;
const HeightMap = 180
let noFitBounds = false;

let tasks: iDestinos[] = []

const DeliveryPainelView: React.FC = () => {


    const { user } = useSelector(selectUser);
    const [rows, setRows] = React.useState<iPanelDeliveryInfo[]>([])
    //const [tasks, setListTasks] = React.useState<iDestinos[]>([])
    const dispatch = useDispatch()

    async function getIconMaps() {
        try {
            let iconUrl = require(`../../../assets/icons_maps/car1.png`);
            let isvalid = iconUrl ? true : false
            console.log(isvalid)
        } catch (e) {
            console.error('Icon not found:');
        }
    }

    async function getTasksRouter(idrouter: number) {
        const result = await httpApiV4.post("/delivery/ListTasksByRouter", {
            idrouter: idrouter
        });
        tasks = result.data;
        getDados()
    }




    async function getDados() {
        const result = await httpApiV4.get("/delivery/ListTaskDeliveryInPanel", {
            params: { idclient: user.idcliente }
        });
        let resp: iPanelDeliveryInfo[] = result.data;
        console.log(resp)
        let mks: iMarker[] = []
        for (const item of resp) {

            let marker: iMarker = {
                position: { lat: Number(item.VehicleLocationLat), lng: Number(item.VehicleLocationLng) },
                isDragable: false,
                icon: process.env.REACT_APP_ICON_PATH + `/${item.VehicleAvatar}.png`,
                label: item.VehiclePlaca
            }
            mks.push(marker)
        }

        if (tasks.length) {
            for (const item of tasks) {

                let marker: iMarker = {
                    position: { lat: Number(item.lat), lng: Number(item.lng) },
                    isDragable: false,
                    icon: process.env.REACT_APP_ICON_PATH + `/army.png`,
                    label: item.descr
                }
                mks.push(marker)
            }
        }
        /* let mapConfig: iReduxMap = {
             action: ActionMaps.SHOW_MARKERS,
             mapEvtClick: false,
             resetMap: false,
             markers: mks,
             height: HeightMap,
             width: WidthMap,
             noFitBounds: noFitBounds
         }
         noFitBounds = true*/

        /*let mapConfig: iReduxMap = {
            action: ActionMaps.SHOW_ROUTER,
            ender: '',
            markers: mks,
            polyline: GroupRouter?.polyline
        }
*/
        /* dispatch(changeMap(mapConfig))*/
        setRows(resp)
    }

    function ShowMap() {
        /*   dispatch(changeMenu({ openMap: new Date().getTime() }))
           const windowWidth = window.innerWidth;
           let mapConfig: iReduxMap = {
               mapEvtClick: false,
               resetMap: false,
               height: HeightMap,
               width: WidthMap
           }
           dispatch(changeMap(mapConfig))*/
    }


    const CtrlInterval = () => {

        console.log("Start interval")
        interval = setInterval(() => {
            console.log("get Dados Panel")
            getDados()
        }, 5000)

    }

    React.useEffect(() => {
        if (isInit) {
            isInit = false;
            noFitBounds = false
            getIconMaps()
            CtrlInterval()
            getDados()
            ShowMap()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct Panel Delivery")
            clearInterval(interval)
            // dispatch(changeMenu({ openMap: 0 }))
            isInit = true;
        }
    }, [])

    const handelSelectVehicle = (item: iPanelDeliveryInfo) => {
        console.log(item)
        noFitBounds = false
        getTasksRouter(item.idGroupDelivery)
    }

    return (
        <>

            {rows.map((i: iPanelDeliveryInfo) => <CardDeliveryPanel key={i.idGroupDelivery} dataCard={i} onClickCard={handelSelectVehicle}></CardDeliveryPanel>)}
        </>
    )

}
export default DeliveryPainelView