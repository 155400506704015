import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar/Avatar';
import Stack from '@mui/material/Stack';
import VehiclesDialog from './VehiclesDialog';
import { iVehicle } from '../interfaces/interfaces';
import { DirectionsCarFilled } from '@mui/icons-material';

interface DialogProps {
    handleSelVehicle: (item: iVehicle) => void,
    VehicleSel?: iVehicle
}

const VehiclesComp: React.FC<DialogProps> = (props) => {

    const [itemSelect, setSelected] = React.useState<iVehicle>()
    const [IsOpen, setOpen] = React.useState<boolean>(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (params: iVehicle) => {
        setSelected(params)
        props.handleSelVehicle(params)
        handleClose()
    }

    React.useEffect(() => {
        console.log("useEffect Vehicle Comp")
        if (props.VehicleSel) setSelected(props.VehicleSel)
    }, [props.VehicleSel])

    const card = (
        <div className='cardBox'>
            <div className='titleCard'>Veículo</div>
            <div className='cardContent' onClick={handleClickOpen}>
                <Stack direction="row" spacing={1}>
                    <Avatar sx={{ width: 32, height: 32 }} ><DirectionsCarFilled /></Avatar>

                    <div className='infoCards'>
                        Selecione aqui
                    </div>
                </Stack>
            </div>
        </div>
    );

    const cardItem = (
        <div className='cardBox'>
            <div className='titleCard'>Veículo</div>
            <div className='cardContent' onClick={handleClickOpen}>
                <Stack direction="row" spacing={1}>
                    <Avatar sx={{ width: 32, height: 32 }} ><DirectionsCarFilled /></Avatar>
                    <div className='infoCards'>
                        {itemSelect?.placa}
                    </div>
                </Stack>
            </div>
        </div>
    );

    return <>
        <VehiclesDialog onClose={handleClose} open={IsOpen} onMultiSelect={false} handleSelect={handleSelect} />
        <Box sx={{ minWidth: 200, maxWidth: 230 }}>
            {itemSelect ? <Card variant="outlined">{cardItem}</Card> : <Card variant="outlined">{card}</Card>}
        </Box>
    </>
}

export default React.memo(VehiclesComp)