import { formatDateToScreenString } from "../../../utils/Converter";

function formateTitle(tipo: string, evt: string, param1: string, speed: number): string {
    switch (tipo) {
        case 'IGN':
            const ignTipo = evt === 'ON' ? 'Ignição Ligada' : 'Ignição Desligada';
            return `${ignTipo}`;
        case 'CERCA':
            const cercaTipo = evt === '1' ? 'Entrada' : 'Saída';
            return `${cercaTipo} da Cerca [${param1}]`;
        case 'VEL':
        case 'SPEED':
            return `Excesso de Velocidade [${speed} Km/h]`;
        case 'BATCUT':
            return evt === '0' ? 'Corte de Bateria' : 'Bateria Conectada';
        case 'BLK':
            return evt === '1' ? 'Veículo Bloqueado' : 'Veículo Desbloqueado';
        case 'PANICO':
        case 'PANIC':
        case 'SOS':
            return 'Botão de Pânico Acionado';
        case 'HARACC':
            return 'Aceleração Brusca';
        case 'HARDEC':
            return 'Frenagem Brusca';
        case 'MOVE':
            return 'Movimento Detectado';
        case 'DMSPHONE':
            return 'Uso de Celular!';
        case 'DMSSMOKING':
            return 'Uso de Cigarro!';
        case 'DMSDISTRATION':
            return 'Distração ao Volante!';
        case 'DMSYAWN':
            return 'Sonolência ao Volante!';
        default:
            return '';
    }
}

function formateDescr(
    tipo: string,
    evt: string,
    param1: string,
    speed: number,
    driverName: string | null,
    vehicle: string,
    duraction: number,
    distance: number
): string {
    switch (tipo) {
        case 'IGN':
            const ignSource = vehicle;
            const ignTipo = evt === 'ON' ? 'Ligou a Ignição' : 'Desligou a Ignição';
            const ignInfo = evt === 'ON'
                ? `ficou parado por ${converteIntInTimer(duraction)}!`
                : `percorreu ${distance} km, no tempo de ${converteIntInTimer(duraction)}!`;
            return `${ignSource}, ${ignTipo} ${param1}, ${ignInfo}`;
        case 'CERCA':
            const cercaSource = driverName ?? vehicle;
            const cercaTipo = evt === '1' ? 'Entrou em' : 'Saiu de';
            const cercaInfo = evt === '1'
                ? `permaneceu fora por ${converteIntInTimer(duraction)}!`
                : `permaneceu dentro por ${converteIntInTimer(duraction)}!`;
            return `${cercaSource} ${cercaTipo} ${param1}, ${cercaInfo}`;
        case 'VEL':
        case 'SPEED':
            const speedSource = driverName ?? vehicle;
            return `${speedSource} Ultrapassou o limite de [${param1} Km/h]`;
        case 'BATCUT':
            return evt === '1' ? 'Bateria ou equipamento foi removido' : 'Bateria ou equipamento foi instalado';
        case 'BLK':
            return evt === '1' ? `${vehicle} foi bloqueado!` : `${vehicle} foi desbloqueado!`;
        case 'PANICO':
        case 'PANIC':
        case 'SOS':
            const panicSource = driverName ?? vehicle;
            return `${panicSource} Acionou o Botão de Pânico`;
        case 'HARACC':
            const accSource = driverName ?? vehicle;
            return `${accSource} Acelerou Bruscamente`;
        case 'HARDEC':
            const decSource = driverName ?? vehicle;
            return `${decSource} Freou Bruscamente`;
        case 'MOVE':
            const moveSource = driverName ?? vehicle;
            return `${moveSource} Movimento Detectado`;
        case 'DMSPHONE':
            const phoneSource = driverName ?? 'O Motorista';
            return `${phoneSource} Está usando o celular enquanto dirige!`;
        case 'DMSSMOKING':
            const smokingSource = driverName ?? 'O Motorista';
            return `${smokingSource} Está Fumando dentro do veículo!`;
        case 'DMSDISTRATION':
            const distractionSource = driverName ?? 'O Motorista';
            return `${distractionSource} Está Distraindo!`;
        case 'DMSYAWN':
            const yawnSource = driverName ?? 'O Motorista';
            return `${yawnSource} Está Sonolento!`;
        default:
            return '';
    }
}

// Função auxiliar para converter duração em um formato legível
function converteIntInTimer(duraction: number): string {
    // Implementação da lógica de conversão de duração
    const minutes = Math.floor(duraction / 60);
    const seconds = duraction % 60;
    return `${minutes}min ${seconds}s`; // Exemplo de conversão
}



// Método para formatar o risco
export function formaterAlert(params: any): AlertsItemModels[] {
    // Mapping the result to the AlertsItemModels interface
    const alerts: AlertsItemModels[] = params.map((item: any) => ({
        id: item.id,
        isRead: item.is_read,
        idClient: item.idclient,
        idDriver: item.iddriver,
        idVehicle: item.idvehicle,
        idEvt: item.idevt,
        idAlert: item.id_alert,
        idCerca: item.idcerca,
        priority: item.priority,
        points: item.points,
        tipo: item.tipo,
        evt: item.evt,
        param1: item.param1,
        avatar: item.avatar,
        title: formateTitle(item.tipo, item.evt, item.param1, item.speed),
        descr: formateDescr(item.tipo, item.evt, item.param1, item.speed, item.driverName, item.vehicle, item.duraction, item.distance),
        lat: item.lat,
        lng: item.lng,
        obs: item.obs,
        createdAt: item.created_at,
        dtevtAt: formatDateToScreenString(item.dtevt_at),
        driverAvatar: item.driveravatar,
        driverName: item.drivername,
        vehicle: item.vehicle,
        icone: item.icone,
        speed: item.speed,
        iconActive: item.iconActive,
        riskLabel: item.riskLabel,
        riskColor: item.riskColor, // assuming a string color code
        riskIcon: item.riskIcon,    // assuming an icon string identifier
    }));

    return alerts;
}


// Assuming that you will get a color as a string (like hex or RGB) and icons will be string identifiers
export interface AlertsItemModels {
    id: number;
    isRead: number;
    idClient: number;
    idDriver: number;
    idVehicle: number;
    idEvt: number;
    idAlert: number;
    idCerca: number;
    priority: number;
    points: number;
    tipo: string;
    evt: string;
    param1: string;
    avatar: string;
    title: string;
    descr: string;
    lat: number;
    lng: number;
    obs?: string;
    createdAt: string;
    dtevtAt: string;
    driverAvatar?: string;
    driverName: string;
    vehicle: string;
    icone: string;
    speed: number;
    iconActive: boolean;
    riskLabel: string;
    riskColor: string; // Assuming riskColor is a hex color string
    riskIcon: string;  // Assuming riskIcon can be represented as a string identifier for the icon
}
