import { useDispatch, useSelector } from 'react-redux';
import React, { useRef } from 'react';
import { Box, Button, IconButton, Stack, TextField, ThemeProvider } from '@mui/material';
import styled from 'styled-components';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';


import DatePicker, { registerLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import ptBr from 'date-fns/locale/pt-BR';
import { ContainerFlexInline } from '../../../styles/Styles';
import { changeAlert } from '../../../redux/alertServiceSlice';
import { iMsgAlert } from '../../../interfaces/interfaces';
import { selectUser } from '../../../redux/userSlice';
import httpApiV3 from '../../../services/http-xsmart-apiv3';
import { itemDriver } from '../Components/ViewListDrivers';
import { ContainerBar, ContainerCenter, ValueItem2 } from '../Styles/DriverStyle';
import { Close, Save } from '@mui/icons-material';
import SelectComp from '../../../components/forms/components/SelectFieldComp';
import Input from '../../../components/forms/components/InputComp';
import TextAreaComp from '../../../components/forms/components/TextFieldComp';
import { getDateToBd } from '../../../utils/Converter';
registerLocale('ptBr', ptBr)

interface FormDataSave {
    driverId: number
    idempresa: number
    IdCompany: number
    DriverName: string
    DriverCard: string
    cpf: string
    category: string
    numberCNH: string
    dtniver: string
    cnhValidate: string
    ender: string
    tel: string
    email: string
    isJornada: number
    idperfil: number
}


interface iProps {
    initialData?: itemDriver
    onCloseForm: () => void
    onCloseFormUpdate: () => void
}


const ContainerForm = styled('div')({
    display: 'block',
    padding: 8
});

const ContainerDate = styled.div`
    display: block;`

const ContainerHours = styled.div`
    display: block;
    margin-left: 10px;
    & > Input {
        text-align: center;
        width: 80px;
        border: 1px solid #b1b1b1;
    }`

const ContainerInput80px = styled.div`
display: block;
margin-right: 10px;
& > Input {
    text-align: left;
    width: 100px;
    border: 1px solid #b1b1b1;
}`

const ContainerInput250px = styled.div`
display: block;
margin-right: 10px;
& > Textarea {
    text-align: left;
    width: 90%;
    border: 1px solid #b1b1b1;
}`

const ContainerSelect100px = styled.div`
display: block;
margin-right: 10px;
& > select {
    text-align: left;
    width: 100px;
    border: 1px solid #b1b1b1;
}`

const ContainerPicker = styled.div`
    display: flex;
`
const ContainerInline = styled.div`
    display: flex;
`

const ContainerCard = styled.div`
      display: flex;
    flex-direction: column;
    align-items: initial;
    padding: 18px;
    background: #ffffff;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 15%);
   
`

const ContainerInput = styled.div`
display: block;
margin-right: 10px;
& > Input {
    text-align: left; 
    width: 220px;
    border: 1px solid #b1b1b1;
}`


interface iOptions {
    label: string
    value: string
}

const listCategory: iOptions[] = [
    { value: "A", label: "Categoria A" },
    { value: "B", label: "Categoria B" },
    { value: "C", label: "Categoria C" },
    { value: "D", label: "Categoria D" },
    { value: "E", label: "Categoria E" },
    { value: "AB", label: "Categoria AB" },
    { value: "AC", label: "Categoria AC" },
    { value: "AD", label: "Categoria AD" },
    { value: "AE", label: "Categoria AE" },
    { value: "BE", label: "Categoria BE" },
    { value: "CE", label: "Categoria CE" },
    { value: "DE", label: "Categoria DE" },
]
const listJornada: iOptions[] = [
    { label: "Baixa", value: "1" },
    { label: "Média", value: "2" },
    { label: "Alta", value: "3" }
]

const CadDriverForms: React.FC<iProps> = (props) => {

    const dispatch = useDispatch()
    const formRef = useRef<FormHandles>(null)
    const { user } = useSelector(selectUser);
    const [isOpenDataPicker, setOpenDataPicker] = React.useState(false);
    const [DateExpirateCNH, setDateExpirateCNH] = React.useState(new Date());
    const [DateBirthday, setDateBirthday] = React.useState(new Date());
    const [HoursSelected, setHoursSelected] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);


    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }

    const handleSaveInServer = async (data: FormDataSave) => {
        try {
            data.IdCompany = user.idcliente
            data.idempresa = user.idempresa
            console.log(data)
            const result = data.driverId ? await httpApiV3.post("alterDriver", data) : await httpApiV3.post("createDriver", data)
            handleSetMsgInfo({ msg: `Registro ${data.driverId ? 'Alterado' : 'Criado'} com sucesso!`, type: 'success' })
            formRef.current?.reset()
            props.onCloseFormUpdate()

        } catch (error: unknown) {
            console.log(error)
            if (error instanceof Error) {
                handleSetMsgInfo({ msg: error.message, type: 'error' })
            }
        }


    }

    React.useEffect(() => {
        console.log("***********************", props.initialData)
        if (props.initialData) {
            setDateBirthday(new Date(props.initialData.dtniver))
            setDateExpirateCNH(new Date(props.initialData.cnhValidate))
        }
    }, [props.initialData])

    const handleSubmitSaveCad: SubmitHandler<FormDataSave> = async data => {
        console.log(data)
        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                DriverName: yup.string().required("Esse campo é Obrigatório"),
                cpf: yup.string().required("Esse campo é Obrigatório")
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            if (props.initialData) { data.driverId = props.initialData.id }
            data.dtniver = getDateToBd(DateBirthday)
            data.cnhValidate = getDateToBd(DateExpirateCNH)
            handleSaveInServer(data)
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }

    const handleChangeDateExpirationCNH = (newValue: any) => {
        setDateExpirateCNH(newValue);
    };
    const handleChangeBirthday = (newValue: any) => {
        setDateBirthday(newValue);
    };


    const formatDates = (date: Date | null) => {
        if (!date) {
            return '';
        }
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('pt-BR');
    }


    const formDataExpirateCNH = (
        <React.Fragment>
            <ContainerDate>
                <label >Vencimento CNH</label>
                <DatePicker
                    selected={DateExpirateCNH}
                    onChange={handleChangeDateExpirationCNH}
                    popperPlacement="top-end"
                    locale="ptBr"
                    dateFormat="dd-MM-yyyy"
                    className='InputDate'
                />
            </ContainerDate>
        </React.Fragment>
    );
    const formBirthday = (
        <React.Fragment>
            <ContainerDate>
                <label >Data Aniversário</label>
                <DatePicker
                    selected={DateBirthday}
                    onChange={handleChangeBirthday}
                    popperPlacement="top-end"
                    locale="ptBr"
                    dateFormat="dd-MM-yyyy"
                    className='InputDate'
                />
            </ContainerDate>
        </React.Fragment>
    );

    const CadFormCNH = (
        <React.Fragment>
            <ContainerForm>
                <ContainerInline>
                    <ContainerInput80px> <Input className='theme-light' name="numberCNH" label='Num CNH' placeholder='Num CNH' disabled={isLoading} role="presentation" type='text' /></ContainerInput80px>
                    <ContainerInput80px>  {formDataExpirateCNH}</ContainerInput80px>
                    <ContainerSelect100px><SelectComp name="category" options={listCategory} label="Categoria" /></ContainerSelect100px>
                </ContainerInline>
            </ContainerForm>
        </React.Fragment>
    );
    const CadFormCPF = (
        <React.Fragment>
            <ContainerForm>
                <ContainerInput> <Input className='theme-light' name="DriverName" label='Nome*' placeholder='Nome' disabled={isLoading} role="presentation" type='text' /></ContainerInput>

                <ContainerInline>
                    <ContainerInput80px> <Input className='theme-light' name="cpf" label='CPF' placeholder='CPF' disabled={isLoading} role="presentation" type='text' /></ContainerInput80px>
                    <ContainerInput80px> {formBirthday}</ContainerInput80px>
                    <ContainerInput80px> <Input className='theme-light' name="DriverCard" label='Ibutton/Card' placeholder='Ibutton/Card' disabled={isLoading} role="presentation" type='text' /></ContainerInput80px>
                </ContainerInline>
                <ContainerInline>
                    <ContainerInput> <Input className='theme-light' name="email" label='E-mail' placeholder='E-mail' disabled={isLoading} role="presentation" type='text' /></ContainerInput>
                    <ContainerInput80px> <Input className='theme-light' name="tel" label='Telefone' placeholder='Telefone' disabled={isLoading} role="presentation" type='text' /></ContainerInput80px>
                </ContainerInline>
            </ContainerForm>
        </React.Fragment>
    );
    const CadFormAddress = (
        <React.Fragment>
            <ContainerForm>
                <ContainerInput250px>
                    <TextAreaComp rows={3} className='theme-light' name="ender" label='Endereço' placeholder='Endereço' disabled={isLoading} role="presentation" />
                </ContainerInput250px>
            </ContainerForm>
        </React.Fragment>
    );

    const onChange = (event: any) => {
        const { value } = event.target;
        setHoursSelected(value);
    }


    return (
        <>
            <ContainerBar>
                <ContainerCenter>
                    <IconButton color="primary" aria-label="show Maps" onClick={() => props.onCloseForm()}>
                        <Close />
                    </IconButton>
                    <IconButton color="primary" aria-label="show Maps" onClick={() => formRef.current?.submitForm()}>
                        <Save />
                    </IconButton>
                    <ValueItem2>{props.initialData ? `${props.initialData?.DriverName}` : 'Cadastro de Motorista'}</ValueItem2>
                </ContainerCenter>
            </ContainerBar>
            <Form placeholder={""} ref={formRef} onSubmit={handleSubmitSaveCad} className='Input-Style' noValidate={true} initialData={props.initialData}>
                <ContainerFlexInline>
                    <ContainerCard>
                        {CadFormCPF}
                        {CadFormCNH}
                        {CadFormAddress}
                    </ContainerCard>
                </ContainerFlexInline>
            </Form>

        </>

    )

}
export default CadDriverForms