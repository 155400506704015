import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';
import { ptBR as coreBgBG } from '@mui/material/locale';

export const themeLight = createTheme(
    {
        palette: {
            primary: { main: '#F18F01' },
            secondary: { main: '#99C24D' },
        },
    },
    ptBR, // x-data-grid translations
    coreBgBG, // core translations
);


export const themeDark = createTheme(
    {
        palette: {
            mode: 'dark',
            primary: { main: '#43b309' },
            secondary: { main: '#43b309' }
        },
    },
    ptBR, // x-data-grid translations
    coreBgBG, // core translations

);