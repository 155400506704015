import React, { useRef, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import { upsertSupplier } from '../repository/SupplierRepo';
import { AuthConfig } from '../../../Config/AuthContext';
import { v4 } from 'uuid';

type Supplier = {
    id?: number;
    unique_id: string;
    id_client: number;
    id_empresa: number;
    id_categ: number;
    name: string;
    tipo_servico_produto: string;
    cnpj_cpf: string;
    endereco_completo: string;
    contato_principal?: string;
    telefone?: string;
    whatsapp?: string;
    email?: string;
    website?: string;
    condicoes_pagamento?: string;
    dados_bancarios?: string;
    historico_compras?: string;
    avaliacao_desempenho?: string;
    status?: string;
    observacoes_gerais?: string;
    latitude?: number;
    longitude?: number;
    create_at?: Date;
    update_at?: Date;
    delete_at?: Date | null;
    sync_version?: number;
    active?: boolean;
};

interface FormSuppliersProps {
    initialData?: Supplier;
    onCancel: () => void;
    onUpdate: () => void;
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;


const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #45a049;
  }
`;

const CancelButton = styled.button`
  padding: 12px 24px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #e53935;
  }
`;

const schema = yup.object().shape({
    //id_categ: yup.number().required('O ID da categoria é obrigatório'),
    name: yup.string().required('O nome é obrigatório'),
    tipo_servico_produto: yup.string().required('O tipo de serviço/produto é obrigatório'),
    cnpj_cpf: yup.string().required('O CNPJ/CPF é obrigatório'),
    endereco_completo: yup.string().required('O endereço é obrigatório'),
    email: yup.string().email('E-mail inválido').nullable(),
    telefone: yup.string().nullable(),
    whatsapp: yup.string().nullable(),
    website: yup.string().url('URL inválida').nullable(),
});

const FormSuppliers: React.FC<FormSuppliersProps> = ({ initialData, onUpdate, onCancel }) => {
    const formRef = useRef<FormHandles>(null);
    const { user } = AuthConfig();



    const sendToServer = async (payload: any): Promise<any> => {
        try {
            console.log(payload)
            await upsertSupplier(payload)
            onUpdate()
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async (data: Supplier) => {
        try {
            console.log(user)
            formRef.current?.setErrors({});
            await schema.validate(data, { abortEarly: false });
            if (user === undefined) {
                throw new Error('Usuário não autenticado');
            }
            const formData: Supplier = {
                ...data,
                id_categ: 0,
                unique_id: initialData?.unique_id ?? v4(),
                id_client: user.idclient ?? 0,
                id_empresa: user.idempresa,
            };
            console.log(formData)

            sendToServer(formData);
        } catch (err) {
            console.log(err);
            if (err instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }

    };

    return (
        <FormContainer>
            <Form placeholder={''} ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <InputFieldForm label="Nome Fantasia*" name="name" placeholder="Nome do fornecedor" type="text" />
                <Row>
                    <InputFieldForm label="Tipo de Serviço/Produto" name="tipo_servico_produto" placeholder="Tipo de serviço/produto" type="text" />
                    <InputFieldForm label="Contato Principal" name="contato_principal" placeholder="Contato Principal" type="text" />
                </Row>
                <Row>
                    <InputFieldForm label="CNPJ/CPF*" name="cnpj_cpf" placeholder="CNPJ ou CPF" type="text" />
                    <InputFieldForm label="Telefone" name="telefone" placeholder="Número de telefone" type="text" />
                    <InputFieldForm label="WhatsApp" name="whatsapp" placeholder="Número de WhatsApp" type="text" />
                </Row>

                <InputFieldForm label="Endereço Completo*" name="endereco_completo" placeholder="Endereço completo" type="text" />

                <Row>
                    <InputFieldForm label="E-mail" name="email" placeholder="E-mail de contato" type="email" />
                    <InputFieldForm label="Website" name="website" placeholder="Website" type="text" />
                </Row>

                <Row>
                    <InputFieldForm label="Condições de Pagamento" name="condicoes_pagamento" placeholder="Condições de pagamento" type="text" />
                    <InputFieldForm label="Dados Bancários" name="dados_bancarios" placeholder="Dados bancários" type="text" />
                </Row>

                <InputFieldForm label="Observações Gerais" name="observacoes_gerais" placeholder="Observações gerais" type="text" />

                <ActionButtons>
                    <SubmitButton type="submit" >Salvar</SubmitButton>
                    <CancelButton type="button" onClick={onCancel}>Cancelar</CancelButton>
                </ActionButtons>
            </Form>
        </FormContainer>
    );
};

export default FormSuppliers;
