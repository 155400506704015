import { httpAxiosV4 } from "../../../gateways/Axios";







export async function saveTanksFuelServer(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(//request, response
        `fuel/stocks/tanks`,
        "POST",
        payload
    );
    return data;
}


export async function getListTanks(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(//request, response
        `fuel/stock/tanks`,
        "GET"
    );
    return data;
}

export async function getInvoices(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(//request, response
        `fuel/stocks/entries`,
        "GET"
    );
    return data;
}
export async function saveFuelEntries(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(//request, response
        `fuel/stocks/entries`,
        "POST",
        payload
    );
    return data;
}




// Definindo o tipo do objeto baseado em iFuelInvoice
export type iFuelInvoice = {
    unique_id: string;
    tankId: number;
    fuelId: number;
    clientId: number;
    quantity: number;
    pricePerLiter: number;
    supplierUUID: string | null;
    paymentType: string;
    createdBy: number;
    observations?: string;
};


export type iTank = {
    id?: number;                  // ID do tanque (opcional)
    identifier: string;          // Identificador do tanque (ex: 'Tank 1')
    max_capacity: number;         // Capacidade máxima do tanque (em litros)
    stock_min: number;            // Estoque mínimo do tanque (em litros)
    fuel_id: number;              // ID do tipo de combustível armazenado
    current_volume: number;       // Volume atual do tanque (em litros)
    location: string;            // Localização do tanque
    branchId?: number;           // ID da filial (opcional)
    color_fuel: string;          // Cor do combustível
}