import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridToolbarQuickFilter, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { Box, IconButton, Stack } from '@mui/material';
import { LocationOn, Refresh } from '@mui/icons-material';
import BarTable from './BarTable';
import { ctrlButtons, iDestinos } from '../interfaces/interfaces'
import WidgetDataGridComp from '../widgets/WidgetDataGrid';


interface ListProps {
    list: iDestinos[],
    onCenterMap: (item: iDestinos) => void,
    onClickOpenDialog?: () => void
    handleClickDelete?: (itens: number[]) => void
    handleClickRouter?: () => void
    handleClickRefresh?: () => void
    handleClickMap?: () => void

}

const initialBtns: ctrlButtons = {
    addShow: true,
    addDisable: false,
    RefreshShow: true,
    RefreshDisable: false,
    DeleteShow: true,
    DeleteDisable: true,
    RouterShow: true,
    RouterDisable: false,
    OpenMap: true
}

const ListDest: React.FC<ListProps> = (props) => {

    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);



    const columns: GridColDef[] = [

        {
            field: 'maps', headerName: 'Mapa', width: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => openRowClick(params.row)}>
                            <LocationOn />
                        </IconButton>
                    </>
                );
            }


        },
        {
            field: 'order_item',
            headerName: 'Ordem',
            width: 90, editable: true
        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width: 250,
        },
        {
            field: 'contact',
            headerName: 'Contato',
            width: 250
        },
        {
            field: 'document',
            headerName: 'Documento',
            width: 150
        },
        {
            field: 'ender',
            headerName: 'Endereço',
            width: 350,
        },
        {
            field: 'phone',
            headerName: 'Telefone',
            width: 130
        },
        {
            field: 'email',
            headerName: 'E-mail',
            width: 250
        }
    ];


    const openRowClick = (item: iDestinos) => {
        props.onCenterMap(item)
    }

    const setSel = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
        CtrlBtns.DeleteDisable = i.length ? false : true
        setCtrlBtns(CtrlBtns)
    }

    const openClickDialog = () => {
        props.onClickOpenDialog?.()
    }

    const deleteClickDialog = () => {
        props.handleClickDelete?.(selectdRows)
    }

    const routerClickDialog = () => {
        props.handleClickRouter?.()
    }

    const refreshClickDialog = () => {
        props.handleClickRefresh?.()
    }

    const MapClickDialog = () => {
        props.handleClickMap?.()
    }

    return (
        <>
            <Box sx={{ width: '100%', background: 'white' }}>
                <WidgetDataGridComp
                    isLoading={isLoading}
                    rows={props.list}
                    columns={columns}
                    CheckBoxSelect={true}
                    handleSelRow={setSel}
                    initialBtns={CtrlBtns}
                    onRefresClick={refreshClickDialog}
                    onAddClick={openClickDialog}
                    onDeleteClick={deleteClickDialog}
                    onRouterClick={routerClickDialog}
                    onMapClick={MapClickDialog}
                />
            </Box>

        </>
    )

}
export default ListDest