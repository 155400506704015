import React from 'react';
import styled from 'styled-components';

// Styled component para o divisor
const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1px 0;
`;

const DividerLabel = styled.span`
  font-size: 16px;
  color: #666; /* Cor do texto */
`;

const DividerLine = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid #ccc; /* Linha cinza claro */
  margin-top: 5px;
`;

interface DividerProps {
    label: string;
}

const StyledDivider: React.FC<DividerProps> = ({ label }) => {
    return (
        <Divider>
            <DividerLabel>{label}</DividerLabel>
            <DividerLine />
        </Divider>
    );
};

export default StyledDivider;
