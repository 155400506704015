

import { httpAxiosV4 } from "../../../gateways/Axios";




export async function getListMessages(categ: number): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `messages?categ=${categ}`,
        "GET"
    );
    return response;
}

export async function sendMessages(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `messages/send`,
        "POST",
        payload
    );
    return response;
}



export type iMsgLogs = {
    idcliente: number;        // INTEGER NOT NULL -> number
    idempresa: number;        // INTEGER NOT NULL -> number
    cliente_nome: string;     // VARCHAR(100) NOT NULL -> string
    idrecord: number;         // INTEGER NOT NULL -> number
    request: string;          // VARCHAR(100) NOT NULL -> string
    contact: string;          // VARCHAR(100) NOT NULL -> string
    type_send: string;        // VARCHAR(50) NOT NULL -> string
    descr: string;            // VARCHAR(250) NOT NULL -> string
    status_log: string;       // VARCHAR(50) NOT NULL -> string
    info: string;             // VARCHAR(250) NOT NULL -> string
    msg: string;              // TEXT NOT NULL -> string
    unique_id: string;        // UUID UNIQUE NOT NULL -> string
    created_at?: Date;         // TIMESTAMP DEFAULT NOW() -> Date
    updated_at?: Date;         // TIMESTAMP -> Date
    idtemplate?: number;      // INTEGER, optional
}