
import './styles/App.css'
import React from 'react';
import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from './redux/userSlice';
import AppBarComp from './components/AppBarComp'
import { themeLight } from './styles/themes/MuiTheme';
import { ThemeProvider } from '@mui/material/styles';

import RoutesComp from "./routes";
import { selectAppBar } from './redux/appBarSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertComp from './components/AlertComp';
import PanelRightComp from './components/PanelsRight/PanelRightComp';
import WebSocketProvider from './gateways/WebSocket';
import { NotificationProvider } from './Notifications/NotificationContext';
import { NotificationContainer } from './Notifications/NotificationContainer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ConfigProvider } from './Config/configContext';
import { GoogleMapProvider } from './GoogleMaps/GoogleMapProvider';
import { DrawerProvider } from './Drawer/drawerContext';
import LayoutWithDrawer from './LayoutWithDrawer';
import { AuthConfig, AuthProvider } from './Config/AuthContext';
import AppMain from './AppMain';

function App() {


  const dispatch = useDispatch();
  const { state } = useLocation();
  const { user } = useSelector(selectUser);
  const { appbar } = useSelector(selectAppBar);
  const nav = useNavigate();
  /*
    const CheckLogged = () => {
      let dados_token = window.localStorage.getItem('dados_token') || null
      if (dados_token) {
        try {
          let dados_str = aes.decrypt(dados_token, process.env.REACT_APP_KEY_AES || '').toString(utf8)
          let dados_user = JSON.parse(dados_str)
          dispatch(changeUser(converteObjLogin(dados_user)))
          dispatch(changeCred(dados_user.credentials))
          document.title = "Gestão de Frotas"
          console.log('dados_user', dados_user)
          //loading
          if (!window.location.hash.length) {
            let last_page = window.localStorage.getItem('last_page') || '/map'
            console.log('last_page', last_page)
            nav(last_page)
          }
  
        } catch (error) {
          console.log('[CheckLogged]', error)
          dispatch(logout())
        }
      } else {
        console.log("dados_token invalid")
        dispatch(logout())
      }
    }*/

  // const CheckLogged = () => {
  //   let dados_token = window.localStorage.getItem('dados_token') || null
  //   if (dados_token) {
  //     let dados_str = aes.decrypt(dados_token, process.env.REACT_APP_KEY_AES || '').toString(utf8)
  //     let dados_user = JSON.parse(dados_str)
  //     console.log('dados_user', dados_user, isLogged)
  //     updateConfig({ isLogged: true, user: dados_user, dados_token: dados_token });
  //   }
  // }




  React.useEffect(() => {
    const onPageLoad = () => {
      //CheckLogged()
      console.log('onPageLoad')
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <AuthProvider>
      <DrawerProvider>
        <ConfigProvider>
          <NotificationProvider>
            <NotificationContainer />
            <WebSocketProvider>
              <ThemeProvider theme={themeLight}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ptBR">
                  <AppMain />
                </LocalizationProvider>
              </ThemeProvider>
            </WebSocketProvider>
          </NotificationProvider>
        </ConfigProvider>
      </DrawerProvider>
    </AuthProvider>
  );
}

export default App;
