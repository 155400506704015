import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box } from '@mui/material';
import TemplatePage from '../../Templates/View/TemplatePage';
import MessagesListTab from './MessagesListTab';
import MessagesCreate from './MessagesCreate';

const MessagesPage: React.FC = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Mensagens" value="1" />
                        <Tab label="Enviar Mensagens" value="2" />
                    </TabList>
                </Box>
                <div>
                    <TabPanel value="1"><MessagesListTab categ={2} /></TabPanel>
                    <TabPanel value="2"><MessagesCreate /></TabPanel>
                    <TabPanel value="3"><TemplatePage /></TabPanel>
                </div>
            </TabContext>
        </>
    );
}
export default MessagesPage