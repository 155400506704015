import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

interface iOptions {
    label: string
    value: string
}

interface Props {
    name: string
    label?: string
    searchTerm?: string
    options: iOptions[]
}

type InputProps = JSX.IntrinsicElements['select'] & Props

export default function SelectComp({ name, label, searchTerm, options, ...rest }: InputProps) {
    const inputRef = useRef<HTMLSelectElement>(null)

    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        /*const filteredOptions = this.props.options.filter((option) =>
          option.toLowerCase().includes(searchTerm.toLowerCase())
        );
    
        this.setState({ searchTerm, filteredOptions });*/
    };

    return (
        <>
            {label && <label htmlFor={fieldName}>{label}</label>}

            <select
                id={fieldName}
                ref={inputRef}
                defaultValue={defaultValue}
                {...rest}
            >
                {options.map((item: iOptions) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>

            {error && <span>{error}</span>}
        </>
    )
}