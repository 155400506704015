import { createSlice } from '@reduxjs/toolkit'
import { iAppBar } from '../interfaces/interfaces'
import { RootState } from './store'


const init: iAppBar = {
    openDrawer: 1,
    openMap: 0,
    openAlerts: 0,
    openDetailsTask: 0
}

export const slice = createSlice({
    name: 'appbar',
    initialState: init,
    reducers: {
        changeMenu(state, { payload }) {
            console.log(payload)
            return { ...state, ...payload }
        }
    }
})

export const { changeMenu } = slice.actions

export const selectAppBar = (state: RootState) => state

export default slice.reducer