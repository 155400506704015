import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/userSlice';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const Message = styled.h2`
  margin-bottom: 20px;
`;

const LoginButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: #0056b3;
  }
`;

const UnauthorizedPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectToLogin = () => {
    window.localStorage.removeItem("dados_token")
    dispatch(logout())
    navigate("/login")
  };

  return (
    <Container>
      <Message>Você não está autorizado a acessar esta página.</Message>
      <LoginButton onClick={redirectToLogin}>Login</LoginButton>
    </Container>
  );
};

export default UnauthorizedPage;
