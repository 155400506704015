// ColorPickerModal.tsx
import React from 'react';
import styled from 'styled-components';
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";

const ModalContent = styled.div`
  padding: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

interface ColorPickerModalProps {
    initialColor: string;
    onColorSelect: (color: string) => void;
    onClose: () => void;
}

const ColorPickerModal: React.FC<ColorPickerModalProps> = ({ initialColor, onColorSelect, onClose }) => {
    const [selectedColor, setSelectedColor] = useColor(initialColor);

    return (
        <ModalContent>
            <ColorPicker
                height={180}
                color={selectedColor}
                onChange={setSelectedColor}
                hideAlpha={true}
                hideInput={["rgb", "hsv"]}
            />
            <ButtonRow>
                <button onClick={onClose}>Cancelar</button>
                <button onClick={() => onColorSelect(selectedColor.hex)}>Selecionar</button>
            </ButtonRow>
        </ModalContent>
    );
};

export default ColorPickerModal;
