import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';

type Config = {
    dateStartFilter: Dayjs;
    dateEndFilter: Dayjs;
    filterSelect: string;
    updateConfig: (newConfig: Partial<Config>) => void;
};

const defaultConfig: Config = {
    filterSelect: '0',
    dateStartFilter: dayjs(new Date()),
    dateEndFilter: dayjs(new Date()),
    updateConfig: () => { },
};

const ConfigContext = createContext<Config>(defaultConfig);

export const ReportConfig = () => {
    return useContext(ConfigContext);
};

type ConfigProviderProps = {
    children: ReactNode;
};

export const ReportProvider = ({ children }: ConfigProviderProps) => {

    // Função para carregar o estado inicial do localStorage
    const loadInitialConfig = (): Config => {
        const localConfig = localStorage.getItem('ReportConfig');
        if (localConfig) {
            try {
                const parsedConfig = JSON.parse(localConfig);

                // Garantir que dateStartFilter e dateEndFilter sejam convertidos para instâncias Dayjs
                return {
                    ...parsedConfig,
                    dateStartFilter: dayjs(parsedConfig.dateStartFilter),
                    dateEndFilter: dayjs(parsedConfig.dateEndFilter),
                };
            } catch (error) {
                console.error('Erro ao fazer parse da configuração armazenada: ', error);
                return defaultConfig;
            }
        }
        return defaultConfig;
    };
    // Inicialize o estado com a configuração carregada do localStorage
    const [config, setConfig] = useState(loadInitialConfig);

    useEffect(() => {
        // Somente salva no localStorage quando a config mudar, evitando sobrescrever no primeiro carregamento
        if (config !== defaultConfig) {
            localStorage.setItem('ReportConfig', JSON.stringify(config));
        }
    }, [config]);


    const updateConfig = (newConfig: Partial<Config>) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...newConfig,
        }));
    };

    return (
        <ConfigContext.Provider value={{ ...config, updateConfig }}>
            {children}
        </ConfigContext.Provider>
    );
};
