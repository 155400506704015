import React, { useState } from "react";

interface Props {
    items: string[];
    onChange: (stateSelectedItems: string[]) => void;
    selectedItems?: string[];
}

const MultiSelect: React.FC<Props> = ({ items, onChange, selectedItems = [] }) => {
    const [stateSelectedItems, setSelectedItems] = useState<string[]>(selectedItems);

    const handleChange = (item: string) => {
        let list: string[] = []
        if (stateSelectedItems.includes(item)) {
            list = stateSelectedItems.filter((i) => i !== item)
        } else {
            list = [...stateSelectedItems, item];
        }
        setSelectedItems(list)
        onChange(list);
    };

    const handleSelectAll = () => {
        setSelectedItems([...items]);
        onChange([...items])
    };

    const handleDeselectAll = () => {
        setSelectedItems([]);
        onChange([])
    };

    return (
        <div>
            <button onClick={handleSelectAll}>Marcar Tudo</button>
            <button onClick={handleDeselectAll}>Desmarcar Tudo</button>
            {items.map((item) => (
                <div key={item}>
                    <input
                        type="checkbox"
                        checked={stateSelectedItems.includes(item)}
                        onChange={() => handleChange(item)}
                    />
                    {item}
                </div>
            ))}
        </div>
    );
};

export default MultiSelect;
