import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';



interface LoadingIndicatorProps {
    message?: string;
}


const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ message }) => {
    return (
        <div className="flex flex-col items-center justify-center h-[100px] text-orange-500">
            <FontAwesomeIcon icon={faSpinner} size="2x" className="animate-spin" />
            <p className="mt-2 font-bold">{message || 'Carregando...'}</p>
        </div>
    );
};


export default LoadingIndicator;