import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';

import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';

import { iTank, saveTanksFuelServer } from '../repository/TanksRepo';
import ColorPickerModal from '../components/ColorPickerModal';
import ModalComp from '../../../components/datagridComp/ModalComp';

const TankFormContainer = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FieldRow = styled.div`
  display: flex;
  gap: 16px;
`;

const ColorDisplay = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.color};
  border: 1px solid #000;
  border-radius: 4px;
  margin-left: 8px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
`;

const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
`;

const ColorLabel = styled.label`
  margin-right: 10px;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
  position: relative;
  top: 20px;
  bottom: 20px;
`;

const CancelButton = styled.button`
  border: 0.5px solid #f56565;
  color: #f56565;
  text-transform: uppercase;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  
  &:hover {
    background-color: #f56565;
    color: #fff;
  }
`;

const SaveButton = styled.button`
  border: 0.5px solid #48bb78;
  color: #48bb78;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #48bb78;
    color: #fff;
  }
`;

const schema = yup.object().shape({
  identifier: yup.string().required('Identificador é obrigatório'),
  max_capacity: yup.number().required('Capacidade máxima é obrigatória').positive('Capacidade deve ser positiva'),
  stock_min: yup.number().required('Estoque mínimo é obrigatório').positive('Estoque mínimo deve ser positivo'),
  fuel_id: yup.number().required('Tipo de combustível é obrigatório'),
});

interface TankFormProps {
  initialData?: iTank;
  onCancel: () => void;
  onUpdate: () => void;
}

const TankForm: React.FC<TankFormProps> = ({ initialData, onCancel, onUpdate }) => {
  const formRef = useRef<FormHandles>(null);
  const [selectedColor, setSelectedColor] = useState(initialData?.color_fuel || '#f3e306');
  const [isColorModalOpen, setColorModalOpen] = useState(false);


  const saveTanksFuel = async (payload: any): Promise<any> => {
    try {
      console.log(payload)
      await saveTanksFuelServer(payload)
      onUpdate()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (data: iTank) => {
    try {
      console.log(data, initialData)
      data.fuel_id = 1;
      data.id = initialData?.id;
      formRef.current?.setErrors({});
      console.log(data)
      await schema.validate(data, { abortEarly: false });
      saveTanksFuel({ ...data, color_fuel: selectedColor });
    } catch (err) {
      console.log(err)
      if (err instanceof yup.ValidationError) {
        const errors = err.inner.reduce((acc, currentErr) => {
          acc[currentErr.path!] = currentErr.message;
          return acc;
        }, {} as Record<string, string>);
        formRef.current?.setErrors(errors);
      }
    }
  };

  const handleOpenColorModal = () => setColorModalOpen(true);
  const handleCloseColorModal = () => setColorModalOpen(false);

  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
    handleCloseColorModal();
  };

  return (
    <TankFormContainer>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={initialData}
        placeholder={""}
        noValidate>

        <InputFieldForm
          label="Identificador"
          name="identifier"
          placeholder="Identificador do tanque"
          type="text"
        />

        <FieldRow>
          <InputFieldForm
            label="Capacidade Máxima (L)"
            name="max_capacity"
            placeholder="Capacidade máxima em litros"
            type="number"
          />
          <InputFieldForm
            label="Estoque Mínimo (L)"
            name="stock_min"
            placeholder="Estoque mínimo em litros"
            type="number"
          />
        </FieldRow>
        {/* 
        <SelectFieldForm
          name="fuel_id"
          options={optionsMetricsType}
          label="Tipo de Combustível"
        /> */}

        <ColorPickerContainer onClick={handleOpenColorModal}>
          <ColorLabel>Cor do Combustível:</ColorLabel>
          <ColorDisplay color={selectedColor} />
        </ColorPickerContainer>



        <ContainerButton>
          <CancelButton type="button" onClick={onCancel}>
            Cancelar
          </CancelButton>
          <SaveButton type="submit">Salvar</SaveButton>
        </ContainerButton>

      </Form>

      {isColorModalOpen && (
        <ModalComp title="Cor do Combustível" subtitle="" onClose={() => { }}>
          <ColorPickerModal
            initialColor={selectedColor}
            onColorSelect={handleColorSelect}
            onClose={handleCloseColorModal}
          />
        </ModalComp>
      )}
    </TankFormContainer>
  );
};

export default TankForm;
