import React, { useState } from 'react';
import styled from 'styled-components';
import { read, utils } from 'xlsx';
import WidgetDataGridComp from '../../../widgets/WidgetDataGrid';
import { ctrlButtons, iMsgAlert } from '../../../interfaces/interfaces';
import { GridColDef } from '@mui/x-data-grid';
import { itemChips, itemChipsCheck, reqChip } from '../../../interfaces/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { changeAlert } from '../../../redux/alertServiceSlice';
import { alterContractChip, getListChipSimple, getListChips, saveChip, updateChip } from '../Gateways/chipsGateway';
import CardTotal from '../../../components/CardTotal';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { Column, SelectColumn } from 'react-data-grid';
import ChipCardList, { iChipsResume } from '../Components/ChipCardList';
import { SearchAndSelectContainer } from '../Styles/StyleChips';
import SearchField from '../../../components/datagridComp/SearchField';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import ColumnSelect from '../../../components/datagridComp/ColumnSelect';
import { useNotifications } from '../../../Notifications/NotificationContext';
import Sidebar from '../../../components/datagridComp/SideBar';
import SidebarActionChip, { typeMethodsSide } from '../Components/SidebarActionChip';
import SidebarImportChip from '../Components/SidebarImportChip';
import { iChipSave, iChipUpdate, iChipUpdate2 } from './ChipsCadForm';
import { add } from '@amcharts/amcharts5/.internal/core/util/Time';
import { verify } from 'crypto';
import SmallButton from '../../../components/datagridComp/SmallButton';
import ChipStatusEquip from '../Components/ChipStatusEquip';
import StatusChip from '../Components/ChipStatus';
import ChipStatusContract from '../Components/ChipStatusContract';

interface President {
    id: number
    serial: string
    linha: string
    idfornecedor?: number
    operadora: string
    conn?: string
    consumo?: string
    imei?: string
}

const ContainerFlexInline = styled.div`
    display: flex;
    justify-content: center; 
    overflow: auto;   
`

const ContainerTable = styled.div`
padding: 6px;
    height: calc(100vh - 200px);
    overflow: auto;
`
const initialBtns: ctrlButtons = {
    RefreshShow: true,
    FilterShow: true,
    ChatShow: true
}

var reader = new FileReader();

let chipsExcel: President[] = []

type lineChip = {
    id: number
    status: string
    idproviderClient: number
    idproviderExcel: number
    serial_provider: string | null
    number_provider: string | null
    operadora_provider: string | null
    serial_registed?: string | null
    number_registed?: string | null
    operadora_registed?: string | null
    id_registed?: number | null
    update_number: boolean
    update_operadora: boolean
    update_provider: boolean
    franchise?: number
    payment?: number
    idapn?: number
}
type lineChipToDel = {
    id: number
    serial: string
    number: string
    operadora: string
}

type iResumeImport = {
    totalExcel: number
    totalRegisted: number
    totalWithoutRegister: number
    totalRegisterNoInProvider: number
    totalAttLine: number
    totalErrProvider: number
}

type filterSelected = {
    allExcel: boolean
    allRegisted: boolean
    newChips: boolean
    chipsDelete: boolean
    registerError: boolean
    toUpdate: boolean
}

let valueFilter: filterSelected = {
    allExcel: true,
    allRegisted: false,
    newChips: false,
    chipsDelete: false,
    registerError: false,
    toUpdate: false
}

let resumeImport: iResumeImport = {
    totalExcel: 0,
    totalRegisted: 0,
    totalWithoutRegister: 0,
    totalRegisterNoInProvider: 0,
    totalAttLine: 0,
    totalErrProvider: 0
}



const REGISTER_CHIPS = 'REGISTER_CHIPS'
const UPDATE_CHIPS = 'UPDATE_CHIPS'
const SETDEL_CHIPS = 'SETDEL_CHIPS'


const optionsActions = [
    { value: REGISTER_CHIPS, label: 'Registrar chips' },
    { value: UPDATE_CHIPS, label: 'Atualizar chips' },
    { value: SETDEL_CHIPS, label: 'Marcar p/ Deletar chips' }
];




const ChipsInportsPage: React.FC = () => {

    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = React.useState<File>()
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [dataCategoryResume, setDataCategoryResume] = React.useState<iChipsResume[]>([]);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    //sidebar
    const handleCloseSideBar = () => setSideContent(undefined);
    const [sideContent, setSideContent] = React.useState<string>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<lineChip[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<lineChip[]>(rows);

    const [rowsToDelete, setRowsToDelete] = React.useState<itemChips[]>([]);
    const [filteredRowsToDelete, setFilteredRowsToDelete] = React.useState<itemChips[]>(rowsToDelete);

    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }

    async function recalcCards(data: any) {

        const chipsResumeData: iChipsResume[] = [
            { category: "Total Excel", category_icon: "", total_amount: data?.totalExcel ?? 0, isSelected: valueFilter.allExcel, filterKey: 'allExcel' },
            { category: "Total Cadastrado", category_icon: "", total_amount: data?.totalRegisted ?? 0, isSelected: valueFilter.allRegisted, filterKey: 'allRegisted' },
            { category: "Chips Novos", category_icon: "", total_amount: data?.totalWithoutRegister ?? 0, isSelected: valueFilter.newChips, filterKey: 'newChips' },
            { category: "Chips A Deletar", category_icon: "", total_amount: data?.totalRegisterNoInProvider ?? 0, isSelected: valueFilter.chipsDelete, filterKey: 'chipsDelete' },
            { category: "Fornecedor registrado errado", category_icon: "", total_amount: data?.totalErrProvider ?? 0, isSelected: valueFilter.registerError, filterKey: 'registerError' },
            { category: "Atualizar Linha", category_icon: "", total_amount: data?.totalAttLine ?? 0, isSelected: valueFilter.toUpdate, filterKey: "toUpdate" },
        ];
        setDataCategoryResume(chipsResumeData)

    }

    const processItens = (chips: itemChipsCheck[]) => {
        try {
            //console.log(chips)
            //console.log(chipsExcel)
            let rowsLine: lineChip[] = []
            resumeImport = {
                totalExcel: 0,
                totalRegisted: 0,
                totalWithoutRegister: 0,
                totalRegisterNoInProvider: 0,
                totalAttLine: 0,
                totalErrProvider: 0
            }
            resumeImport.totalExcel = chipsExcel.length
            resumeImport.totalRegisted = chips.length
            for (const item of chipsExcel) {
                let [chip] = chips.filter(i => i.serial === item.serial)
                let status = 'NOVO'
                if (chip !== undefined) {//chip cadastrado
                    status = 'CADASTRADO'
                    let isUpdateProvider = item.idfornecedor !== chip.idprovider
                    let isUpdateLine = item.linha !== chip.linha
                    let isUpdateOperadora = item.operadora !== chip.operadora
                    if (isUpdateProvider) status = 'FORNECEDOR ERRADO'
                    if (!isUpdateProvider && (isUpdateLine || isUpdateOperadora)) status = 'ATUALIZAR LINHA'
                    let line: lineChip = {
                        id: item.id,
                        status,
                        idproviderClient: chip.idprovider ?? 0,
                        idproviderExcel: item.idfornecedor ?? 0,
                        serial_provider: item.serial,
                        number_provider: item.linha,
                        operadora_provider: item.operadora,
                        serial_registed: chip.serial,
                        number_registed: chip.linha,
                        operadora_registed: chip.operadora,
                        id_registed: chip.id,
                        update_number: isUpdateLine,
                        update_operadora: isUpdateOperadora,
                        update_provider: isUpdateProvider
                    }
                    if (line.update_number) resumeImport.totalAttLine++
                    if (line.update_provider) resumeImport.totalErrProvider++
                    rowsLine.push(line)
                    //console.log(line, chip, item)
                } else {
                    resumeImport.totalWithoutRegister += 1
                    let line: lineChip = {
                        id: item.id,
                        status,
                        idproviderClient: 0,
                        idproviderExcel: item.idfornecedor ?? 0,
                        serial_provider: item.serial,
                        number_provider: item.linha,
                        operadora_provider: item.operadora,
                        id_registed: 0,
                        update_number: true,
                        update_operadora: true,
                        update_provider: true
                    }
                    rowsLine.push(line)
                }
            }
            for (const chip of chips) {
                let [itemExcel] = chipsExcel.filter(i => i.serial === chip.serial)
                if (itemExcel === undefined) {
                    resumeImport.totalRegisterNoInProvider += 1
                }
            }
            setRows(rowsLine)
            setFilteredRows(rowsLine)
            recalcCards(resumeImport)
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[processItens]', error)
        }
    }



    async function reqListChipsSimple() {
        try {
            setIsLoading(true)
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                idEmpresas: [49, 20250087, 20250098]
            }
            const result = await getListChipSimple(payload);
            let chips = result.map(item => {
                let out: itemChipsCheck = {
                    id: item.id,
                    serial: item.serial ? item.serial.replace(/\D/g, "") : '',
                    linha: item.linha ? item.linha.replace(/\D/g, "") : '',
                    operadora: item.operadora ? item.operadora.replace(/[^a-zA-Z0-9]/g, "").toUpperCase() : '',
                    idprovider: item.idprovider
                }
                return out
            })
            processItens(chips)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[reqListChipsSimple]', error)
        }
    }
    async function reqListChipsToAnalise() {
        try {
            setIsLoading(true)
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                idEmpresas: [49, 20250087, 20250098],
            }
            const result = await getListChips(payload);
            if (result) {
                let rowsToDel: itemChips[] = []
                for (const chip of result) {
                    let serial = chip.serial ? chip.serial.replace(/\D/g, "") : ''
                    let [itemExcel] = chipsExcel.filter(i => i.serial === serial)
                    if (itemExcel === undefined) {
                        rowsToDel.push(chip)
                    }
                }
                setRowsToDelete(rowsToDel)
                setFilteredRowsToDelete(rowsToDel)
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[reqListChipsSimple]', error)
        }
    }

    const columnsToDelete: Column<itemChips>[] = [
        {
            ...SelectColumn,
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'contract_status',
            name: 'Contrato',
            width: 110,
            renderCell: (props: any) => (
                <ChipStatusContract status={props.row.contract_status} />
            )
        },
        {
            key: 'status_conn',
            name: 'Status',
            width: 100,
            renderCell: (props: any) => (
                <StatusChip status={props.row.status_conn} />
            )
        },
        {
            key: 'equip_status',
            name: 'Equip',
            width: 100,
            renderCell: (props: any) => (
                <ChipStatusEquip status={props.row.equip_status} />
            )
        },
        {
            key: 'fornecedor',
            name: 'Fornecedor',
            width: 150,
        },
        {
            key: 'serial',
            name: 'Serial',
            width: 230,
        },
        {
            key: 'nDiasConn',
            name: 'Conectado',
            width: 100
        },
        {
            key: 'equip_imei',
            name: 'Imei',
            width: 280,
            renderCell(props: any) {
                return (
                    props.row.equip_imei ? <>
                        <div><i>{props.row.equip_imei}</i> / <strong>{props.row.fab_nome}-{props.row.mod_ref}</strong></div>
                    </> : <></>
                )
            }
        },
        {
            key: 'cliente_name',
            name: 'Cliente',
            width: 240
        },
        {
            key: 'veiculo_placa',
            name: 'Veículo',
            width: 130
        },


    ];


    const columns: Column<lineChip>[] = [
        {
            ...SelectColumn,
            width: 150,
        },

        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'status',
            name: 'Status',
            width: 150
        },
        {
            key: 'idproviderExcel',
            name: 'ID Forn.',
            width: 65
        },
        {
            key: 'serial_provider',
            name: 'Serial Forn.',
            width: 220
        },
        {
            key: 'number_provider',
            name: 'Linha Forn.',
            width: 150
        },
        {
            key: 'operadora_provider',
            name: 'Oper Forn.',
            width: 100
        },
        {
            key: 'id_registed',
            name: 'Cód Cad',
            width: 60
        },
        {
            key: 'idproviderClient',
            name: 'Forn. Reg',
            width: 65
        },
        {
            key: 'serial_registed',
            name: 'Serial Cad.',
            width: 220
        },
        {
            key: 'number_registed',
            name: 'Linha Cad.',
            width: 150
        },
        {
            key: 'operadora_registed',
            name: 'Oper Cad.',
            width: 100
        },
    ]


    const handleFilter = (item: string) => {
        deselectAll()
        let filter: filterSelected = {
            allExcel: false,
            allRegisted: false,
            newChips: false,
            chipsDelete: false,
            registerError: false,
            toUpdate: false
        }
        filter[item as keyof filterSelected] = true
        valueFilter = filter
        recalcCards(resumeImport)

        if (item === 'chipsDelete') {
            reqListChipsToAnalise()
        }
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }


    const handleSubmission = async () => {
        try {
            setIsLoading(true)
            reader.onload = function (e) {
                var f = e.target?.result;
                const wb = read(f, { type: 'binary' });
                const data = utils.sheet_to_json<President>(wb.Sheets[wb.SheetNames[0]]);
                let newdata = data.map((item, i) => {
                    let out: President = {
                        id: i,
                        serial: item.serial ? item.serial.replace(/\D/g, "") : '',
                        linha: item.linha ? item.linha.replace(/\D/g, "").substring(2) : '',
                        operadora: item.operadora ? item.operadora.replace(/[^a-zA-Z0-9]/g, "").toUpperCase() : '',
                        idfornecedor: item.idfornecedor ? item.idfornecedor : 0
                    }
                    return out

                })
                chipsExcel = newdata
                reqListChipsSimple()
                setIsLoading(false)

            }

            reader.readAsArrayBuffer(selectedFile as unknown as Blob);
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[ReadExcel]', error)
        }
    }

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos um registro', 'warning');
            return;
        }
        switch (action) {
            case REGISTER_CHIPS:
                setSideContent(REGISTER_CHIPS)
                break
            case UPDATE_CHIPS:
                setSideContent(UPDATE_CHIPS)
                break
            case SETDEL_CHIPS:
                setSideContent(SETDEL_CHIPS)
                break
        }
    };

    const handleCloseSidebar = () => {
        setSideContent(undefined);
    };

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((Expense) => Expense.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const handleRegisterChips = async (value: any) => {
        try {
            let idprovider = value.provider.id_provider
            let idapn = value.provider.id
            let franchise = value.franchise ?? 0
            let payment = value.payment ?? 0
            let operator = value.provider.Operadora ?? ''
            if (idprovider === 0 || idapn === 0) {
                addNotification('', 'Fornecedor não selecionado', 'warning');
                return;
            }
            let itensSave: iChipSave[] = []
            selectedRows.forEach((id) => {
                let chip = rows.find(i => i.id === id)
                if (chip === undefined) return
                if (chip.status !== 'NOVO') return
                let input: iChipSave = {
                    serial: chip.serial_provider ?? '',
                    line: chip.number_provider ?? '',
                    franchise: franchise,
                    payment: payment,
                    idapn: idapn,
                    idprovider: idprovider,
                    operator: operator
                }
                itensSave.push(input)
            })
            for (let x = 0; x < itensSave.length; x++) {
                let resp = await saveChip(itensSave[x])
            }
            handleCloseSidebar()
            reqListChipsSimple()
            deselectAll()
            addNotification('Cadastro de chips', `${itensSave.length} Chips Cadastrados com sucesso`, 'success');

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[handleRegisterChips]', error)
        }

    }

    const handleUpdateChips = async () => {
        try {

            let itensSave: iChipUpdate[] = []
            selectedRows.forEach((id) => {
                let chip = rows.find(i => i.id === id)
                if (chip === undefined) return
                if (chip.status === 'NOVO' || chip.status === 'CADASTRADO') return
                let input: iChipUpdate = {
                    id: chip.id_registed ?? 0,
                    serial: chip.serial_provider ?? '',
                    line: chip.number_provider ?? '',
                    franchise: chip.franchise ?? 0,
                    payment: chip.payment ?? 0,
                    idapn: chip.idapn ?? 0,
                    idprovider: chip.idproviderExcel ?? 0,
                    operator: chip.operadora_provider ?? '',
                    reason: 'importação de chips via excel'
                }
                itensSave.push(input)
            })
            console.log(itensSave)
            for (let x = 0; x < itensSave.length; x++) {
                let resp = await updateChip(itensSave[x])
            }
            handleCloseSidebar()
            reqListChipsSimple()
            deselectAll()
            addNotification('Atualização de chips', `${itensSave.length} Chips Atualizados com sucesso`, 'success');

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[handleRegisterChips]', error)
        }

    }

    const handleDeleteChips = async () => {
        try {

            let itensSave: iChipUpdate2[] = []
            selectedRows.forEach((id) => {
                let chip = rowsToDelete.find(i => i.id === id)
                if (chip === undefined) return
                let input: iChipUpdate2 = {
                    id: chip.id ?? 0,
                    contract_status: 'DELETAR'
                }
                itensSave.push(input)
            })
            for (let x = 0; x < itensSave.length; x++) {
                let resp = await alterContractChip(itensSave[x])
            }
            handleCloseSidebar()
            reqListChipsSimple()
            deselectAll()
            addNotification('Alterar Status de chips', `${itensSave.length} Chips Atualizados com sucesso`, 'success');

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[handleRegisterChips]', error)
        }

    }


    return (
        <>
            <ContainerFlexInline>
                <input type="file" name="file" onChange={changeHandler} />
                {isFilePicked ? <>
                    <div>
                        <button onClick={handleSubmission}>Submit</button>
                    </div>

                </> : (
                    <p>Select a file to show details</p>
                )}
            </ContainerFlexInline>
            <ChipCardList categories={dataCategoryResume} onClick={handleFilter} />
            <SearchAndSelectContainer>
                <SearchField onSearch={handleSearch} />
                <ActionSelect
                    options={optionsActions}
                    onSelect={handleSelectAction}
                    title='Ação em Massa'
                />
            </SearchAndSelectContainer>
            <ContainerTable>
                {valueFilter.chipsDelete ?
                    <DataGridComp
                        rows={rowsToDelete}
                        columns={columnsToDelete}
                        selectedRows={selectedRows}
                        onRowSelect={handleRowSelect}
                    /> :
                    <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        onRowSelect={handleRowSelect}
                    />}
            </ContainerTable>

            <Sidebar isOpen={sideContent === REGISTER_CHIPS} onClose={handleCloseSidebar} title={'Cadastrar Chips'}>
                <SidebarImportChip
                    requireJustification={false}
                    isShow={sideContent === REGISTER_CHIPS}
                    type={sideContent ?? ''}
                    message="Cadastrar chips selecionados!"
                    onConfirm={handleRegisterChips}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === UPDATE_CHIPS} onClose={handleCloseSidebar} title={'Atualizar Chips'}>
                <SidebarImportChip
                    requireJustification={false}
                    isShow={sideContent === UPDATE_CHIPS}
                    type={sideContent ?? ''}
                    message="Atualizar chips selecionados!"
                    onConfirm={handleUpdateChips}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === SETDEL_CHIPS} onClose={handleCloseSidebar} title={'Status á deletar'}>
                <SidebarImportChip
                    requireJustification={false}
                    isShow={sideContent === SETDEL_CHIPS}
                    type={sideContent ?? ''}
                    message="Marcar chips para deletar! esse procedimento não deleta os chips, apenas marca para deletar"
                    onConfirm={handleDeleteChips}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
        </>
    )
}

export default ChipsInportsPage


