import React, { useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { styled } from 'styled-components'

interface iOptions {
    label: string
    value: string
}

interface Props {
    name: string
    label?: string
    searchTerm?: string
    options: iOptions[]
}

const InputContainer = styled.div`
  margin-bottom: 0px;
  padding: 0px;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 3px;
`;

const Input = styled.select`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  outline: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px orange;
  }  
`;

const SelectInput = styled.input`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  &:focus {
    outline: none;
    border-color: blue;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

type InputProps = JSX.IntrinsicElements['select'] & Props

export default function SelectFieldForm({ name, label, searchTerm, options, ...rest }: InputProps) {
    const inputRef = useRef<HTMLSelectElement>(null)
    /*const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<iOptions | null>(null);
    const [filteredOptions, setFilteredOptions] = useState<iOptions[]>(options);
    const selectRef = useRef<HTMLDivElement>(null);*/

    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])

    /*  useEffect(() => {
          function handleClickOutside(event: MouseEvent) {
              if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                  setIsOpen(false);
              }
          }
  
          document.addEventListener('mousedown', handleClickOutside);
          return () => document.removeEventListener('mousedown', handleClickOutside);
      }, [selectRef]);
  
      useEffect(() => {
          const newFilteredOptions = options.filter(option =>
              option.label.toLowerCase().includes(searchTerm?.toLowerCase() ?? '')
          );
          setFilteredOptions(newFilteredOptions);
  
          // Se não encontrarmos o item selecionado na lista filtrada, limparemos a seleção
          if (selectedOption && !newFilteredOptions.find(option => option.value === selectedOption.value)) {
              setSelectedOption(null);
          }
      }, [searchTerm, options, selectedOption]);*/

    return (
        <InputContainer>
            {label && <Label htmlFor={fieldName}>{label}</Label>}

            <Input
                id={fieldName}
                ref={inputRef}
                defaultValue={defaultValue}
                {...rest}
            >
                {options.map((item: iOptions) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </Input>

            {error && <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {error}
            </ErrorMessage>}
        </InputContainer>
    )
}