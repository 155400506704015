import { Dialog, DialogTitle, DialogContent, Stack, Box, Avatar } from '@mui/material';
import * as React from 'react';
import { DataGrid, GridColDef, GridToolbarQuickFilter, GridToolbarColumnsButton, GridToolbarDensitySelector, GridEventListener } from '@mui/x-data-grid';
import BarTable from './BarTable';
import { ctrlButtons, iVehicle } from '../interfaces/interfaces';
import http from '../services/http-common';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/userSlice';

interface DialogProps {
    open: boolean
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void
    onMultiSelect: boolean
    handleMultSelect?: (item: iVehicle[]) => void
    handleSelect?: (item: iVehicle) => void
}

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    SaveShow: true,
    CancelShow: true,
    SaveDisable: true
}


const VehiclesDialog: React.FC<DialogProps> = (props) => {


    const { user } = useSelector(selectUser);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [list, setList] = React.useState<iVehicle[]>([]);


    async function getDados() {
        const result = await http.post("/v2/getListVehicleByClient", {
            idclient: user.idcliente
        });
        let resp = result.data;
        setList(resp)
        console.log(resp)
    }

    const columns: GridColDef[] = [


        {
            field: 'id',
            headerName: 'Cód Veículo',
            width: 100,
        },
        /*{
            field: 'avatar',
            headerName: 'Avatar',
            width: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <Avatar
                            sx={{ width: 32, height: 32, bgcolor: deepOrange[500] }}
                            src={`http://smart.maxtracer.com.br/img/marker/${params.row.icon}.png`}
                        />
                    </>
                );
            }

        },*/
        {
            field: 'apelido',
            headerName: 'Apelido',
            width: 180
        },
        {
            field: 'marca',
            headerName: 'Marca',
            width: 250
        },
        {
            field: 'modelo',
            headerName: 'Modelo',
            width: 250,
        },
        {
            field: 'placa',
            headerName: 'Placa',
            width: 150
        }

    ];

    React.useEffect(() => {
        (async () => {
            if (props.open) {
                console.log("useEffect VehiclesDialog")
                CtrlBtns.SaveShow = props.onMultiSelect ? true : false
                CtrlBtns.SaveDisable = props.onMultiSelect ? false : true
                setCtrlBtns(CtrlBtns)
                if (user.idcliente) { getDados() } else { console.log("idCliente Invalid VehiclesDialog") }
            }
        })();
    }, [props.open]);

    const setSel = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
        CtrlBtns.SaveDisable = i.length ? false : true
        setCtrlBtns(CtrlBtns)
    }

    const SaveSel = () => {
        let itens: iVehicle[] = []
        for (let x = 0; x < selectdRows.length; x++) {
            let i = list.find(element => element.id === selectdRows[x])
            if (i) { itens.push(i) }
        }
        props.handleMultSelect?.(itens)
    }

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        if (!props.onMultiSelect) {
            props.handleSelect?.(params.row)
        }

    };


    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    height: 64,
                    padding: 1,
                    backgroundColor: '#ededed',
                }} >
                <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                    <GridToolbarColumnsButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarQuickFilter />
                    <BarTable CtrlButtons={CtrlBtns} onSaveClick={SaveSel} onCancelClick={props.onClose} />
                </Stack>
            </Box>
        );
    }


    return <>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800 } }}
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Veículos</DialogTitle>
            <DialogContent>
                <Box sx={{ height: '70vh', width: '100%', background: 'white' }}>

                    <DataGrid
                        rows={list}
                        columns={columns}
                        density="compact"


                        checkboxSelection={props.onMultiSelect}
                        /*disableRowSelectionOnClick*/

                        /*getRowHeight={() => 'auto'}*/
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSel(newSelectionModel)
                        }}
                        onRowClick={handleRowClick}
                        components={{ Toolbar: QuickSearchToolbar }}
                    />
                </Box>

            </DialogContent>
        </Dialog></>

}

export default VehiclesDialog