
import React, { } from 'react';
import styled from 'styled-components';
import AvatarCuston from '../../../components/AvatarCuston';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import ModalListTanks from '../../../Modals/ModalListTanks';



const Box = styled('div')({
    marginLeft: '1px',
    margin: '1px'
});


const Container = styled('div')({
    display: 'flex',
    padding: '2px',
    justifyContent: 'space-around',
    alignItems: 'center',
    //border: '1px solid #dbdbdb',
    borderRadius: '5px',
    maxWidth: '330px',
    minHeight: '50px',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '0px 0 15px rgb(1 41 112 / 15%)'
});

const ContainerInfo = styled('div')({
    display: 'block',
    padding: 5,
    minWidth: '270px'

});

const ContainerInline = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'

});

const TextApelido = styled('div')({
    fontSize: '10px',
    textTransform: 'uppercase'
});
const Title = styled('div')({
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px'
});
const Subtitle = styled('div')({
    fontSize: '10px',
    fontWeight: '600',
    textTransform: 'uppercase',
});

export interface iSupplier {
    identifier: string | number;
    type: string;
    descr: string;
    current_volume: number;
    color_fuel?: string;
}

interface iItemProps {
    supplier?: iSupplier
    updateItem: (supplier: iSupplier | undefined) => void
}

const SelectTanksSmall: React.FC<iItemProps> = ({ supplier, updateItem }) => {
    const [isOpenListVehicles, setIsOpenListVehicles] = React.useState(false);

    const handleSelect = (row: iSupplier) => {
        updateItem(row);
        setIsOpenListVehicles(false);
    }
    return (
        <>
            <Box>
                <TextApelido>Bomba Interna</TextApelido>
                {supplier?.identifier ?
                    <><Container onClick={() => { setIsOpenListVehicles(true) }}>
                        <AvatarCuston icon={faWarehouse} />
                        <ContainerInfo>
                            <Title>{supplier?.descr}</Title>
                        </ContainerInfo>
                    </Container>
                    </>
                    :
                    <Container onClick={() => { setIsOpenListVehicles(true) }}>
                        <AvatarCuston icon={faWarehouse} />
                        <ContainerInfo>
                            <Subtitle>Selecione uma Bomba</Subtitle>
                        </ContainerInfo>
                    </Container>
                }
            </Box>
            {isOpenListVehicles && <ModalListTanks
                onRequestClose={() => { setIsOpenListVehicles(false) }}
                isOpen={isOpenListVehicles}
                isMultiSelect={false}
                onSelectAlert={handleSelect} />}
        </>
    )

}
export default SelectTanksSmall