import { Column, SelectColumn } from 'react-data-grid';
import { iMsgLogs } from '../repository/MessagesRepo';

export const getColumnsClientes = (): Column<iClients>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        // Coluna para o ID
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Coluna para o Nome
        {
            key: 'name',
            name: 'Nome',
            width: 280,
            resizable: true,
        },
        // Coluna para o Telefone
        {
            key: 'phone1',
            name: 'Telefone',
            width: 150,
            resizable: true,
        },
    ];
};


export interface iClients {
    id: number;
    name: string;
    doc_number: string;
    phone1: string;
};