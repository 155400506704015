import React, { useState } from 'react';
import VideoCard from './components/VideoCard';
import VideoModal from './components/VideoModal';
import { httpAxiosV4 } from '../../gateways/Axios';
import NoVideosFound from './components/NoVideosFound';
import LoadingIndicatorVideo from './components/LoadingIndicatorVideo';

export type VideoData = {
    title: string;
    subtitle: string;
    descr: string | null;
    categ: string;
    tags: string | null;
    video_url: string;
    thumbnail_url: string;
};


const HelpPage: React.FC = () => {
    const [selectedVideo, setSelectedVideo] = useState<VideoData | null>(null);
    const [videos, setVideos] = useState<VideoData[]>([]);
    const [isLoading, setIsLoading] = useState(false);


    const fetchVideos = async (): Promise<VideoData[]> => {
        try {
            const { data } = await httpAxiosV4<any, VideoData[]>(
                `help-desktop`,
                "GET"
            );
            return data;
        } catch (error) {
            console.error('Erro ao buscar vídeos:', error);
            throw error;
        }
    };
    const loadVideos = async () => {
        try {
            setIsLoading(true);
            const fetchedVideos = await fetchVideos();
            setVideos(fetchedVideos);
        } catch (error) {
            console.error('Erro ao buscar vídeos:', error);
        } finally {
            setIsLoading(false);
        }
    };


    React.useEffect(() => {
        loadVideos();
    }, []);

    return (
        <div className="p-4 bg-gray-100" style={{ minHeight: 'calc(100vh - 65px)' }}>
            <div className="mb-4 flex justify-center items-center">
                <input
                    type="text"
                    placeholder="Qual sua dúvida?"
                    className="w-full md:w-1/2 p-2 border border-orange-300 rounded-md focus:outline-none focus:ring focus:border-orange-500 mx-auto block"
                />
                <button
                    onClick={loadVideos}
                    className="ml-4 bg-orange-500 hover:bg-orange-600 text-white p-2 rounded-md flex items-center"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    Atualizar
                </button>
            </div>
            {isLoading ? (
                <LoadingIndicatorVideo />
            ) : (
                <div className="flex flex-wrap justify-center">
                    {videos.length > 0 ? (
                        videos.map(video => (
                            <VideoCard
                                key={video.title}
                                video={video}
                                onClick={() => setSelectedVideo(video)}
                            />
                        ))
                    ) : (
                        <NoVideosFound />
                    )}
                </div>
            )}
            {selectedVideo && (
                <VideoModal
                    videoUrl={`${process.env.REACT_APP_HELPDESK}/videos/${selectedVideo.video_url}`}
                    title={selectedVideo.title}
                    onClose={() => setSelectedVideo(null)}
                />
            )}
        </div>
    );
};

export default HelpPage;