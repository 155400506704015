import React from 'react';
import ConfirmationDialog, { typeMethods } from '../../../components/datagridComp/ConfirmationDialog';
import { Column, SelectColumn } from 'react-data-grid';
import ModalComp from '../../../components/datagridComp/ModalComp';
import LoadingIndicator from '../../../components/datagridComp/LoadingComp';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import ButtonEdit from '../../../components/datagridComp/ButtonEdit';
import { PriorityItem, BtnCheckBox, ContainerBarTop, ContainerInfo, ContainerSearch, ContainerSpan, ContainerTable, GridContainer, InfoBox, SearchAndSelectContainer, PointsItem } from '../style/StyleExpenses';
import SearchField from '../../../components/datagridComp/SearchField';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import ColumnSelect from '../../../components/datagridComp/ColumnSelect';
import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import ButtonAdd from '../../../components/datagridComp/ButtonAdd';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { faTable, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iReduxMap } from '../../../interfaces/interfaces';
import { ActionMaps } from '../../../utils/values';
import { changeMap } from '../../../redux/mapSlice';
import { getExpenses, getExpensesByClient, ExpenseItemScreen, reqExpenses, alterStatus, alterVehicles, alterDrivers, deleteExpenses } from '../repository/ExpensesRepo';
import ExpensesCard from '../components/ExpenseCard';
import StatusChip from '../components/StyledChip';
import Sidebar from '../../../components/datagridComp/SideBar';
import SidebarEdit, { typeMethodsSide } from '../components/SidebarEdit';
import { useNotifications } from '../../../Notifications/NotificationContext';
import FilterExpensesCard from '../components/FilterExpensesCard';
import { get } from 'http';
import { Form } from 'react-router-dom';
import FormExpenses from './FormExpenses';


interface iModelCmd {
    onConfirm: (value?: any) => Promise<void> | void;
    message: string
    title?: string
    requireJustification: boolean
    type: typeMethods

}

interface iAlterStatus {
    ids: number[],
    newStatus: string,
    reason: string
}
interface iDeleteStatus {
    ids: number[],
    reason: string
}
interface iAlterObject {
    ids: number[],
    idParam: number,
    reason: string
}

const LOADING_CONTENT = 'loading'
const CONFIRMED_ACTION = 'confirm_action'
const JUSTIFIED_ACTION = 'justified_action'
const DETAILS_EXPENSES = 'DETAILS_EXPENSES'
const FILTER_EXPENSES = 'FILTER_EXPENSES'
const EDIT_EXPENSES = 'EDIT_EXPENSES'
const ALTERSTATUS = 'ALTERSTATUS'
const ALTERVEHICLE = 'ALTERVEHICLE'
const ALTERSUPPLIERS = 'ALTERSUPPLIERS'
const ALTERDRIVER = 'ALTERDRIVER'
const DELETE_EXPENSES = 'DELETE_EXPENSES'

const optionsActions = [
    { value: 'ALTERSTATUS', label: 'Alterar Status' },
    { value: 'ALTERVEHICLE', label: 'Alterar Veículo' },
    { value: 'ALTERDRIVER', label: 'Alterar Motorista' },
    { value: 'DELETE_EXPENSES', label: 'Apagar Despesas' },

];

let payloadReqExpenses: reqExpenses = {
    idclient: 0,
}

const ListExpensesPage: React.FC = () => {

    const { addNotification } = useNotifications();
    const dispatch = useDispatch();
    const { user } = useSelector(selectUser);
    const [isTableView, setViewTable] = React.useState(() => {
        const saved = localStorage.getItem('viewTableExpenses');
        return saved === null ? true : saved === 'true';
    });

    const [isShowForm, setIsShowForm] = React.useState(false);
    const [ExpenseEdit, setExpenseEdit] = React.useState<ExpenseItemScreen>();

    //modal
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const handleCloseModelCmd = () => setModelCmd(undefined);
    const [modalContent, setModalContent] = React.useState<string>('');
    const [modelCmd, setModelCmd] = React.useState<iModelCmd>();

    //sidebar
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const handleCloseSideBar = () => setSideContent(undefined);

    const [expenseSel, setExpenseSel] = React.useState<ExpenseItemScreen>();
    const [sideContent, setSideContent] = React.useState<string>();

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<ExpenseItemScreen[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<ExpenseItemScreen[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<ExpenseItemScreen>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));

    React.useEffect(() => {
        getDados()
    }, [])
    React.useEffect(() => {
        localStorage.setItem('viewTableExpenses', isTableView.toString());
    }, [isTableView]);




    const columns: Column<ExpenseItemScreen>[] = [
        {
            ...SelectColumn,
            name: 'Selecionar',
            width: 150,
        },

        {
            key: 'id',
            name: 'Cód',
            width: 80
        },

        {
            key: 'edit',
            name: 'Ações',
            width: 140,
            renderCell(props: any) {
                return (
                    <><BtnCheckBox onClick={() => viewInMap(props.row)}>📂 Abrir</BtnCheckBox>
                        <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox></>
                )
            }
        },
        {
            key: 'status',
            name: 'Status',
            width: 120,
            renderCell: (props: any) => (
                <StatusChip status={props.row.status} />
            )
        },
        {
            key: 'categories',
            name: 'Categoria',
            width: 150,
        },
        {
            key: 'amount',
            name: 'Valor',
            width: 120,
            renderCell(props: any) {
                return (
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.row.amount as number
                    ))
            }
        },
        {
            key: 'placa',
            name: 'Veículo',
            width: 150,
        },
        {
            key: 'drivername',
            name: 'Motorista',
            width: 150,
        },

        {
            key: 'evt_at',
            name: 'Data',
            width: 180,
            renderCell(props: any) {
                return new Date(props.row.evt_at).toLocaleString()
            },
        },
        {
            key: 'supplier_name',
            name: 'Fornecedor',
            width: 180
        },
        {
            key: 'descr',
            name: 'Descrição',
            width: 350,
            renderCell(props: any) {
                return props.row.descr.replace(/<[^>]*>?/gm, '');
            }
        },


    ];

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const handleEdit = (row: ExpenseItemScreen) => {
        // console.log(row)
    }
    const handleClickRow = (row: ExpenseItemScreen) => {
        // console.log(row)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }


    const setFilterExpenses = async (value: any) => {
        handleCloseSideBar()
        payloadReqExpenses.iddriver = value.selectDriver ? value.selectDriver.id : undefined
        payloadReqExpenses.idvehicle = value.selectVehicle ? value.selectVehicle.id : undefined
        //payloadReqExpenses.idcateg = value.selectCategory.id
        payloadReqExpenses.status = value.selectFilterStatus === 'TODOS' ? undefined : value.selectFilterStatus
        payloadReqExpenses.dtFilter = value.selectDate
        getDados()
    }

    const alterStatusExpenses = async (value: any) => {
        handleCloseSideBar()
        try {
            const payload: iAlterStatus = {
                ids: Array.from(selectedRows),
                reason: value.justification,
                newStatus: value.selectValue,
            }
            const result = await alterStatus(payload);
            deselectAll()
            addNotification('', 'Alteração realizada com sucesso!', 'success');
            getDados()
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[ListTasksDelivery]', error)
        }
    }
    const DeleteExpenses = async (value: any) => {
        try {
            const payload: iDeleteStatus = {
                ids: Array.from(selectedRows),
                reason: value.justification,
            }
            console.log(payload)
            const result = await deleteExpenses(payload);
            deselectAll()
            addNotification('', 'Despesas apagadas com sucesso', 'success');
            handleCloseSideBar()
            getDados()
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[ListTasksDelivery]', error)
        }
    }
    const alterVehiclesExpenses = async (value: any) => {
        handleCloseSideBar()
        try {
            const payload: iAlterObject = {
                ids: Array.from(selectedRows),
                reason: value.justification,
                idParam: value.selectVehicle.id,
            }
            const result = await alterVehicles(payload);
            getDados()
            deselectAll()
            addNotification('', 'Alteração realizada com sucesso!', 'success');
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[ListTasksDelivery]', error)
        }
    }
    const alterDriverExpenses = async (value: any) => {
        handleCloseSideBar()
        try {
            const payload: iAlterObject = {
                ids: Array.from(selectedRows),
                reason: value.justification,
                idParam: value.selectDriver.id,
            }
            const result = await alterDrivers(payload);
            deselectAll()
            getDados()
            addNotification('', 'Alteração realizada com sucesso!', 'success');
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('Error', error)
        }
    }

    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos um registro', 'warning');
            return;
        }
        switch (action) {
            case ALTERSTATUS:
                setIsSidebarOpen(true)
                setSideContent(ALTERSTATUS)
                break
            case ALTERVEHICLE:
                setIsSidebarOpen(true)
                setSideContent(ALTERVEHICLE)
                break
            case ALTERSUPPLIERS:
                setIsSidebarOpen(true)
                // setSideContent(ALTERSUPPLIERS)
                break
            case ALTERDRIVER:
                setIsSidebarOpen(true)
                setSideContent(ALTERDRIVER)
                break
            case DELETE_EXPENSES:
                setIsSidebarOpen(true)
                setSideContent(DELETE_EXPENSES)
                break
        }
    };

    async function getDados() {
        try {
            payloadReqExpenses.idclient = user.idcliente
            const result = await getExpensesByClient(payloadReqExpenses);
            setRows(result)
            setFilteredRows(result);
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }

    const handleSelectByCard = (id: number) => {
        setSelectedRows((prevSelectedRows) => {
            const newSet = new Set(prevSelectedRows);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((Expense) => Expense.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const viewInMap = (Expense: ExpenseItemScreen) => {

        //setIsSidebarOpen(true)
        setSideContent(DETAILS_EXPENSES)
        setExpenseSel(Expense)
    }
    const editItem = (Expense: ExpenseItemScreen) => {
        setExpenseEdit(Expense)
        setIsShowForm(true)
    }

    const handleCloseSidebar = () => {
        //setIsSidebarOpen(false);
        setSideContent(undefined);
    };

    const newRegister = () => {
    }

    return (
        <>
            {!isShowForm &&
                <>
                    <SearchAndSelectContainer>
                        <SearchField onSearch={handleSearch} />
                        <ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
                            title='Selecione a ação'
                        />
                        <ColumnSelect
                            columns={columns}
                            onColumnVisibilityChange={(visibleColumnKeys) => {
                                setVisibleColumns(visibleColumnKeys);
                            }}
                        />
                        <button onClick={() => setSideContent(FILTER_EXPENSES)} className="border border-gray-300 px-4 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500">
                            🗓 Filtros Avançados
                        </button>
                        <ButtonRefresh onClick={getDados} />
                        <ButtonAdd onClick={() => setIsShowForm(true)} />


                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <BtnCheckBox onClick={() => setViewTable(true)}>
                            <FontAwesomeIcon icon={faTable} /> Tabela
                        </BtnCheckBox>
                        <BtnCheckBox onClick={() => setViewTable(false)}>
                            <FontAwesomeIcon icon={faAddressCard} /> Card
                        </BtnCheckBox>
                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    {isTableView ?
                        <ContainerTable><DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            visibleColumns={visibleColumns}
                            onRowClick={handleClickRow}
                            onRowSelect={handleRowSelect}
                        /> </ContainerTable> :
                        <GridContainer>
                            {filteredRows.map((Expense) => (
                                <ExpensesCard
                                    key={Expense.id}
                                    Expense={Expense}
                                    onSelect={handleSelectByCard}
                                    editItem={editItem}
                                    isSelected={selectedRows.has(Expense.id)}
                                />
                            ))}
                        </GridContainer>}

                </>
            }
            {isShowForm && <FormExpenses initialData={ExpenseEdit} closeForm={() => {
                setIsShowForm(false)
                setExpenseEdit(undefined)
                getDados()
            }
            } />}
            {isModalOpen && (
                <ModalComp title="" subtitle="" onClose={handleClose}>
                    {modalContent === LOADING_CONTENT && <LoadingIndicator />}
                    {modalContent === CONFIRMED_ACTION && <ConfirmationDialog type='CONFIRMED' message="Operação realizada com sucesso!" onConfirm={() => handleClose()} onCancel={() => handleClose()} />}

                </ModalComp>
            )}
            <Sidebar isOpen={sideContent === FILTER_EXPENSES} onClose={handleCloseSidebar} title="Filtros Avançados">
                {<FilterExpensesCard
                    key={"filterExpense"}
                    onConfirm={setFilterExpenses}
                    onCancel={handleCloseSidebar}
                />} </Sidebar>
            <Sidebar isOpen={sideContent === DETAILS_EXPENSES} onClose={handleCloseSidebar} title={expenseSel?.descr}>
                {expenseSel && <ExpensesCard
                    key={expenseSel.id}
                    Expense={expenseSel}
                    editItem={editItem}
                    onSelect={handleSelectByCard}
                    isSelected={false}
                />} </Sidebar>
            <Sidebar isOpen={sideContent === ALTERSTATUS} onClose={handleCloseSidebar} title='Alterar Status'>
                <SidebarEdit
                    requireJustification={true}
                    isShow={sideContent === ALTERSTATUS}
                    type={sideContent as typeMethodsSide}
                    message="Alterar Status das Despesas selecionadas!"
                    onConfirm={alterStatusExpenses}
                    onCancel={() => handleCloseSideBar()}
                /></Sidebar>
            <Sidebar isOpen={sideContent === DELETE_EXPENSES} onClose={handleCloseSidebar} title='Apagar Despesas'>
                <SidebarEdit
                    requireJustification={true}
                    isShow={sideContent === DELETE_EXPENSES}
                    type={sideContent as typeMethodsSide}
                    message="Apagar as Despesas selecionadas!"
                    onConfirm={DeleteExpenses}
                    onCancel={() => handleCloseSideBar()}
                /></Sidebar>
            <Sidebar isOpen={sideContent === ALTERVEHICLE} onClose={handleCloseSidebar} title='Alterar Veículo'>
                <SidebarEdit
                    requireJustification={true}
                    isShow={sideContent === ALTERVEHICLE}
                    type={sideContent as typeMethodsSide}
                    message="Alterar Veículo das Despesas selecionadas!"
                    onConfirm={alterVehiclesExpenses}
                    onCancel={() => handleCloseSideBar()}
                /></Sidebar>
            <Sidebar isOpen={sideContent === ALTERDRIVER} onClose={handleCloseSidebar} title='Alterar Motorista'>
                <SidebarEdit
                    requireJustification={true}
                    isShow={sideContent === ALTERDRIVER}
                    type={sideContent as typeMethodsSide}
                    message="Alterar Motorista das Despesas selecionadas!"
                    onConfirm={alterDriverExpenses}
                    onCancel={() => handleCloseSideBar()}
                /></Sidebar>
            {/*<Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} >
                {sideContent === DETAILS_EXPENSES && expenseSel && <ExpensesCard
                    key={expenseSel.id}
                    Expense={expenseSel}
                    onSelect={handleSelectByCard}
                    isSelected={false}
                />}
                {sideContent === ALTERSTATUS && <SidebarEdit
                    requireJustification={true}
                    type={sideContent}
                    message="Alterar Status das Despesas selecionadas!"
                    onConfirm={alterStatusExpenses}
                    onCancel={() => handleCloseSideBar()}
                />}
                {sideContent === ALTERVEHICLE && <SidebarEdit
                    requireJustification={true}
                    type={sideContent}
                    message="Alterar Veículo das Despesas selecionadas!"
                    onConfirm={alterVehiclesExpenses}
                    onCancel={() => handleCloseSideBar()}
                />}
                {sideContent === ALTERDRIVER && <SidebarEdit
                    requireJustification={true}
                    type={sideContent}
                    message="Alterar Motorista das Despesas selecionadas!"
                    onConfirm={alterDriverExpenses}
                    onCancel={() => handleCloseSideBar()}
                />}
            </Sidebar>*/}
        </>
    )

}
export default ListExpensesPage