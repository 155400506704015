import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTimes,
    faLock,
    faCog,
    faRoute,
    faStreetView,
    faMapMarkedAlt,
    faShareSquare,
    faSatelliteDish,
    faSyncAlt,
    faBroom
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../components/datagridComp/Tooltip';
import { MapConfig } from '../../utilsMap/mapContext';

library.add(faTimes, faLock, faCog, faRoute, faStreetView, faMapMarkedAlt, faShareSquare, faSatelliteDish, faSyncAlt, faBroom);

const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Espaçamento entre os botões */
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }

  .fa-icon {
    font-size: 24px;
    color: #7f7f7f;
  }
`;



const ToolBarIconsButtonSettings: React.FC = () => {

    const { updateConfig } = MapConfig();
    const onIconClick = (action: string) => {
        updateConfig({ actionPressButton: action });
    };
    return (
        <IconButtonContainer>

        </IconButtonContainer>
    );
};


export default ToolBarIconsButtonSettings;
