import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faStopwatch, faRoad, faTachometerFast, faTruck } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../components/datagridComp/Tooltip';
// Import do componente Tooltip

export interface MetricsProps {
  averageSpeed: number;
  maxSpeed: number;
  totalStoppedTime: number;
  totalMovingTime: number;
  totalDistance: number;
}

const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-around;
  position: absolute;
    top: 112px;
    z-index: 10;
    left: 415px;
`;

const MetricItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  min-width: 90px;
  justify-content: space-between;
  color: #414141;  // Cor cinza clara
  background-color: #f9f9f9;  // Cor cinza clara
`;

const MetricValue = styled.div`
  font-size: 0.8rem;  // Reduzindo o tamanho da fonte
  font-weight: bold;
  margin-top: 5px;
`;

const MetricsDisplay: React.FC<MetricsProps> = ({
  averageSpeed,
  maxSpeed,
  totalStoppedTime,
  totalMovingTime,
  totalDistance
}) => {

  // Helper function to convert time from seconds to HH:MM:SS format
  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hrs}h ${mins}m ${secs}s`;
  };

  return (
    <MetricsContainer>
      <Tooltip text="Velocidade Média">
        <MetricItem>
          <FontAwesomeIcon icon={faTachometerAlt} size="1x" />
          <MetricValue>{averageSpeed.toFixed(2)} km/h</MetricValue>
        </MetricItem>
      </Tooltip>

      <Tooltip text="Velocidade Máxima">
        <MetricItem>
          <FontAwesomeIcon icon={faTachometerFast} size="1x" />
          <MetricValue>{maxSpeed} km/h</MetricValue>
        </MetricItem>
      </Tooltip>

      <Tooltip text="Tempo Parado">
        <MetricItem>
          <FontAwesomeIcon icon={faStopwatch} size="1x" />
          <MetricValue>{formatTime(totalStoppedTime)}</MetricValue>
        </MetricItem>
      </Tooltip>

      <Tooltip text="Tempo em Movimento">
        <MetricItem>
          <FontAwesomeIcon icon={faTruck} size="1x" />
          <MetricValue>{formatTime(totalMovingTime)}</MetricValue>
        </MetricItem>
      </Tooltip>

      <Tooltip text="Distância Percorrida">
        <MetricItem>
          <FontAwesomeIcon icon={faRoad} size="1x" />
          <MetricValue>{totalDistance.toFixed(2)} km</MetricValue>
        </MetricItem>
      </Tooltip>
    </MetricsContainer>
  );
};

export default MetricsDisplay;
