import React from 'react';
import SearchField from '../../../components/datagridComp/SearchField';
import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import DataGrid, { CellClickArgs, Column, SortColumn } from 'react-data-grid';
import { getComparator, renderCheckbox, renderSortStatus, rowKeyGetter } from '../../../utils/datagridTools';
import { httpAxiosV4 } from '../../../gateways/Axios';
import "../../../styles/datagrid/datagrid.css"

export interface IEquipList {
    iddisp: number;
    equip_imei: string;
    equip_status: string;
    fab_nome: string;
    mod_ref: string;
    cliente_name: string | null;
    veiculo_placa: string | null;
    idveiculo: number;
    idcliente: number;
}


const columns: Column<IEquipList>[] = [
    {
        key: 'iddisp',
        name: 'ID',
        width: 80,
    },
    {
        key: 'equip_imei',
        name: 'IMEI',
        width: 180,
    },
    {
        key: 'equip_status',
        name: 'Status',
        width: 120,
    },
    {
        key: 'fab_nome',
        name: 'Fabricante',
        width: 150,
    },
    {
        key: 'mod_ref',
        name: 'Modelo',
        width: 120,
    },
    {
        key: 'cliente_name',
        name: 'Cliente',
        width: 200,
    },
    {
        key: 'veiculo_placa',
        name: 'Placa do Veículo',
        width: 120,
    },
];

interface ChipEquipSelectProps {
    onRowSelect?: (row: IEquipList) => void;
}

const ChipEquipSelect: React.FC<ChipEquipSelectProps> = ({ onRowSelect }) => {

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);
    const [rows, setRows] = React.useState<IEquipList[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<IEquipList[]>(rows);



    async function getData() {
        try {
            const { data } = await httpAxiosV4<unknown, IEquipList[]>(
                `chip-noequip`,
                "GET"
            );
            setRows(data)
            setFilteredRows(data)

        } catch (e) {
        }
    }

    React.useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);


    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const sortedRows = React.useMemo((): readonly IEquipList[] => {
        return [...filteredRows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator<IEquipList>(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [filteredRows, sortColumns]);

    const handleCellClick = React.useCallback((args: CellClickArgs<IEquipList, unknown>) => {
        const row = args.row;
        onRowSelect?.(row);
    }, [onRowSelect]);

    return (
        <div className="p-6 h-[calc(100vh-210px)] overflow-auto">

            <div>
                <div className="flex items-center gap-4">
                    <SearchField onSearch={handleSearch} />
                    <ButtonRefresh onClick={() => getData()} />
                </div>
            </div>
            <div className="flex flex-col box-border p-8">
                <DataGrid
                    columns={columns}
                    rows={sortedRows}
                    rowKeyGetter={rowKeyGetter}
                    onRowsChange={setRows}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    selectedRows={selectedRows}
                    renderers={{ renderSortStatus }}
                    onCellClick={handleCellClick}
                    defaultColumnOptions={{
                        resizable: true,
                        sortable: true
                    }}
                    className='DatagridClass'
                />
            </div>
        </div>
    );
};

export default ChipEquipSelect;
