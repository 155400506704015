
import React, { useState } from 'react';
import ButtonReportCard from '../components/ButtonReportComp';
import { FaRoute, FaBell, FaHistory, FaCar } from 'react-icons/fa';

import styled from 'styled-components';
import DateFilterReport from '../components/DateFilterReport';
import { ReportConfig } from '../utilsMap/ReportContext';
import ButtonGroupMode from '../components/Report/ButtonGroupMode';
import { getVehiclesToExpense } from '../../ExpensesPage/repository/ExpensesRepo';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { formatDateDayJs } from '../../../utils/Converter';
import { getReportEvents, getReportHistory, getReportStops, getReportSummary, openReportInNewAba, openReportInNewAbaExcel } from '../../Repoter/repository/ReportRepo';
import ModalListVehicles from '../../../Modals/ModalListVehicles';
import CardVehicleViewWidget from '../../../components/Widgets/CardVehicleViewWidget';
import { iVehicleStorage } from '../../../Config/StorageRepository';

const Container = styled.div`
display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 5px;  
    background-color: #f5f5f5;
    padding: 5px;
    width: 100%;
  height: 87vh;
    `;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 10px;  
  box-sizing: border-box;
`;
const FullWidthCard = styled(ButtonReportCard) <{ selected: boolean }>`
  width: 100%;
  max-width: 300px;
  flex: 1;
  border: ${(props) => (props.selected ? '2px solid #00c853' : '1px solid #e5e5e5')};
  box-shadow: ${(props) => (props.selected ? '0 4px 8px rgba(0, 200, 83, 0.3)' : 'none')};
`;

interface Option {
    value: string;
    label: string;
}

let loadingTrajects = false;

const TabReports: React.FC = () => {
    const { dateStartFilter, dateEndFilter, filterSelect, updateConfig } = ReportConfig();
    const [selectedCard, setSelectedCard] = useState<string | null>(null);
    const [vehicle, setVehicle] = React.useState<iVehicleStorage>();

    const { addNotification } = useNotifications();

    const handleCardSelect = (title: string) => {
        if (selectedCard === title) {
            setSelectedCard(null); // Desseleciona se já estiver selecionado
        } else {
            setSelectedCard(title); // Seleciona o card clicado
        }
    };

    async function createReportStops(mode: string) {
        console.log('createReportStops')
        if (loadingTrajects) return;
        console.log('getTrajetos loanding...')
        loadingTrajects = true;
        const dtIni = formatDateDayJs(dateStartFilter)
        const dtFim = formatDateDayJs(dateEndFilter)
        const payload = {
            dtIni,
            dtFim,
            idveiculo: vehicle?.id,
            mode,
            idclient: vehicle?.idclient
        };
        try {
            const result = await getReportStops(payload);
            console.log('result', result)
            if (mode === 'pdf') openReportInNewAba(result);
        } catch (error) {
            console.error('Erro ao gerar relatório:', error);
        }
        loadingTrajects = false;
    }
    async function createReportHistory(mode: string) {
        console.log('createReportStops')
        if (loadingTrajects) return;
        console.log('getTrajetos loanding...')
        loadingTrajects = true;
        const dtIni = formatDateDayJs(dateStartFilter)
        const dtFim = formatDateDayJs(dateEndFilter)
        const payload = {
            dtIni,
            dtFim,
            idveiculo: vehicle?.id,
            mode
        };
        try {
            const result = await getReportHistory(payload);
            openReportInNewAba(result);
        } catch (error) {
            console.error('Erro ao gerar relatório:', error);
        }
        loadingTrajects = false;
    }

    async function createReportEvents(mode: string) {
        console.log('createReportEvents')
        if (loadingTrajects) return;
        console.log('getTrajetos loanding...')
        loadingTrajects = true;
        const dtIni = formatDateDayJs(dateStartFilter)
        const dtFim = formatDateDayJs(dateEndFilter)
        const payload = {
            dtIni,
            dtFim,
            idveiculo: vehicle?.id,
            mode,
            idclient: vehicle?.idclient
        };
        try {
            const result = await getReportEvents(payload);
            console.log('result', result)
            if (mode === 'pdf') openReportInNewAba(result);
        } catch (error) {
            console.error('Erro ao gerar relatório:', error);
        }
        loadingTrajects = false;
    }

    async function createReportSumary(mode: string) {
        console.log('createReportSumary')
        if (loadingTrajects) return;
        console.log('getTrajetos loanding...')
        loadingTrajects = true;
        const dtIni = formatDateDayJs(dateStartFilter)
        const dtFim = formatDateDayJs(dateEndFilter)
        const payload = {
            dtIni,
            dtFim,
            idveiculo: vehicle?.id,
            mode,
            idclient: vehicle?.idclient
        };
        try {
            const result = await getReportSummary(payload);
            console.log('result', result)
            if (mode === 'pdf') openReportInNewAba(result);
            if (mode === 'excel') openReportInNewAbaExcel(result);
        } catch (error) {
            console.error('Erro ao gerar relatório:', error);
        }
        loadingTrajects = false;
    }

    const handleGenerateReport = (mode: string) => {
        if (vehicle === undefined) {
            addNotification(
                'Selecione um veículo',
                'Selecione um veículo para gerar o relatório',
                'warning',
            );
            return;
        }
        switch (selectedCard) {
            case 'ROTAS':
                createReportStops(mode);
                break;
            case 'HISTÓRICO':
                createReportHistory(mode);
                break;
            case 'EVENTOS':
                createReportEvents(mode);
                break;
            case 'SUMARIO':
                createReportSumary(mode);
                break;
            default:
                break;
        }
    };
    return (
        <>
            <Container>
                <DateFilterReport />

                {/* <SelectFieldForm
                    name={'id_veiculo'}
                    options={vehicles}
                    label={'Selecione um Veículo'}
                    onChange={(e) => {
                        console.log(e);
                    }}
                /> */}
                <ButtonContainer>
                    <FullWidthCard
                        title="ROTAS"
                        Icon={FaRoute}
                        isNew={false}
                        selected={selectedCard === 'ROTAS'}
                        onClick={() => handleCardSelect('ROTAS')}
                    />
                    <FullWidthCard
                        title="SUMARIO"
                        Icon={FaCar}
                        isNew={false}
                        selected={selectedCard === 'SUMARIO'}
                        onClick={() => handleCardSelect('SUMARIO')}
                    />
                    <FullWidthCard
                        title="EVENTOS"
                        Icon={FaBell}
                        isNew={false}
                        selected={selectedCard === 'EVENTOS'}
                        onClick={() => handleCardSelect('EVENTOS')}
                    />
                    <FullWidthCard
                        title="HISTÓRICO"
                        Icon={FaHistory}
                        isNew={false}
                        selected={selectedCard === 'HISTÓRICO'}
                        onClick={() => handleCardSelect('HISTÓRICO')}
                    />
                </ButtonContainer>
                <ButtonGroupMode onGenerateReport={handleGenerateReport} />
                <CardVehicleViewWidget
                    vehicle={vehicle}
                    setVehicle={setVehicle}
                />
            </Container>

        </>
    );
};

export default TabReports;