import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'
import VideoPlaceholder from '../widgets/VideoPlaceholder';
import { styled } from 'styled-components';

export interface actionStream {
  channel: string
  action: string
}

export interface iStreamH264 {
  streamUrl: string;
  channelName: string;
  channel: string;
  uniqueKey: string;
  isPlaying: boolean;
}


export interface StreamH264Props {
  streamUrl: string;
  channelName: string;
  channel: string;
  onClickAction?: (arg0: actionStream) => void;
  width?: number | string;
  height?: number | string;
}

const Container = styled.div`
  display: flex;

`

const StreamH264Player: React.FC<StreamH264Props> = (
  { onClickAction,
    streamUrl,
    width = '400px', height = '240px',
    channelName, channel,
  }) => {
  //const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [numErros, setNumErros] = useState(0);
  //const playerRef = useRef<H264Player>(null);




  /*
    useEffect(() => {
      let timerId: NodeJS.Timeout;
  
      const checkStreamAvailability = async () => {
        try {
          console.log("checkStreamAvailability", streamUrl)
          const response = await fetch(streamUrl);
          if (!response.ok) {
            throw new Error('Stream indisponível');
          }
          console.log("streamok", streamUrl)
          setIsLoading(false);
          setIsError(false);
        } catch (error) {
          console.error('Erro ao verificar stream:', error);
          setIsError(true);
          setIsLoading(false);
        }
      }
      console.log("useEffect", streamUrl)
  
      //checkStreamAvailability(); // Verifica imediatamente ao montar o componente
  
      //timerId = setInterval(checkStreamAvailability, 5000); // Verifica a cada 5 segundos
  
      return () => {
        //clearInterval(timerId); // Limpa o timer ao desmontar o componente
      };
    }, [streamUrl]);*/

  const handlePlayerError = (error: Error) => {
    //console.error('Erro ao reproduzir stream:', error, channelName, streamUrl);
    setNumErros(numErros + 1);
    /* onClickAction?.({
       channel: channel,
       action: 'OFF'
     })*/
  };
  const handlePlayeroK = (sync: any) => {
    setIsError(false);
    setNumErros(0);
    //console.log("handlePlayeroK", sync)
    onClickAction?.({
      channel: channel,
      action: 'ON'
    })
  };

  React.useEffect(() => {
    //console.log("numErros", numErros, channelName)
    if (numErros > 3) {
      setIsError(true);
      onClickAction?.({
        channel: channel,
        action: 'OFF'
      })
    }
  }, [numErros])

  const OpenStreamHandle = () => {
    onClickAction?.({
      channel: channel,
      action: 'ON'
    })
  }
  const CloseStreamHandle = () => {
    onClickAction?.({
      channel: channel,
      action: 'OFF'
    })
  }

  return (
    <div style={{ width, height }}>
      {isError ? (
        <VideoPlaceholder
          width={width}
          height={height}
        />
      ) : (
        <Container>
          <ReactPlayer
            url={streamUrl}
            width={width}
            height={height}
            loop={false}
            controls={true}
            playing={true}
            onError={handlePlayerError}
            onProgress={handlePlayeroK}
          />
        </Container>
      )}

    </div>
  );
};

export default StreamH264Player;
