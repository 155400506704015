import { createSlice } from '@reduxjs/toolkit'
import { iUser } from '../interfaces/interfaces'
import { RootState } from './store'




export const slice = createSlice({
    name: 'auth',
    initialState: {} as iUser,
    reducers: {
        changeUser(state, { payload }) {
            payload.isAuthenticated = true
            return { ...state, ...payload }

        },
        logout(state) {
            return { ...state, ...{ isAuthenticated: false } }
        }
    }
})

export const { changeUser, logout } = slice.actions

export const selectUser = (state: RootState) => state

export default slice.reducer