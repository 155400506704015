import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import StreamH264Player from './StreamH264Player';


interface DialogProps {
    open: boolean
    videoFile: string
    handleClose: () => void;
}

const DialogStreamEvt: React.FC<DialogProps> = ({ videoFile, open, handleClose }) => {
    const videoUrl = `https://dvr.maxtracer.com.br/playiot/${videoFile}`;

    console.log('videoUrl', videoUrl)
    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 750, maxHeight: 600 } }}
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {videoFile}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <StreamH264Player
                        key={videoFile} // Usa o nome do arquivo como chave
                        channelName={''}
                        channel={''}
                        streamUrl={videoUrl}
                        width={700}
                        height={500}
                        onClickAction={() => console.log('click', videoUrl)}
                    />
                </DialogContent>
            </Dialog>
        </>
    );

}

export default DialogStreamEvt