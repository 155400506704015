
import React, { } from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import { iVehicleStorage } from '../../../Config/StorageRepository';
import ModalListVehicles from '../../../Modals/ModalListVehicles';
import AvatarCuston from '../../../components/AvatarCuston';
import { faTruck } from '@fortawesome/free-solid-svg-icons';



const Box = styled('div')({
    marginLeft: '1px',
    margin: '1px'
});


const Container = styled('div')({
    display: 'flex',
    padding: '2px',
    justifyContent: 'space-around',
    alignItems: 'center',
    //border: '1px solid #dbdbdb',
    borderRadius: '5px',
    maxWidth: '330px',
    minHeight: '50px',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '0px 0 15px rgb(1 41 112 / 15%)'
});

const ContainerInfo = styled('div')({
    display: 'block',
    padding: 5,
    minWidth: '270px'

});

const ContainerInline = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'

});

const TextApelido = styled('div')({
    fontSize: '10px',
    textTransform: 'uppercase'
});
const Title = styled('div')({
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px'
});
const Subtitle = styled('div')({
    fontSize: '10px',
    fontWeight: '600',
    textTransform: 'uppercase',
});

interface iPropsvehicle {
    vehicle?: iVehicleStorage
    updateItem: (vehicle: iVehicleStorage | undefined) => void
}

const SelectVehiclesSmall: React.FC<iPropsvehicle> = ({ vehicle, updateItem }) => {
    const [isOpenListVehicles, setIsOpenListVehicles] = React.useState(false);

    const handleSelectVehicle = (row: any) => {
        updateItem(row);
        setIsOpenListVehicles(false);
    }
    return (
        <>
            <Box>
                <TextApelido>Veículo</TextApelido>
                {vehicle?.id ?
                    <><Container onClick={() => { setIsOpenListVehicles(true) }}>
                        <AvatarCuston icon={faTruck} />
                        <ContainerInfo>
                            <TextApelido>{vehicle?.manufacturer} - {vehicle?.model}</TextApelido>
                            <Title>{vehicle?.placa}</Title>
                            <ContainerInline>
                                <Subtitle>{vehicle?.odometer_vehicle ?? 0} KM</Subtitle>
                                <Subtitle>Cód: {vehicle?.id}</Subtitle>
                            </ContainerInline>
                        </ContainerInfo>
                    </Container>
                    </>
                    :
                    <Container onClick={() => { setIsOpenListVehicles(true) }}>
                        <AvatarCuston icon={faTruck} />
                        <ContainerInfo>
                            <Subtitle>Selecione um veículo</Subtitle>
                        </ContainerInfo>
                    </Container>
                }
            </Box>
            {isOpenListVehicles && <ModalListVehicles
                onRequestClose={() => { setIsOpenListVehicles(false) }}
                isOpen={isOpenListVehicles}
                isMultiSelect={false}
                onSelectAlert={handleSelectVehicle} />}
        </>
    )

}
export default SelectVehiclesSmall