import React from 'react';

interface Option {
    value: string;
    label: string;
}

interface SelectFormInputProps {
    options: Option[];
    onSelect: (action: string) => void;
    title: string;
    selected: string;
}

const SelectFormInput: React.FC<SelectFormInputProps> = ({ options, onSelect, title, selected }) => {

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onSelect(event.target.value);
    };

    return (
        <select
            value={selected}
            onChange={handleSelectChange}
            className="border border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
        >
            <option value="" disabled selected>{title}</option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
            ))}
        </select>
    );
};

export default SelectFormInput;
