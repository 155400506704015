// src/services/GeocoderService.ts

export interface iRespGeocoder {
    address: string;
    position: google.maps.LatLng;
}

export const GeocoderService = async (param: string | google.maps.LatLng | null): Promise<iRespGeocoder | undefined> => {
    try {
        let obj = typeof param === 'string' ? { address: param } : param instanceof google.maps.LatLng ? { location: param } : null;

        if (!obj) {
            console.log("Params mal formatado");
            return;
        }
        const geocoder = new google.maps.Geocoder();
        let { results } = await geocoder.geocode(obj);

        if (!results || !results[0]) {
            console.log("Nenhum resultado encontrado");
            return;
        }

        let address = results[0].formatted_address;
        let location = results[0].geometry.location;
        let position = new google.maps.LatLng(location.lat(), location.lng());
        let resp: iRespGeocoder = {
            address,
            position: position
        };

        return resp;
    } catch (error) {
        console.log(error);
        return;
    }
};
