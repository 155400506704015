export const copyOptions = [
    { value: '', label: 'Copiar dados', disabled: true },
    /*cliente*/
    { value: '{cliente_nome}', label: 'Nome do Cliente' },
    { value: '{cliente_address}', label: 'Endereço do Cliente' },
    { value: '{cliente_tel1}', label: 'Telefone do Cliente' },
    { value: '{cliente_email}', label: 'Email do Cliente' },
    { value: '{cliente_cnpj}', label: 'CNPJ do Cliente' },
    /*empresa*/
    { value: '{empresa_nome}', label: 'Nome da Empresa' },
    { value: '{empresa_email}', label: 'Email da Empresa' },
    { value: '{empresa_tel1}', label: 'Telefone da Empresa' },
    { value: '{empresa_cnpj}', label: 'CNPJ da Empresa' },
    { value: '{empresa_address}', label: 'Endereço da Empresa' },
    /*veiculo*/
    { value: '{veiculo_marca}', label: 'Marca do Veículo' },
    { value: '{veiculo_modelo}', label: 'Modelo do Veículo' },
    { value: '{veiculo_ano}', label: 'Ano do Veículo' },
    { value: '{veiculo_placa}', label: 'Placa do Veículo' },
    { value: '{veiculo_cor}', label: 'Cor do Veículo' },
    { value: '{veiculo_km}', label: 'Km do Veículo' },
    { value: '{veiculo_chassi}', label: 'Chassi do Veículo' },
];

export const saveFormatOptions = [
    { value: 'WHATSAPP', label: 'Salvar para WhatsApp' },
    { value: 'SMS', label: 'Salvar para SMS' },
    { value: 'PDF', label: 'Salvar para PDF' },
];

export const WHATSAPP = 'WHATSAPP'