

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';

import { useSelector } from 'react-redux';
import { selectUser } from '../redux/userSlice';

const CenterComp = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    height: '92vh',
    fontSize: '0.6em'
});


interface CompProps {
    location: string
}

const AngularPageComp: React.FC<CompProps> = (props) => {

    const { user } = useSelector(selectUser);
    let obj = JSON.stringify(user)
    let url = encodeURI(`${process.env.REACT_APP_SMARTWEB_V1}/cadastro.html#!/${props.location}?iduser=${user.iduser}&nivel=${user.nivel}&idcliente=${user.idcliente}&idempresa=${user.idempresa}&idgroup=${user.idgroup}&name=${user.name}&login=${user.login}&host=${user.host}&empresa_avatar=${user.empresa_avatar}&fantasia=${user.fantasia}`)
    //console.log(url)
    return (
        <>
            <CenterComp >
                <iframe
                    src={url} width={'100%'} height={'100%'} title="angularjsapp"></iframe>
            </CenterComp>
        </>
    )

}
export default AngularPageComp