import { httpAxiosV4 } from "../../../gateways/Axios";
import httpApiV3 from "../../../services/http-xsmart-apiv3";
import { itemDriver } from "../Components/ViewListDrivers";




export async function getListDrivers(payload: any): Promise<itemDriver[]> {
    const result = await httpApiV3.post("/ListDrivers", payload);
    return result.data;
}
export async function sendDeleteDriver(payload: any): Promise<any> {
    const result = await httpApiV3.post("/delete-driver", payload);
    return result.data;
}


export async function getListDriversV4(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `drivers`,
        "GET"
    );
    return data;
}

/*export async function getListDriversV4(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `drivers`,
        "GET"
    );
    return data;
}*/


