import React, { useState } from 'react';
import SelectFormInput from '../../../components/datagridComp/SelectFormInput';
import { styled } from 'styled-components';
import { MdSave } from 'react-icons/md';
import { start } from 'repl';
import { DatePicker } from '@mui/x-date-pickers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';



interface IOption {
    value: string;
    label: string;
}

export interface dtFilter {
    dtStart?: string;
    dtEnd?: string;
    lastreg: number;
}

// Tipos para props e para o objeto de datas
type DateFilterProps = {
    onDateChange: (dates: dtFilter) => void;
};

const optionsStatus: IOption[] = [
    { value: 'M0', label: 'Mês Atual' },
    { value: 'M1', label: 'Mês Anterior' },
    { value: '6', label: 'Últimos 7 Dias' },
    { value: '29', label: 'Últimos 30 Dias' },
    { value: '89', label: 'Últimos 90 Dias' },
    { value: 'A1', label: 'Ano Passado' },
    { value: 'A0', label: 'Ano Corrente' },
    { value: 'intervalo', label: 'Intervalo Personalizado' },

];

const Container = styled.div`
  margin-top: 10px;
`;
const ContainerInline = styled.div`
display: flex;
justify-content: center;
    align-items: start;
    gap: 10px;
`;


const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
`;

const CustomInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const IconButton = styled.button`
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-top: 10px;

  &:hover {
    background: #f0f0f0;  // Efeito de hover para melhor feedback visual
  }

  svg {
    margin-left: 5px;  // Espaço entre o texto e o ícone
  }
`;

const ErrorMessage = styled.div`
  color: red;
    font-size: 16px;
    margin: 5px;
    font-weight: 700;
    display: flex;
    justify-content: center;
`;

const InfoMessage = styled.div`
  color: gray;
    font-size: 16px;
    margin: 5px;
    font-weight: 600;
    display: flex;
    justify-content: left;
`;

const ContainerInlinePicker = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;
    margin-bottom: 10px;
    max-width: 350px;
    `;



const DashboardExpensesPage: React.FC<DateFilterProps> = ({ onDateChange }) => {
    const [startDate, setStartDate] = useState<Dayjs | null>();
    const [endDate, setEndDate] = useState<Dayjs | null>();
    const [selectValue, setSelectValue] = React.useState<string>('M0');
    const [error, setError] = useState<string>('');

    const handleDateChange = (value: any) => {
        setSelectValue(value);
        if (value === 'intervalo') {
            return;
        }

        let start = new Date();
        let end = new Date();

        switch (value) {
            case 'M0':
                start.setDate(1);
                start.setMonth(start.getMonth());
                end.setDate(0);
                end.setMonth(end.getMonth() + 1);
                break;
            case 'M1':
                start.setDate(1);
                start.setMonth(start.getMonth() - 1);
                end.setDate(0);
                end.setMonth(end.getMonth());
                break;
            case 'A0':
                start.setDate(1);
                start.setMonth(0);
                end.setDate(31);
                end.setMonth(11);
                break;
            case 'A1':
                start.setDate(1);
                start.setMonth(0);
                start.setFullYear(start.getFullYear() - 1);
                end.setDate(31);
                end.setMonth(11);
                end.setFullYear(end.getFullYear() - 1);
                break;
            default:
                start.setDate(start.getDate() - Number(value));
                break;
        }





        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        setStartDate(dayjs(start));
        setEndDate(dayjs(end));


    };

    const formatDate = (date: Dayjs): string => {
        return `${date.year()}-${('0' + (date.month() + 1)).slice(-2)}-${('0' + date.date()).slice(-2)} ${('0' + date.hour()).slice(-2)}:${('0' + date.minute()).slice(-2)}:${('0' + date.second()).slice(-2)}`;
    };


    React.useEffect(() => {
        handleCustomDateSet();
    }, [startDate, endDate]);

    React.useEffect(() => {
        handleDateChange('89');
    }, []);

    const handleCustomDateSet = () => {
        if (!startDate || !endDate) {
            setError('Data inválida');
            return;
        }

        if (startDate.isAfter(endDate)) {
            setError('Data inicial maior que a final');
            return;
        }

        setError('');
        const formattedStart = formatDate(startDate);
        const formattedEnd = formatDate(endDate);
        console.log(formattedStart, formattedEnd);
        onDateChange({ dtStart: formattedStart, dtEnd: formattedEnd, lastreg: 0 });
    };

    const handleStartDateChange = (value: Dayjs | null) => {
        setStartDate(value);
        setSelectValue('intervalo')
    }

    const handleEndDateChange = (value: Dayjs | null) => {
        setEndDate(value);
        setSelectValue('intervalo')
    }

    return (
        <Container>
            <ContainerInline >
                <FormControl sx={{ m: 0, width: 280 }}>
                    <InputLabel id="demo-multiple-chip-label">Selecione o período</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        value={selectValue}
                        onChange={(e) => handleDateChange(e.target.value)}
                        label="Selecione o período"
                    >
                        {optionsStatus.map((item) => (
                            <MenuItem
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <ContainerInlinePicker>
                    <DatePicker
                        value={startDate}
                        label="Data Inicial"
                        onChange={(newValue) => handleStartDateChange(newValue)}
                        format='DD/MM/YYYY'
                    />
                    <DatePicker
                        value={endDate}
                        label="Data Final"
                        onChange={(newValue) => handleEndDateChange(newValue)}
                        format='DD/MM/YYYY'
                    />
                </ContainerInlinePicker>
            </ContainerInline>
        </Container>
    );
};

export default DashboardExpensesPage;
