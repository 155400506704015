import styled from 'styled-components';
import { useField } from '@unform/core'
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const InputContainer = styled.div`
  margin-bottom: 0px;
  padding: 0px;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 3px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  outline: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px orange;
  }  
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;


interface Props {
  name: string
  label?: string
}

type InputProps = JSX.IntrinsicElements['input'] & Props

export default function InputFieldForm({ name, label, ...rest }: InputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      <InputContainer>
        {label && <Label htmlFor={fieldName}>{label}</Label>}
        <Input
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          {...rest}
        />
        {error && <ErrorMessage>
          <FontAwesomeIcon icon={faExclamationCircle} /> {error}
        </ErrorMessage>}
      </InputContainer>
    </>
  );
};

