import React from 'react';
import { styled } from 'styled-components';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';

import { Form } from '@unform/web';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as yup from 'yup';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { createGroupCmd, iCommandsConfig, iGroupCmd, updateGroupCmd } from '../Repositories/CommandsRepo';

interface CompProps {
    initialData?: iCommandsConfig
    closeForm: () => void
}

const Container = styled.div`
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: start;
    max-width: 750px;  
    gap: 20px;`;

const ContainerForm = styled.div`
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    max-width: 750px;
    min-width: 350px;  
    gap: 5px;`;

const ContainerInline = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: start;
    gap: 10px;`;



export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

interface Option {
    value: string;
    label: string;
}





const FormConfigCmd: React.FC<CompProps> = ({ initialData, closeForm }) => {
    const { addNotification } = useNotifications();
    const formRef = React.useRef<FormHandles>(null)

    const submitForm: SubmitHandler<iGroupCmd> = async data => {

        try {
            formRef.current?.setErrors({})
            let itemShape: any = {
                group_name: yup.string().required("Esse campo é Obrigatório"),
            };
            const schema = yup.object().shape(itemShape);
            await schema.validate(data, {
                abortEarly: false,
            });
            console.log(initialData)
            initialData?.id ? await updateGroupCmd(data, initialData.id) : await createGroupCmd(data)
            addNotification('', initialData?.id ? 'Grupo Atualizado com sucesso' : 'Grupo Criado com sucesso', 'success');
            closeForm()
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            } else {
                if (err instanceof Error) {
                    addNotification('Falha na Operação', err.message, 'error');
                }
            }
        }
    }



    /*const SaveExpenseFunc = async (expense: iExpense) => {
        try {
            const result = await saveExpense(expense);
            addNotification('', 'Despesa salva com sucesso', 'success');
            formRef.current?.reset()
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }


    }*/



    React.useEffect(() => {
        // getListCategories()
    }, [])








    return (
        <Form placeholder={""} ref={formRef} onSubmit={submitForm} noValidate={true} initialData={initialData}>

            <Container>
                <ContainerInline>
                    <ContainerForm>
                        <InputFieldForm
                            label="Título"
                            placeholder="Insira o título"
                            name={'title'}
                            type='text'
                        />
                        <InputFieldForm
                            label="Descrição"
                            placeholder="Insira a descrição"
                            name={'descr'}
                            type='text'
                        />
                    </ContainerForm>
                </ContainerInline>
                <ContainerInline>
                    <ContainerForm>
                        <InputFieldForm
                            label="Sintaxe CMD"
                            placeholder="Insira a sintaxe de comando"
                            name={'sintaxe_cmd'}
                            type='text'
                        />
                        <InputFieldForm
                            label="Sintaxe SMS"
                            placeholder="Insira a sintaxe de SMS"
                            name={'sintaxe_sms'}
                            type='text'
                        />
                    </ContainerForm>
                </ContainerInline>
                <ContainerInline>
                    <ContainerForm>
                        <InputFieldForm
                            label="Texto do Botão"
                            placeholder="Insira o texto do botão"
                            name={'label_btnsend'}
                            type='text'
                        />
                        <InputFieldForm
                            label="Tipo de Comando"
                            placeholder="Insira o tipo de comando"
                            name={'type_cmd'}
                            type='text'
                        />
                        <InputFieldForm
                            label="Categoria"
                            placeholder="Insira a categoria"
                            name={'categ'}
                            type='text'
                        />
                    </ContainerForm>
                </ContainerInline>


            </Container>
            <ContainerInline>
                <div className="flex justify-around w-full max-w-md">
                    <button
                        className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors"
                        onClick={() => {
                            closeForm()
                            formRef.current?.reset()
                        }}>
                        Cancelar
                    </button>
                    <button
                        className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors"
                        type="submit">
                        Confirmar
                    </button>
                </div>
            </ContainerInline>
        </Form>
    )

}
export default FormConfigCmd

export interface FormErrors {
    group_name?: string;
}