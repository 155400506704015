
import React, { useState } from 'react';
import styled from 'styled-components';

// Importação dos SVGs como componentes React
import { ReactComponent as IgnON } from '../../../assets/icons_alerts/speed-car.svg';
import { ReactComponent as BatCut } from '../../../assets/icons_alerts/cars-battery.svg';
import { ReactComponent as OffLine } from '../../../assets/icons_alerts/offline.svg';
// Interface do Filtro
export interface FilterVehicles {
  type: string;
  count: number;
  title: string;
}

// Propriedades do componente FilterList
interface FilterListProps {
  filters: FilterVehicles[];
  onFilterSelect: (selectedFilters: string[]) => void;
}

// Estilos com styled-components
const FilterContainer = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: 10px;
`;

const FilterCard = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  padding: 4px;
  border: 2px solid ${({ selected }) => (selected ? '#ff7a00' : '#ccc')};
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#fff3e0' : 'white')};
  transition: all 0.3s ease;

  &:hover {
    border-color: #ff7a00;
  }
`;

const IconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Espaço entre o ícone e o count */
`;

const StyledIconPng = styled.div`
  height: 32px; /* Tamanho dinâmico do ícone */
  width: 32px; /* Tamanho dinâmico do ícone */
  flex: none; 
`;

const StyledIconSvg = styled.div<{ selected: boolean }>`
  height: 32px; /* Tamanho dinâmico do ícone */
  width: 32px; /* Tamanho dinâmico do ícone */
  flex: none;

  svg {
    height: 100%;
    width: 100%;
    fill: ${(props) => (props.selected ? "#ff7a00" : '#929292')}; /* Cor dinâmica do ícone */
    stroke: ${(props) => (props.selected ? "#ececec" : "#ececec")};
  }
`;

const Count = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const Title = styled.div`
  margin-top: 2px;
  font-size: 14px;
  color: #666;
  text-align: center;
  white-space: nowrap; /* Impede a quebra de linha */
  overflow: hidden; /* Oculta o texto que ultrapassa o componente */
  text-overflow: ellipsis; /* Exibe reticências (...) quando o texto é muito longo */
  width: 100%; /* Garante que o efeito funcione no espaço disponível */
`;
const ClearAllFiltersButton = styled.button`
  background: transparent;
  border: 1px solid #ff7a00;
  color: #ff7a00;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7a00;
    color: white;
  }
`;

// Componente FilterList que exibe os filtros e permite a seleção de múltiplos
const FilterList: React.FC<FilterListProps> = ({ filters, onFilterSelect }) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const handleFilterClick = (type: string) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(type)) {
        // Remove o filtro se já estiver selecionado
        return prevFilters.filter((filter) => filter !== type);
      } else {
        // Adiciona o filtro ao array de selecionados
        return [...prevFilters, type];
      }
    });
  };

  const handleClearAllFilters = () => {
    setSelectedFilters([]);
    onFilterSelect([]); // Envia array vazio para o componente pai para limpar todos os filtros
  };

  // Mapeamento de ícones para cada filtro
  const iconMap: Record<string, JSX.Element> = {
    move: <IgnON />,
    batcut: <BatCut />,
    offline: <OffLine />,
  };

  // Quando a lista de filtros selecionados mudar, notificar o componente pai
  React.useEffect(() => {
    onFilterSelect(selectedFilters);
  }, [selectedFilters, onFilterSelect]);

  return (
    <>
      <FilterContainer>
        {filters.map((filter) => (
          <FilterCard
            key={filter.type}
            selected={selectedFilters.includes(filter.type)}
            onClick={() => handleFilterClick(filter.type)}
          >
            <IconRow>
              <StyledIconSvg selected={selectedFilters.includes(filter.type)}>
                {iconMap[filter.type]}
              </StyledIconSvg>
              <Count>{filter.count}</Count>
            </IconRow>
            <Title>{filter.title}</Title>
          </FilterCard>
        ))}
      </FilterContainer>
    </>
  );
};

export default FilterList;