import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { ctrlButtons, iCateg } from '../../interfaces/interfaces';
import httpCommon from '../../services/http-common';
import avatar from '../../assets/icons/SleepOrange.png'
import WidgetDataGridComp from '../../widgets/WidgetDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { ContainerBlock, ContainerFlexInline } from '../../styles/Styles';

const Container = styled('div')({
    background: '#f6f9ff',
    width: '100%',
    height: 500
});


const ImgBox = styled.img`
    margin: 0 auto;
    height: 36px;
`


const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '10px',
    height: '450px',
    overflow: 'auto'
});


const initialBtns: ctrlButtons = {
    RefreshShow: true,
    CancelShow: true
}

interface DialogProps {
    open: boolean
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void
    selectCateg?: (item: iCateg) => void
}

const CategModal: React.FC<DialogProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [listDatagrid, setListDatagrid] = React.useState<iCateg[]>([]);

    const columns: GridColDef[] = [
        {
            field: 'icon', headerName: '', width: 80,
            align: 'center',
            renderCell: (params) => {
                let img = avatar
                try {
                    img = require(`../../assets/icons/${params.formattedValue}`)
                } catch (error) {
                    console.log(params.formattedValue, params.row.icon, params.row.descr)
                }
                return (
                    <>
                        {<ImgBox src={img} alt={params.row.descr} />}
                    </>
                );
            }


        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width: 350
        }
    ];


    const GetDados = async () => {
        const result = await httpCommon.get("ListCateg")
        let resp = result.data;
        setListDatagrid(resp)
        console.log(resp)
    }


    const handleRowClick = (params: iCateg) => {
        props.selectCateg?.(params)
    };


    React.useEffect(() => {
        if (props.open) {
            GetDados()
        }
    }, [props.open])

    return <>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 800, maxHeight: 600 } }}
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Categorias</DialogTitle>
            <DialogContent>
                <ContainerTable>
                    <WidgetDataGridComp
                        isLoading={isLoading}
                        rows={listDatagrid}
                        columns={columns}
                        CheckBoxSelect={false}
                        initialBtns={initialBtns}
                        onCancelClick={() => props.onClose}
                        onRowClick={handleRowClick}
                    />
                </ContainerTable>
            </DialogContent>
        </Dialog></>

}

export default CategModal