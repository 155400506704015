// VehicleEquipComp.tsx
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryHalf, faBatteryFull, faBatteryThreeQuarters, faBatteryQuarter, faBatteryEmpty } from '@fortawesome/free-solid-svg-icons';
import * as timeago from 'timeago.js';
import pt_BR from 'timeago.js/lib/lang/pt_BR';
import { EventsData, TrajectData } from '../repository/MapsRepo';
import { formatDateToScreenString } from '../../../utils/Converter';

import { ReactComponent as ParkingIcon } from '../../../assets/icons_alerts/parking-sign.svg';
import { ReactComponent as RouteIcon } from '../../../assets/icons_alerts/route.svg';
import JourneyTimeline, { JourneyData } from './JourneyTimeline';
import { formateTime } from '../utilsMap/tools';
import SpeedChart from './SpeedChart';
import SpeedTrajectsChart from './GraphicsMap/SpeedTrajectsChart';

// Registra a localidade portuguesa
timeago.register('pt_BR', pt_BR);

interface IconProps {
  isAlert?: boolean;
  color?: string;
}

interface TextProps {
  isColor?: boolean;
  color?: string;
}

const getStatusColor = (status: 'moving' | 'stoped'): string => {
  switch (status) {
    case 'stoped':
      return '#fc7609'; // Laranja
    case 'moving':
      return '#28a745'; // Verde  
    default:
      return '#ddd'; // Padrão
  }
};


const CardContainer = styled.div<{ cardStatus: 'moving' | 'stoped' }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  width: 299px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  border-top: 5px solid ${({ cardStatus }) => getStatusColor(cardStatus)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;

`;
const BoxHeader = styled.div`
  margin-left: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.h3`
  font-size: 12px;
  color: #666;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoTxRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 1px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DriverInfo = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`;

const RowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const ContainerInfoTx = styled.div`
font-size: 12px;
 display: flex;
  align-items: center;
  justify-content: space-around;

`

const StyledIconSvg = styled.div<{ selected: boolean }>`
  height: 32px; /* Tamanho dinâmico do ícone */
  width: 32px; /* Tamanho dinâmico do ícone */
  flex: none;

  svg {
    height: 100%;
    width: 100%;
    fill: ${(props) => (props.selected ? "#ff7a00" : '#929292')}; /* Cor dinâmica do ícone */
    stroke: ${(props) => (props.selected ? "#ececec" : "#ececec")};
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #c5c5c5;
  margin: 1px 0;
`;

const Address = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Odometer = styled.div`
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  color: red;
`;



const Icon = styled(FontAwesomeIcon) <IconProps>`
  font-size: 18px;
  color: ${({ isAlert, color }) => (isAlert ? color : '#7E7E7E')};
`;
const IconBat = styled(FontAwesomeIcon) <{ color: string }>`
font-size: 16px;
color: ${({ color }) => color};
`;


const IconInlinne = styled(FontAwesomeIcon) <IconProps>`
  font-size: 14px;
  color: ${({ isAlert, color }) => (isAlert ? color : '#7E7E7E')};
  margin-right: 4px;
  min-width: 20px;
`;


const IconContainer = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
`;

const EventImage = styled.img`
    z-index: 0;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 4px;
    left: 4px;
`;

const CustomLabel = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: bold;
    color: #FFF; /* Ajuste de cor conforme necessário */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Para melhor contraste */
`;

interface VehicleEquipCompProps {
  journeyData: JourneyData[];
  onPointClick: (item: JourneyData) => void;
}


const SummaryTrajectWithPositionsComp: React.FC<VehicleEquipCompProps> = ({ journeyData, onPointClick }) => {



  return (
    <>
      {journeyData.map((item, index) =>
        < CardContainer key={index} cardStatus={item.isStop ? 'stoped' : 'moving'}
          onClick={() => onPointClick(item)}>
          <JourneyTimeline data={item} />
        </CardContainer >
      )}
    </>
  );
};

export default SummaryTrajectWithPositionsComp;
