import React from 'react';
import styled from 'styled-components';
import { useConfig } from '../../../Config/configContext';
import { MapConfig } from '../utilsMap/mapContext';

interface DeviceIconProps {
  avatar?: string;
  color?: string;
}

const Container = styled.div`
  position: relative;
  width: 46px;
  height: 46px;
`;

const BackgroundCircle = styled.div<{ color: string }>`
  width: 46px;
  height: 46px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Image = styled.img`
      z-index: 0;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
`;

const DeviceIcon: React.FC<DeviceIconProps> = ({ avatar, color = 'white' }) => {
  const iconPath = `assets/icons/${avatar}.png`;

  return (
    <Container>
      <BackgroundCircle color={color} />
      <Image src={iconPath} />
    </Container>
  );
};

export default DeviceIcon;
