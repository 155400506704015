
import React, { useEffect } from 'react';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { useSelector } from 'react-redux';
import { ContainerTable, SearchAndSelectContainer } from '../../Messages/Style/StyleMessages';
import BarChart, { BarChartData } from '../../../components/graphics/BarCharts';
import { selectUser } from '../../../redux/userSlice';
import DateFilterDashboard, { dtFilter } from '../../ExpensesPage/components/DateFilterDashboard';
import { getFuelDashboad, iCategoryFuelResume, IDashboardFuel, reqDashboardFuel } from '../repository/FuelRepo';
import { styled } from 'styled-components';
import CategoryCardFuel from '../components/CategoryCardFuel';
import DateFilterFuel from '../components/DateFilterFuel';
import { FuelConfig } from '../Utils/FuelContext';
import { date } from 'yup';
import { formatDateDayJs } from '../../../utils/Converter';
import { Column } from 'react-data-grid';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import NoData from '../../../components/NoData';
import { handleSearch } from '../Utils/searchUtils';

const ContainerInline = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;  // Alterado de 'start' para 'flex-start' para maior compatibilidade
  gap: 10px;
  flex-wrap: wrap;          // Adicionado para permitir que os elementos quebrem linha
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  min-width: 400px;         // Definir a largura mínima do gráfico
  height: 400px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  margin: 5px;
`;



const DashboardFuelPage: React.FC = () => {

    const { dateStartFilter, dateEndFilter, updateConfig } = FuelConfig();

    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const [dataDriver, setDataDriver] = React.useState<BarChartData[]>([]);
    const [dataCategory, setDataCategory] = React.useState<BarChartData[]>([]);

    const [dataLitros, setDataLitros] = React.useState<BarChartData[]>([]);
    const [dataKm, setDataKm] = React.useState<BarChartData[]>([]);
    const [dataAmount, setDataAmount] = React.useState<BarChartData[]>([]);
    const [dataCostKm, setDataCostKm] = React.useState<BarChartData[]>([]);
    const [dataMediaKmPorLitro, setDataMediaKmPorLitro] = React.useState<BarChartData[]>([]);
    const [dataCategoryResume, setDataCategoryResume] = React.useState<iCategoryFuelResume[]>([]);

    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<IDashboardFuel[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<IDashboardFuel[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<IDashboardFuel>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });


    const columns: Column<IDashboardFuel>[] = [
        { key: 'id_veiculo', name: 'ID do Veículo' },
        { key: 'placa', name: 'Placa do Veículo' },
        {
            key: 'total_gastos', name: 'Total de Gastos',
            renderCell(props: any) {
                return (
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.row.total_gastos as number
                    ))
            }
        },
        {
            key: 'total_litros', name: 'Litros',
            width: 120,
            renderCell(props: any) {
                return (
                    new Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(props.row.total_litros as number
                    ))
            }
        },
        {
            key: 'custo_por_km', name: 'Custo',
            renderCell(props: any) {
                return (
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.row.custo_por_km as number
                    ))
            }
        },
        {
            key: 'total_km_rodados', name: 'KM Rodados',
            width: 120,
            renderCell(props: any) {
                return (
                    new Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(props.row.total_km_rodados as number
                    ))
            }
        },
        {
            key: 'media_combustivel', name: 'km/Litro',
            width: 120,
            renderCell(props: any) {
                return (
                    new Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(props.row.media_combustivel as number
                    ))
            }
        },

        { key: 'total_abastecimentos', name: 'Total de Abastecimentos' },
    ];


    async function getFuelByVehicle() {
        try {

            let dtFilter = {
                dtStart: formatDateDayJs(dateStartFilter),
                dtEnd: formatDateDayJs(dateEndFilter),
                lastreg: 1000
            }
            let payload: reqDashboardFuel = {
                dtFilter: dtFilter,
            }
            const result = await getFuelDashboad(payload);
            setRows(result);

            let sumLitros = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.total_litros,
                    id: item.id_veiculo
                }
            })
            let sumKm = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.total_km_rodados,
                    id: item.id_veiculo
                }
            })
            let sumAmount = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.total_gastos,
                    id: item.id_veiculo
                }
            })
            let sumCostKm = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.custo_por_km,
                    id: item.id_veiculo
                }
            })
            let sumMediaKmPorLitro = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.media_km_por_litro,
                    id: item.id_veiculo
                }
            })
            // Calcula os totais acumulados da frota
            let sumCategoryResume = result.reduce((acc: any, item: any) => {
                return {
                    total_km_rodados: acc.total_km_rodados + item.total_km_rodados,
                    total_litros: acc.total_litros + item.total_litros,
                    total_gastos: acc.total_gastos + item.total_gastos,
                    total_abastecimentos: acc.total_abastecimentos + Number(item.total_abastecimentos)
                };
            }, {
                total_km_rodados: 0,
                total_litros: 0,
                total_gastos: 0,
                total_abastecimentos: 0
            });

            // Calcula o custo por km total da frota
            const custoPorKmTotal = sumCategoryResume.total_gastos / sumCategoryResume.total_km_rodados;

            // Calcula a média geral de km/litro da frota
            const mediaKmPorLitroFrota = sumCategoryResume.total_km_rodados / sumCategoryResume.total_litros;

            // Formata os dados para exibir no resumo
            let categ: iCategoryFuelResume[] = [];
            categ.push({ category: 'Total KM Rodados', total_amount: `${sumCategoryResume.total_km_rodados.toFixed(2)} KM` });
            categ.push({ category: 'Total Litros', total_amount: `${sumCategoryResume.total_litros.toFixed(2)} L` });
            categ.push({ category: 'Total Gastos', total_amount: `${currencyFormatter.format(sumCategoryResume.total_gastos)}` });
            categ.push({ category: 'Total Abastecimentos', total_amount: `${Number(sumCategoryResume.total_abastecimentos)}` });
            categ.push({ category: 'Custo por KM Total', total_amount: `${currencyFormatter.format(custoPorKmTotal)}` });
            categ.push({ category: 'Média Geral de KM/Litro', total_amount: `${mediaKmPorLitroFrota.toFixed(2)} KM/L` });

            // Atualiza o estado com os dados formatados
            setDataCategoryResume(categ);

            setDataLitros(sumLitros)
            setDataKm(sumKm)
            setDataAmount(sumAmount)
            setDataCostKm(sumCostKm)
            setDataMediaKmPorLitro(sumMediaKmPorLitro)
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }


    React.useEffect(() => {
        getFuelByVehicle()
    }, [dateStartFilter, dateEndFilter]);



    useEffect(() => {
        const filtered = handleSearch(rows, findInputTerms);
        setFilteredRows(filtered);
    }, [findInputTerms, rows]);

    const handleClickRow = (row: any) => {
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
    }

    return (
        <>
            <>
                <SearchAndSelectContainer>
                    <DateFilterFuel />
                </SearchAndSelectContainer>

            </>
            {rows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <CategoryCardFuel categories={dataCategoryResume} />
                    <ContainerTable><DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        onRowClick={handleClickRow}
                        onRowSelect={handleRowSelect}
                    /> </ContainerTable>
                    <ContainerInline>
                        <Container>
                            <h1>Total de Litros</h1>
                            <BarChart data={dataLitros} />
                        </Container>
                        <Container>
                            <h1>Total Pago </h1>
                            <BarChart data={dataAmount} />
                        </Container>
                        <Container>
                            <h1>Custo por KM</h1>
                            <BarChart data={dataCostKm} />
                        </Container>
                        <Container>
                            <h1>Média KM/Litro</h1>
                            <BarChart data={dataMediaKmPorLitro} />
                        </Container>
                        <Container>
                            <h1>Total de KM Percorrido</h1>
                            <BarChart data={dataKm} />
                        </Container>


                    </ContainerInline>
                </>)}
        </>
    )

}
export default DashboardFuelPage