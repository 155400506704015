import { httpAxiosV4 } from "../../../gateways/Axios";

const version = 'v2'


export async function getAllConfigCmd(): Promise<any> {
    const { data } = await httpAxiosV4<any, iCommandsConfig[]>(
        `${version}/config-cmd`,
        "GET"
    );
    return data;
}

export async function getAllGroupCmd(): Promise<any> {
    const { data } = await httpAxiosV4<any, iCommandsConfig[]>(
        `${version}/groupCmd`,
        "GET"
    );
    return data;
}

export async function createGroupCmd(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, iCommandsConfig[]>(
        `${version}/groupCmd`,
        "POST", payload
    );
    return data;
}
export async function updateGroupCmd(payload: any, id: number): Promise<any> {
    const { data } = await httpAxiosV4<any, iCommandsConfig[]>(
        `${version}/groupCmd/${id}`,
        "PUT", payload
    );
    return data;
}
export async function getVehiclesToExpense(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `vehicles/expenses`,
        "GET"
    );
    return data;
}


export async function updateExpense(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses`,
        "PUT",
        payload
    );
    return data;
}


export interface iCommandsOptions {
    config: iCommandsConfig;
    itens: iItemCmdConfig[];
}
export interface iCommandsConfig {
    id: number;
    id_actions: number;
    model_equip: string;
    model_group: string;
    icon_config: string;
    title: string;
    descr: string;
    sitaxe_cmd: string;
    sitaxe_sms: string | null;
    label_btnsend: string | null;
    type_cmd: string;
    categ: string;
    itens: iItemCmdConfig[];
}

export interface iItemCmdConfig {
    id: number;
    id_config: number;
    disable: number;
    type_element: string;
    type_value: string | null;
    label: string;
    descr: string | null;
    icon_item: string;
    value_min: number;
    value_max: number;
    value_default: string | null;
    value: string;
    opcoes: string | null;
    cmd: string;
    order_itens: number;
}

export interface iPrepareCmd {
    imei: string
    id_config: number
    id_item: number
    deviceId: string
    deviceType: string
    sintaxe_cmd: string
    model_equip: string
    type_cmd: string
    categ: string
    values: iValueCmd[]
}

export interface iValueCmd {
    key: string
    value: any
}


export interface iGroupCmd {
    id: number
    group_name: string
    descr: string
}