import React from 'react';


const VehiclesTab: React.FC = () => {

    return (
        <>
            painel
        </>
    )

}
export default VehiclesTab