import React from 'react';
import { Column, SelectColumn } from 'react-data-grid';
import { httpAxiosV4 } from '../../../gateways/Axios';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import "../../../styles/datagrid/datagrid.css"
import { BtnCheckBox, ContainerInfo, ContainerTable, InfoBox, SearchAndSelectContainer } from '../StyleCommands';
import SearchField from '../../../components/datagridComp/SearchField';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import ColumnSelect from '../../../components/datagridComp/ColumnSelect';
import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import ButtonAdd from '../../../components/datagridComp/ButtonAdd';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { getAllConfigCmd, iCommandsConfig } from '../Repositories/CommandsRepo';
import FormConfigCmd from '../forms/FormConfigCmd';





const optionsActions = [
    { value: 'ALTERSTATUS', label: 'Alterar Status' },
    { value: 'ALTERVEHICLE', label: 'Alterar Veículo' },
    { value: 'ALTERDRIVER', label: 'Alterar Motorista' },
    { value: 'DELETE_EXPENSES', label: 'Apagar Despesas' },

];

const CommandsCreatorPage: React.FC = () => {

    const { addNotification } = useNotifications();
    const [isShowForm, setIsShowForm] = React.useState(false);
    const [itemEdit, setItemEdit] = React.useState<iCommandsConfig>();

    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<iCommandsConfig[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<iCommandsConfig[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<iCommandsConfig>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));


    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const [modalContent, setModalContent] = React.useState<string>('');

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const editItem = (param: iCommandsConfig) => {
        setItemEdit(param)
        setIsShowForm(true)
    }

    const columns: Column<iCommandsConfig>[] = [
        {
            ...SelectColumn,
            headerCellClass: "selectCellClassname",
            cellClass: "selectCellClassname",
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80,
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 100,
            renderCell(props: any) {
                return (
                    <><BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox></>
                )
            }
        },
        {
            key: 'protocol',
            name: 'Protocolo',
            width: 120,
        },
        {
            key: 'group_name',
            name: 'Grupo do Modelo',
            width: 120,
        },
        {
            key: 'title',
            name: 'Título',
            width: 150,
        },
        {
            key: 'type_cmd',
            name: 'Tipo de Comando',
            width: 120,
        },
        {
            key: 'categ',
            name: 'Categoria',
            width: 100,
        },
        {
            key: 'sintaxe_cmd',
            name: 'Sintexa CMD',
            width: 100,
        },
    ]

    async function getDados() {
        try {
            const data = await getAllConfigCmd()
            setRows(data)
            setFilteredRows(data);
            setColumnsFilter(columns)
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {

        (async () => {

            getDados();
        })();
    }, []);

    const handleClickRow = (row: any) => {
        // console.log(row)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((Expense) => Expense.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos um registro', 'warning');
            return;
        }

    };


    return (
        <>
            {!isShowForm && <>
                <SearchAndSelectContainer>
                    <SearchField onSearch={handleSearch} />
                    <ActionSelect
                        options={optionsActions}
                        onSelect={handleSelectAction}
                        title='Selecione a ação'
                    />
                    <ColumnSelect
                        columns={columns}
                        onColumnVisibilityChange={(visibleColumnKeys) => {
                            setVisibleColumns(visibleColumnKeys);
                        }}
                    />
                    <ButtonRefresh onClick={getDados} />
                    <ButtonAdd onClick={() => setIsShowForm(true)} />


                </SearchAndSelectContainer>
                <ContainerInfo>
                    <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                    <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                    <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                    <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                </ContainerInfo>
                <ContainerTable>
                    <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        visibleColumns={visibleColumns}
                        onRowClick={handleClickRow}
                        onRowSelect={handleRowSelect}
                    /> </ContainerTable>
            </>}
            {isShowForm && <FormConfigCmd initialData={itemEdit} closeForm={() => {
                setIsShowForm(false)
                setItemEdit(undefined)
                getDados()
            }} />}
        </>

    );

}
export default CommandsCreatorPage