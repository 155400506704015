import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { iTasks } from '../../interfaces/interfaces';

const Container = styled('div')({
    background: '#f6f9ff',
    width: '100%',
    height: 500
});

interface DialogProps {
    open: boolean
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void
    task?: iTasks
}

const ActionDeliveryModal: React.FC<DialogProps> = (props) => {



    React.useEffect(() => {
        console.log(props.task)
    }, [props.open])
    return <>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800, minHeight: 500 } }}
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Tarefa Nº {props.task && props.task!.id}</DialogTitle>
            <DialogContent>
                <Container>
                    {props.task && props.task!.uniqueId}
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog></>

}

export default ActionDeliveryModal