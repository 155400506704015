
import { Edit } from '@mui/icons-material';

interface ButtonRefreshProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ButtonEdit: React.FC<ButtonRefreshProps> = ({ onClick }) => {
  return (
    <button
      className=" rounded-full hover:bg-orange-400  text-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-300"
      aria-label="Atualizar"
      onClick={onClick}
    >
      <Edit className="h-5 w-5" />
    </button>
  );
}

export default ButtonEdit;
