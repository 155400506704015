import React, { useRef, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';
import InputFieldForm from '../../components/datagridComp/InputFieldForm';
import SelectFieldForm from '../../components/datagridComp/SelectFieldForm';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { createCompany } from './repository/EmpresaRepo';
import { useNotifications } from '../../Notifications/NotificationContext';
import axios from 'axios';
import { AuthConfig } from '../../Config/AuthContext';

interface IOption {
    value: string;
    label: string;
}

const optionsEmpresaType: IOption[] = [
    { value: '1', label: 'Pessoa Física' },
    { value: '2', label: 'Pessoa Jurídica' },
];

const Title = styled.h1`
font-size: 24px;
color: #333;
text-align: center;
margin-bottom: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

interface BoxProps {
    width?: string;
}

const Box = styled.div<BoxProps>`
    min-width: ${(props) => props.width || '100%'};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #45a049;
  }
`;


const schema = yup.object().shape({
    empresa_tipo: yup.string().required('O tipo de empresa é obrigatório'),
    cnpj: yup.string().required('CPF ou CNPJ é obrigatório'),
    razaoSocial: yup.string().required('O nome/razão social é obrigatório'),
    fantasia: yup.string().required('O nome fantasia é obrigatório'),
    ender: yup.string().required('O endereço é obrigatório'),
    num: yup.string().required('O número é obrigatório'),
    bairro: yup.string().required('O bairro é obrigatório'),
    cidade: yup.string().required('A cidade é obrigatória'),
    uf: yup.string().required('O estado é obrigatório'),
    cep: yup.string().required('O CEP é obrigatório'),

    email: yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
    tel1: yup.string().required('O WhatsApp é obrigatório'),
    password: yup.string().required('A senha é obrigatória').min(4, 'A senha deve ter pelo menos 4 caracteres'),
    login: yup.string().required('O login é obrigatório'),
    password_confirme: yup.string()
        .oneOf([yup.ref('password')], 'As senhas devem ser iguais')
        .required('A confirmação de senha é obrigatória'),

});


interface IFormCompanyData {
    empresa_tipo: string;
    cnpj: string;
    razaoSocial: string;
    fantasia: string;
    ender: string;
    email: string;
    tel1: string;
    tel2: string;
    login: string;
    password: string;
}

const FormCompany: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [empresaType, setEmpresaType] = React.useState('2');
    const { addNotification } = useNotifications();
    const { logout } = AuthConfig();

    const sendToServer = async (payload: IFormCompanyData): Promise<any> => {
        try {
            await createCompany(payload);
            addNotification('Empresa criada com sucesso', 'A empresa foi criada com sucesso', 'success');
            formRef.current?.reset();
            logout();
        } catch (error: any) {
            let message =
                "Erro ao realizar a operação. Por favor, tente novamente mais tarde.";
            if (axios.isAxiosError(error)) {
                message = error.response?.data.message || message;
            } else if (error instanceof Error) {
                message = error.message;
            }
            addNotification('Erro ao criar empresa', message, 'error');

        }
    };

    const handleSubmit = async (data: IFormCompanyData) => {
        try {
            formRef.current?.setErrors({});
            await schema.validate(data, { abortEarly: false });
            if (empresaType === '1') {
                if (!cpf.isValid(data.cnpj)) {
                    formRef.current?.setErrors({ cnpj: 'CPF inválido' });
                    return;
                }
            } else {
                if (!cnpj.isValid(data.cnpj)) {
                    formRef.current?.setErrors({ cnpj: 'CNPJ inválido' });
                    return;
                }
            }
            sendToServer(data);
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    };

    return (
        <FormContainer>
            <Title>Registrar Conta Admin</Title>

            <Form ref={formRef} onSubmit={handleSubmit} placeholder={undefined}>
                <Row>
                    <SelectFieldForm
                        options={optionsEmpresaType}
                        label={'Tipo de Empresa'}
                        name={'empresa_tipo'}
                        value={empresaType}
                        onChange={(e) => setEmpresaType(e.target.value)}
                    />
                    <InputFieldForm
                        label={empresaType === '1' ? "CPF" : "CNPJ"}
                        name="cnpj"
                        placeholder={empresaType === '1' ? "CPF" : "CNPJ"}
                        type="text"
                    />
                </Row>
                <Row>
                    <InputFieldForm label={empresaType === '1' ? "Nome*" : "Nome Empresarial*"} name="razaoSocial" />
                    <InputFieldForm label="Nome Fantasia*" name="fantasia" />
                </Row>
                <Row>
                    <InputFieldForm label="Endereço*" name="ender" />
                    <Box width="50px">
                        <InputFieldForm label="Numero*" name="num" />
                    </Box>
                </Row>
                <Row>
                    <InputFieldForm label="Bairro*" name="bairro" />
                    <InputFieldForm label="Cidade*" name="cidade" />
                    <Box width="75px">
                        <InputFieldForm label="Estado*" name="uf" />
                    </Box>
                    <Box width="120px">
                        <InputFieldForm label="CEP*" name="cep" />
                    </Box>
                </Row>
                <Row>
                    <InputFieldForm label="E-mail*" name="email" type="email" />
                    <InputFieldForm label="WhatsApp*" name="tel1" />
                    <InputFieldForm label="Telefone" name="tel2" />
                </Row>
                <Row>
                    <InputFieldForm label="Login*" name="login" />
                    <InputFieldForm label="Senha*" name="password" type="password" />
                    <InputFieldForm label="Confirme a Senha*" name="password_confirme" type="password" />
                </Row>
                <ActionButtons>
                    <SubmitButton type="submit">Salvar</SubmitButton>
                </ActionButtons>
            </Form>
        </FormContainer>
    );
};

export default FormCompany;
