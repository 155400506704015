import { httpAxiosV4 } from "../../../gateways/Axios";



export async function getRankingApi(filter: string): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `delivery-ranking?filter=${filter}`,
        "GET"
    );
    return data;
}
export async function getRulesExpenses(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `Expenses-rules-client`,
        "GET"
    );
    return data;
}
export async function ListTasksByRouterServer(id: number): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `delivery/ListTasksByRouter`,
        "POST",
        { idrouter: id }
    );
    return data;
}


export interface DeliveryData {
    id: number;
    num_opened: number;
    num_canceled: number;
    num_finished: number;
    DriverName: string;
    veiculo_placa: string;
    descr: string;
    dt_create: string;
    dt_finished?: string | null;
}