import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import httpServerFiles from '../../../services/http-server-files';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { itemDriver } from '../Components/ViewListDrivers';
import httpApiV3 from '../../../services/http-xsmart-apiv3';

interface DialogProps {
    open: boolean
    onClose: () => void
    UpdateAvatar: (avatar: string) => void
    driver: itemDriver
}

const AlterImgDriver: React.FC<DialogProps> = (props) => {

    const { user } = useSelector(selectUser);
    const [file, setFile] = React.useState<File | null>(null);
    const [previewURL, setPreviewURL] = React.useState<string | null>(null);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);

            const imageURL = URL.createObjectURL(selectedFile);
            setPreviewURL(imageURL);
        }
    };


    const UpdateAvatarInServer = async (filename: string) => {
        try {
            let payload = {
                iddriver: props.driver.id,
                filename
            }
            let resp = await httpApiV3.post('UpdateAvatarDriver', payload)
            setLoading(false)
            props.UpdateAvatar(filename);
            console.log(resp)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }



    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (!file) {
            console.log('Nenhuma imagem selecionada.');
            return;
        }

        setLoading(true)
        const formData = new FormData();
        formData.append('image', file);
        formData.append('idclient', user.idcliente.toString());
        formData.append('idempresa', user.idempresa.toString());
        formData.append('idrecord', props.driver.id.toString());
        formData.append('oldAvatar', props.driver.DriverAvatar);

        httpServerFiles.post('/uploadAvatarDrivers', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                console.log(response.data);
                UpdateAvatarInServer(response.data.filename)
            })
            .catch((error) => {
                setLoading(false)
                console.error(error);
            });
    };

    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: 450, maxHeight: 800, minHeight: 300 } }}
                maxWidth="md"
                open={props.open}
                onClose={props.onClose}
            >
                <DialogTitle>Avatar Motorista</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <input type="file" onChange={handleFileChange} />
                        {previewURL && (
                            <div style={{ width: '350px', height: '350px' }}>
                                <img
                                    src={previewURL}
                                    alt="Preview"
                                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                                />
                            </div>
                        )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose()} >
                        Fechar
                    </Button>
                    <Button onClick={handleSubmit} >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AlterImgDriver