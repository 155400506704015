import React, { useState } from 'react';
import { styled } from 'styled-components';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useConfig } from '../../../Config/configContext';


interface IOption {
    value: string;
    label: string;
}

export interface dtFilter {
    dtStart?: string;
    dtEnd?: string;
    lastreg: number;
}

// Tipos para props e para o objeto de datas
type DateFilterProps = {
    onDateChange: (dates: dtFilter) => void;
};

const optionsStatus: IOption[] = [
    //{ value: '-1', label: 'Últimos 1000 registros' },
    { value: '0', label: 'Hoje' },
    { value: '1', label: 'Ontem' },
    { value: '3', label: 'Últimos 3 Dias' },
    { value: '6', label: 'Últimos 7 Dias' },
    { value: 'M0', label: 'Mês Atual' },
    { value: 'M1', label: 'Mês Anterior' },
    { value: 'intervalo', label: 'Intervalo Personalizado' },

];

const Container = styled.div`
  margin: 10px;

`;
const ContainerBlock = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
    align-items: start;
    gap: 10px;
`;


const ErrorMessage = styled.div`
  color: red;
    font-size: 16px;
    margin: 5px;
    font-weight: 700;
    display: flex;
    justify-content: center;
`;

const InfoMessage = styled.div`
  color: gray;
    font-size: 16px;
    margin: 5px;
    font-weight: 600;
    display: flex;
    justify-content: left;
`;

const ContainerInlinePicker = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;
    margin-bottom: 10px;
    max-width: 350px;
    `;

const selectDefault = '0'

const DateFilterMap: React.FC<DateFilterProps> = ({ onDateChange }) => {
    const { dateStartFilterMap, dateEndFilterMap, updateConfig } = useConfig();
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [selectValue, setSelectValue] = React.useState<string>(selectDefault);
    const [error, setError] = useState<string>('');

    const handleDateChange = (value: any) => {
        setSelectValue(value);
        if (value === 'intervalo') {
            return;
        }

        let start = new Date();
        let end = new Date();

        switch (value) {
            case 'M0':
                start.setDate(1);
                start.setMonth(start.getMonth());
                end.setDate(0);
                end.setMonth(end.getMonth() + 1);
                break;
            case 'M1':
                start.setDate(1);
                start.setMonth(start.getMonth() - 1);
                end.setDate(0);
                end.setMonth(end.getMonth());
                break;
            case '1':
                start.setDate(start.getDate() - Number(value));
                end.setDate(end.getDate() - Number(value));
                break;
            case '2':
                start.setDate(start.getDate() - Number(value));
                end.setDate(end.getDate() - Number(value));
                break;
            default:
                start.setDate(start.getDate() - Number(value));
                break;
        }
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 0);
        setStartDate(dayjs(start));
        setEndDate(dayjs(end));
        updateConfig({ dateStartFilterMap: dayjs(start), dateEndFilterMap: dayjs(end) });


    };

    const formatDate = (date: Dayjs): string => {
        return `${date.year()}-${('0' + (date.month() + 1)).slice(-2)}-${('0' + date.date()).slice(-2)} ${('0' + date.hour()).slice(-2)}:${('0' + date.minute()).slice(-2)}:${('0' + date.second()).slice(-2)}`;
    };


    React.useEffect(() => {
        handleCustomDateSet();
    }, [startDate, endDate]);

    React.useEffect(() => {
        handleDateChange(selectDefault);
    }, []);

    const handleCustomDateSet = () => {
        if (!startDate || !endDate) {
            setError('Data inválida');
            return;
        }

        if (startDate.isAfter(endDate)) {
            setError('Data inicial maior que a final');
            return;
        }

        setError('');
        const formattedStart = formatDate(startDate);
        const formattedEnd = formatDate(endDate);
        //console.log(formattedStart, formattedEnd);
        onDateChange({ dtStart: formattedStart, dtEnd: formattedEnd, lastreg: 0 });
        updateConfig({ dateStartFilterMap: dayjs(startDate), dateEndFilterMap: dayjs(endDate) });
    };

    const handleStartDateChange = (newValue: Dayjs | null) => {
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue: Dayjs | null) => {
        setEndDate(newValue);
    };


    return (
        <Container>
            <ContainerBlock >
                <FormControl sx={{ m: 0, width: 280 }}>
                    <InputLabel id="demo-multiple-chip-label">Selecione o período</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        value={selectValue}
                        onChange={(e) => handleDateChange(e.target.value)}
                        label="Selecione o período"
                    >
                        {optionsStatus.map((item) => (
                            <MenuItem
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <ContainerInlinePicker>
                    <DatePicker
                        value={startDate}
                        label="Data Inicial"
                        onChange={handleStartDateChange}
                        format="DD/MM/YYYY"
                    />
                    <TimePicker
                        value={startDate}
                        views={['hours', 'minutes', 'seconds']}
                        label="Hora Inicial"
                        ampm={false}
                        onChange={handleStartDateChange}
                    />
                </ContainerInlinePicker>
                <ContainerInlinePicker>
                    <DatePicker
                        value={endDate}
                        label="Data Final"
                        onChange={handleEndDateChange}
                        format="DD/MM/YYYY"
                    />
                    <TimePicker
                        value={endDate}
                        views={['hours', 'minutes', 'seconds']}
                        label="Hora Final"
                        ampm={false}
                        onChange={handleEndDateChange}
                    />
                </ContainerInlinePicker>
            </ContainerBlock>
        </Container>
    );
};

export default DateFilterMap;
