import { httpAxiosV4 } from "../../../gateways/Axios";


const version = ''

export async function createCompany(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `createEmpresa`,
        "POST",
        payload
    );
    return data;
}
