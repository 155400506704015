import React, { useEffect, useState } from 'react';
import SelectFormInput from '../../../components/datagridComp/SelectFormInput';
import { styled } from 'styled-components';
import { MdSave } from 'react-icons/md';
import { start } from 'repl';
import { DatePicker } from '@mui/x-date-pickers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FuelConfig } from '../Utils/FuelContext';
import { isValidDate } from '../../../utils/Converter';



interface IOption {
    value: string;
    label: string;
}

export interface dtFilter {
    dtStart?: string;
    dtEnd?: string;
    lastreg: number;
}


const optionsStatus: IOption[] = [
    { value: 'M0', label: 'Mês Atual' },
    { value: 'M1', label: 'Mês Anterior' },
    { value: '6', label: 'Últimos 7 Dias' },
    { value: '29', label: 'Últimos 30 Dias' },
    { value: '89', label: 'Últimos 90 Dias' },
    { value: 'A1', label: 'Ano Passado' },
    { value: 'A0', label: 'Ano Corrente' },
    { value: 'intervalo', label: 'Intervalo Personalizado' },

];

const Container = styled.div`
  margin-top: 10px;
`;
const ContainerInline = styled.div`
display: flex;
justify-content: center;
    align-items: start;
    gap: 10px;
`;

const ContainerInlinePicker = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;
    margin-bottom: 10px;
    max-width: 350px;
    `;



const DateFilterFuel: React.FC = () => {
    const { dateStartFilter, dateEndFilter, filterSelect, updateConfig } = FuelConfig();
    const [error, setError] = useState('');
    const [areDatesValid, setAreDatesValid] = useState(false);


    const handleDateChange = (value: any) => {
        if (value === 'intervalo') {
            updateConfig({ filterSelect: value });
            return;
        }

        let start = new Date();
        let end = new Date();

        switch (value) {
            case 'M0':
                start.setDate(1);
                start.setMonth(start.getMonth());
                end.setDate(0);
                end.setMonth(end.getMonth() + 1);
                break;
            case 'M1':
                start.setDate(1);
                start.setMonth(start.getMonth() - 1);
                end.setDate(0);
                end.setMonth(end.getMonth());
                break;
            case 'A0':
                start.setDate(1);
                start.setMonth(0);
                end.setDate(31);
                end.setMonth(11);
                break;
            case 'A1':
                start.setDate(1);
                start.setMonth(0);
                start.setFullYear(start.getFullYear() - 1);
                end.setDate(31);
                end.setMonth(11);
                end.setFullYear(end.getFullYear() - 1);
                break;
            default:
                start.setDate(start.getDate() - Number(value));
                break;
        }

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        updateConfig({ filterSelect: value, dateStartFilter: dayjs(start), dateEndFilter: dayjs(end) });

    };


    const handleStartDateChange = (value: Dayjs | null) => {
        updateConfig({ dateStartFilter: value ? value : dayjs(new Date()), filterSelect: 'intervalo' });
    }

    const handleEndDateChange = (value: Dayjs | null) => {
        updateConfig({ dateEndFilter: value ? value : dayjs(new Date()), filterSelect: 'intervalo' });
    }

    useEffect(() => {
        const areDatesValid = isValidDate(dateStartFilter) && isValidDate(dateEndFilter);
        setAreDatesValid(areDatesValid);
    }, [dateStartFilter, dateEndFilter]);

    return (
        <Container>
            <ContainerInline >
                <FormControl sx={{ m: 0, width: 250 }}>
                    <InputLabel id="demo-multiple-chip-label">Selecione o período</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        value={filterSelect}
                        onChange={(e) => handleDateChange(e.target.value)}
                        label="Selecione o período"
                    >
                        {optionsStatus.map((item) => (
                            <MenuItem
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {areDatesValid && (
                    <ContainerInlinePicker>
                        <DatePicker
                            value={dateStartFilter}
                            label="Data Inicial"
                            onChange={(newValue) => handleStartDateChange(newValue)}
                            format='DD/MM/YYYY'
                        />
                        <DatePicker
                            value={dateEndFilter}
                            label="Data Final"
                            onChange={(newValue) => handleEndDateChange(newValue)}
                            format='DD/MM/YYYY'
                        />

                    </ContainerInlinePicker>
                )}
            </ContainerInline>
        </Container>
    );
};

export default DateFilterFuel;
