import { IconButton } from '@mui/material';
import * as React from 'react';

import { ctrlButtons, iFilterListSetup, iMsgAlert, iTasks } from '../../../interfaces/interfaces';

import { selectUser } from '../../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';

import * as am5percent from "@amcharts/amcharts5/percent";
import httpApiV3 from '../../../services/http-xsmart-apiv3';
import { changeAlert } from '../../../redux/alertServiceSlice';
import styled from 'styled-components';
import { selectAppBar } from '../../../redux/appBarSlice';
import { FileOpen } from '@mui/icons-material';
import { ContainerDataGrid, ContainerTable } from '../../../styles/datagrid/datagridStyle';
import { getComparator, renderCheckbox, renderSortStatus, rowKeyGetter } from '../../../utils/datagridTools';

import DataGrid, { Column, SelectColumn, SortColumn } from 'react-data-grid';
import SearchField from '../../../components/datagridComp/SearchField';
import ActionSelect from '../../../components/datagridComp/ActionSelect';


import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import httpApiV4 from '../../../services/http-xsmart-apiv4';
import { DeliveryData, ListTasksByRouterServer } from '../repository/DeliveryRepo';
import DeliveryCard from './DeliveryCard';
import Sidebar from '../../../components/datagridComp/SideBar';

let interval: any
let isInit = true

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    FilterShow: true,
    ChatShow: true
}


const Subtitle = styled('div')({
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'uppercase',
});

var rootAm5: any;
var chart: any

let filterList: iFilterListSetup = {

    statusFilter: ['EM PREPARAÇÃO', 'EM TRÂNSITO'],
    isDateFilter: false,
    SelDriver: null,
    SelVehicle: null
}

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; // Espaço entre os componentes
`;

const optionsActions = [
    { value: 'AN', label: 'Adicionar Nota' },
    { value: 'CC', label: 'Categoria à Cancelar' },
    { value: 'CA', label: 'Categoria Ativo' },
    { value: 'CI', label: 'Categoria Inativo' }
];

interface ListTaskRouterCompProps {
    id: number
}

const DETAILS_TASK = 'DETAILS_TASK'


const ListTaskRouterComp: React.FC<ListTaskRouterCompProps> = ({ id }) => {


    const dispatch = useDispatch();
    const { user } = useSelector(selectUser);
    const { appbar } = useSelector(selectAppBar);
    //const [listDatagrid, setListDatagrid] = React.useState<iTasks[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [IsOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
    const [IsOpenAction, setIsOpenAction] = React.useState<boolean>(false);
    const [filterInfo, setFilterInfo] = React.useState<string>();
    const [itemTask, setItemTask] = React.useState<iTasks>();

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);
    const [rows, setRows] = React.useState<iTasks[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<iTasks[]>(rows);

    //sidebar
    const [sideContent, setSideContent] = React.useState<string>();

    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }
    const OpenDetails = (item: iTasks) => {
        setItemTask(item)
        setIsOpenAction(true)
    }


    async function getDados() {
        try {
            setIsLoading(true)
            const result = await ListTasksByRouterServer(id)
            setRows(result)
            setFilteredRows(result);
            setIsLoading(false)

        } catch (error) {
            console.log('[ListTasksDelivery]', error)
            setIsLoading(false)
            handleSetMsgInfo({ msg: "error", type: 'error' })
        }
    }


    const columns: Column<iTasks>[] = [

        {
            key: 'actions', name: 'Eventos', width: 70,
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => openDetails(params.row)}>
                            <FileOpen />
                        </IconButton>

                    </>
                );
            }


        },
        {
            key: 'idtask',
            name: 'Cód Ordem',
            width: 70,
        },
        {
            key: 'order_item',
            name: 'Ordem',
            width: 70,
        },
        {
            key: 'status',
            name: 'Status',
            width: 150,
        },
        {
            key: 'company',
            name: 'Destino',
            width: 220
        },
        {
            key: 'ender',
            name: 'Endereço',
            width: 350,
        },
        {
            key: 'phone',
            name: 'Telefone',
            width: 130,
        }

    ];

    const CtrlInterval = () => {

        console.log("Start interval")
        interval = setInterval(() => {
            console.log("get Dados Panel")
            getDados()
        }, 5000)

    }

    React.useEffect(() => {
        console.log("Init List Delivery")
        if (isInit) {
            isInit = false;
            CtrlInterval()
            getDados()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct List Delivery")
            clearInterval(interval)
            isInit = true;
        }
    }, [])





    const handleCloseFilter = () => {
        setIsOpenFilter(false)
    }
    const handleCloseDetails = () => {
        setIsOpenAction(false)
    }


    const handleSetFilter = (filter: iFilterListSetup) => {
        filterList = filter
        setIsOpenFilter(false)
        getDados()
    }


    const sortedRows = React.useMemo((): readonly iTasks[] => {
        return [...filteredRows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator<iTasks>(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [filteredRows, sortColumns]);


    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };


    const handleSelectAction = (action: string) => {
        console.log("Ação selecionada:", action);
        //setIsModalOpen(true); // Exemplo: abrir um modal
    };

    const openDetails = (item: iTasks) => {

        setSideContent(DETAILS_TASK)
        setItemTask(item)
    }

    return <>

        <ContainerTable>
            <SearchAndSelectContainer>
                <SearchField onSearch={handleSearch} />
                <ButtonRefresh onClick={getDados} />


            </SearchAndSelectContainer>
            <ContainerDataGrid>
                <DataGrid
                    columns={columns}
                    rows={sortedRows}
                    rowKeyGetter={rowKeyGetter}
                    onRowsChange={setRows}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    renderers={{ renderSortStatus, renderCheckbox }}
                    defaultColumnOptions={{
                        resizable: true,
                        sortable: true
                    }}
                    className='DatagridClass'
                />
            </ContainerDataGrid>
        </ContainerTable>
        <Sidebar isOpen={sideContent === DETAILS_TASK} onClose={() => { setSideContent(undefined) }} title={itemTask?.company}>
            {itemTask && <DeliveryCard
                key={itemTask.id}
                item={itemTask}
                editItem={() => { }}
                onSelect={() => { }}
                isSelected={false}
            />} </Sidebar>
    </>

}

export default React.memo(ListTaskRouterComp)