import React from 'react';


interface CompProps {
    item: string
}



const MasterData: React.FC<CompProps> = ({ item }) => {


    return (
        <>
            Master

        </>
    )

}
export default MasterData