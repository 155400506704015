import axios from "axios";
import { httpAxiosV4 } from "../../../gateways/Axios";



export async function getVehiclesByStream(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `panel/vehicles`,
        "POST",
        payload
    );
    return data;
}

export async function ctrlStreamCam(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `ctrlStreamIothubJimi`,
        "POST",
        payload
    );
    return data;
}

export async function getEvents(payload: itemReqEvents): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `events/list-events-cam`,
        "POST",
        payload
    );
    return data;
}

export async function sendCommandIotHub(payload: iCmdIothubJimi): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `commandsIothubJimi`,
        "POST",
        payload
    );
    return data;
}

export async function ListHistoryDashcam(imei: string): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `reqListHistoryDashCam?imei=${imei}`,
        "GET"
    );
    return data;
}

export async function getFilesInServer(imei: string): Promise<any> {
    const { data } = await axios.get(`https://dvr.maxtracer.com.br/listfiles/${imei}`);
    return data;
}
export function downLoadFile(imei: string, file: string): string {

    return `https://dvr.maxtracer.com.br/filedownload/${imei}/${file}`;
}

export type IFilesVideo = {
    nome: string;
    tamanho: number;
    dataCriacao: string;
};



export interface iCmdIothubJimi {
    imei: string;
    cmdContent: any;
    proNo: number;
}


export interface iReqDashcamResourceList {
    channel: number;          // Representa o canal (câmera)
    beginTime: string;        // Data no formato YYMMDDHHMMSS
    endTime: string;          // Data no formato YYMMDDHHMMSS
    alarmFlag: number;        // Indicador de alarme (0 ou 1)
    resourceType: number;     // Tipo de recurso
    codeType: number;         // Tipo de código
    storageType: number;      // Tipo de armazenamento
    instructionID: string;    // ID da instrução, string para representar números
}


export interface iFtpUploadRequest {
    serverAddress: string;      // Endereço IP do servidor FTP
    ftpPort: number;            // Porta FTP
    userName: string;           // Nome de usuário FTP
    password: string;           // Senha FTP
    fileUploadPath: string;     // Caminho para upload do arquivo
    channel: number;            // Canal (câmera)
    beginTime: string;          // Data no formato YYMMDDHHMMSS
    endTime: string;            // Data no formato YYMMDDHHMMSS
    alarmFlag: number;          // Indicador de alarme (0 ou 1)
    resourceType: number;       // Tipo de recurso
    codeType: number;           // Tipo de código
    storageType: number;        // Tipo de armazenamento
    condition: number;          // Condição (possivelmente de upload)
    instructionID: string;      // ID da instrução
}



export interface iListHistoryDashcam {
    codeType: number;
    fileSize: number;
    channel: number;
    storageType: number;
    beginTime: string; // ou Date se for tratado como um objeto de data
    endTime: string; // ou Date se for tratado como um objeto de data
    alarmFlag: number;
    resourceType: number;
}



export interface iParamsCamIotHub {
    imei: string
    tipo: string
    channel: string
    action: string
}

// types.ts
export interface VehicleCardProps {
    plate: string;
    nickname: string;
    vehicle: string;
    owner: string;
    updatedAt: string;
    status: string;
    signal: number;
    battery: number;
    voltage: number;
    connections: number;
    ignition: boolean;
    lock: boolean;
    address: string;
    odometer: number;
    cardStatus: 'warning' | 'success' | 'error';
}


export interface itemReqEvents {
    lastregister: number
    idclient: number
    idVehicle: number
    dtStart: string
    dtEnd: string
}


export interface iEventReport {
    id: number;
    idveiculo: number;
    idcliente: number;
    idempresa: number;
    idmotorista: number;
    idviagem: number;
    iddisp: number;
    idcell: number;
    lat: string;
    lng: string;
    start_lat: string;
    start_lng: string;
    evento: number;
    dlastevento: string; // ISO date string
    sevento: string;
    devento: string; // ISO date string
    tevento: string; // duration in HH:MM:SS format
    duraction: number;
    dist: number;
    dist_gps: number;
    ocioso: number;
    status: string;
    dsync: string; // ISO date string
    source: number;
    param1: string;
    param2: string | null;
    param3: string | null;
    apelido: string;
    isrecalc: number;
    isnew: number;
    event_cam: number;
    files_stream: string;
    create_at: string | null; // ISO date string or null
    delete_at: string | null; // ISO date string or null
    update_at: string | null; // ISO date string or null
    is_sync: number;
    dt_evt: string; // date string
    dt_old_evt: string; // date string
    icone: string | null;
    nome: string | null;
    driver_avatar: string;
    driver_name: string;
}
