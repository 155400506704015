import React from 'react';

type VideoModalProps = {
    videoUrl: string;
    title: string;
    onClose: () => void;
};

const VideoModal: React.FC<VideoModalProps> = ({ videoUrl, title, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4" onClick={onClose}>
            <div className="bg-white rounded-lg shadow-xl overflow-auto" style={{ maxWidth: '70%', maxHeight: '85%' }} onClick={e => e.stopPropagation()}>
                <div className="flex justify-between items-center p-4">
                    <h3 className="text-lg font-bold text-orange-800">{title}</h3>
                    <button onClick={onClose} className="text-orange-600 hover:text-orange-800">
                        <span className="text-2xl">&times;</span>
                    </button>
                </div>
                <video controls autoPlay className="w-full max-h-full">
                    <source src={videoUrl} type="video/mp4" />
                    Seu navegador não suporta vídeos.
                </video>
            </div>
        </div>
    );
};

export default VideoModal;
