
import React, { useEffect, useState } from 'react';
import { BoxIcon, BtnFaIcon, ContainerTable, SearchAndSelectContainer } from '../Styles/StyleStreamCam';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { Column, SelectColumn } from 'react-data-grid';
import { getVehiclesByStream, iCmdIothubJimi, iFtpUploadRequest, iListHistoryDashcam, iReqDashcamResourceList, ListHistoryDashcam, sendCommandIotHub } from '../repository/StreamRepo';
import { useSelector } from 'react-redux';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { selectUser } from '../../../redux/userSlice';
import { faAdd, faDownload, faList, faRefresh, faSync, faUpload } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from 'nanoid';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import CustomError from '../../../utils/CustomErros';
import { formatDateToScreenString } from '../../../utils/Converter';

interface Option {
    value: string;
    label: string;
}

const DashCamHistory: React.FC = () => {
    const formRef = React.useRef<FormHandles>(null)
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);

    const [vehicles, setVehicles] = React.useState<Option[]>([]);
    const [vehicle, setVehicle] = React.useState<string>();
    const [listSyncIn, setListSyncIn] = React.useState<string>('');

    //data
    const [startDate, setStartDate] = useState<Dayjs>(dayjs());
    //datagrid
    const [rows, setRows] = React.useState<iListHistoryDashcam[]>([]);

    const formatDate = (date: Dayjs, isStart: boolean = true): string => {
        const formattedDate = `${date.year().toString().slice(-2)}` + // YY
            `${('0' + (date.month() + 1)).slice(-2)}` + // MM
            `${('0' + date.date()).slice(-2)}` + // DD
            `${isStart ? '000000' : '235959'}`; // HHMMSS: 00:00:00 for beginStart, 23:59:59 for beginEnd
        return formattedDate;
    };

    const formatDateUpload = (date: Dayjs): string => {
        return `${date.year().toString().slice(-2)}` + // YY
            `${('0' + (date.month() + 1)).slice(-2)}` + // MM
            `${('0' + date.date()).slice(-2)}` + // DD
            `${('0' + date.hour()).slice(-2)}` + // HH
            `${('0' + date.minute()).slice(-2)}` + // MM
            `${('0' + date.second()).slice(-2)}`; // SS
    };


    async function getVehicles() {
        const payload = {
            idcliente: user.idcliente,
            idempresa: user.idempresa,
            nivel: user.nivel
        }
        const result = await getVehiclesByStream(payload);
        let list: Option[] = []
        for (let i = 0; i < result.length; i++) {
            list.push({ value: result[i].imei, label: `${result[i].apelido}` })
        }
        setVehicles(list)
        setVehicle(list[0].value)
    }


    useEffect(() => {
        getVehicles()
    }, [])

    async function requestListFromDb() {
        try {
            if (vehicle === undefined) {
                addNotification('', 'Selecione um veículo', 'error');
                return
            }
            const result = await ListHistoryDashcam(vehicle);
            setListSyncIn(formatDateToScreenString(result.created_at))
            setRows(result.resourcelist)
        } catch (e: any) {
            if (e.response && e.response.data) {
                const error = e.response.data as CustomError;
                addNotification('', error.message, 'error');
                console.log(error);
            } else {
                addNotification('Erro', 'Ocorreu um erro inesperado', 'error');
                console.log(e);
            }
        }

    }
    async function requestListFromDevice() {
        try {
            if (vehicle === undefined) {
                addNotification('', 'Selecione um veículo', 'error');
                return
            }

            const cmdContent: iReqDashcamResourceList = {
                channel: 0,
                beginTime: formatDate(startDate, true),
                endTime: formatDate(startDate, false),
                alarmFlag: 0,
                resourceType: 0,
                codeType: 0,
                storageType: 0,
                instructionID: nanoid(10)
            }
            let cmd: iCmdIothubJimi = {
                imei: vehicle,
                cmdContent: cmdContent,
                proNo: 37381
            }

            const result = await sendCommandIotHub(cmd);
            console.log(result)
            addNotification('', 'Solicitação enviada com sucesso', 'success');
        } catch (e: any) {
            if (e.response && e.response.data) {
                const error = e.response.data as CustomError;
                addNotification('', error.message, 'error');
                console.log(error);
            } else {
                addNotification('Erro', 'Ocorreu um erro inesperado', 'error');
                console.log(e);
            }
        }

    }

    const handleStartDateChange = (value: Dayjs | null) => {
        if (!value) return;
        setStartDate(value);
    }
    const handleRequestUpload = async (value: iListHistoryDashcam) => {
        try {
            if (vehicle === undefined) {
                addNotification('', 'Selecione um veículo', 'error');
                return
            }
            let cmdContent: iFtpUploadRequest = {
                serverAddress: process.env.REACT_APP_SERVER_FTP_ADDRESS || '',
                ftpPort: parseInt(process.env.REACT_APP_SERVER_FTP_PORT || '21'),
                userName: process.env.REACT_APP_SERVER_FTP_USER || '',
                password: process.env.REACT_APP_SERVER_FTP_PASSWORD || '',
                fileUploadPath: `/${vehicle}/`,
                channel: value.channel,
                beginTime: formatDateUpload(dayjs(value.beginTime)),
                endTime: formatDateUpload(dayjs(value.endTime)),
                alarmFlag: 0,
                resourceType: 0,
                codeType: 0,
                storageType: 0,
                condition: 0,
                instructionID: nanoid(10)
            }
            let cmd: iCmdIothubJimi = {
                imei: vehicle,
                cmdContent: cmdContent,
                proNo: 37382
            }

            const result = await sendCommandIotHub(cmd);
            console.log(result)
            addNotification('', 'Solicitação enviada com sucesso', 'success');
        } catch (e: any) {
            if (e.response && e.response.data) {
                const error = e.response.data as CustomError;
                addNotification('', error.message, 'error');
                console.log(error);
            } else {
                addNotification('Erro', 'Ocorreu um erro inesperado', 'error');
                console.log(e);
            }
        }
    }



    const columns: Column<iListHistoryDashcam>[] = [

        {
            key: 'upload',
            name: 'Upload',
            width: 130,
            renderCell(props: any) {
                return (
                    <BtnFaIcon title='Upload Vídeo' onClick={() => handleRequestUpload(props.row)} >
                        <BoxIcon icon={faUpload} iconColor='green' /> Upload Vídeo
                    </BtnFaIcon >
                )
            }
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },

        {
            key: 'channel',
            name: 'Canal',
            width: 70
        },
        {
            key: 'beginTime',
            name: 'Data Inicio',
            width: 250,
            renderCell(props: any) {
                return (
                    <div>{formatDateToScreenString(props.row.beginTime)}</div>
                )
            }
        },
        {
            key: 'endTime',
            name: 'Data Fim',
            width: 250,
            renderCell(props: any) {
                return (
                    <div>{formatDateToScreenString(props.row.endTime)}</div>
                )
            }
        },
        {
            key: 'alarmFlag',
            name: 'Alarme',
            width: 100
        },
        {
            key: 'fileSize',
            name: 'Tamanho',
            width: 100
        },


    ]

    return (
        <>
            <SearchAndSelectContainer>
                <Form placeholder={""} ref={formRef} onSubmit={() => { }} noValidate={true}>

                    <SelectFieldForm
                        name={'imei'}
                        options={vehicles}
                        label={'Selecione um Veículo'}
                        onChange={(e) => {
                            setVehicle(e.target.value)
                        }}
                    />
                </Form>
                <DatePicker
                    value={startDate}
                    label="Data Inicial"
                    onChange={(newValue) => handleStartDateChange(newValue)}
                    format='DD/MM/YYYY'
                />
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={requestListFromDb} >
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Solicitar Listas salvas
                </BtnFaIcon >
                <BtnFaIcon title='Cadastrar nova regra' onClick={requestListFromDevice} >
                    <BoxIcon icon={faList} iconColor='green' /> Solicitar Lista na Câmera
                </BtnFaIcon >

            </SearchAndSelectContainer>
            <h3>Última sincronização: {listSyncIn}</h3>
            <ContainerTable>
                <DataGridComp
                    rows={rows}
                    columns={columns}
                    selectedRows={new Set()}
                /> </ContainerTable>
        </>
    )

}
export default DashCamHistory