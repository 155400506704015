import { Dialog, DialogTitle, DialogContent, Stack, DialogActions, Button, List, Avatar, ListItem, ListItemAvatar, ListItemText, Box, TextField, IconButton, Grid } from '@mui/material';
import { AddLocation, ShareLocation, Edit } from '@mui/icons-material'
import * as React from 'react';
import { DataGrid, GridColDef, GridToolbarQuickFilter, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import BarTable from '../../components/BarTable';
import { ctrlButtons, iDestinos, iDialogConfirme, iReduxMap, iPosition, iMsgAlert } from '../../interfaces/interfaces';

import http from '../../services/http-common'
import { selectUser } from '../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import DialogConfirme from '../../components/DialogConfirme'
import WidgetDataGridComp from '../../widgets/WidgetDataGrid';


import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as yup from 'yup';
import Input from '../../components/forms/components/InputComp';
import '../../styles/InputForm.css'
import '../../styles/global.css'

import { useEffect, useRef } from 'react';
import LoadingCircularComp from '../../components/LoadingCircularComp';
import httpCommon from '../../services/http-common';
import { changeMenu, selectAppBar } from '../../redux/appBarSlice';
import { changeMap, resetMap, selectMap } from '../../redux/mapSlice';
import TextAreaComp from '../../components/forms/components/TextFieldComp';
import { ActionMaps } from '../../utils/values';
import { changeAlert } from '../../redux/alertServiceSlice';
import Sidebar from '../../components/datagridComp/SideBar';
import { OPEN_MAP } from './utilsDelivery/constDelivery';
import MapDelivery from './Components/MapDelivery';
import { GeocoderService } from '../../GoogleMaps/GeocoderService';
import { styled } from 'styled-components';
import { DeliveryConfig, iMarkerForm, iMarkerPoint } from './utilsDelivery/DeliveryProvider';


const initialBtns: ctrlButtons = {
    RefreshShow: true,
    DeleteShow: true,
    DeleteDisable: true,
    addShow: true
}

const CtrlBtnsCad: ctrlButtons = {
    CancelShow: true,
    SaveShow: true
}

interface FormDataSave {
    id: number
    descr: string
    contact: string
    document: string
    ender: string
    email: string
    phone: string
    idempresa: number
    idcliente: number
    lat: number
    lng: number
}

interface iContentSideBar {
    type: string
    item: any
}


// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px; /* Espaçamento entre os botões */
`;


const InfoText = styled.i`
  margin-bottom: 8px;
`;

const Coordinates = styled.p`
  margin-top: 8px;
  font-weight: bold;
  font-size: 10px;
`;



const ListDestComp: React.FC = (props) => {

    const { MarkerPoint, MarkerForm, updateConfig } = DeliveryConfig()
    const dispatch = useDispatch()
    const formRef = useRef<FormHandles>(null)
    const { user } = useSelector(selectUser);
    const { mapOptions } = useSelector(selectMap);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSaveLoading, setSaveLoading] = React.useState<boolean>(false);
    const [isShowCad, setShowCad] = React.useState<boolean>(false);
    const [listDialog, setListDialog] = React.useState<iDestinos[]>([]);
    const [listDatagrid, setListDatagrid] = React.useState<iDestinos[]>([]);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);
    const [IsOpenDialogConfime, setOpenDialogConfirme] = React.useState<boolean>(false);
    const [ConfirmeDialogContent, setConfirmeDialogContent] = React.useState<iDialogConfirme>();
    const [positionMakerMap, setPositionMakerMap] = React.useState<iPosition | undefined>();
    const [formCadData, setformCadData] = React.useState<iDestinos | undefined>();

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();
    const handleCloseSideBar = () => setSideContent(undefined);



    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }


    async function getDados() {
        const result = await http.post("/GetListPlaces", {
            idcliente: user.idcliente,
            type: 1
        });
        let resp = result.data.dados;
        setListDialog(resp)
        setListDatagrid(resp)
        console.log(resp)
    }

    async function DeleteDestinos() {
        try {
            let obj = {
                iduser: user.iduser,
                idcliente: user.idcliente,
                idempresa: user.idempresa,
                rows: selectdRows
            }
            const result = await http.post("/setDelDestinos", obj);
            let resp = result.data;
            getDados()
            console.log(resp)
        } catch (error) {
            console.log(error)
        }
    }

    const columns: GridColDef[] = [

        {
            field: 'actions', headerName: 'Eventos', width: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => ShowInMapClick(params.row)}>
                            <ShareLocation />
                        </IconButton>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => EditItemClick(params.row)}>
                            <Edit />
                        </IconButton>
                    </>
                );
            }


        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width: 250,
        },
        {
            field: 'contact',
            headerName: 'Contato',
            width: 250
        },
        {
            field: 'document',
            headerName: 'Documento',
            width: 150
        },
        {
            field: 'ender',
            headerName: 'Endereço',
            width: 350,
        },
        {
            field: 'phone',
            headerName: 'Telefone',
            width: 130
        },
        {
            field: 'email',
            headerName: 'E-mail',
            width: 250
        }

    ];

    React.useEffect(() => {
        (async () => {
            console.log("useEffect ListDestComp")
            if (user.idcliente) { getDados() } else { console.log("cliente Invalid ListDestComp") }
        })();
    }, [user]);

    const setSel = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
        CtrlBtns.DeleteDisable = i.length ? false : true
        setCtrlBtns(CtrlBtns)
    }

    const handleRefresh = () => {
        if (user.idcliente) { getDados() }
    }

    const handleDelete = () => {
        console.log(selectdRows)
        setConfirmeDialogContent({
            title: "Confirme Acão?",
            msg: "Deseja realmente apagar este ponto? , ele vai ser removido de todas as tarefas e rotas",
            icon: ""
        })
        setOpenDialogConfirme(true)
    };

    const handleCloseDialogConfirme = () => {
        setOpenDialogConfirme(false)
    };


    const CadNewPlaces = () => {
        setformCadData(undefined)
        setPositionMakerMap(undefined)
        setShowCad(true)
        let position = { lat: 0, lng: 0 }
        let address = ''
        let newMarkerForm: iMarkerForm = {
            position,
            address
        }
        let newMarkerPoint: iMarkerPoint = {
            position,
            address,
            editable: true,
            title: 'Novo Ponto'
        }
        updateConfig({ MarkerForm: newMarkerForm, MarkerPoint: newMarkerPoint })
    };

    const handleCancelCad = () => {
        formRef.current?.reset()
        setPositionMakerMap(undefined)
        setShowCad(false)
    }

    const handleSaveInServer = async (data: FormDataSave) => {
        setSaveLoading(true)
        data.idcliente = user.idcliente
        data.idempresa = user.idempresa
        const result = data.id ? await httpCommon.put("Places", data) : await httpCommon.post("Places", data)
        let resp = result.data;
        if (resp.IsSuccess) {
            handleSetMsgInfo({ msg: `Ponto ${data.id ? 'Alterado' : 'Criado'} com sucesso!`, type: 'success' })
        } else {
            handleSetMsgInfo({ msg: resp.msg, type: 'error' })
            formRef.current?.setFieldError(resp.field, resp.msg)
        }
        if (resp.IsSuccess) {
            formRef.current?.reset()
            handleRefresh()
            handleCancelCad()
        }

    }

    const handleSubmitSaveCad: SubmitHandler<FormDataSave> = async data => {
        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                descr: yup.string().required("Esse campo é Obrigatório"),
                contact: yup.string(),
                document: yup.string(),
                ender: yup.string().required("Esse campo é Obrigatório"),
                email: yup.string().email("Digite um e-mail válido"),
                phone: yup.string(),

            });
            await schema.validate(data, {
                abortEarly: false,
            });
            if (MarkerForm?.position && MarkerForm?.position.lat === 0) {
                formRef.current?.setFieldError('ender', "Precisa validar esse endereço no mapa")
                return
            }
            data.lat = MarkerForm?.position.lat ?? 0
            data.lng = MarkerForm?.position.lng ?? 0
            if (formCadData) {
                data.id = formCadData.id
            }
            handleSaveInServer(data)
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }


    const ShowInMapClick = (item: iDestinos) => {
        let position = { lat: item.lat, lng: item.lng }
        updateConfig({
            MarkerPoint: {
                position: position,
                address: item.ender ?? '',
                editable: false,
                title: item.descr ?? ''
            }
        })
        setSideContent(OPEN_MAP)

    }

    const EditItemClick = (item: iDestinos) => {
        console.log("Edit", item)
        setformCadData(item)
        setShowCad(true)
        let position = { lat: item.lat, lng: item.lng }
        let address = item.ender ?? ''
        let newMarkerForm: iMarkerForm = {
            position,
            address
        }
        let newMarkerPoint: iMarkerPoint = {
            position,
            address,
            editable: true,
            title: item.descr ?? ''
        }
        updateConfig({ MarkerForm: newMarkerForm, MarkerPoint: newMarkerPoint })
    }

    const handleConfirmeDialogOK = () => {
        setOpenDialogConfirme(false)
        DeleteDestinos()
    };

    const handleValidatePoint = async () => {
        let ender = formRef.current?.getFieldValue('ender')
        const result = await GeocoderService(ender);
        console.log(result)
        if (result) {
            // let position = { lat: result.position.lat, lng: result.position.lng }
            // setPositionMakerMap(position)
            formRef.current?.setFieldValue('ender', result.address)
        }
    }
    const handleOpenMap = async () => {
        let descr = formRef.current?.getFieldValue('descr')
        let address = formRef.current?.getFieldValue('ender')
        updateConfig({
            MarkerPoint: {
                position: MarkerForm?.position ?? { lat: 0, lng: 0 },
                address: address ?? '',
                editable: true,
                title: descr.length > 0 ? descr : 'Novo Ponto'
            }
        })
        setSideContent(OPEN_MAP)
    }

    useEffect(() => {
        if (!MarkerForm) return;
        formRef.current?.setFieldValue('ender', MarkerForm.address)
    }, [MarkerForm]);


    const handleResponseMap = () => {
        console.log(mapOptions.response)
        formRef.current?.setFieldValue('ender', mapOptions.response?.ender)
        setPositionMakerMap(mapOptions.response?.position)

    }


    React.useEffect(handleResponseMap, [mapOptions.response]);

    const cardNewPass = (
        <React.Fragment>

            <div className='form-box'>
                <Box
                    sx={{
                        height: 44,
                        padding: 1,
                        backgroundColor: '#ededed',
                    }} >
                    <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                        <BarTable CtrlButtons={CtrlBtnsCad} onSaveClick={() => formRef.current?.submitForm()} onCancelClick={handleCancelCad} />

                    </Stack>
                </Box>
                <div className='form-container2 margin-left'>
                    <Form placeholder={""} ref={formRef} onSubmit={handleSubmitSaveCad} className='Input-Style' noValidate={true} initialData={formCadData}>
                        <div className='block-grid'>
                            <div className='subblock'>
                                <Input className='theme-light' name="descr" label='Descrição*' placeholder='Descrição' disabled={isLoading} role="presentation" type='text' />
                                <TextAreaComp rows={5} className='theme-light' name="ender" label='Endereço*' placeholder='Endereço' disabled={isLoading} role="presentation" />
                                <Container>
                                    <InfoText>
                                        {positionMakerMap ? 'Ponto validado' : 'Utilize o botão abaixo para validar o ponto'}
                                    </InfoText>

                                    <ButtonContainer>
                                        <Button onClick={handleOpenMap} type="button">
                                            <AddLocation /> BUSCAR NO MAPA
                                        </Button>

                                    </ButtonContainer>

                                    {(MarkerForm?.position && MarkerForm.position.lat !== 0) && (
                                        <Coordinates>
                                            Coordenadas: Lat: {MarkerForm?.position.lat}, Lng: {MarkerForm?.position.lng}
                                        </Coordinates>
                                    )}
                                </Container>
                            </div>
                            <div className='subblock'>
                                <Input className='theme-light' name="contact" label='Contato' placeholder='Contato' disabled={isLoading} role="presentation" type='text' />
                                <Input className='theme-light' name="document" label='Num Documento' placeholder='Num Documento' disabled={isLoading} role="presentation" type='text' />
                                <Input className='theme-light' name="phone" label='Telefone' placeholder='Telefone' disabled={isLoading} role="presentation" type='phone' />
                                <Input className='theme-light' name="email" label='E-mail' placeholder='E-mail' disabled={isLoading} role="presentation" type='email' />
                            </div>
                            {isLoading ? <LoadingCircularComp /> : <></>}
                        </div>
                    </Form>
                </div>
            </div>

        </React.Fragment>
    );



    const QuickSearchToolbar = () => {
        return (
            <>{cardNewPass}</>
        );
    }


    return <>
        <DialogConfirme open={IsOpenDialogConfime} onClose={handleCloseDialogConfirme} onConfirme={handleConfirmeDialogOK} content={ConfirmeDialogContent} />

        {isShowCad ? <>{QuickSearchToolbar()}</> :

            <WidgetDataGridComp
                isLoading={isLoading}
                rows={listDatagrid}
                columns={columns}
                CheckBoxSelect={true}
                handleSelRow={setSel}
                initialBtns={CtrlBtns}
                onRefresClick={handleRefresh}
                onAddClick={CadNewPlaces}
                onDeleteClick={handleDelete}
            />
        }
        <Sidebar width='700px' isOpen={sideContent === OPEN_MAP} onClose={handleCloseSideBar} title={MarkerPoint?.title}>
            <MapDelivery />
        </Sidebar>
    </>

}

export default React.memo(ListDestComp)