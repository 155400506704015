

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box } from '@mui/material';
import DashboardFuelPage from './views/DashboardFuelPage';
import { FuelProvider } from './Utils/FuelContext';
import DataFuelPage from './views/DataFuelPage';
import FuelStockTab from './views/FuelStockTab';
import SuppliersFuelTab from './views/SuppliersFuelTab';


const FuelMainPage: React.FC = () => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <FuelProvider>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Dashboard" value="1" />
                        <Tab label="Lançamentos" value="2" />
                        <Tab label="Estoque" value="3" />
                        <Tab label="Fornecedores" value="4" />
                    </TabList>
                </Box>
                <div>
                    <TabPanel value="1"><DashboardFuelPage /></TabPanel>
                    <TabPanel value="2"><DataFuelPage /></TabPanel>
                    <TabPanel value="3"><FuelStockTab /></TabPanel>
                    <TabPanel value="4"><SuppliersFuelTab /></TabPanel>
                </div>
            </TabContext>
        </FuelProvider>
    );

}
export default FuelMainPage