import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import { iFuelInvoice, saveFuelEntries, saveTanksFuelServer } from '../repository/TanksRepo';
import SelectTanksSmall from '../components/SelectTanksSmall';
import SelectSuppliersSmall, { iSupplier } from '../components/SelectSuppliersSmall';
import { v4 } from 'uuid';
import { StorageConfig } from '../../../Config/StorageContext';
import { AuthConfig } from '../../../Config/AuthContext';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { add, set } from 'date-fns';
import { FormatterCurrency } from '../../../utils/format';

// Schema de validação com Yup
const schema = yup.object().shape({
    quantity: yup.number().positive().required('A quantidade é obrigatória'),
    pricePerLiter: yup.number().positive().required('O preço por litro é obrigatório'),
    observations: yup.string().optional(),
});

interface TankFormProps {
    initialData?: iFuelInvoice;
    onCancel: () => void;
    onUpdate: () => void;
}

interface FuelTransaction {
    unique_id: string;           // Identificador único para a transação
    tankId: number;               // Identificador do tanque
    fuelId: number;               // Identificador do tipo de combustível
    clientId: number;             // Identificador do cliente
    quantity: number;             // Quantidade de combustível
    pricePerLiter: number;        // Preço por litro de combustível
    supplierUUID: string;         // UUID do fornecedor
    totalValue: number;          // Valor total da transação
}



const TankInvoiceForm: React.FC<TankFormProps> = ({ initialData, onCancel, onUpdate }) => {

    const formRef = useRef<FormHandles>(null);
    const [supplier, setSupplier] = React.useState<iSupplier>();
    const [tank, setTanks] = React.useState<iSupplier>();
    const { user } = AuthConfig();
    const { addNotification } = useNotifications();
    const [totalValue, setTotalValue] = React.useState<number>(0);


    const calculateFuelValue = (quantity: number, pricePerLiter: number) => {
        if (!quantity || !pricePerLiter) {
            setTotalValue(0);
            return;
        }
        setTotalValue(quantity * pricePerLiter);
    }


    const saveInServer = async (payload: any): Promise<any> => {
        try {
            await saveFuelEntries(payload)
            addNotification("Sucesso", "Transação de combustível salva com sucesso", "success");
            onUpdate()
        } catch (error) {
            console.log(error)
            addNotification("Erro", "Erro ao salvar transação de combustível", "error");
        }
    }



    const handleSubmit = async (data: iFuelInvoice) => {
        try {
            formRef.current?.setErrors({}); // Limpa os erros
            await schema.validate(data, { abortEarly: false }); // Validação com Yup
            //  saveTanksFuelServer(data); // Salva os dados no servidor
            if (!supplier || !tank) {
                console.log("Fornecedor ou tanque não selecionado");
                addNotification("Erro", "Fornecedor ou tanque não selecionado", "error");
                return;
            }
            let transaction: FuelTransaction = {
                unique_id: v4(),
                tankId: Number(tank?.identifier || 0),
                fuelId: 0,
                clientId: Number(user?.idclient || 0),
                quantity: data.quantity,
                pricePerLiter: data.pricePerLiter,
                supplierUUID: supplier?.identifier.toString() || "",
                totalValue: data.quantity * data.pricePerLiter
            }
            saveInServer(transaction);
        } catch (err) {
            console.log(err);
            if (err instanceof yup.ValidationError) {
                const errorMessages: { [key: string]: string } = {};
                err.inner.forEach((error) => {
                    if (error.path) {
                        errorMessages[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(errorMessages); // Define os erros
            }
        }
    };

    return (
        <Container>
            <SelectSuppliersSmall

                supplier={supplier}
                updateItem={setSupplier}
            />
            <SelectTanksSmall
                supplier={tank}
                updateItem={setTanks}
            />
            <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={initialData}
                placeholder=""
                noValidate
            >
                <InputFieldForm
                    label="Quantidade"
                    name="quantity"
                    type="number"
                    placeholder="Digite a quantidade"
                    onChange={(e) => calculateFuelValue(Number(e.target.value), Number(formRef.current?.getFieldValue('pricePerLiter')))}
                />

                <InputFieldForm
                    label="Preço por Litro"
                    name="pricePerLiter"
                    type="number"
                    placeholder="Digite o preço por litro"
                    onChange={(e) => calculateFuelValue(Number(formRef.current?.getFieldValue('quantity')), Number(e.target.value))}
                />

                <InfoValue>{FormatterCurrency(totalValue)}</InfoValue>

                <ContainerButton>
                    <CancelButton type="button" onClick={onCancel}>
                        Cancelar
                    </CancelButton>
                    <SaveButton type="submit">
                        Salvar
                    </SaveButton>
                </ContainerButton>
            </Form>
        </Container>
    );
};

export default TankInvoiceForm;

const InfoValue = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin: 16px 0;
    `;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: #f8f8f8;
  border-radius: 8px;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
  position: relative;
  top: 20px;
  bottom: 20px;
`;

const CancelButton = styled.button`
  border: 0.5px solid #f56565;
  color: #f56565;
  text-transform: uppercase;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  
  &:hover {
    background-color: #f56565;
    color: #fff;
  }
`;

const SaveButton = styled.button`
  border: 0.5px solid #48bb78;
  color: #48bb78;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #48bb78;
    color: #fff;
  }
`;
