import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { iMarker } from '../../../interfaces/interfaces';

type ConfigDelivery = {
    tabActive: string
    MarkerPoint: iMarkerPoint | undefined;
    MarkerForm: iMarkerForm | undefined;
    RouterInMap: iRouterInMap | undefined;
    updateConfig: (newConfig: Partial<ConfigDelivery>) => void;
};

const defaultConfig: ConfigDelivery = {
    tabActive: 'DeliveryA',
    updateConfig: () => { },
    MarkerPoint: undefined,
    MarkerForm: undefined,
    RouterInMap: undefined
};

const DeliveryContext = createContext<ConfigDelivery>(defaultConfig);

export const DeliveryConfig = () => {
    return useContext(DeliveryContext);
};

type DeliveryProviderProps = {
    children: ReactNode;
};

export const DeliveryProvider = ({ children }: DeliveryProviderProps) => {
    const [config, setConfig] = useState(defaultConfig);

    /*useEffect(() => {
        // Carregar configurações do localStorage ao montar
        const loadConfig = () => {
            const localConfig = localStorage.getItem('DeliveryConfig');
            if (localConfig) {
                setConfig(JSON.parse(localConfig));
            } else {
                // Salvar configurações padrão no localStorage se não houver registro
                localStorage.setItem('DeliveryConfig', JSON.stringify(defaultConfig));
            }
        };

        loadConfig();
    }, []);*/

    /*  useEffect(() => {
          // Salvar configurações no localStorage sempre que mudarem
          localStorage.setItem('DeliveryConfig', JSON.stringify(config));
      }, [config]);*/

    const updateConfig = (newConfig: Partial<ConfigDelivery>) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...newConfig,
        }));
    };

    return (
        <DeliveryContext.Provider value={{ ...config, updateConfig }}>
            {children}
        </DeliveryContext.Provider>
    );
};


export interface iMarkerPoint {
    position: { lat: number, lng: number };
    address: string;
    editable: boolean;
    title: string;
}
export interface iMarkerForm {
    position: { lat: number, lng: number };
    address: string;
}

export interface iRouterInMap {
    points: iMarker[]
    polyline: string
}
