import { faBatteryEmpty, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatteryThreeQuarters } from "@fortawesome/free-solid-svg-icons";
import { VehicleData } from "../../StreamCam/StreamInterfaces";
import { FilterVehicles } from "../components/FilterVehicles";
import { TrajectData } from "../repository/MapsRepo";
import { JourneyData } from "../components/JourneyTimeline";
import { formatDateToScreenString } from "../../../utils/Converter";



export const checkVehiclsHasOnline = (lastUpdate: string) => {
    if (!lastUpdate) return false;
    const lastUpdateDate = new Date(lastUpdate).getTime();
    const now = new Date().getTime();
    const diff = Math.floor((now - lastUpdateDate) / 1000);
    return diff < 3600;
}

export const prepareStatusFilter = (dados: VehicleData[]): FilterVehicles[] => {
    let filter: FilterVehicles[] = [];
    let countOffline = 0;
    let countMove = 0;
    let countBatCut = 0;


    dados.forEach((vehicle) => {
        if (!checkVehiclsHasOnline(vehicle.dtPing)) {
            countOffline++;
        } else {
            if (vehicle.gpsvel > 0)
                countMove++;
        }
        if (Number(vehicle.batveic || 0) < 2)
            countBatCut++;
    });

    filter.push({
        type: 'offline',
        count: countOffline,
        title: 'Offline'
    });

    filter.push({
        type: 'move',
        count: countMove,
        title: 'Em movimento'
    });

    filter.push({
        type: 'batcut',
        count: countBatCut,
        title: 'Corte de bateria'
    });

    return filter
}

export const formateTime = (diffSeconds: number) => {
    const totalMinutos = Math.floor(diffSeconds / 60);
    const dias = Math.floor(totalMinutos / 1440); // 1440 minutos em um dia
    const horas = Math.floor((totalMinutos % 1440) / 60);
    const minutos = totalMinutos % 60;
    const segundos = diffSeconds % 60; // Segundos restantes após minutos

    let resultado = '';

    if (dias > 0) {
        resultado += `${dias}d `;
    }

    if (horas > 0) {
        resultado += `${horas}h `;
    }

    if (minutos > 0) {
        resultado += `${minutos}min `;
    }

    if (segundos > 0) {
        resultado += `${segundos}s`;
    }

    return resultado.trim();
}


export const getIsCamera = (typeDevice: string) => {
    const isCam = ['JC4xx', 'JC450', 'JC400-WABA'].includes(typeDevice ?? '');
    return isCam;
}


export const checkIsOnline = (lastUpdate: string) => {
    if (!lastUpdate) return false;
    const lastUpdateDate = new Date(lastUpdate).getTime();
    const now = new Date().getTime();
    const diff = Math.floor((now - lastUpdateDate) / 1000);
    return diff < 3600;
}


export const getBatteryIconAndColor = (batteryPercentage: number) => {
    if (batteryPercentage > 75) {
        return { iconBatBck: faBatteryFull, colorBatBck: 'green' };
    } else if (batteryPercentage > 50) {
        return { iconBatBck: faBatteryThreeQuarters, colorBatBck: 'limegreen' };
    } else if (batteryPercentage > 25) {
        return { iconBatBck: faBatteryHalf, colorBatBck: 'orange' };
    } else if (batteryPercentage > 0) {
        return { iconBatBck: faBatteryQuarter, colorBatBck: 'red' };
    } else {
        return { iconBatBck: faBatteryEmpty, colorBatBck: 'darkred' };
    }
};

export const getSignalColor = (signalStrength: number) => {
    if (signalStrength > 75) {
        return 'green';
    } else if (signalStrength > 50) {
        return 'limegreen';
    } else if (signalStrength > 25) {
        return 'orange';
    } else if (signalStrength > 0) {
        return 'red';
    } else {

        return 'darkred';
    }
};

export const getCardStatus = (status: boolean, ignOn: boolean) => {
    return status ? ignOn ? 'success' : 'warning' : 'error';
}


function haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const toRad = (value: number) => value * (Math.PI / 180); // Conversão de graus para radianos

    const R = 6371000; // Raio da Terra em metros
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distância em metros
}

export function processTrajectoryData(data: TrajectData[]): {
    averageSpeed: number;
    maxSpeed: number;
    totalStoppedTime: number; // Tempo total parado em segundos
    totalMovingTime: number;  // Tempo total em movimento em segundos
    totalDistance: number;    // Total de distância percorrida em quilômetros
    events: JourneyData[];
} {
    let totalSpeed = 0;
    let speedCount = 0;
    let maxSpeed = 0;

    let totalStoppedTime = 0; // Acumula o tempo parado
    let totalMovingTime = 0;  // Acumula o tempo em movimento
    let totalDistance = 0;    // Acumula a distância total percorrida

    const events: JourneyData[] = [];
    let currentEvent: JourneyData | null = null;
    let stopTime: Date | null = null;

    data.forEach((item, index) => {
        const currentSpeed = item.speed;
        const currentIgn = item.ign === 'ON';
        const currentTime = new Date(item.dthr_equip);
        stopTime = stopTime || currentTime;

        // Velocidade média e máxima
        if (currentIgn) {
            totalSpeed += currentSpeed;
            speedCount++;
            if (currentSpeed > maxSpeed) maxSpeed = currentSpeed;
        }

        // Início de um novo evento
        if (!currentEvent) {
            currentEvent = {
                dtStart: formatDateToScreenString(item.dthr_equip),
                dtEnd: formatDateToScreenString(item.dthr_equip),
                dtStartDate: currentIgn ? currentTime : stopTime,
                dtEndDate: currentTime,
                duration: 0,  // Valor inicial
                locationName: "No Address", // Adicionar localização se disponível
                distance: 0, // Reinicializa a distância
                timeTaken: 0,
                isStop: !currentIgn,  // true se o evento for de parada (ignição OFF)
                lat: parseFloat(item.lat),
                lng: parseFloat(item.lng),
            };
        }

        // Verifica se a ignição mudou ou se é o último item
        const nextItem = data[index + 1];
        if (nextItem) {
            const nextLat = parseFloat(nextItem.lat);
            const nextLng = parseFloat(nextItem.lng);
            const currentLat = parseFloat(item.lat);
            const currentLng = parseFloat(item.lng);

            // Se a ignição estiver ligada e temos um próximo ponto, calculamos a distância
            if (currentIgn && currentLat && currentLng && nextLat && nextLng) {
                const distance = haversineDistance(currentLat, currentLng, nextLat, nextLng) / 1000; // Converte para km
                currentEvent.distance += distance; // Incrementa a distância no evento atual em km
            }
        }

        // Atualiza a duração do evento
        currentEvent.dtEndDate = currentTime;
        const durationInSeconds = (currentEvent.dtEndDate.getTime() - currentEvent.dtStartDate.getTime()) / 1000;
        currentEvent.duration = durationInSeconds;
        if (!nextItem || nextItem.ign !== item.ign) {
            // Acumula o tempo parado ou em movimento
            if (currentEvent.isStop) {
                totalStoppedTime += durationInSeconds; // Acumula o tempo parado
                stopTime = currentTime;
            } else {
                totalMovingTime += durationInSeconds; // Acumula o tempo em movimento
                totalDistance += currentEvent.distance; // Acumula a distância percorrida
            }

            // Adiciona o evento à lista de eventos
            events.push(currentEvent);

            // Resetar o evento
            currentEvent = null;
        }
    });

    // Calcular velocidade média
    const averageSpeed = speedCount > 0 ? totalSpeed / speedCount : 0;

    return {
        averageSpeed,
        maxSpeed,
        totalStoppedTime, // Tempo total parado
        totalMovingTime,  // Tempo total em movimento
        totalDistance,    // Distância total em quilômetros
        events,
    };
}