

import React from 'react';

import './PainelRight.css'
import { useSelector } from 'react-redux';
import { selectAppBar } from '../../redux/appBarSlice';
import AlertsPanelRight from './AlertsPanelRight';

const PanelRightComp: React.FC = () => {
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    const { appbar } = useSelector(selectAppBar);

    /*React.useMemo(() => {
        (async () => {
            if (!scriptLoaded) {
                console.log("Loading map...")
                const googleMapScript = loadMapApi2222();
                googleMapScript.addEventListener('load', function () {
                    setScriptLoaded(true);
                });
            }
        })();
    }, [appbar.openMap]);*/

    return (
        <>
            {/* <div className={`PainelBase ${appbar.openMap ? 'Opened' : ''}`}>
                {scriptLoaded && (
                    <GoogleMapV2 />
                )}
            </div>
            <div className={`PainelBaseAlerts ${appbar.openAlerts ? 'Opened' : ''}`}>
                <AlertsPanelRight />
            </div> */}
        </>
    )

}
export default PanelRightComp