import { Badge } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface TabButtonProps {
    title: string;
    isActive: boolean;
    badgeContent?: number;
    onClick: () => void;
}


const ButtonContainer = styled.button<{ isActive: boolean }>`
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 14px;
    height:fit-content;
    font-weight: ${({ isActive }) => (isActive ? 700 : 600)};   
    color: ${({ isActive }) => (isActive ? '#242424' : '#777777')};

    &:focus {
        outline: none;
    }
`;

const Title = styled.span`
    margin-bottom: 5px;
`;

const ActiveIndicator = styled.div`
    width: 50px;
    height: 3px;
    background-color: #FFA500;
    border-radius: 2px;
`;



const TabButton: React.FC<TabButtonProps> = ({ title, isActive, onClick, badgeContent }) => {
    return (
        <Badge color="secondary" badgeContent={badgeContent} max={9999}>
            <ButtonContainer onClick={onClick} isActive={isActive}>
                <Title>{title}</Title>
                {isActive && <ActiveIndicator />}
            </ButtonContainer>
        </Badge>
    );
};

export default TabButton;


