import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div<{ visible: boolean; top: number; left: number }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 120px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  position: absolute;
  z-index: 9999;
  bottom: 125%;
  left: 50%;
  margin-left: 0px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s, visibility 0.3s;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    top: 100%; /* No fundo do tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`;

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top + window.scrollY,
        left: rect.left + rect.width / 2 - 60, // Centralizar o tooltip
      });
    }
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const tooltip = (
    <TooltipText visible={isVisible} top={tooltipPosition.top} left={tooltipPosition.left}>
      {text}
    </TooltipText>
  );

  return (
    <>
      <TooltipContainer
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </TooltipContainer>
      {ReactDOM.createPortal(
        <div style={{ position: 'absolute', top: tooltipPosition.top, left: tooltipPosition.left }}>
          {tooltip}
        </div>,
        document.body
      )}
    </>
  );
};

export default Tooltip;
