import React, { useEffect } from 'react';
import ConfirmationDialog, { typeMethods } from '../../components/datagridComp/ConfirmationDialog';
import { getAlerts, reqAlerts, setReadAlerts } from './repository/AlertsRepo';
import { Column, SelectColumn } from 'react-data-grid';
import ModalComp from '../../components/datagridComp/ModalComp';
import LoadingIndicator from '../../components/datagridComp/LoadingComp';
import DataGridComp from '../../components/datagridComp/DataGridComp';
import ButtonEdit from '../../components/datagridComp/ButtonEdit';
import { PriorityItem, BtnCheckBox, ContainerBarTop, ContainerInfo, ContainerSearch, ContainerSpan, ContainerTable, GridContainer, InfoBox, SearchAndSelectContainer, PointsItem } from './style/StyleAlerts';
import SearchField from '../../components/datagridComp/SearchField';
import ActionSelect from '../../components/datagridComp/ActionSelect';
import ColumnSelect from '../../components/datagridComp/ColumnSelect';
import ButtonRefresh from '../../components/datagridComp/ButtonRefresh';
import ButtonAdd from '../../components/datagridComp/ButtonAdd';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import DataDisplay from './Components/DataDisplay';
import AlertsCard from './Components/AlertsCard';
import { faTable, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iReduxMap } from '../../interfaces/interfaces';
import { ActionMaps } from '../../utils/values';
import { changeMap } from '../../redux/mapSlice';
import { AlertsItemModels, formaterAlert } from './models/AlertItemModels';


interface iModelCmd {
    onConfirm: (value?: any) => Promise<void> | void;
    message: string
    title?: string
    requireJustification: boolean
    type: typeMethods

}

const LOADING_CONTENT = 'loading'
const CONFIRMED_ACTION = 'confirm_action'
const JUSTIFIED_ACTION = 'justified_action'

const optionsActions = [
    { value: 'SETREAD', label: 'Marcar como Lida' },
    { value: 'SETREASON', label: 'Justificar' }
];

const AlertsPageTable: React.FC = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(selectUser);
    const [isTableView, setViewTable] = React.useState(() => {
        const saved = localStorage.getItem('viewTableAlerts');
        return saved === null ? true : saved === 'true';
    });
    const [searchTextField, setSearchText] = React.useState<string>('');

    //modal
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const handleCloseModelCmd = () => setModelCmd(undefined);
    const [modalContent, setModalContent] = React.useState<string>('');
    const [modelCmd, setModelCmd] = React.useState<iModelCmd>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<AlertsItemModels[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<AlertsItemModels[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<AlertsItemModels>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));

    React.useEffect(() => {
        getDados()
    }, [])
    React.useEffect(() => {
        localStorage.setItem('viewTableAlerts', isTableView.toString());
    }, [isTableView]);

    const columns: Column<AlertsItemModels>[] = [
        {
            ...SelectColumn,
            name: 'Selecionar',
            width: 150,
        },
        {
            key: 'isRead',
            name: 'Lida',
            width: 35,
            renderCell(props: any) {
                return (
                    props.row.status_info === 0 ? <div title="Nova Notificação">✉️</div> : <div title="Notificação Lida">📝</div>

                )
            },
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },

        {
            key: 'edit',
            name: 'Ações',
            width: 80,
            renderCell(props: any) {
                return (
                    <BtnCheckBox onClick={() => viewInMap(props.row)}>📌 Mapa</BtnCheckBox>
                )
            }
        },
        {
            key: 'vehicle',
            name: 'Veículo',
            width: 150,
        },
        {
            key: 'driverName',
            name: 'Motorista',
            width: 150,
        },
        {
            key: 'priority',
            name: 'Nível',
            width: 90,
            renderCell(props: any) {
                return (
                    <PriorityItem level={props.row.priority}>{['', '🟢 Baixa', '🟣 Média', '🔴 Alta'][props.row.priority]}</PriorityItem>
                )
            },
        },
        {
            key: 'points',
            name: 'Pontos',
            width: 75,
            renderCell(props: any) {
                return (
                    <PointsItem title='Pontos perdidos do motorista' show={props.row.points > 0}>⛔ {props.row.points}</PointsItem>

                )
            },
        },

        {
            key: 'dtevtAt',
            name: 'Data',
            width: 180,

        },
        {
            key: 'title',
            name: 'Titulo',
            width: 180
        },
        {
            key: 'descr',
            name: 'Descrição',
            width: 350
        },


    ];


    const handleSearch = () => {
        let searchText = searchTextField
        const filtered = rows.filter((item: AlertsItemModels) => {
            const normalizedSearchText = searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();

            // Verifica se algum valor do veículo corresponde ao texto de busca
            const matchesSearchText = Object.values(item).some((value) => {
                const normalizedValue = String(value)
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            });

            // let isOnline = checkVehiclsHasOnline(vehicle.dtPing);
            // let isMoving = vehicle.gpsvel > 0 && isOnline;
            // let isBatteryCut = Number(vehicle.batveic || 0) < 2;

            // const matchesStatusFilter =
            //     filterVehicles.length === 0 || // Se nenhum filtro foi passado, retorna todos
            //     (filterVehicles.includes("offline") && !isOnline) ||
            //     (filterVehicles.includes("move") && isMoving) ||
            //     (filterVehicles.includes("batcut") && isBatteryCut);

            return matchesSearchText;

        })


        setFilteredRows(filtered);
    };

    const handleEdit = (row: AlertsItemModels) => {
        // console.log(row)
    }
    const handleClickRow = (row: AlertsItemModels) => {
        // console.log(row)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const setAlertsRead = async (reason?: string) => {
        console.log(reason)
        try {
            const payload: any = {
                idalerts: Array.from(selectedRows),
                reason: reason
            }
            const result = await setReadAlerts(payload);
            deselectAll()
            setIsModalOpen(true)
            setModalContent(CONFIRMED_ACTION)
            getDados()
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }

    const handleSelectAction = (action: string) => {
        switch (action) {
            case 'SETREASON':
                setIsModalOpen(true)
                setModalContent(JUSTIFIED_ACTION)
                break
            case 'SETREAD':
                setAlertsRead()
                /*setIsModalOpen(true)
                setModalContent(LOADING_CONTENT)*/
                break
        }
    };

    async function getDados() {
        try {
            const payload: reqAlerts = {
                idclient: user.idcliente
            }
            const result = await getAlerts(payload);
            const alerts = formaterAlert(result)
            setRows(alerts)
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }

    useEffect(() => {
        handleSearch()
    }, [rows])

    useEffect(() => {
        handleSearch();
    }, [searchTextField]);

    const handleSelectByCard = (id: number) => {
        setSelectedRows((prevSelectedRows) => {
            const newSet = new Set(prevSelectedRows);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((alert) => alert.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const viewInMap = (alert: AlertsItemModels) => {

        let mapConfig: iReduxMap = {
            mapEvtClick: false,
            resetMap: false,
            action: ActionMaps.SHOW_MARKER,
            ender: '',
            marker: {
                position: { lat: Number(alert.lat), lng: Number(alert.lng) },
                isDragable: false
            }
        }
        dispatch(changeMap(mapConfig))
    }

    return (
        <>
            <ContainerTable>
                <>
                    <SearchAndSelectContainer>
                        <SearchField onSearch={setSearchText} />
                        <ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
                            title='Ações'
                        />
                        <ColumnSelect
                            columns={columns}
                            onColumnVisibilityChange={(visibleColumnKeys) => {
                                setVisibleColumns(visibleColumnKeys);
                            }}
                        />
                        <ButtonRefresh onClick={getDados} />


                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <BtnCheckBox onClick={() => setViewTable(true)}>
                            <FontAwesomeIcon icon={faTable} /> Tabela
                        </BtnCheckBox>
                        <BtnCheckBox onClick={() => setViewTable(false)}>
                            <FontAwesomeIcon icon={faAddressCard} /> Card
                        </BtnCheckBox>
                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    {isTableView ? <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        visibleColumns={visibleColumns}
                        onRowClick={handleClickRow}
                        onRowSelect={handleRowSelect}
                    /> :
                        <GridContainer>
                            {filteredRows.map((alert) => (
                                <AlertsCard
                                    key={alert.id}
                                    alert={alert}
                                    onSelect={handleSelectByCard}
                                    isSelected={selectedRows.has(alert.id)}
                                />
                            ))}
                        </GridContainer>}

                </>
            </ContainerTable>
            {isModalOpen && (
                <ModalComp title="" subtitle="" onClose={handleClose}>
                    {modalContent === LOADING_CONTENT && <LoadingIndicator />}
                    {modalContent === CONFIRMED_ACTION && <ConfirmationDialog type='CONFIRMED' message="Operação realizada com sucesso!" onConfirm={() => handleClose()} onCancel={() => handleClose()} />}
                    {modalContent === JUSTIFIED_ACTION && <ConfirmationDialog requireJustification={true} type='QUESTION' message="Inform o motivo da ação!" onConfirm={setAlertsRead} onCancel={() => handleClose()} />}

                </ModalComp>
            )}
        </>
    )

}
export default AlertsPageTable