// src/components/NotificationContainer.tsx

import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNotifications } from './NotificationContext';

const fadeOut = keyframes`
  from { width: 100%; }
  to { width: 0%; }
`;

const NotificationBox = styled.div<{ type: 'error' | 'info' | 'success' | 'warning' }>`
  display: flex;
  align-items: center; 
  min-width: 300px;
  max-width: 400px;
  padding: 10px;
  margin: 5px 0;
  color: ${({ type }) => {
    switch (type) {
      case 'error': return 'white';
      case 'info': return 'white';
      case 'success': return 'white';
      case 'warning': return 'black';
    }
  }};;
background-color: ${({ type }) => {
    switch (type) {
      case 'error': return 'red';
      case 'info': return 'blue';
      case 'success': return 'green';
      case 'warning': return 'darkorange';
    }
  }};
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;


const ProgressBar = styled.div<{ duration: number }>`
  background-color: rgba(255, 255, 255, 0.5);
  height: 5px;
  animation: ${fadeOut} linear ${({ duration }) => duration}ms forwards;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
`;

const SvgAlertClass = styled.img`
  fill: rgb(223, 16, 16);
  color: blue;
  width: 70px;
  height: 70px;
  stroke: #111ed6;
  margin-right: 10px;
`;


export const NotificationContainer: React.FC = () => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <div style={{ position: 'fixed', bottom: '20px', left: '20px', zIndex: 1000, display: 'flex', flexDirection: 'column-reverse' }}>
      {notifications.map(({ id, title, message, type, iconSvg }) => (

        <NotificationBox key={id} type={type}>

          {iconSvg && <SvgAlertClass src={iconSvg} alt="Notification Icon" />}
          <div style={{ flex: 1 }}>
            <TitleBar>
              <strong>{title || "Notificação"}</strong>
              <CloseButton onClick={() => removeNotification(id)}>X</CloseButton>
            </TitleBar>
            <div>{message}</div>
            <ProgressBar duration={3000} />
          </div>
        </NotificationBox>
      ))}
    </div>
  );
};

