import React, { useEffect, useState } from 'react';
import { ctrlButtons, iItemDespesa, iItemTravel } from '../interfaces/interfaces';
import { ContainerFlexInline } from '../styles/Styles';
import WidgetDataGridComp from '../widgets/WidgetDataGrid';
import BarTable from './BarTable';
import CardDriverWidget from './Widgets/CardDriverWidget';
import CardVehicleWidget from './Widgets/CardVehicleWidget';
import styled from 'styled-components';
import { GridColDef } from '@mui/x-data-grid';

import { IconButton } from '@mui/material';
import { FileOpen, Edit } from '@mui/icons-material';
import httpCommon from '../services/http-common';
import DespesasModal from './Modal/DespesasModal';

interface iProps {
    travel: iItemTravel | undefined
    onCancelClick: () => void
}

const CtrlBtns: ctrlButtons = {
    RefreshShow: true,
    addShow: true,
    DeleteShow: true
}

const CtrlBtnsCad: ctrlButtons = {
    CancelShow: true
}

const Container = styled('div')({
    background: '#f6f9ff',
    width: '100%'
});

const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '10px'
});


const TravelDetailsComp: React.FC<iProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isOpenCadDespesas, setOpenCadDespesas] = React.useState<boolean>(false);
    const [listDatagrid, setListDatagrid] = React.useState<iItemDespesa[]>([]);
    const [formCadData, setformCadData] = React.useState<iItemDespesa | undefined>();


    const handleGetListDespesas = async () => {
        const result = await httpCommon.get("ListDespesasByFilter", { params: { idtravel: props.travel?.id } })
        let resp = result.data;
        console.log(resp)
        setListDatagrid(resp)
    }


    const columns: GridColDef[] = [
        {
            field: 'actions', headerName: 'Eventos', width: 80,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => OpenItemClick(params.row)}>
                            <FileOpen />
                        </IconButton>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => EditItemClick(params.row)}>
                            <Edit />
                        </IconButton>
                    </>
                );
            }


        },
        {
            field: 'id',
            headerName: 'Cód',
            width: 90
        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width: 150,
        },
        {
            field: 'amount',
            headerName: 'Valor',
            width: 80,
        },
        {
            field: 'categ',
            headerName: 'Categoria',
            width: 150,
        },
        {
            field: 'DriverName',
            headerName: 'Colaborador',
            width: 180,
        },
        {
            field: 'veiculo_placa',
            headerName: 'Veículo',
            width: 150
        }

    ];


    const OpenItemClick = (item: iItemDespesa) => {
        console.log(item)
    }
    const EditItemClick = (item: iItemDespesa) => {
        setformCadData(item)
        setOpenCadDespesas(true)
    }
    const handleRefresh = () => {
        handleGetListDespesas()
    }
    const handleCloseModalDespesa = () => {
        setOpenCadDespesas(false)
    }
    const handleCadDespesa = () => {
        setformCadData(undefined)
        setOpenCadDespesas(true)
    }


    React.useEffect(() => {
        console.log("useEffect Details Travel", props.travel)
        handleGetListDespesas()
    }, [props.travel])


    return (
        <Container>
            {<DespesasModal onClose={handleCloseModalDespesa} open={isOpenCadDespesas} ParamsData={formCadData} />}
            <BarTable CtrlButtons={CtrlBtnsCad} onCancelClick={() => props.onCancelClick()} />
            <ContainerFlexInline>
                {<CardDriverWidget idMotorista={props.travel?.idmotorista} />}
                {<CardVehicleWidget idVehicle={props.travel?.idvehicle} />}
            </ContainerFlexInline>
            <ContainerTable>
                <WidgetDataGridComp
                    isLoading={isLoading}
                    rows={listDatagrid}
                    columns={columns}
                    CheckBoxSelect={true}
                    //handleSelRow={setSel}
                    initialBtns={CtrlBtns}
                    onRefresClick={handleRefresh}
                    onAddClick={handleCadDespesa}
                //onDeleteClick={handleDelete}
                />
            </ContainerTable>

        </Container>
    )

}
export default TravelDetailsComp