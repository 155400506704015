
// EventCard.tsx
import React from 'react';
import styled from 'styled-components';
import { ExpenseItemScreen } from '../repository/ExpensesRepo';
import { iReduxMap } from '../../../interfaces/interfaces';
import { ActionMaps } from '../../../utils/values';
import { changeMap } from '../../../redux/mapSlice';
import { useDispatch } from 'react-redux';
import { FormatterCurrency } from '../../../utils/format';
import { ReactComponent as GasIcon } from '../../../assets/icons/gas-station.svg';
import { ReactComponent as FuelIcon } from '../../../assets/icons/fuel-pump.svg';
import StatusChip from './StyledChip';
import ImageGallery from './ImageGallery';
import { BtnCheckBox } from '../style/StyleExpenses';

type ExpensesProps = {
  Expense: ExpenseItemScreen;
  onSelect: (id: number) => void;
  editItem: (Expense: ExpenseItemScreen) => void;
  isSelected: boolean;
};

const Card = styled.div<{ isSelected: boolean }>`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
  width: calc(100% - 16px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: ${({ isSelected }) => (isSelected ? '#e7f3ff' : 'white')};
`;

const TitleCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 2px;
`;

const Data = styled.span`
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  margin-right: 8px;
`;

const Points = styled.span<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inline' : 'none')};
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

const Priority = styled.span<{ level: number }>`
  /*background-color: ${({ level }) => (level === 1 ? 'lightgreen' : level === 2 ? 'yellow' : 'red')};*/
  /*color: ${({ level }) => (level === 3 ? 'white' : 'black')};*/
  color: black;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;
const Detail = styled.div`
  margin-bottom: 4px;
`;

const DetailWithIcons = styled.div`
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 8px; // Espaço entre ícones e texto
`;

// Placeholder para os ícones. Substitua estas linhas pelos componentes de ícone reais.
const VehicleIcon = styled.span`
cursor: pointer;
  &:before {
    content: "🚗"; // Placeholder para ícone de veículo
  }
`;

const IconsInfo = styled.span`
  cursor: pointer;
  display: inline-block; // Para permitir a aplicação do background
  width: 24px; // Largura do ícone
  height: 24px; // Altura do ícone
`;


const DriverIcon = styled.span`
cursor: pointer;
  &:before {
    content: "👤"; // Placeholder para ícone de motorista
  }
`;

const Separator = styled.div`
  height: 1px;
  background-color: #e0e0e0; // Escolha uma cor que combine com o design do seu card
  margin: 4px 0; // Ajuste as margens conforme necessário para o layout
`;

const InfoButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const MapIcon = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  &:before {
    content: '📌'; // Placeholder para ícone de mapa
  }
`;


const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
`;

const TextOverflow = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const ExpensesCard: React.FC<ExpensesProps> = ({ Expense, onSelect, isSelected, editItem }) => {

  const dispatch = useDispatch()

  function removeHTMLTags(str: string): string {
    return str.replace(/<[^>]*>?/gm, '');
  }


  const viewInMap = () => {

    let mapConfig: iReduxMap = {
      mapEvtClick: false,
      resetMap: false,
      action: ActionMaps.SHOW_MARKER,
      ender: '',
      marker: {
        position: { lat: Number(Expense.lat), lng: Number(Expense.lng) },
        isDragable: false
      }
    }
    dispatch(changeMap(mapConfig))
  }

  return (
    <Card isSelected={isSelected}>
      <TitleCheckboxContainer>
        <Title>{Expense.descr}</Title>
        <input
          type="checkbox"
          checked={isSelected}
          onClick={(e) => e.stopPropagation()} // Impede que o clique no checkbox dispare o evento de clique do card
          onChange={() => onSelect(Expense.id)} // Altera o estado de seleção quando o checkbox é modificado
        />
      </TitleCheckboxContainer>
      <Separator />
      <InfoButtonContainer>
        <div>
          <Data title='Data do Evento'>{new Date(Expense.evt_at).toLocaleString()}</Data>
          <BtnCheckBox onClick={() => editItem(Expense)}>✏️ Editar Despesas</BtnCheckBox>
        </div>
        <StatusChip status={Expense.status} />
      </InfoButtonContainer>
      <DetailContainer>
        <span>Cód: {Expense.id} |</span>
        <VehicleIcon title="Dados do Veículo" />
        <span>{Expense.placa} |</span>
        <DriverIcon title="Dados do Motorista" />
        <TextOverflow >
          {Expense.drivername}
        </TextOverflow>
      </DetailContainer>
      <Separator />
      {Expense.id_categ === 1 && <DetailWithIcons className="flex items-center space-x-2">
        <GasIcon title='Total de Litros' className="h-5 w-5 flex-none stroke-orange-200 fill-orange-500" />
        {FormatterCurrency(Expense.price_unity)}/L | {Expense.qty}L |
        <span className="flex items-center">
          <FuelIcon title='Tipo de Combustível' className="h-5 w-5 flex-none stroke-orange-200 fill-orange-500" />
          {Expense.fuel_descr}
        </span>

      </DetailWithIcons>}
      {Expense.id_categ === 1 && <Separator />}
      <ImageGallery filenames={[
        Expense.photo1,
        Expense.photo2,
        Expense.photo3

      ]} />
    </Card>
  );
};

export default ExpensesCard;
