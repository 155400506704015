import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';

// Props para receber os estados das condições e o callback do componente pai
interface SaveProgressProps {
    nameFlag: string;
    markerPosition: boolean;
    isCircleMode: boolean;
    isPolygonSaved: boolean;
    onEnableSave: (isEnabled: boolean) => void;
}

// Estilos dos componentes
const Container = styled.div`
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 317px;
`;

const StepItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: -1px;
`;

const Label = styled.label<{ completed: boolean }>`
    font-size: 14px;
    color: ${({ completed }) => (completed ? '#4CAF50' : '#333')};
    margin-left: 8px;
`;

// Componente principal
const SaveProgress: React.FC<SaveProgressProps> = ({ nameFlag, markerPosition, isCircleMode, isPolygonSaved, onEnableSave }) => {
    const isName = nameFlag.length > 0;
    const isMarker = markerPosition;
    const isCircle = isCircleMode && !isPolygonSaved;
    const isPolygon = isPolygonSaved && !isCircleMode;

    // Condição para habilitar o salvamento
    const enableSave = isName && isMarker && (isCircle || isPolygon);

    // Chamada ao componente pai para habilitar o botão de salvamento
    useEffect(() => {
        onEnableSave(enableSave);
    }, [enableSave, onEnableSave]);

    return (
        <Container>
            <h3>Progresso para Salvar</h3>
            <StepItem>
                <FaCheckCircle color={isName ? '#4CAF50' : '#999'} />
                <Label completed={isName}>Nome preenchido</Label>
            </StepItem>
            <StepItem>
                <FaCheckCircle color={isMarker ? '#4CAF50' : '#999'} />
                <Label completed={isMarker}>Posição do Ponto no Mapa</Label>
            </StepItem>
            {isCircleMode && !isPolygonSaved && (
                <StepItem>
                    <FaCheckCircle color={isMarker && isCircle ? '#4CAF50' : '#999'} />
                    <Label completed={isMarker && isCircle}>{isMarker ? 'Círculo definido' : 'Criar Raio'}</Label>
                </StepItem>
            )}
            {!isCircleMode && (
                <StepItem>
                    <FaCheckCircle color={isPolygon ? '#4CAF50' : '#999'} />
                    <Label completed={isPolygon}>Criar Polígono</Label>
                </StepItem>
            )}
        </Container>
    );
};

export default SaveProgress;
