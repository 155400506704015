import React from 'react';

interface Option {
    value: string;
    label: string;
}

interface ActionSelectProps {
    options: Option[];
    onSelect: (action: string) => void;
    title: string;
}

const ActionSelect: React.FC<ActionSelectProps> = ({ options, onSelect, title }) => {
    const [selected, setSelected] = React.useState('');

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onSelect(event.target.value);
        setSelected('');
    };

    return (
        <select
            value={selected}
            onChange={handleSelectChange}
            className="border border-gray-300 px-4 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
        >
            <option value="" disabled selected>{title}</option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
            ))}
        </select>
    );
};

export default ActionSelect;
