
import styled from 'styled-components';


export const ContainerDate = styled.div`
    display: block;`

export const ContainerHours = styled.div`
    display: block;
    margin-left: 10px;
    & > Input {
        text-align: center;
        width: 80px;
        border: 1px solid #b1b1b1;
    }`
export const ContainerInput80px = styled.div`
display: block;
margin-right: 10px;
& > Input {
    text-align: left;
    width: 80px;
    border: 1px solid #b1b1b1;
}`

export const ContainerSelect = styled.div`
display: block;
& > select {
    text-align: left;
    width: 220px;
    border: 1px solid #b1b1b1;
}`

export const ContainerPicker = styled.div`
    display: flex;
`
export const ContainerInline = styled.div`
    display: flex;
`

export const ContainerCard = styled.div`
      display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px;
    background: #ffffff;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 15%);
    width: 300px;
`



export const Label1 = styled.div`
   margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    padding: 16px 24px;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
`