

import React, { useEffect, useState } from 'react';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../../Messages/Style/StyleMessages';
import { handleSearch } from '../Utils/searchUtils';
import { getColumns } from '../Utils/FuelColumns';
import { getFuelInvoices, iFuelInvoices, reqDashboardFuel } from '../repository/FuelRepo';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { FuelConfig } from '../Utils/FuelContext';
import { formatDateDayJs } from '../../../utils/Converter';
import NoData from '../../../components/NoData';
import DateFilterFuel from '../components/DateFilterFuel';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../../components/datagridComp/SideBar';
import FormFuel from '../FormsFuel/FormFuelView';
import FormFuelView from '../FormsFuel/FormFuelView';
import { v4 as uuidv4 } from 'uuid';
import { iFuelInvoicesScreen } from '../models/FuelListModels';
import { get } from 'http';

const OPEN_FORM = 'OPEN_FORM';
const EDIT_FORM = 'EDIT_FORM';
const NEW_FORM = 'NEW_FORM';

const DataFuelPage: React.FC = () => {
    const { addNotification } = useNotifications();
    const { dateStartFilter, dateEndFilter, updateConfig } = FuelConfig();

    const [InvoiceEdit, setInvoiceEdit] = React.useState<iFuelInvoicesScreen>();

    const [findInputTerms, setFindInputTerms] = useState<string>('');


    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();
    const handleCloseSideBar = () => setSideContent(undefined);

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<iFuelInvoicesScreen[]>([]);
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);


    async function getFuelByVehicle() {
        try {

            let dtFilter = {
                dtStart: formatDateDayJs(dateStartFilter),
                dtEnd: formatDateDayJs(dateEndFilter),
                lastreg: 1000
            }
            let payload: reqDashboardFuel = {
                dtFilter: dtFilter,
            }
            const result = await getFuelInvoices(payload);
            setRows(result);

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }


    // Função para abrir no mapa, controlada pelo componente pai
    const handleViewInMap = (row: iFuelInvoicesScreen) => {
        console.log('Abrindo no mapa:', row);
        // Lógica adicional aqui
    };

    // Função para editar o item, controlada pelo componente pai
    const handleEditItem = (row: iFuelInvoicesScreen) => {
        setInvoiceEdit(row);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    React.useEffect(() => {
        getFuelByVehicle()
    }, [dateStartFilter, dateEndFilter]);

    React.useEffect(() => {
        if (InvoiceEdit) {
            setSideContent(EDIT_FORM);
        }
    }, [InvoiceEdit]);

    const CancelFormFuel = () => {
        setInvoiceEdit(undefined);
        setSideContent(undefined);
    }




    // Obtenha as colunas passando as funções de controle
    const columns = getColumns(handleViewInMap, handleEditItem);

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((alert) => alert.id));
        setSelectedRows(allIds);
    };

    const handleNewInvoice = () => {
        setInvoiceEdit(undefined);
        setSideContent(NEW_FORM);
    };

    const handleUpdateList = () => {
        getFuelByVehicle();
        CancelFormFuel();
    };

    return (
        <>

            <SearchAndSelectContainer>
                <DateFilterFuel />
            </SearchAndSelectContainer>
            {rows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <SearchAndSelectContainer>
                        <ContainerSearch>
                            <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                        </ContainerSearch>
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getFuelByVehicle} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        <BtnFaIcon title='Cadastrar Novo Abastecimento' onClick={handleNewInvoice} >
                            <BoxIcon icon={faAdd} iconColor='green' /> Novo Abastecimento
                        </BtnFaIcon >


                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    <ContainerTable
                    ><DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}

            {InvoiceEdit && <Sidebar width='800px' isOpen={sideContent === EDIT_FORM} onClose={CancelFormFuel} title={'Editar Abastecimento'}>
                <FormFuelView updateList={handleUpdateList} uuid={uuidv4()} initialData={InvoiceEdit} closeForm={CancelFormFuel} />
            </Sidebar>
            }
            <Sidebar width='800px' isOpen={sideContent === NEW_FORM} onClose={CancelFormFuel} title={'Cadastro de Abastecimento'}>
                <FormFuelView updateList={handleUpdateList} uuid={uuidv4()} closeForm={CancelFormFuel} />
            </Sidebar>

        </>
    )

}
export default DataFuelPage