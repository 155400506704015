



import React from 'react';
import { GoogleMapProvider } from './GoogleMaps/GoogleMapProvider';
import AlertComp from './components/AlertComp';
import AppBarComp from './components/AppBarComp';
import LayoutWithDrawer from './LayoutWithDrawer';
import PanelRightComp from './components/PanelsRight/PanelRightComp';
import RoutesComp from './routes';
import { AuthConfig } from './Config/AuthContext';
import { StorageProvider } from './Config/StorageContext';



const AppMain: React.FC = () => {

    const { isLogged, loading } = AuthConfig();


    return (
        <>
            {!loading && isLogged ?
                <StorageProvider>
                    <AlertComp />
                    <GoogleMapProvider>
                        <AppBarComp />
                        <LayoutWithDrawer />
                        <PanelRightComp />
                    </GoogleMapProvider>
                </StorageProvider> :
                <RoutesComp />
            }
        </>
    )

}
export default AppMain