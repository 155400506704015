


import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { ctrlButtons, iMsgAlert } from '../../../interfaces/interfaces';
import WidgetDataGridComp from '../../../widgets/WidgetDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { getListDrivers } from '../Gateways/DriversGateway';
import { changeAlert } from '../../../redux/alertServiceSlice';
import { ContainerTable } from '../../../styles/Styles';
import { Delete, Edit, FileOpen, FolderOpen } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface CompProps {
    openDetails: (item: itemDriver) => void
    cadEditDriver: (item: itemDriver) => void
    cadNewDriver: () => void
}

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    addShow: true
}

const ViewListDrivers: React.FC<CompProps> = ({ openDetails, cadNewDriver, cadEditDriver }) => {

    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [listDatagrid, setListDatagrid] = React.useState<itemDriver[]>([]);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);


    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }

    async function getDados() {
        try {
            setIsLoading(true)
            const result = await getListDrivers({ idclient: user.idcliente });
            setListDatagrid(result)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[ListDrivers]', error)
        }
    }

    const handleRowClick = (item: itemDriver) => {
        openDetails(item)
    };
    const handleAddOnClick = () => {
        cadNewDriver()
    };

    React.useEffect(() => {
        getDados()
    }, [])

    const columns: GridColDef<itemDriver>[] = [
        {
            field: 'actions', headerName: 'Eventos', width: 80,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="primary" aria-label="show Maps" onClick={e => handleRowClick(params.row)}>
                            <FolderOpen />
                        </IconButton>
                        <IconButton color="primary" aria-label="show Maps" onClick={e => cadEditDriver(params.row)}>
                            <Edit />
                        </IconButton>
                    </>
                );
            }


        },
        {
            field: 'id',
            headerName: 'Cód',
            width: 120
        },
        {
            field: 'DriverName',
            headerName: 'Nome',
            width: 220
        },
        {
            field: 'DriverCard',
            headerName: 'Ibutton/Card',
            width: 140
        },
        {
            field: 'profileDescr',
            headerName: 'Jornada',
            width: 120
        },
        {
            field: 'email',
            headerName: 'E-mail',
            width: 200
        },
        {
            field: 'tel',
            headerName: 'Telefone',
            width: 180
        },
    ];



    return (
        <>
            <ContainerTable>
                {<WidgetDataGridComp
                    isLoading={isLoading}
                    rows={listDatagrid}
                    columns={columns}
                    CheckBoxSelect={true}
                    initialBtns={CtrlBtns}
                    onRefresClick={() => getDados()}
                    onAddClick={handleAddOnClick}
                />}
            </ContainerTable>
        </>
    )

}
export default ViewListDrivers

export type itemDriver = {
    DriverAvatar: string
    DriverCard: string
    DriverName: string
    SelCar: number
    category: string
    cnhValidate: string
    cpf: string
    dtCad: string
    dtniver: string
    email: string
    ender: string
    id: number
    idperfil: number
    isJornada: number
    profileDescr: string
    numberCNH: string
    tel: string
    veiculoId: number
}