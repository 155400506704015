import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

interface StatusChipProps {
    status: 'OFFLINE' | 'ONLINE' | 'ESTOQUE';
}

const getColor = (status: string) => {
    switch (status.toUpperCase()) {
        case 'ESTOQUE':
            return '#FFA500'; // orange
        case 'ONLINE':
            return '#008000'; // green
        case 'CANCELADO':
            return '#FF0000'; // red
        case 'EM ANÁLISE':
            return '#0000FF'; // blue
        case 'OFFLINE':
            return '#A52A2A'; // brown
        case 'PAGO':
            return '#800080'; // purple
        default:
            return '#808080'; // grey
    }
};

const StyledChip = styled(Chip)<{ status: string }>(({ status }) => ({
    backgroundColor: getColor(status) + 30, // 50% opacity
    color: 'black',
    border: `1px solid ${getColor(status)}`,
    height: '20px',
    fontWeight: '500',
}));

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
    return (
        <>
            {status ? <StyledChip label={status} variant="outlined" status={status} /> : <></>
            }</>
    );
};

export default StatusChip;
