import { Column, SelectColumn } from "react-data-grid";
import { BtnCheckBox } from "../../Messages/Style/StyleMessages";
import StatusChip from "../../Chips/Components/ChipStatus";
import { iFuelInvoices } from "../repository/FuelRepo";
import { iFuelInvoicesScreen } from "../models/FuelListModels";


// Função para gerar as colunas com as ações recebidas do componente pai
export const getColumns = (
    viewInMap: (row: iFuelInvoicesScreen) => void,
    editItem: (row: iFuelInvoicesScreen) => void
): Column<iFuelInvoices>[] => {
    return [{
        ...SelectColumn,
        name: 'Selecionar'
    },

    {
        key: 'id',
        name: 'Cód',
        width: 65
    },
    {
        key: 'placa',
        name: 'Veículo',
        width: 120,
    },

    {
        key: 'edit',
        name: 'Ações',
        width: 90,
        renderCell(props: any) {
            return (
                <>{/*<BtnCheckBox onClick={() => viewInMap(props.row)}>📂 Abrir</BtnCheckBox>*/}
                    <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox></>
            )
        }
    },
    {
        key: 'status',
        name: 'Status',
        width: 110,
        renderCell: (props: any) => (
            <StatusChip status={props.row.status} />
        )
    },
    {
        key: 'amount',
        name: 'Valor',
        width: 90,
        renderCell(props: any) {
            return (
                new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.row.amount as number
                ))
        }
    },
    {
        key: 'price_unity',
        name: 'Preço/Litro',
        width: 90,
        renderCell(props: any) {
            return (
                new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.row.price_unity as number
                ))
        }
    },
    {
        key: 'qty',
        name: 'Litros',
        width: 70
    },
    {
        key: 'metrics',
        name: 'Métricas',
        width: 250
    }, {
        key: 'cost_km',
        name: 'Custo',
        width: 90
    },
    {
        key: 'avg_fuel',
        name: 'Média',
        width: 90
    },
    {
        key: 'drivername',
        name: 'Motorista',
        width: 150,
    },

    {
        key: 'evt_at',
        name: 'Data',
        width: 180,
        renderCell(props: any) {
            return new Date(props.row.evt_at).toLocaleString()
        },
    },
    {
        key: 'supplier_name',
        name: 'Fornecedor',
        width: 180
    },
    {
        key: 'descr',
        name: 'Descrição',
        width: 350,
        renderCell(props: any) {
            return props.row.descr.replace(/<[^>]*>?/gm, '');
        }
    },


    ];
};